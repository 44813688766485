import { useMemo, useCallback, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Column } from 'react-table';
import { Button, Dropdown, Menu, message, Modal } from 'antd';

import { StopPropagation } from '@core/ui';
import { CheckCell, tableColumns } from '@core/table';
import * as Icons from '@ant-design/icons';
import { useBus } from '@core/event-bus';
import { useService } from '@core/inversify-react';

import { branchQueryKeys } from '../../utils';
import { IBranch } from '../../interfaces';
import { IRemoveBranchesRepo, RemoveBranchesRepoType } from '../../repos';

export const useBranchesTableColumns = () => {
  const history = useHistory();
  const location = useLocation();
  const { publish } = useBus();
  const removeBranchesRepo = useService<IRemoveBranchesRepo>(RemoveBranchesRepoType);

  const actionsColumn = useMemo<Column<IBranch>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openEdit = useCallback(() => {
          history.push(generatePath('/@next/branches/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const openDetails = useCallback(() => {
          history.push(generatePath('/@next/branches/:id/details', { id: original.id }));
        }, [original.id]);

        const remove = useCallback(() => {
          const onOk = async () => {
            const result = await removeBranchesRepo.execute([original.id]);
            if (result.status === 200) {
              publish({ type: '@branches/remove/succeed', payload: [original.id] });
            } else {
              message.error(result.response);
            }
          };
          Modal.confirm({ title: 'Filialı silməyə əminsinizmi?', content: 'Silməni təsdiqlədikdən sonra filialların geri qaytarılması mümkün olmayacaq.', onOk });
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.Item onClick={openDetails} icon={<Icons.InfoCircleFilled />}>
              Ətraflı
            </Menu.Item>
            <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
              Düzəliş et
            </Menu.Item>
            <Menu.Item onClick={remove} icon={<Icons.DeleteOutlined />}>
              Sil
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [history, location, publish, removeBranchesRepo],
  );
  return useMemo<Column<IBranch>[]>(
    () => [
      actionsColumn,
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: branchQueryKeys.id,
        Header: 'Kod',
      },
      {
        accessor: (row) => row.name,
        id: branchQueryKeys.name,
        Header: 'Ad',
      },
      {
        accessor: (row) => row.workingHours,
        id: branchQueryKeys.workingHours,
        Header: 'İş saatları',
      },
      {
        accessor: (row) => row.isBranch,
        id: branchQueryKeys.isBranch,
        Header: 'Filial',
        Cell: CheckCell,
      },
    ],
    [actionsColumn],
  );
};
