import React, { useCallback, useMemo, useState } from 'react';
import { Card, DatePicker, Spin, Table } from 'antd';
import moment, { Moment } from 'moment';
import { urlFactory } from '../../../core/utils/caller';
import { useInfo } from '../../../hooks/common/useInfo';
import { CommonConstants } from '../../../constants/common';
import { NavLink } from 'react-router-dom';
import { makePath } from '../../../core/utils/makePath';
import routes from '../../../constants/routes';

const { RangePicker } = DatePicker;

const SpecialBalanceStatistics = () => {
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment());
  const date = useMemo<[Moment, Moment]>(() => [startDate, endDate], [startDate, endDate]);
  const useInfoOptions = useMemo(
    () => ({
      apiUrl: urlFactory('/api/admin/stats/balance', { start_date: startDate.format(CommonConstants.DATE), end_date: endDate.format(CommonConstants.DATE) }),
      responseGetter: (response) => response.data,
    }),
    [startDate, endDate],
  );
  const { data: rawData, loading } = useInfo(useInfoOptions);

  const data = useMemo(() => {
    return rawData
      ? [
          {
            key: 1,
            paymentTypeId: 1,
            paymentTypeName: 'Nağd',
            amountUSD: rawData.USD['1'],
            amountTRY: rawData.TRY['1'],
          },
          {
            key: 2,
            paymentTypeId: 2,
            paymentTypeName: 'Bank',
            amountUSD: rawData.USD['2'],
            amountTRY: rawData.TRY['2'],
          },
          {
            key: 3,
            paymentTypeId: 3,
            paymentTypeName: 'Terminal',
            amountUSD: rawData.USD['3'],
            amountTRY: rawData.TRY['3'],
          },
          {
            key: 4,
            paymentTypeId: 4,
            paymentTypeName: 'Online',
            amountUSD: rawData.USD['4'],
            amountTRY: rawData.TRY['4'],
          },
        ]
      : [];
  }, [rawData]);

  const handleChangeDate = useCallback((value) => {
    setStartDate((value || [])[0] || moment().startOf('month'));
    setEndDate((value || [])[1] || moment());
  }, []);

  const columns = useMemo(
    () => [
      { dataIndex: 'paymentTypeName', key: 'paymentTypeName', title: 'Ödənişin növü' },
      {
        dataIndex: 'amountUSD',
        key: 'amountUSD',
        title: 'Məbləğ (USD)',
        render: (value, row) => {
          return (
            <NavLink
              to={makePath(
                routes.dashboard.special.balance.details,
                {},
                {
                  start_date: startDate.format(CommonConstants.DATE),
                  end_date: endDate.format(CommonConstants.DATE),
                  payment_type: row.paymentTypeId,
                  currency: 'USD',
                },
              )}
            >
              {value}
            </NavLink>
          );
        },
      },
      {
        dataIndex: 'amountTRY',
        key: 'amountTRY',
        title: 'Məbləğ (TRY)',
        render: (value, row) => {
          return (
            <NavLink
              to={makePath(
                routes.dashboard.special.balance.details,
                {},
                {
                  start_date: startDate.format(CommonConstants.DATE),
                  end_date: endDate.format(CommonConstants.DATE),
                  payment_type: row.paymentTypeId,
                  currency: 'TRY',
                },
              )}
            >
              {value}
            </NavLink>
          );
        },
      },
    ],
    [startDate, endDate],
  );

  return (
    <Card className='dashboard-special-card' size='small' title='Balans'>
      <Spin spinning={loading}>
        <RangePicker value={date} onChange={handleChangeDate} />
        <Table className='dashboard-special-card-table' pagination={false} bordered={true} dataSource={data} columns={columns} />
      </Spin>
    </Card>
  );
};

export default SpecialBalanceStatistics;
