import React, { FC, MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import { Form, Select } from 'antd';
import { FormItemProps } from 'antd/lib/form';
import { SelectProps } from 'antd/lib/select';
import { caller } from '../../core/utils/caller';

export type ISelectFieldProps = WrappedFieldProps & {
  item: FormItemProps;
  field: SelectProps<any>;
  apiUrl?: string;
  valueGetter: Function;
  labelGetter: Function;
  responseGetter: Function;
  customRef: MutableRefObject<any>;
};

const BaseSelectField: FC<ISelectFieldProps> = ({ input, item, field, apiUrl, customRef, valueGetter, labelGetter, responseGetter, meta: { touched, error, warning } }) => {
  const errorText = useMemo(() => touched && error, [touched, error]);
  const warningText = useMemo(() => touched && warning, [touched, warning]);
  const validateStatus = useMemo(() => {
    if (errorText) {
      return 'error';
    } else if (warningText) {
      return 'warning';
    }
  }, [errorText, warningText]);

  useEffect(() => {
    if (customRef) {
      customRef.current = { reFetch: () => handleGetOptions().then(setOptions) };
    }
  });

  const [options, setOptions] = useState<{ value: string | number; label: string | number }[]>([]);

  const handleGetOptions = useCallback(async () => {
    if (apiUrl) {
      const response = await caller(apiUrl);

      if (response.ok) {
        const data = await response.json();

        const options = responseGetter(data);
        return options.map((option: any) => ({
          value: valueGetter(option),
          label: labelGetter(option),
        }));
      }
    }

    return [];
  }, [apiUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleGetOptions().then(setOptions);
  }, [handleGetOptions]);

  const filterOption = useCallback((input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0, []);

  const newInput = useMemo(
    () => ({
      ...input,
      value: typeof input.value === 'number' ? input.value : input.value.length ? input.value : undefined,
      onBlur: () => input.onBlur(input.value),
    }),
    [input],
  );

  return (
    <Form.Item validateStatus={validateStatus} help={errorText || warningText} {...item}>
      <Select showSearch={true} filterOption={filterOption} allowClear={true} {...newInput} {...field}>
        {options.map(({ value, label }) => {
          return (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

BaseSelectField.defaultProps = {
  valueGetter: (object: any) => object.value,
  labelGetter: (object: any) => object.label,
  responseGetter: (object: any) => object,
};

export const SelectField = React.memo<ISelectFieldProps>(BaseSelectField);

export const StaticSelectField = React.memo<WrappedFieldProps & { item: FormItemProps; field: SelectProps<any>; options: { value: string | number; label: string | number; disabled?: boolean }[] }>(
  ({ input, item, options, field, meta: { touched, error, warning } }) => {
    const errorText = useMemo(() => touched && error, [touched, error]);
    const warningText = useMemo(() => touched && warning, [touched, warning]);
    const validateStatus = useMemo(() => {
      if (errorText) {
        return 'error';
      } else if (warningText) {
        return 'warning';
      }
    }, [errorText, warningText]);

    const handleBlur = useCallback(() => {
      input.onBlur(input.value);
    }, [input]);

    const newValue = useMemo(() => (typeof input.value === 'number' ? input.value : input.value.length ? input.value : undefined), [input.value]);

    return (
      <Form.Item {...item} validateStatus={validateStatus} help={errorText || warningText}>
        <Select {...input} value={newValue} onBlur={handleBlur} {...field}>
          {options.map((option) => (
            <Select.Option key={option.value} value={option.value} disabled={option.disabled}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  },
);
