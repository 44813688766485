import { Dispatch } from 'redux';
import { message } from 'antd';
import { tableFetchDataAction } from './table';
import { caller, urlFactory } from '../core/utils/caller';
import { formDataBuilder } from '../core/utils/form-data-builder';
import { UnexpectedError } from '../core/helpers/errors';

export const removeAction = (objectId: (number | string)[]) => async (dispatch: Dispatch) => {
  const messageKey = 'popups-remove-message';
  const values = { popup_id: objectId };

  const onSuccess = (success) => {
    message.success({ content: 'Popup(lar) silindi', key: messageKey });
    dispatch(tableFetchDataAction('popups', '/api/admin/popups'));
    return success;
  };

  const onError = (error) => {
    message.error({ content: 'Popup(lar) silinə bilmədi', key: messageKey });
    return error;
  };

  message.loading({ content: 'Əməliyyat aparılır', key: messageKey });
  try {
    const url = urlFactory('/api/admin/popups/cancel');
    const body = formDataBuilder({ values });

    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      return onSuccess(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};
