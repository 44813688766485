import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';

import { FlightsBulkManifestPage } from './pages/bulk-manifest';
import { CloseFlightPage } from './pages/close';

const FlightsModule = () => {
  return (
    <Fragment>
      <Route path='/flights/:flightId/bulk_manifest' component={FlightsBulkManifestPage} />
      <Route path='/flights/:id/close/:type' component={CloseFlightPage} />
    </Fragment>
  );
};

export default FlightsModule;
