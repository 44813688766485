import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SettingsPage } from '../pages';

export const SettingsRouter = () => {
  return (
    <Switch>
      <Route path='/settings' component={SettingsPage} />
    </Switch>
  );
};
