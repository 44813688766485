import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IUpdateDeclarationsStatusRepoResponse = IRepoResponse<200> | IRepoResponse<400 | 500, string>;

export type IUpdateDeclarationsStatusRepo = IRepo<[{ declarationIds: (string | number)[]; statusId: string | number; description?: string }], IUpdateDeclarationsStatusRepoResponse>;

export const UpdateDeclarationsStatusRepoType = Symbol.for('UpdateDeclarationsStatusRepo');

@bind(UpdateDeclarationsStatusRepoType)
export class UpdateDeclarationsStatusRepo implements IUpdateDeclarationsStatusRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ declarationIds, statusId, description }) {
    try {
      const result = await this.httpClient.post({ url: urlMaker('/api/admin/declaration/edit/state', { declaration_id: declarationIds, state_id: statusId, descr: description }) });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
