import React from 'react';
import ContentCard from '../../containers/Layout/Sidebar/Content/Card';
import TicketTemplateActions from '../../containers/TicketTemplates/Actions';
import TicketTemplatesTable from '../../containers/TicketTemplates/Table';

const TicketTemplates = () => {
  return (
    <ContentCard>
      <TicketTemplateActions />
      <TicketTemplatesTable />
    </ContentCard>
  );
};

export default TicketTemplates;
