import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetPartnersRepoType, IGetPartnersRepo } from '../repos';
import { IPartner } from '../interfaces';

export const usePartners = (options?: UseQueryOptions<IPartner[], Error, IPartner[], ['partners']>) => {
  const getStatusByModelIdRepo = useService<IGetPartnersRepo>(GetPartnersRepoType);

  return useQuery<IPartner[], Error, IPartner[], ['partners']>(
    ['partners'],
    async () => {
      const result = await getStatusByModelIdRepo.execute();

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};
