import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';
import { GetCurrencyRateRepoType, IGetCurrencyRateRepo } from '../repos';

export const useGetCurrencyRateQuery = (currency: string) => {
  const getCurrencyRate = useService<IGetCurrencyRateRepo>(GetCurrencyRateRepoType);

  return useQuery<number, Error, number, string[]>(
    ['currency-rates', currency],
    async ({ queryKey }) => {
      const [, currency] = queryKey;

      const result = await getCurrencyRate.execute({ currency });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { initialData: 0 },
  );
};
