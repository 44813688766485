import { useMemo } from 'react';
import { InputField } from '../../components/ReduxForm/Input';

export const useConfigShopsFields = () => {
  const nameFieldProps = useMemo(
    () => ({
      name: 'name',
      item: { label: 'Mağazanın adı', style: { marginBottom: 0 } },
      field: { placeholder: 'Mağazanın adını daxil edin...' },
      component: InputField,
    }),
    [],
  );

  return { nameFieldProps };
};
