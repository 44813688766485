import React, { FC, useContext, useEffect } from 'react';
import { Formik, FormikProps } from 'formik';
import { message, Modal } from 'antd';
import { AntForm } from '@core/ui';
import { TextAreaField } from '@core/form';

import { useDeclarationsStuckAtCustoms } from '../hooks';
import { DeclarationsStuckAtCustomsDto } from '../dto';
import { DeclarationsStuckAtCustomsContext } from '../context';

const FormikComponents: FC<FormikProps<DeclarationsStuckAtCustomsDto>> = ({ handleSubmit, isSubmitting }) => {
  const { ids, filters, onCancel } = useContext(DeclarationsStuckAtCustomsContext);

  useEffect(() => {
    (async () => {
      if (!ids && !filters) {
        onCancel();
        message.warning('Bağlama seçilməyib');
      }
    })();
  }, [filters, ids, onCancel]);

  if (!ids && !filters) {
    return null;
  }

  return (
    <Modal width={576} visible={true} onCancel={onCancel} onOk={() => handleSubmit()} confirmLoading={isSubmitting} title='Baxlamalar gömrükdə saxlanılıb'>
      <AntForm layout='vertical'>
        <TextAreaField name='description' item={{ label: 'Gömrükdə saxlanılma səbəbi' }} input={{ placeholder: 'Bağlamaların gömrükdə saxlanılma səbəbini qeyd edin...' }} />
      </AntForm>
    </Modal>
  );
};

export const DeclarationsStuckAtCustoms = () => {
  const { initialState, onSubmit } = useDeclarationsStuckAtCustoms();

  return <Formik initialValues={initialState} onSubmit={onSubmit} component={FormikComponents} />;
};
