import React, { FC, useCallback } from 'react';

import ContentCard from '../containers/Layout/Sidebar/Content/Card';
import Table from '../containers/DnsQueues/Table';
import { useTitle } from '../hooks/common/useTitle';
import { Button, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { tableFetchDataAction } from '../actions/table';
import { useDispatch } from 'react-redux';

const DnsQueuesPage: FC = () => {
  const dispatch = useDispatch();
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - DGK növbələri`);

  const fetchTableData = useCallback(() => {
    dispatch(tableFetchDataAction('dnsQueues', '/api/admin/customs/queues'));
  }, [dispatch]);

  return (
    <ContentCard>
      <div className='actions-wrapper'>
        <div className='actions-wrapper-left'>
          <Tooltip title='Yenilə' mouseLeaveDelay={0}>
            <Button icon={<ReloadOutlined />} onClick={fetchTableData}>
              Yenilə
            </Button>
          </Tooltip>
        </div>
      </div>
      <Table />
    </ContentCard>
  );
};

export default React.memo(DnsQueuesPage);
