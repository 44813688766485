import React, { FC } from 'react';

import ContentCard from '../containers/Layout/Sidebar/Content/Card';
import Table from '../containers/Plans/Table';
import Actions from '../containers/Plans/Actions';
import { useTitle } from '../hooks/common/useTitle';

const PlansPage: FC = () => {
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - Tariflər`);

  return (
    <ContentCard>
      <Actions />
      <Table />
    </ContentCard>
  );
};

export default React.memo(PlansPage);
