import React, { FC } from 'react';
import classNames from 'classnames';

const TableRow: FC<any> = ({ prepareRow, row, getRowProps }) => {
  prepareRow(row);
  const toggleRowSelectedProps = row.getToggleRowSelectedProps();
  const handleClick = () => {
    toggleRowSelectedProps.onChange({
      target: { checked: !toggleRowSelectedProps.checked },
    });
  };
  const extraProps = getRowProps ? getRowProps(row.id, row.original) : {};
  return (
    <div
      {...row.getRowProps({
        className: classNames('next-table-tr', {
          'next-table-tr-selected': toggleRowSelectedProps.checked,
        }),
        onClick: handleClick,
        ...extraProps,
      })}
    >
      {row.cells.map((cell: any) => {
        return (
          <div
            {...cell.getCellProps({
              className: classNames('next-table-td', cell.column.className),
            })}
          >
            {cell.render('Cell')}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo<any>(TableRow);
