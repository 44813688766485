import { Select } from 'antd';

import { paymentTypes } from '../utils';

export const TransactionsTablePaymentTypeFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <Select style={{ width: '100%' }} value={filterValue} onChange={setFilter} allowClear={true}>
      {paymentTypes.map((type) => (
        <Select.Option key={type.id} value={type.id.toString()}>
          {type.name}
        </Select.Option>
      ))}
    </Select>
  );
};
