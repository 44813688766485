import { useMemo } from 'react';
import { TextAreaField } from '../../components/ReduxForm/TextArea';

export const useFormFields = () => {
  const messageFieldProps = useMemo(
    () => ({
      name: 'message',
      item: { label: 'Mətn', style: { marginBottom: 0 } },
      field: { placeholder: 'Mətni daxil edin...', rows: 5 },
      component: TextAreaField,
    }),
    [],
  );

  return { messageFieldProps };
};
