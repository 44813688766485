import React, { useCallback, useMemo } from 'react';
import { actionsCol, dateCol, idCol } from '../../core/helpers/table';
import { OverflowCell } from '../../components/Table/core/Components/Cell/OverflowCell';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';
import { Button, Dropdown, Menu, message, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { tableFetchDataAction } from '../../actions/table';
import { deleteConfigReturnTypes } from '../../actions/configReturnTypes';

export const useConfigReturnTypesTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        ...actionsCol,
        Cell: ({ row: { original } }) => {
          const gotoEdit = useCallback(() => {
            history.push(makePath(routes.config.returnTypes.edit, { id: original.id }));
          }, [original.id]);

          const handleDelete = useCallback(async () => {
            const action = async () => {
              message.loading({ content: 'İadə növü silinir...', key: 'config-shops-remove-message' });
              const result = await deleteConfigReturnTypes([original.id]);
              if (result) {
                message.success({ content: 'İadə növü silindi...', key: 'config-shops-remove-message' });
                dispatch(tableFetchDataAction('configReturnTypes', '/api/admin/return_reasons'));
              } else {
                message.destroy();
              }
            };

            Modal.confirm({
              title: 'Təsdiqlə',
              content: `İadə növünü silməyə əminsinizmi?`,
              onOk: action,
            });
          }, [original.id]);

          return (
            <span onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item onClick={gotoEdit} icon={<EditOutlined />}>
                      Dəyişdir
                    </Menu.Item>
                    <Menu.Item onClick={handleDelete} icon={<DeleteOutlined />}>
                      Sil
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button className='next-table-action-btn' type='text' icon={<MoreOutlined />} />
              </Dropdown>
            </span>
          );
        },
      },
      {
        ...idCol,
      },
      {
        accessor: 'reason',
        Header: 'İadə növü',
        Cell: OverflowCell,
      },
      {
        accessor: 'created_at',
        Header: 'Yaradılıb',
        ...dateCol,
      },
    ],
    [history, dispatch],
  );

  return { columns };
};
