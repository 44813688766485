import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IHandoverDeclarationsDetails } from '../interfaces';
import { HandoverDeclarationDetailsMapper } from '../mappers';

export type IGetHandoverDeclarationsDetailsRepoResponse = IRepoResponse<200, IHandoverDeclarationsDetails> | IRepoResponse<400 | 500, string>;
export type IGetHandoverDeclarationsDetailsRepoInput = { ids: (number | string)[]; body?: { small_package: string; medium_package: string; big_package: string } };

export type IGetHandoverDeclarationsDetailsRepo = IRepo<[IGetHandoverDeclarationsDetailsRepoInput], IGetHandoverDeclarationsDetailsRepoResponse>;

export const GetHandoverDeclarationsDetailsRepoType = Symbol.for('GetHandoverDeclarationsDetailsRepo');

@bind(GetHandoverDeclarationsDetailsRepoType)
export class GetHandoverDeclarationsDetailsRepo implements IGetHandoverDeclarationsDetailsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ ids, body }) {
    try {
      const formData = new FormData();
      if (body) appendToFormData(body, formData);
      const result = await this.httpClient.post({ url: urlMaker('/api/admin/declaration/pay', { declaration_id: ids }), body: formData });

      if (result.status === 200) {
        const data = HandoverDeclarationDetailsMapper.toDomain(result.data.data);

        return {
          status: 200 as 200,
          response: data,
        };
      } else if (result.status === 422 || result.status === 400) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join(', '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
