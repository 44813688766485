import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Modal } from 'antd';

import { useClose } from '../../hooks/common';
import routes from '../../constants/routes';
import DeclarationsTable from '../../next/modules/declarations/containers/Table';

const FlightsInfo: FC<RouteComponentProps<any>> = ({ match: { params } }) => {
  const handleClose = useClose(routes.flights.index);

  return (
    <Modal maskTransitionName='' transitionName='' width={992} visible={true} onCancel={handleClose} footer={null} closable={false} title='Flight-dakı bağlamalar'>
      <DeclarationsTable flightId={params.id} />
    </Modal>
  );
};

export default React.memo(FlightsInfo);
