import React, { FC } from 'react';

const TRY: FC<any> = ({ cell: { value } }) => <span>{value || 0} &#8378;</span>;

const USD: FC<any> = ({ cell: { value } }) => <span>{value || 0} &#36;</span>;

const AZN: FC<any> = ({ cell: { value } }) => <span>{value || 0} &#8380;</span>;

const Auto: FC<any> = ({ cell: { value }, row: { original } }) => {
  switch (original.currency) {
    case 'TRY':
      return <span>{value} &#8378;</span>;
    case 'USD':
      return <span>{value} &#36;</span>;
    case 'AZN':
      return <span>{value} &#8380;</span>;
    default:
      return null;
  }
};

const CurrencyCells = { TRY: React.memo(TRY), USD: React.memo(USD), AZN: React.memo(AZN), Auto: React.memo(Auto) };

export default CurrencyCells;
