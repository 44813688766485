import { message } from 'antd';
import { tableFetchDataAction } from './table';
import { caller, urlFactory } from '../core/utils/caller';
import { formDataBuilder } from '../core/utils/form-data-builder';
import { UnexpectedError } from '../core/helpers/errors';

export const fetchUsers = () => (dispatch) => {
  dispatch(tableFetchDataAction('users', '/api/admin/v2/client/list'));
};

export const blockAction = (id: number[], block: boolean) => async (dispatch) => {
  const messageKey = 'users-block-message';
  const values = { user_id: id };

  const onSuccess = (success) => {
    const messageContent = block ? 'İstifadəçi(lər) bloklandı.' : 'İstifadəçi(lər) blokdan çıxarıldı.';

    message.success({ key: messageKey, content: messageContent });
    dispatch(fetchUsers());

    return success;
  };

  const onError = (error) => {
    const messageContent = block ? 'İstifadəçi(lər) bloklana bilmədi.' : 'İstifadəçi(lər) blokdan çıxarıla bilmədi.';

    message.error({ key: messageKey, content: messageContent });

    return error;
  };

  try {
    message.loading({ key: messageKey, content: 'Əməliyyat aparılır.' });
    const url = urlFactory(block ? '/api/admin/client/block' : '/api/admin/client/unblock');
    const body = formDataBuilder({ values });
    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      return onSuccess(response);
    } else {
      UnexpectedError();
    }
  } catch (error) {
    throw onError(error);
  }
};

export const deleteAction = (id: number[]) => async (dispatch) => {
  const messageKey = 'users-remove-message';
  const values = { user_id: id };

  const onSuccess = (success) => {
    message.success({ key: messageKey, content: 'İstifadəçi(lər) silindi.' });
    dispatch(fetchUsers());

    return success;
  };

  const onError = (error) => {
    message.error({ key: messageKey, content: 'İstifadəçi(lər) silinə bilmədi.' });

    return error;
  };

  try {
    message.loading({ key: messageKey, content: 'Əməliyyat aparılır.' });
    const url = urlFactory('/api/admin/client/delete');
    const body = formDataBuilder({ values });
    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      return onSuccess(response);
    } else {
      UnexpectedError();
    }
  } catch (error) {
    throw onError(error);
  }
};

export const changePermissionAction = (id: number[], permission: 0 | 1 | 2 | 3) => async (dispatch) => {
  const messageKey = 'users-change-permission-message';
  const values = { user_id: id, admin: permission };

  const onSuccess = (success) => {
    message.success({ key: messageKey, content: 'Səlahiyyət(lər) dəyişdirildi.' });
    dispatch(fetchUsers());

    return success;
  };

  const onError = (error) => {
    message.error({ key: messageKey, content: 'Səlahiyyət(lər) dəyişdirilə bilmədi.' });

    return error;
  };

  try {
    message.loading({ key: messageKey, content: 'Əməliyyat aparılır.' });
    const url = urlFactory('/api/admin/client/set_admin');
    const body = formDataBuilder({ values });
    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      return onSuccess(response);
    } else {
      UnexpectedError();
    }
  } catch (error) {
    throw onError(error);
  }
};
