import React, { useCallback, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { ClearOutlined, CloseCircleOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons/lib';
import { useDispatch, useSelector } from 'react-redux';
import { tableSelector } from '../../selectors/table';
import { tableFetchDataAction, tableResetAction, tableSetSelectedRowIdsAction } from '../../actions/table';
import { removeAction } from '../../actions/conflictedDeclarations';

const ConflictedDeclarationsActions = () => {
  const dispatch = useDispatch();
  const table = useSelector(tableSelector('conflictedDeclarations'));

  const selection = useMemo(() => {
    return Object.keys(table.selectedRowIds);
  }, [table.selectedRowIds]);

  const clearSelection = useCallback(() => {
    dispatch(tableSetSelectedRowIdsAction('conflictedDeclarations', {}));
  }, [dispatch]);

  const resetTable = useCallback(() => {
    dispatch(tableResetAction('conflictedDeclarations'));
  }, [dispatch]);

  const fetchTableData = useCallback(() => {
    dispatch(tableFetchDataAction('conflictedDeclarations', '/api/admin/v2/conflicted_declaration/list'));
  }, [dispatch]);

  const handleDelete = useCallback(() => {
    const action = () => removeAction(selection)(dispatch).catch(() => null);

    Modal.confirm({
      title: 'Təsdiqlə',
      content: `${selection.length} ədəd mübahisəli bağlama seçilib. Silməyə əminsinizmi?`,
      onOk: action,
    });
  }, [selection, dispatch]);

  return (
    <div className='actions-wrapper'>
      <div className='actions-wrapper-left'>
        <Button className='' icon={<ClearOutlined />} onClick={resetTable}>
          Sıfırla
        </Button>
        <Button className='actions-wrapper-additional-button' icon={<ReloadOutlined />} onClick={fetchTableData}>
          Yenilə
        </Button>
        {!!selection.length && (
          <Button className='actions-wrapper-additional-button actions-wrapper-selection-button' icon={<CloseCircleOutlined />} onClick={clearSelection}>
            {selection.length} sətir seçilib
          </Button>
        )}
      </div>
      <div className='actions-wrapper-right'>
        <Button className='actions-wrapper-additional-button' ghost={true} disabled={!selection.length} type='primary' danger={true} onClick={handleDelete} icon={<DeleteOutlined />}>
          Sil
        </Button>
      </div>
    </div>
  );
};

export default ConflictedDeclarationsActions;
