import React, { useMemo } from 'react';
import { SelectField } from '../../../../components/ReduxForm/Select';
import { urlFactory } from '../../../../core/utils/caller';
import { InputField } from '../../../../components/ReduxForm/Input';
import { positiveFloatNormalizer, positiveIntNormalizer } from '../../../../core/helpers/normalizers';
import { CheckboxField } from '../../../../components/ReduxForm/Checkbox';
import { TextAreaField } from '../../../../components/ReduxForm/TextArea';
import { UploadField } from '../../../../components/ReduxForm/Upload';
import { Button, Tooltip } from 'antd';
import { LinkOutlined } from '@ant-design/icons/lib';
import { useSelector } from 'react-redux';
import { formFieldValueSelector } from '../../../../selectors/form';
import { usePermissions } from '../../../../hooks/permissions/usePermissions';

export const useDeclarationFormFields = () => {
  const { can } = usePermissions();
  const uploadedFile = useSelector(formFieldValueSelector('conflictedDeclarations', 'uploaded_file'));

  const userFieldProps = useMemo(
    () => ({
      name: 'user_id',
      item: { label: 'Müştəri' },
      field: { placeholder: 'Müştəri seçin' },
      type: 'select',
      component: SelectField,
      apiUrl: urlFactory('/api/admin/client/list'),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => `${object.id} - ${object.name} ${object.surname}`,
      responseGetter: (object: any) => object.data,
    }),
    [],
  );

  const productTypesFieldProps = useMemo(
    () => ({
      name: 'product_type_id',
      item: { label: 'Məhuslun tipi' },
      field: { placeholder: 'Məhsulun tipini seçin' },
      type: 'select',
      component: SelectField,
      apiUrl: urlFactory('/api/admin/product-types/getlist'),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => object.name,
      responseGetter: (object: any) => object.data,
    }),
    [],
  );

  const globalTrackCode = useMemo(
    () => ({
      name: 'global_track_code',
      item: { label: 'Qlobal izləmə kodu' },
      field: { placeholder: 'Qlobal izləmə kodunu daxil edin' },
      component: InputField,
    }),
    [],
  );

  const shopNameFieldProps = useMemo(
    () => ({
      name: 'shop_name',
      item: { label: 'Mağaza' },
      field: { placeholder: 'Mağazanın adını daxil edin' },
      component: InputField,
    }),
    [],
  );

  const weightFieldProps = useMemo(
    () => ({
      name: 'weight',
      item: { label: 'Çəki' },
      field: {
        placeholder: 'Çəki daxil edin',
        addonAfter: 'KQ',
        disabled: !can('changeweightdeclaration'),
      },
      normalize: positiveFloatNormalizer,
      component: InputField,
    }),
    [can],
  );

  const quantityFieldProps = useMemo(
    () => ({
      name: 'quantity',
      item: { label: 'Say' },
      field: { placeholder: 'Say daxil edin' },
      normalize: positiveIntNormalizer,
      component: InputField,
    }),
    [],
  );

  const priceFieldProps = useMemo(
    () => ({
      name: 'price',
      item: { label: 'Qiymət' },
      field: { placeholder: 'Qiymət daxil edin', addonAfter: <>&#8378;</> },
      normalize: positiveFloatNormalizer,
      component: InputField,
    }),
    [],
  );

  const deliveryPriceFieldProps = useMemo(
    () => ({
      name: 'delivery_price',
      item: { label: 'Çatdırılma qiyməti' },
      field: {
        placeholder: 'Çatdırılma qiymətini daxil edin',
        addonAfter: <>&#36;</>,
        disabled: !can('changedeliveryprice'),
      },
      normalize: positiveFloatNormalizer,
      component: InputField,
    }),
    [can],
  );

  const documentFieldProps = useMemo(
    () => ({
      name: 'document_file',
      item: {
        label: (
          <>
            Sənəd yüklə
            <Tooltip title={!!uploadedFile ? 'Mövcud sənəd' : 'Mövcud sənəd yoxdur'}>
              <Button disabled={!uploadedFile} style={{ padding: '0 8px', height: 23 }} type='link'>
                <LinkOutlined />
              </Button>
            </Tooltip>
          </>
        ),
      },
      component: UploadField.Simple,
    }),
    [uploadedFile],
  );

  const descriptionsFieldProps = useMemo(
    () => ({
      name: 'descr',
      item: { label: 'Açıqlama', style: { marginBottom: 0 } },
      field: { placeholder: 'Açıqlama daxil edin', rows: 3 },
      component: TextAreaField,
    }),
    [],
  );

  const liquidFieldProps = useMemo(
    () => ({
      name: 'type',
      item: { label: <>&nbsp;</>, style: { marginBottom: 0 } },
      field: { children: 'Tərkibində maye var' },
      component: CheckboxField,
      type: 'checkbox',
    }),
    [],
  );

  const specialFieldProps = useMemo(
    () => ({
      name: 'is_special',
      item: { label: <>&nbsp;</>, style: { marginBottom: 0 } },
      field: { children: 'Xüsusi tarifdir' },
      component: CheckboxField,
      type: 'checkbox',
    }),
    [],
  );

  const plansCategoryFieldProps = useMemo(
    () => ({
      name: 'tariff_category_id',
      item: { label: 'Tarif' },
      field: { placeholder: 'Tarif kateqoriyasını seçin' },
      type: 'select',
      component: SelectField,
      apiUrl: urlFactory('/api/admin/tariff/category_list'),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => `${object.id} - ${object.name}`,
      responseGetter: (object: any) => object.data.filter((_, index) => index !== object.data.length - 1),
    }),
    [],
  );

  return useMemo(
    () => ({
      userFieldProps,
      productTypesFieldProps,
      shopNameFieldProps,
      quantityFieldProps,
      globalTrackCode,
      priceFieldProps,
      deliveryPriceFieldProps,
      descriptionsFieldProps,
      weightFieldProps,
      liquidFieldProps,
      documentFieldProps,
      specialFieldProps,
      plansCategoryFieldProps,
    }),
    [
      userFieldProps,
      productTypesFieldProps,
      shopNameFieldProps,
      globalTrackCode,
      quantityFieldProps,
      priceFieldProps,
      deliveryPriceFieldProps,
      descriptionsFieldProps,
      weightFieldProps,
      liquidFieldProps,
      documentFieldProps,
      specialFieldProps,
      plansCategoryFieldProps,
    ],
  );
};
