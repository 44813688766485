import React, { FC, useContext, useCallback, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, Col, Modal, Row, Select } from 'antd';

import { AntForm } from '@core/ui';
import { CheckboxField, TextField, SelectField, TextAreaField } from '@core/form';

import { CreatePlanContext } from '../contexts';
import { usePlanTypes } from '@modules/plans';
import { useCreatePlan } from '../hooks';
import { CreatePlanDto } from '../dtos';

const FormikComponent: FC<FormikProps<CreatePlanDto>> = ({ values, handleSubmit, setFieldValue, isSubmitting }) => {
  const { id, onCancel } = useContext(CreatePlanContext);
  const planTypes = usePlanTypes();

  const planTypeOptions = React.useMemo(
    () =>
      planTypes.data?.map((planType) => (
        <Select.Option key={planType.id} value={planType.id.toString()}>
          {planType.name}
        </Select.Option>
      )),
    [planTypes.data],
  );

  const [maxWeightIsInfinity, setMaxWeightIsInfinity] = useState(false);

  const handleClickMaxWeightInfinity = useCallback(() => {
    if (!maxWeightIsInfinity) {
      setFieldValue('weightTo', '');
    }
    setMaxWeightIsInfinity(!maxWeightIsInfinity);
  }, [maxWeightIsInfinity, setFieldValue]);

  return (
    <Modal width={768} visible={true} onOk={() => handleSubmit()} onCancel={onCancel} confirmLoading={isSubmitting} title={!id ? 'Plan əlavə et' : 'Planda düzəliş et'}>
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 0]}>
          <Col xs={24} md={12}>
            <TextField name='weightFrom' format='decimal' item={{ label: 'Minimal çəki' }} input={{ placeholder: 'Minimal çəki edin', addonAfter: 'KQ' }} />
          </Col>
          <Col xs={24} md={12}>
            <TextField
              name='weightTo'
              format='decimal'
              item={{ label: 'Maksimal çəki' }}
              input={{
                placeholder: 'Maxsimal çəki edin',
                disabled: maxWeightIsInfinity,
                addonAfter: (
                  <div>
                    <Button onClick={handleClickMaxWeightInfinity} className='input-addon-button' type='link'>
                      &#8734;
                    </Button>
                    <span>KQ</span>
                  </div>
                ),
              }}
            />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} md={12}>
            <TextField name='price' format='decimal' item={{ label: 'Qiymət' }} input={{ placeholder: 'Qiymət daxil edin', addonAfter: '$' }} />
          </Col>
          <Col xs={24} md={12}>
            <CheckboxField name='isLiquid' item={{ label: <>&nbsp;</> }} input={{ children: 'Mayedir' }} />
          </Col>
        </Row>
        <CheckboxField name='isSpecial' item={{ label: <>&nbsp;</> }} input={{ children: 'Xüsusi tarifdir' }} />
        {values.isSpecial && (
          <SelectField name='categoryId' item={{ label: 'Tarif' }} input={{ placeholder: 'Tarifi seçin...', loading: planTypes.isLoading, disabled: planTypes.isLoading }}>
            {planTypeOptions}
          </SelectField>
        )}
        <TextAreaField name='description' item={{ label: 'Açıqlama' }} input={{ placeholder: 'Açıqlamanı daxil edin...' }} />
      </AntForm>
    </Modal>
  );
};

export const CreatePlan: FC = () => {
  const { initialValues, onSubmit } = useCreatePlan();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
