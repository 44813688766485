import React, { FC } from 'react';
import { Card, Spin, Typography } from 'antd';
import { CardProps } from 'antd/lib/card';

const { Title } = Typography;

interface IDashboardChartProps extends CardProps {
  title: string;
  data?: any;
  options?: any;
  component: any;
  loading?: boolean;
}

const DashboardChart: FC<IDashboardChartProps> = ({ title, data, options, loading = false, component: Chart, ...card }) => {
  return (
    <Card
      className='dashboard-chart-card'
      size='small'
      title={
        <Title className='dashboard-chart-card-title' level={4}>
          {title}
        </Title>
      }
      {...card}
    >
      <Spin spinning={loading}>
        <Chart data={data || {}} options={options} />
      </Spin>
    </Card>
  );
};

export default React.memo(DashboardChart);
