import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Avatar, Button, Card, Comment, Dropdown, Form, List, Menu, Popover, Select, Space } from 'antd';
import { AppstoreOutlined, DeleteOutlined, FileOutlined, HistoryOutlined, ReadOutlined, SendOutlined } from '@ant-design/icons';
import { RouteComponentProps } from 'react-router-dom';
import { ArrowLeftOutlined, SmileOutlined } from '@ant-design/icons';
import { Picker as EmojiPicker } from 'emoji-mart';

import { TextAreaField } from '../../components/ReduxForm/TextArea';
import logo from '../../assets/images/logo.png';
import { useInfo } from '../../hooks/tickets/useInfo';
import routes from '../../constants/routes';
import { urlFactory } from '../../core/utils/caller';
import { useForm } from '../../hooks/common/useForm';
import { UploadField } from '../../components/ReduxForm/Upload';
import { useQuery } from '../../next/shared/modules/use-query/hooks';
import { caller } from '../../next/shared/utils/caller';
import { urlMaker } from '../../next/shared/utils/url-maker';
import { selectFilterOption } from '../../next/shared/modules/form/utils';
import { useSelector } from 'react-redux';
import { formFieldValueSelector } from '../../selectors/form';

const TicketsInfoPage: FC<InjectedFormProps<any, any> & RouteComponentProps<any>> = ({ match: { params }, history, handleSubmit: baseHandleSubmit, initialize, submitting, change }) => {
  const [templateId, setTemplateId] = useState<string | undefined>();
  const [initialLoading, setInitialLoading] = useState(true);
  const { ticket, messages, loading, fetch, changeRead, changeState, remove, goToStateHistory } = useInfo(params.id);
  const messageValue = useSelector(formFieldValueSelector('ticketInbox', 'message', ''));

  useEffect(() => {
    if (!loading) {
      setInitialLoading(loading);
    }
  }, [loading]);

  const handleTemplateChange = useCallback((value: string) => {
    setTemplateId(value);
  }, []);

  const formOptions = useMemo(
    () => ({
      handleClose: () => {},
      handleSubmit: baseHandleSubmit,
      submitMiddlewares: [(values) => ({ ...values, ticket_id: params.id })],
      initialize,
      createUrl: routes.tickets.info,
      createApiUrl: urlFactory('/api/admin/tickets/message/create'),
      onSuccess: fetch,
    }),
    [params.id, baseHandleSubmit, initialize, fetch],
  );

  const fileUploadFieldProps = useMemo(
    () => ({
      name: 'document_file',
      component: UploadField.Multi,
      mini: true,
    }),
    [],
  );

  const { handleSubmit } = useForm(formOptions);

  const goToFile = useCallback((url) => {
    window.open(url, '_blank');
  }, []);

  const goToTickets = useCallback(() => {
    history.push(routes.tickets.index);
  }, [history]);

  const ticketTemplates = useQuery(
    useCallback(async () => {
      const response = await caller(urlMaker('/api/admin/message_templates', { page: 1, per_page: 1000 }));

      if (response.status === 200) {
        const { data } = await response.json();
        return data;
      } else {
        return [];
      }
    }, []),
    useMemo(() => ({ initialData: [] }), []),
  );

  const resetTemplateId = useCallback(() => {
    setTemplateId(undefined);
  }, []);

  useEffect(() => {
    if (templateId) {
      const foundTemplate = ticketTemplates.data.find(({ id }) => id.toString() === templateId);
      if (foundTemplate) {
        change('message', foundTemplate.body);
      }
    }
  }, [templateId, ticketTemplates.data]); //eslint-disable-line

  const handleSelectEmoji = useCallback(
    (emoji) => {
      change('message', messageValue + emoji.native);
    },
    [messageValue, change],
  );

  return (
    <Card
      loading={initialLoading}
      size='small'
      extra={[
        <Space key='actions' size={8}>
          <Button size='small' icon={<ReadOutlined />} onClick={changeRead}>
            {!ticket?.is_new_admin ? 'Oxunmamış kimi qeyd et' : 'Oxunmuş kimi qeyd et'}
          </Button>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => changeState(32)}>Qəbul edilib</Menu.Item>
                <Menu.Item onClick={() => changeState(33)}>Araşdırılır</Menu.Item>
                <Menu.Item onClick={() => changeState(34)}>Açıqdır</Menu.Item>
                <Menu.Item onClick={() => changeState(35)}>Bağlıdır</Menu.Item>
              </Menu>
            }
          >
            <Button size='small' icon={<AppstoreOutlined />}>
              Status dəyiş
            </Button>
          </Dropdown>
          <Button size='small' onClick={goToStateHistory} icon={<HistoryOutlined />}>
            Status tarixçəsi
          </Button>
          <Button size='small' icon={<DeleteOutlined />} onClick={remove} danger={true}>
            Sil
          </Button>
        </Space>,
      ]}
      title={
        ticket ? (
          <div>
            <Button style={{ margin: -5 }} onClick={goToTickets} type='text' icon={<ArrowLeftOutlined />} /> {ticket?.user_name} {ticket?.user_id} ({ticket?.ticket_category_name})
          </div>
        ) : (
          'Müştəri'
        )
      }
    >
      <List
        itemLayout='horizontal'
        dataSource={messages}
        renderItem={(item: any) => {
          const files = item.document_file ? item.document_file.split(',') : [];

          return (
            <li>
              <Comment
                className={item.admin ? 'ticket-message-item-management' : undefined}
                author={item.user_name}
                avatar={!item.admin ? 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png' : logo}
                content={
                  <div>
                    <div>{item.message}</div>
                    <div className='tickets-file-list'>
                      {files.map((url) => {
                        const splitBySlash = url.split('/');
                        const nameWithExt = splitBySlash[splitBySlash.length - 1];
                        const [name, ext] = nameWithExt.split('.') as [string, string];

                        return (
                          <Card key={name} onClick={() => goToFile(url)} className='tickets-file-list-item' hoverable={true} size='small' cover={<FileOutlined />}>
                            <Card.Meta title={name + '.' + ext} />
                          </Card>
                        );
                      })}
                    </div>
                  </div>
                }
                datetime={item.created_at}
              />
            </li>
          );
        }}
      />
      <Comment
        avatar={<Avatar src={logo} alt={`${process.env.REACT_APP_COMPANY_SHORT_NAME} logo`} />}
        content={
          <Form>
            <Select onChange={handleTemplateChange} value={templateId} showSearch={true} placeholder='Şablonlar' filterOption={selectFilterOption} style={{ width: 224, marginBottom: 12 }}>
              {ticketTemplates.data.map((template) => (
                <Select.Option key={template.id} value={template.id.toString()}>
                  {template.title}
                </Select.Option>
              ))}
            </Select>
            <div style={{ display: 'flex', marginBottom: 8 }}>
              <Field
                onChange={resetTemplateId}
                item={{ style: { flex: 1, marginBottom: 0 } }}
                field={{ rows: 4, placeholder: 'Mesajınızı buraya qeyd edin.' }}
                name='message'
                component={TextAreaField}
              />
              <Popover destroyTooltipOnHide={false} trigger='click' arrowContent={null} content={<EmojiPicker onSelect={handleSelectEmoji} />}>
                <Button style={{ height: 'unset' }} icon={<SmileOutlined />} />
              </Popover>
            </div>
            <Field {...fileUploadFieldProps} />
            <Button onClick={handleSubmit} loading={submitting} type='primary' icon={<SendOutlined />}>
              Göndər
            </Button>
          </Form>
        }
      />
    </Card>
  );
};

export default reduxForm<any, any>({ form: 'ticketInbox' })(React.memo(TicketsInfoPage));
