import * as React from 'react';
import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';

import { useAuth } from '@modules/auth';

import { GetCounterRepoType, IGetCounterRepo } from '../repos';
import { ICounter } from '../interfaces';
import { useEffect } from 'react';
import { useBus } from '@core/event-bus';

const initialState: ICounter = {
  orders: 0,
  couriers: 0,
  declarations: 0,
  unknownDeclarations: 0,
  supports: 0,
  handoverQueue: {
    executing: 0,
    pending: 0,
    executed: 0,
  },
};

export const CounterContext = React.createContext<{ state: ICounter }>({
  state: initialState,
});

export const CounterProvider: React.FC = React.memo(({ children }) => {
  const auth = useAuth();
  const getCounterRepo = useService<IGetCounterRepo>(GetCounterRepoType);
  const eventBus = useBus();

  const { data } = useQuery<ICounter>(
    'counter',
    async () => {
      const result = await getCounterRepo.execute();

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {
      initialData: initialState,
      enabled: !!auth.user.data,
      refetchInterval: 10000,
    },
  );

  useEffect(() => {
    eventBus.publish({ type: '@counter/handoverQueue/changed' });
  }, [data?.handoverQueue.executing, data?.handoverQueue.pending, data?.handoverQueue.executed, eventBus]);

  if (!data) {
    return null;
  }

  return <CounterContext.Provider value={{ state: data }}>{children}</CounterContext.Provider>;
});
