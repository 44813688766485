import { useContext } from 'react';

import { useSub } from '@core/event-bus';

import { PlansTableContext } from '../../contexts';
import { usePlanTableColumns } from './use-plan-table-columns';

export const usePlansTable = () => {
  const columns = usePlanTableColumns();
  const { fetch } = useContext(PlansTableContext);
  useSub(['@plans/create/succeed', '@plans/update/succeed'], () => {
    fetch();
  });

  return { columns };
};
