import React, { FC } from 'react';
import { Tag } from 'antd';

const OrdersStateTag: FC<any> = ({ id }) => {
  switch (id) {
    case 1:
      return <Tag color='orange'>Səbət</Tag>;
    case 2:
      return <Tag color='blue'>Sifariş gözlənilir</Tag>;
    case 3:
      return <Tag color='green'>Sifariş verilib</Tag>;
    case 4:
      return <Tag color='red'>Ləğv edilib</Tag>;
    default:
      return null;
  }
};

export default OrdersStateTag
