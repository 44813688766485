import { BalanceStatisticsService } from './balance';
import { DeclarationExecutiveService } from './declaration-executive';
import { PaymentTypesStatisticsService } from './payment-types';
import { StatisticsByCouriersRegionService } from './courier-region';

export class StatisticsService {
  public static Balance = BalanceStatisticsService;
  public static DeclarationExecutives = DeclarationExecutiveService;
  public static PaymentTypes = PaymentTypesStatisticsService;
  public static CourierRegion = StatisticsByCouriersRegionService;
}
