import React, { useMemo } from 'react';
import { Form, Radio } from 'antd';
import { WrappedFieldProps } from 'redux-form';
import { FormItemProps } from 'antd/lib/form';
import { RadioGroupProps } from 'antd/lib/radio';

export const RadioField = React.memo<WrappedFieldProps & { item: FormItemProps; field: RadioGroupProps; options: { value: any; label: any }[] }>(
  ({ input, item, field, options, meta: { touched, error, warning } }) => {
    const errorText = useMemo(() => touched && error, [touched, error]);
    const warningText = useMemo(() => touched && warning, [touched, warning]);
    const validateStatus = useMemo(() => {
      if (errorText) {
        return 'error';
      } else if (warningText) {
        return 'warning';
      }
    }, [errorText, warningText]);

    return (
      <Form.Item {...item} validateStatus={validateStatus} help={errorText || warningText}>
        <Radio.Group {...input} {...field}>
          {options.map((option) => (
            <Radio key={option.value} {...option}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  },
);
