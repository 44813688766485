import { inject } from 'inversify';

import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { ISettingsCashflow } from '../interfaces';
import { SettingsMapper } from '../mappers';

export type IGetGroupSettingsByIdRepoResponse = IRepoResponse<200, ISettingsCashflow> | IRepoResponse<400 | 500, string>;

export type IGetGroupSettingsByIdRepo = IRepo<[number | string], IGetGroupSettingsByIdRepoResponse>;

export const GetGroupSettingsByIdRepoType = Symbol.for('GetGroupSettingsByIdRepo');

@bind(GetGroupSettingsByIdRepoType)
export class GetGroupGroupSettingsByIdRepo implements IGetGroupSettingsByIdRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(id: number | string) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/settings/data', { group_id: 'cashflow' }) });

      if (result.status === 200) {
        const data: any = SettingsMapper.cashflowToDomain(result.data.data);

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
