import { IHandoverDeclarationsDetails } from '../interfaces';
import { IHandoverDeclarationDto, IHandoverDeclarationDtoPersistence } from '../dto';
import { formDataFlat } from '@core/utils';

export class HandoverDeclarationMapper {
  public static toDomain(details: IHandoverDeclarationsDetails): IHandoverDeclarationDto {
    const amount = details.debt.all.azn;

    return {
      cashAmount: amount.toString(),
      amountToBeBorrowed: '',
      terminalAmount: '0',
      handover: false,
      sync: 'cash-terminal',
      bigPackage: '',
      mediumPackage: '',
      smallPackage: '',
      isSync: true,
      handoverPreparations: true,
      redirectToBalance: false,
    };
  }

  public static toPersistence(dto: IHandoverDeclarationDto, declarationIds: string[]): IHandoverDeclarationDtoPersistence {
    return formDataFlat({
      declaration_id: declarationIds,
      cash: dto.cashAmount,
      terminal: dto.terminalAmount,
      accepted: dto.handover,
      medium_package: dto.mediumPackage,
      big_package: dto.bigPackage,
      small_package: dto.smallPackage,
      handover_task: dto.handoverPreparations,
      confirm: true,
    });
  }
}
