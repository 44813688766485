import { NextTable } from '@core/table';

import { useBranchesTable } from '../hooks';
import { BranchesTableContext } from '../contexts';

export const BranchesTable = () => {
  const { columns } = useBranchesTable();

  return <NextTable filterable={false} sortable={false} pagination={false} context={BranchesTableContext} columns={columns} />;
};
