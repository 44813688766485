import { combineEpics, Epic } from 'redux-observable';
import { authEpic } from './auth';
import { tableFetchDataEpic } from './table';
import { shopsFetchDataEpic } from './shops';
import { faqFetchDataEpic } from './faq';
import { newsFetchDataEpic } from './news';
import { bannersFetchDataEpic } from './banners';

const epics: Epic[] = [authEpic, tableFetchDataEpic, shopsFetchDataEpic, faqFetchDataEpic, newsFetchDataEpic, bannersFetchDataEpic];

export default combineEpics(...epics);
