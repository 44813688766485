import React from 'react';
import { Menu } from 'antd';

import { LightMenu } from '../../../shared/components/menu';
import { useTicketsFilterMenu } from '../hooks/use-filter-menu';

const TicketsFilterMenu = () => {
  const { selectedKeys, handleChange } = useTicketsFilterMenu();

  return (
    <LightMenu onClick={handleChange} selectedKeys={selectedKeys} mode='horizontal'>
      <Menu.Item key='all'>Hamısı</Menu.Item>
      <Menu.Item key='read'> Oxunmuş</Menu.Item>
      <Menu.Item key='unread'>Oxunmamış</Menu.Item>
      <Menu.SubMenu title='Statuslar'>
        <Menu.Item key='stateId.34'>Açıq</Menu.Item>
        <Menu.Item key='stateId.35'>Bağlı</Menu.Item>
        <Menu.Item key='stateId.33'>Baxılır</Menu.Item>
        <Menu.Item key='stateId.32'>Qəbul edilib</Menu.Item>
      </Menu.SubMenu>
    </LightMenu>
  );
};

export default TicketsFilterMenu;
