import React, { FC, memo } from 'react';
import { Button, Descriptions, Form, Modal } from 'antd';
import { Formik, FormikProps } from 'formik';
import * as Icons from '@ant-design/icons';

import { FormAlert } from '../../../shared/modules/form/components';
import { FormOptions } from '../../../shared/modules/form/types';
import { UploadField } from '../../../shared/modules/form/containers/upload';

import { useFlightBulkManifestForm } from '../hooks/use-bulk-manifest-form';
import { FlightBulkManifestFormData, FlightBulkManifestResult } from '../types/bulk-manifest';

type FormProps = FormikProps<FlightBulkManifestFormData> & { result: FlightBulkManifestResult | null };

const FormikComponent = memo<FormProps>(({ status, result }) => {
  return (
    <div>
      {status?.type === 'success' && result ? (
        <Descriptions column={1} size='small' bordered={true}>
          <Descriptions.Item label='Ümumi kisələr'>{result.bags.all} ədəd</Descriptions.Item>
          <Descriptions.Item label='Dolu kisələr'>{result.bags.full} ədəd</Descriptions.Item>
          <Descriptions.Item label='Boş kisələr'>{result.bags.empty} ədəd</Descriptions.Item>
          <Descriptions.Item label='Tapılan bağlamalar'>{result.declarations.found} ədəd</Descriptions.Item>
          <Descriptions.Item label='Tapılmayan bağlamalar'>{result.declarations.notFound} ədəd</Descriptions.Item>
          <Descriptions.Item label='Manifest'>
            <a href={result.file} target='_blank' rel='noopener noreferrer'>
              <Button icon={<Icons.CloudDownloadOutlined />} rel='noopener noreferrer'>
                Sənədi endir
              </Button>
            </a>
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Form style={{ marginBottom: -24 }} layout='vertical'>
          <FormAlert $visible={!!status} message={status?.message} type={status?.type} />
          <UploadField name='file'>
            <Button icon={<Icons.CloudUploadOutlined />}>Sənədi yüklə</Button>
          </UploadField>
        </Form>
      )}
    </div>
  );
});

export const FlightBulkManifestForm: FC<FormOptions<{ flightId: string }> & { onClose: () => any }> = ({ onSubmitSucceed, onClose, params }) => {
  const { onSubmit, initialValues, result } = useFlightBulkManifestForm({ onSubmitSucceed, params });

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues}>
      {(props) => (
        <Modal visible={true} width={400} title='Manifest (Kisələrlə)' onOk={() => props.handleSubmit()} confirmLoading={props.isSubmitting} onCancel={onClose}>
          <FormikComponent result={result} {...props} />
        </Modal>
      )}
    </Formik>
  );
};
