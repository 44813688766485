import React, { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';

import ReadyTable from '../../../../components/Table/ReadyTable';
import { useCouriersTableColumns } from '../../../../hooks/couriers/useTableColumns';
import { search2Object } from '../../../../core/utils/search-to-object';
import { tableSetFiltersAction } from '../../../../actions/table';

export const CourierHandoverDetailsTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const columns = useCouriersTableColumns();
  const location = useLocation();

  const query = useMemo(() => ({ ...search2Object(location.search), stat: 'couriers' }), [location.search]);

  useEffect(() => {
    dispatch(
      tableSetFiltersAction(
        'courier_handover_details',
        Object.entries(query).map(([key, value]) => ({ id: key, value })),
      ),
    );
  }, [dispatch, query]);

  return (
    <Modal maskTransitionName='' transitionName='' width={992} visible={true} onCancel={() => history.push('/dashboard')} footer={null} closable={false}>
      <ReadyTable name='courier_handover_details' apiUrl='/api/admin/stats/couriers' columns={columns} />
    </Modal>
  );
};
