import React, { memo } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, Col, Form, Row, Spin, Switch, Typography, Select } from 'antd';

import { FormAlert } from '../../../shared/modules/form/components';
import { TextField } from '../../../shared/modules/form/containers/text';

import { useSettingsForm } from '../hooks/use-form';
import { SettingsFormData } from '../types';
import { Content } from '../../../shared/components/content';
import { SelectField } from '@core/form';
import { useCashRegisters } from '@modules/cash-flow/cash-registers';

const FormikComponent = memo<FormikProps<SettingsFormData>>(({ isSubmitting, handleSubmit, status, initialValues, values, setFieldValue }) => {
  const cashRegisters = useCashRegisters();

  return (
    <Form layout='vertical'>
      <FormAlert $visible={!!status} type={status?.type || 'error'} message={status?.message || ''} />
      <Row gutter={[12, 12]}>
        <Col xs={24} md={12}>
          <Content title='Ayarlar' bodyStyle={{ marginBottom: -12 }}>
            <div style={{ display: 'flex' }}>
              <Typography.Paragraph style={{ flex: 1 }}>Bağlamalar gömrükə göndərilsin</Typography.Paragraph>
              <Switch checked={values.configs.sendDeclarationsToCustoms} onChange={(value) => setFieldValue('configs.sendDeclarationsToCustoms', value)} />
            </div>
            <SelectField name='configs.orders.cashRegisterId' item={{ label: 'Sifarişə bağlı kassa' }} input={{ placeholder: 'Kassa seçin...' }}>
              {cashRegisters.data?.data.map((item) => (
                <Select.Option value={item.id.toString()} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </SelectField>
            <SelectField name='configs.terminal.cashRegisterId' item={{ label: 'Terminala bağlı kassa' }} input={{ placeholder: 'Kassa seçin...' }}>
              {cashRegisters.data?.data.map((item) => (
                <Select.Option value={item.id.toString()} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </SelectField>
          </Content>
        </Col>
        <Col xs={24}>
          <Content title='Şirkətin əlaqə vasitələri'>
            <Row gutter={[12, 0]}>
              <Col xs={24} md={12}>
                <TextField name='contact.number' item={{ label: 'Telefon nömrəsi' }} />
                <TextField name='contact.email' item={{ label: 'Email' }} />
                <TextField name='contact.address' item={{ label: 'Ünvan' }} />
                <TextField name='contact.map' item={{ label: 'Xəritə linki' }} />
              </Col>
              <Col xs={24} md={12}>
                <iframe title='Google Maps Preview' width='100%' height='100%' frameBorder={0} src={initialValues.contact.map} style={{ pointerEvents: 'none' }} />
              </Col>
            </Row>
          </Content>
        </Col>
        <Col xs={24} xl={12}>
          <Content title='Xarici anbar məlumatları'>
            <Row gutter={12}>
              <Col xs={24} sm={12}>
                <TextField name='foreignWarehouse.city' item={{ label: 'İl/Şehir' }} />
              </Col>
              <Col xs={24} sm={12}>
                <TextField name='foreignWarehouse.addressHeading' item={{ label: 'Address başlığı' }} />
              </Col>
              <Col xs={24} sm={12}>
                <TextField name='foreignWarehouse.postalCode' item={{ label: 'ZİP/Post kodu' }} />
              </Col>
              <Col xs={24} sm={12}>
                <TextField name='foreignWarehouse.passportIdentity' item={{ label: 'TC Kimlik' }} />
              </Col>
              <Col xs={24} sm={12}>
                <TextField name='foreignWarehouse.address' item={{ label: 'Address 1' }} />
              </Col>
              <Col xs={24} sm={12}>
                <TextField name='foreignWarehouse.province' item={{ label: 'İlçe' }} />
              </Col>
              <Col xs={24} sm={12}>
                <TextField name='foreignWarehouse.district' item={{ label: 'Mahalle' }} />
              </Col>
              <Col xs={24} sm={12}>
                <TextField name='foreignWarehouse.country' item={{ label: 'Ülke' }} />
              </Col>
              <Col xs={24} sm={12}>
                <TextField name='foreignWarehouse.phone' item={{ label: 'Numara' }} />
              </Col>
            </Row>
          </Content>
        </Col>
        <Col xs={24} lg={12}>
          <Content title='Digər məlumatlar'>
            <TextField name='workingHours' item={{ label: 'İş saatları' }} />
            <TextField name='bonPercent' format='decimal' item={{ label: 'Bon faizi' }} />
          </Content>
        </Col>
        <Col xs={24}>
          <Button onClick={() => handleSubmit()} type='primary' loading={isSubmitting} block={true}>
            Dəyişdir
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

export const SettingsForm = () => {
  const { initialValues, ...form } = useSettingsForm();

  return (
    <Spin spinning={initialValues.loading}>
      <Formik initialValues={initialValues.data} {...form} enableReinitialize={true} component={FormikComponent} />
    </Spin>
  );
};
