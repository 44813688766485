import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { Form, Input, message, Modal } from 'antd';
import { ModalProps } from 'antd/es/modal';

import { FlightService } from '../services';
import { useParams } from 'react-router-dom';
import { InputFormatters } from '@core/form';

export const CloseFlight: FC<{ onSubmitSucceed?: () => any } & ModalProps> = ({ onSubmitSucceed, ...modal }) => {
  const params = useParams<{ id: string; type: string }>();
  const [airWaybillNumber, setAirWaybillNumber] = useState<string>('');
  const [packagingLimit, setPackagingLimit] = useState<string>('100');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmitSucceed = useCallback(() => {
    return onSubmitSucceed?.();
  }, [onSubmitSucceed]);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    const result = await FlightService.Actions.close({ id: params.id, airWaybillNumber, type: params.type, packagingLimit });
    if (result.status === 200) {
      handleSubmitSucceed();
      message.success({ content: 'Flight bağlandı.' });
    } else {
      message.error({ content: result.data });
    }
    setSubmitting(false);
  }, [airWaybillNumber, handleSubmitSucceed, packagingLimit, params.id, params.type]);

  const handleAirWaybillNumberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setAirWaybillNumber(event.target.value);
  }, []);

  const handlePackagingLimitChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPackagingLimit(InputFormatters.integer(event).target.value);
  }, []);

  return (
    <Modal visible={true} confirmLoading={submitting} onOk={handleSubmit} {...modal}>
      <Form size='large' layout='vertical' style={{ marginBottom: -12 }}>
        <Form.Item label='Aviaqaimə nömrəsini'>
          <Input value={airWaybillNumber} onChange={handleAirWaybillNumberChange} placeholder='Aviaqaimə nömrəsini daxil edin...' />
        </Form.Item>
        <Form.Item label='Paketləmə limiti'>
          <Input value={packagingLimit} onChange={handlePackagingLimitChange} placeholder='Paketləmə limitini daxil edin' />
        </Form.Item>
      </Form>
    </Modal>
  );
};
