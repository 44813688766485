import React, { FC, useMemo } from 'react';
import { Modal } from 'antd';
import { search2Object } from '../../../core/utils/search-to-object';
import { useClose } from '../../../hooks/common';
import routes from '../../../constants/routes';
import OrdersTable from '../../Orders/Table';

const DashboardOrdersTable: FC<any> = ({ location: { search } }) => {
  const params = useMemo(() => search2Object(search), [search]);
  const handleClose = useClose(routes.dashboard.index);

  const filters = useMemo(() => Object.entries(params).map(([id, value]) => ({ id, value })), [params]);

  return (
    <Modal maskTransitionName='' transitionName='' width={992} visible={true} onCancel={handleClose} footer={null} closable={false}>
      <OrdersTable defaultFilters={filters} />
    </Modal>
  );
};

export default DashboardOrdersTable;
