import React, { FC, useCallback } from 'react';
import { Button, Modal, Steps } from 'antd';
import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IStatusExecution } from '@modules/status';

import { GetOrderStatusExecutionRepoType, IGetOrderStatusExecutionRepo } from '../repos';

import { tableSetFiltersAction } from '../../../../actions/table';
import routes from '../../../../constants/routes';
import * as Icons from '@ant-design/icons';

export const OrderTimeline: FC<{ onClose: () => void; id: string }> = ({ onClose, id }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getOrderStatusExecutionRepo = useService<IGetOrderStatusExecutionRepo>(GetOrderStatusExecutionRepoType);

  const { data } = useQuery<IStatusExecution[], Error, IStatusExecution[], string[]>(['orders', id, 'status', 'execution'], async ({ queryKey }) => {
    const [, id] = queryKey;
    const result = await getOrderStatusExecutionRepo.execute({ id });

    if (result.status === 200) {
      return result.response;
    } else {
      throw new Error(result.response);
    }
  });

  const makeDescription = useCallback(({ date, executor, name }) => {
    if (!date || !executor) {
      return undefined;
    }

    return `Sifariş "${date}" tarixində "${executor}" tərəfindən "${name}" statusuna keçirilmişdir`;
  }, []);

  const makeStatus = useCallback(({ current, date }) => {
    return current ? 'process' : !!date ? 'finish' : 'wait';
  }, []);

  const openLogs = useCallback(() => {
    dispatch(
      tableSetFiltersAction('logs', [
        {
          id: 'model_name',
          value: 'Bağlamalar',
        },
        {
          id: 'object_id',
          value: id,
        },
      ]),
    );
    history.push(routes.logs.index);
  }, [history, dispatch, id]);

  const openStatusHistory = useCallback(() => {
    dispatch(
      tableSetFiltersAction('stateArchive', [
        {
          id: 'model_name',
          value: 'Sifarişlər',
        },
        {
          id: 'object_id',
          value: id,
        },
      ]),
    );
    history.push(routes.archive.state.index);
  }, [history, dispatch, id]);

  if (!data) {
    return null;
  }

  const footer = (
    <React.Fragment>
      <Button onClick={openLogs} icon={<Icons.HistoryOutlined />}>
        Əməliyyat tarixçəsi
      </Button>
      <Button onClick={openStatusHistory} icon={<Icons.FieldTimeOutlined />}>
        Status tarixçəsi
      </Button>
    </React.Fragment>
  );

  return (
    <Modal visible={true} width={576} onCancel={onClose} footer={footer} title='Status xəritəsi'>
      <Steps progressDot={true} direction='vertical'>
        {data.map((item) => (
          <Steps.Step
            key={item.id}
            status={makeStatus({ current: item.isCurrent, date: item.createdAt })}
            title={item.ref.name}
            subTitle={item.executor?.name}
            description={makeDescription({
              name: item.ref.name,
              executor: item.executor?.name,
              date: item.createdAt,
            })}
          />
        ))}
      </Steps>
    </Modal>
  );
};
