import React, { FC, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { Form, Modal, Spin } from 'antd';

import { useFormFields } from '../../hooks/productTypes/useForm';
import { useClose } from '../../hooks/common';
import { urlFactory } from '../../core/utils/caller';
import { useForm } from '../../hooks/common/useForm';
import { tableFetchDataAction } from '../../actions/table';
import routes from '../../constants/routes';

type IProps = InjectedFormProps<any> & RouteComponentProps<any> & { dispatch: Dispatch };

const ProductTypesForm: FC<IProps> = ({ handleSubmit: baseHandleSubmit, initialize, match: { params }, dispatch, submitting, initialized }) => {
  const handleClose = useClose(routes.productTypes.index);

  const handleSubmitSuccess = useCallback(() => {
    dispatch(tableFetchDataAction('states', '/api/admin/product-types/getlist'));
  }, [dispatch]);

  const formOptions = useMemo(
    () => ({
      handleClose,
      handleSubmit: baseHandleSubmit,
      initialize,
      createUrl: routes.productTypes.create,
      createApiUrl: urlFactory('/api/admin/product-types/create'),
      editApiUrl: urlFactory('/api/admin/product-types/edit', {
        product_type_id: params.id,
      }),
      onSuccess: handleSubmitSuccess,
      initializeUrl: urlFactory('/api/admin/product-types/getinfo', {
        product_type_id: params.id,
      }),
    }),
    [params.id, handleSubmitSuccess, baseHandleSubmit, initialize, handleClose],
  );

  const { handleSubmit, type } = useForm(formOptions);

  const title = useMemo(() => {
    return type === 'create' ? 'Yeni məhsul tipi əlavə et' : 'Məhsul tipində düzəliş et';
  }, [type]);

  const { nameFieldProps, stateFieldProps, nameTrFieldProps, nameEnFieldProps, nameRuFieldProps } = useFormFields();

  return (
    <Modal maskTransitionName='' transitionName='' width={592} visible={true} onCancel={handleClose} confirmLoading={submitting} onOk={handleSubmit} title={title}>
      <Spin spinning={type === 'edit' && !initialized}>
        <Form layout='vertical'>
          <Field {...nameFieldProps} />
          <Field {...nameTrFieldProps} />
          <Field {...nameEnFieldProps} />
          <Field {...nameRuFieldProps} />
          <Field {...stateFieldProps} />
        </Form>
      </Spin>
    </Modal>
  );
};

export default reduxForm<any, any>({ form: 'product_types' })(React.memo<IProps>(ProductTypesForm));
