import { useCallback, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { message } from 'antd';

import { useQuery } from '../../../shared/modules/use-query/hooks';

import { StatisticsService } from '../services';
import { BalanceStatisticsByPaymentTypes } from '../types/balance';

export const useBalanceStatisticsByPaymentTypes = () => {
  const [dateRange, setDateRange] = useState<[Moment, Moment]>([moment().startOf('month'), moment()]);

  const handleChangeDateRange = useCallback((value: [Moment | null, Moment | null] | null) => {
    const startDate = value?.[0] || null;
    const endDate = value?.[1] || null;

    if (startDate && endDate) {
      setDateRange([startDate, endDate]);
    }
  }, []);

  const result = useQuery<BalanceStatisticsByPaymentTypes | null>(
    useCallback(async () => {
      if (!dateRange) {
        return null;
      }

      const result = await StatisticsService.Balance.getByPaymentTypes(dateRange[0], dateRange[1]);

      if (result.status === 200) {
        return result.data;
      } else {
        message.error(result.data);
        throw new Error(result.data);
      }
    }, [dateRange]),
    useMemo(() => ({ enabled: !!dateRange }), [dateRange]),
  );

  return { result, dateRange, handleChangeDateRange };
};
