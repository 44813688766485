import React from 'react';
import { Col, Row } from 'antd';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { DashboardProgress } from '../../components/Dashboard/Progress';
import DashboardChart from '../../components/Dashboard/Chart';
import { useDailyStatistics } from '../../hooks/dashboard/useDailyStatistics';
import { useByStateStatistics } from '../../hooks/dashboard/useByStateStatistics';
import { useBalanceStatistics } from '../../hooks/dashboard/useBalanceStatistics';
import { useDeliveredStatistics } from '../../hooks/dashboard/useDeliveredStatistics';
import { useDeclarationStatusStatistics } from '../../hooks/dashboard/useDeclarationStatusStatistics';

const GeneralStatistics = () => {
  const { data: delivered } = useDeliveredStatistics(0);
  const { data: daily, loading: dailyLoading } = useDailyStatistics(2000);
  const { data: byState, loading: byStateLoading } = useByStateStatistics(3000);
  const { data: balance, loading: balanceLoading } = useBalanceStatistics(4000);
  const { data: declared, loading: declaredLoading } = useDeclarationStatusStatistics(0);

  return (
    <Row gutter={[8, 8]}>
      <Col span={24} lg={12} xxl={6}>
        <DashboardProgress
          title='Sifarişlər'
          subTitle='Çatdırılmış'
          bottomText='Çatdırılmamışlar'
          value={delivered.orders.delivered}
          bottomValue={delivered.orders.notDelivered}
          progress={delivered.orders.percent}
        />
      </Col>
      <Col span={24} lg={12} xxl={6}>
        <DashboardProgress
          title='Bağlamalar'
          subTitle='Çatdırılmış'
          bottomText='Çatdırılmamışlar'
          value={delivered.declarations.delivered}
          bottomValue={delivered.declarations.notDelivered}
          progress={delivered.declarations.percent}
        />
      </Col>
      <Col span={24} lg={12} xxl={6}>
        <DashboardProgress
          title='Kuryerlər'
          subTitle='Çatdırılmış'
          bottomText='Çatdırılmamışlar'
          value={delivered.couriers.delivered}
          bottomValue={delivered.couriers.notDelivered}
          progress={delivered.couriers.percent}
        />
      </Col>
      <Col span={24} lg={12} xxl={6}>
        <DashboardProgress
          title='Bəyan edilmişlər'
          subTitle='Ümumi qiymət'
          bottomText='Ümumi çəki'
          value={declared.total_price}
          valuePrefix='$'
          bottomValue={declared.total_weight}
          progress={declaredLoading ? 0 : 100}
        />
      </Col>
      <Col span={24} lg={12}>
        <DashboardChart title='Bağlamalar' data={daily.declarations} loading={dailyLoading} component={Line} />
      </Col>
      <Col span={24} lg={12}>
        <DashboardChart title='Sifarişlər' data={daily.orders} loading={dailyLoading} component={Line} />
      </Col>
      <Col span={24} lg={12}>
        <DashboardChart title='Balans əməliyyatları' data={balance} loading={balanceLoading} component={Bar} />
      </Col>
      <Col span={24} lg={12}>
        <DashboardChart title='Bağlama statusları' data={byState.declarations} loading={byStateLoading} component={Pie} />
      </Col>
      <Col span={24} lg={12}>
        <DashboardChart title='Sifariş statusları' data={byState.orders} loading={byStateLoading} component={Pie} />
      </Col>
      <Col span={24} lg={12}>
        <DashboardChart title='Kuryer statusları' data={byState.couriers} loading={byStateLoading} component={Pie} />
      </Col>
    </Row>
  );
};

export default React.memo(GeneralStatistics);
