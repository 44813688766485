import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import { CreateBranchPage } from '../pages';

const BranchModalRouter: FC = () => {
  return (
    <React.Fragment>
      <Route path={['/@next/branches/create', '/@next/branches/:id/update']} component={CreateBranchPage} />
    </React.Fragment>
  );
};

export default BranchModalRouter;
