import { Settings, SettingsApi, SettingsFormData, SettingsFormDataApi } from '../types';

export class SettingsMapper {
  public static getFromApi(informations: SettingsApi): Settings {
    return {
      configs: {
        sendDeclarationsToCustoms: !!informations.customs,
        orders: {
          cashRegisterId: informations.cashbox_id,
        },
        terminal: {
          cashRegisterId: informations.terminal_cashbox_id,
        },
      },
      contact: {
        address: informations.contact.address,
        email: informations.contact.emails,
        map: informations.contact.map,
        number: informations.contact.numbers,
      },
      workingHours: informations.workinghours || '',
      bonPercent: informations.bon_percent || '',
      foreignWarehouse: {
        address: informations.warehouse.address,
        addressHeading: informations.warehouse.address_heading,
        city: informations.warehouse.city,
        country: informations.warehouse.country,
        district: informations.warehouse.district,
        passportIdentity: informations.warehouse.passport_identity,
        phone: informations.warehouse.phone,
        postalCode: informations.warehouse.postal_code,
        province: informations.warehouse.province,
      },
    };
  }

  public static formDataToApi(formData: SettingsFormData): SettingsFormDataApi {
    const contact = {
      numbers: formData.contact.number,
      emails: formData.contact.email,
      address: formData.contact.address,
      map: formData.contact.map,
    };

    const foreignWarehouse = {
      city: formData.foreignWarehouse.city,
      address_heading: formData.foreignWarehouse.addressHeading,
      postal_code: formData.foreignWarehouse.postalCode,
      passport_identity: formData.foreignWarehouse.passportIdentity,
      address: formData.foreignWarehouse.address,
      province: formData.foreignWarehouse.province,
      district: formData.foreignWarehouse.district,
      country: formData.foreignWarehouse.country,
      phone: formData.foreignWarehouse.phone,
    };

    return {
      customs: Number(formData.configs.sendDeclarationsToCustoms).toString(),
      cashbox_id: formData.configs.orders.cashRegisterId,
      terminal_cashbox_id: formData.configs.terminal.cashRegisterId,
      contact: JSON.stringify(contact),
      warehouse: JSON.stringify(foreignWarehouse),
      workinghours: formData.workingHours,
      bon_percent: formData.bonPercent,
    };
  }
}
