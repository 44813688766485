import { useService } from '@core/inversify-react';

import { GetWarehousesSettingsType, GetWarehousesSettings } from '../repos';
import { useQuery } from 'react-query';
import { IWarehousesSettingsDto } from '../dtos';

export const useWarehousesSettingsData = () => {
  const GetWarehouseByIdRepo = useService<GetWarehousesSettings>(GetWarehousesSettingsType);

  const data = useQuery<IWarehousesSettingsDto, Error, IWarehousesSettingsDto, string[]>(
    ['settings', 'warehouses'],
    async () => {
      const result = await GetWarehouseByIdRepo.execute();

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {},
  );

  return { data };
};
