import React, { ChangeEvent, FC, memo, useCallback, useMemo } from 'react';
import { FieldConfig, FieldInputProps, FieldMetaProps, useField } from 'formik';
import { Form, Input } from 'antd';

import { InputProps } from 'antd/es/input';
import { FormItemProps } from 'antd/es/form';

import { twoLevelShallowEqualObjects } from '../../../utils/two-level-shallow-equal-objects';
import { InputFormatters } from '@core/form';

export type MemoizedTextFieldProps = {
  item?: FormItemProps;
  input?: InputProps;
  field: FieldInputProps<any>;
  meta: FieldMetaProps<any>;
};

export type TextFieldProps = FieldConfig & {
  item?: FormItemProps;
  input?: InputProps;
  format?: 'text' | 'integer' | 'decimal';
};

const MemoizedTextField = memo<MemoizedTextFieldProps>(({ item, input, field, meta }) => {
  return (
    <Form.Item {...item} validateStatus={meta.touched && !!meta.error ? 'error' : undefined} help={meta.error}>
      <Input {...field} {...input} />
    </Form.Item>
  );
}, twoLevelShallowEqualObjects);

export const TextField: FC<TextFieldProps> = ({ item, input, format = 'text', ...props }) => {
  const [{ onChange, ...field }, meta] = useField(props);

  const newOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      switch (format) {
        case 'decimal':
          return onChange(InputFormatters.decimal(event));
        case 'integer':
          return onChange(InputFormatters.integer(event));
        default:
          return onChange(event);
      }
    },
    [format, onChange],
  );

  const newField = useMemo(() => ({ onChange: newOnChange, ...field }), [field, newOnChange]);

  return <MemoizedTextField field={newField} meta={meta} item={item} input={input} />;
};
