import { StatisticsByCourierRegion, StatisticsByCourierRegionApi } from '../types/courier-region';

export class StatisticsByCourierRegionMapper {
  public static oneFromApi(region: StatisticsByCourierRegionApi): StatisticsByCourierRegion {
    return {
      id: region.region_id,
      name: region.name,
      price: {
        usd: parseFloat(region.price),
        azn: parseFloat(region.price_azn),
      },
      count: region.count,
    };
  }

  public static manyFromApi(regions: StatisticsByCourierRegionApi[]): StatisticsByCourierRegion[] {
    return regions.map((region) => this.oneFromApi(region));
  }
}
