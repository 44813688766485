import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons/lib';
import { Column } from 'react-table';

import ReadyTable from '../../components/Table/ReadyTable';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';
import { actionsCol, smallCol } from '../../core/helpers/table';

const ProductTypesTable = () => {
  const history = useHistory();

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        ...actionsCol,
        Cell: ({ row: { original } }) => {
          const gotoEdit = useCallback(() => {
            history.push(makePath(routes.productTypes.edit, { id: original.id }));
          }, [original.id]);

          return (
            <span onClick={(e) => e.stopPropagation()}>
              <Button className='next-table-action-btn' type='text' onClick={gotoEdit} icon={<EditOutlined />} />
            </span>
          );
        },
      },
      {
        accessor: 'id',
        Header: 'Kod',
        ...smallCol,
      },
      {
        accessor: 'name',
        Header: 'Azərbaycanca',
      },
      {
        accessor: 'name_tr',
        Header: 'Türkçe',
      },
      {
        accessor: 'name_en',
        Header: 'English',
      },
      {
        accessor: 'name_ru',
        Header: 'По-русски',
      },
      {
        accessor: 'state_name',
        Header: 'Status',
      },
      {
        accessor: 'custom_parent_id',
        Header: 'Parent ID',
        ...smallCol,
      },
    ],
    [history],
  );

  return <ReadyTable name='productTypes' apiUrl={'/api/admin/product-types/getlist'} columns={columns} />;
};

export default React.memo(ProductTypesTable);
