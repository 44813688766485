import React, { FC, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Form, Modal, Spin } from 'antd';
import { useClose } from '../../hooks/common';
import { urlFactory } from '../../core/utils/caller';
import { useForm } from '../../hooks/common/useForm';
import { tableFetchDataAction } from '../../actions/table';
import routes from '../../constants/routes';
import { useConfigReturnTypeFields } from '../../hooks/configReturnTypes/useFields';

type IProps = InjectedFormProps<any> & RouteComponentProps<any> & { dispatch: Dispatch };

const ConfigReturnTypesForm: FC<IProps> = ({ handleSubmit: baseHandleSubmit, initialize, dispatch, match: { params }, submitting, initialized }) => {
  const handleClose = useClose(routes.config.returnTypes.index);

  const handleSubmitSuccess = useCallback(
    async (response: Response) => {
      dispatch(tableFetchDataAction('configReturnTypes', '/api/admin/return_reasons'));
      const {
        data: { name },
      } = await response.json();
      handleClose({ return_type: name });
    },
    [dispatch, handleClose],
  );

  const { handleSubmit, type } = useForm(
    useMemo(
      () => ({
        handleClose,
        handleSubmit: baseHandleSubmit,
        initialize,
        createUrl: [routes.config.returnTypes.create],
        createApiUrl: urlFactory('/api/admin/return_reasons/create'),
        editApiUrl: urlFactory('/api/admin/return_reasons/edit'),
        initializeUrl: urlFactory('/api/admin/return_reasons/info', { return_reason_id: params.id }),
        additionalParams: { return_reason_id: params.id },
        onSuccess: handleSubmitSuccess,
      }),
      [handleSubmitSuccess, baseHandleSubmit, initialize, handleClose, params.id],
    ),
  );

  const title = useMemo(() => {
    return type === 'create' ? 'Yeni iadə növü əlavə et' : 'İadə növündə düzəliş et';
  }, [type]);

  const { nameFieldProps } = useConfigReturnTypeFields();

  return (
    <Modal maskTransitionName='' transitionName='' width={592} visible={true} onCancel={handleClose} confirmLoading={submitting} onOk={handleSubmit} title={title}>
      <Spin spinning={type === 'edit' && !initialized}>
        <Form layout='vertical'>
          <Field {...nameFieldProps} />
        </Form>
      </Spin>
    </Modal>
  );
};

export default reduxForm<any, any>({ form: 'configShops' })(React.memo<IProps>(ConfigReturnTypesForm));
