import { useEffect, useMemo, useRef } from 'react';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { RadioField } from '../../../components/ReduxForm/Radio';
import { TextAreaField } from '../../../components/ReduxForm/TextArea';
import { DateField } from '../../../components/ReduxForm/DateField';
import { SelectField, StaticSelectField } from '../../../components/ReduxForm/Select';
import { MultipleInputField } from '../../../components/ReduxForm/MultipleInput';
import { urlFactory } from '../../../core/utils/caller';
import { CommonConstants } from '../../../constants/common';
import moment, { Moment } from 'moment';
import { search2Object } from '../../../core/utils/search-to-object';
import { InjectedFormProps } from 'redux-form';
import { positiveFloatNormalizer } from '../../../core/helpers/normalizers';
import { InputField } from '../../../components/ReduxForm/Input';

export const useBulkEmailFormFields = (props: InjectedFormProps & RouteComponentProps<any>) => {
  const templatesRef = useRef<any>(null);
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => search2Object(location.search), [location.search]);

  useEffect(() => {
    if (query && query.reFetchTemplates) {
      templatesRef.current?.reFetch();
      if (query.id) {
        props.change('template_id', parseInt(query.id));
      }
      history.push({ search: '', pathname: '' });
    }
  }, [query, history]); //eslint-disable-line react-hooks/exhaustive-deps

  const searchType = useMemo(
    () => ({
      name: 'search_id',
      item: { className: 'bottom-margin-free' },
      field: { className: 'radio-input-column-view radio-input-column-scale' },
      options: [
        { value: 'user_names', label: 'Müştəri adlarına görə' },
        { value: 'user_birthday', label: 'Doğum günü olan müştərilər' },
        { value: 'user_mails', label: 'Müştərinin emailinə görə' },
        { value: 'users', label: 'Bütün müştərilərə' },
        { value: 'declarations_state', label: 'Bağlama statusuna görə' },
        { value: 'orders_state', label: 'Sifariş statusuna görə' },
        { value: 'couriers_state', label: 'Kuryer statusuna görə' },
        { value: 'customs_state', label: 'Bəyan statusuna görə' },
        { value: 'user_limit', label: 'Cari ay limitinə görə' },
        { value: 'flight', label: 'Flight-a görə' },
      ],
      type: 'select',
      component: RadioField,
    }),
    [],
  );

  const body = useMemo(
    () => ({
      name: 'body',
      item: { className: 'bottom-margin-free' },
      field: { placeholder: 'SMS-in məzmununu daxil edin', rows: 4 },
      component: TextAreaField,
    }),
    [],
  );

  const monthlyLimitFieldProps = useMemo(
    () => ({
      name: 'amount',
      item: { className: 'bottom-margin-free' },
      field: { placeholder: 'Cari ay limitini daxil edin...' },
      normalize: positiveFloatNormalizer,
      component: InputField,
    }),
    [],
  );

  const template = useMemo(
    () => ({
      name: 'template_id',
      item: { className: 'bottom-margin-free' },
      field: { placeholder: 'Şablon seçin' },
      type: 'select',
      component: SelectField,
      customRef: templatesRef,
      apiUrl: urlFactory('/api/admin/templates/list', { template_type_id: 2 }),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => object.name,
      responseGetter: (object: any) => object.data,
    }),
    [],
  );

  const plannedAt = useMemo(
    () => ({
      name: 'planned_at',
      item: { className: 'bottom-margin-free' },
      field: {
        placeholder: 'Planlanmış vaxtı daxil edin',
        format: CommonConstants.DATE_TIME,
        showTime: true,
        disabledDate: (date: Moment) => date.diff(moment().startOf('day')) < 0,
      },
      component: DateField,
    }),
    [],
  );

  const users = useMemo(
    () => ({
      name: 'user_id',
      item: { className: 'bottom-margin-free' },
      field: { placeholder: 'Müştəriləri seçin', mode: 'multiple' },
      type: 'select-multiple',
      component: SelectField,
      apiUrl: urlFactory('/api/admin/client/list'),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => `${object.id} - ${object.name} ${object.surname}`,
      responseGetter: (object: any) => object.data,
    }),
    [],
  );

  const userEmails = useMemo(
    () => ({
      name: 'email',
      component: MultipleInputField,
      item: { className: 'bottom-margin-free' },
    }),
    [],
  );

  const declarationState = useMemo(
    () => ({
      name: 'state_id',
      field: { placeholder: 'Status seçin', mode: 'multiple' },
      type: 'select-multiple',
      options: [
        { value: 5, label: 'Öncədən bəyan' },
        { value: 7, label: 'Xarici anbarda' },
        { value: 8, label: 'Yolda' },
        { value: 9, label: 'Yerli anbarda' },
        { value: 10, label: 'Təhvil verilib' },
      ],
      component: StaticSelectField,
    }),
    [],
  );

  const orderState = useMemo(
    () => ({
      name: 'state_id',
      item: { className: 'bottom-margin-free' },
      field: { placeholder: 'Status seçin', mode: 'multiple' },
      type: 'select-multiple',
      options: [
        { value: 1, label: 'Səbət' },
        { value: 2, label: 'Sifariş gözlənilir' },
        { value: 3, label: 'Sifariş verilib' },
        { value: 4, label: 'Ləğv edilib' },
      ],
      component: StaticSelectField,
    }),
    [],
  );

  const declareState = useMemo(
    () => ({
      name: 'd',
      item: { className: 'bottom-margin-free' },
      field: { placeholder: 'Status seçin' },
      options: [
        { value: '0', label: 'Bəyan olunmuş' },
        { value: '1', label: 'Bəyan olunmamış' },
      ],
      component: StaticSelectField,
    }),
    [],
  );

  const courierState = useMemo(
    () => ({
      name: 'state_id',
      item: { className: 'bottom-margin-free' },
      field: { placeholder: 'Status seçin', mode: 'multiple' },
      type: 'select-multiple',
      options: [
        { value: 11, label: 'Kuryer sifariş verildi' },
        { value: 12, label: 'Sifariş hazırlanır' },
        { value: 13, label: 'Kuryerə təhvil verildi' },
        { value: 14, label: 'Bağlama təhvil verildi' },
      ],
      component: StaticSelectField,
    }),
    [],
  );

  const flights = useMemo(
    () => ({
      name: 'flight_id',
      item: { className: 'bottom-margin-free' },
      field: { placeholder: 'Flight seçin' },
      component: SelectField,
      apiUrl: urlFactory('/api/admin/v2/flights/list', { page: 1, per_page: 5000 }),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => `${object.id} - ${object.name}`,
      responseGetter: (object: any) => object.data,
    }),
    [],
  );

  const customsState = useMemo(
    () => ({
      name: 'customs',
      item: { className: 'bottom-margin-free' },
      field: { placeholder: 'Bəyan statusunu seçin', allowClear: true },
      options: [
        { value: '1', label: 'Bəyan olunmuş' },
        { value: '0', label: 'Bəyan olunmamış' },
      ],
      component: StaticSelectField,
    }),
    [],
  );

  return {
    searchType,
    body,
    plannedAt,
    users,
    userEmails,
    declarationState,
    orderState,
    courierState,
    declareState,
    template,
    monthlyLimitFieldProps,
    flights,
    customsState,
  };
};
