import { useCallback, useContext, useMemo } from 'react';
import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';
import { Space } from 'antd';

import { HeaderButton, HeadPortal } from '@modules/layout';

import { DeclarationTableContext } from '../context';
import { declarationKeys } from '@modules/declarations';

export const PostDeclarationsActionBar = () => {
  const { selection, resetSelection, selectAll, fetch, reset, state, setFilterById } = useContext(DeclarationTableContext);

  const isRead = useMemo(() => !!state.filters.find((filter) => filter.id === declarationKeys.read)?.value, [state.filters]);

  const toggleRead = useCallback(() => {
    setFilterById(declarationKeys.read, !isRead);
  }, [isRead, setFilterById]);

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir seçilib
    </HeaderButton>
  );

  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <Space size={0}>
          {!selection.length ? selectAllButton : resetSelectionButton}
          <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
            Yenilə
          </HeaderButton>
          <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
            Sıfırla
          </HeaderButton>
        </Space>
        <Space size={0}>
          <HeaderButton onClick={toggleRead} icon={<Icons.ReadOutlined />}>
            {isRead ? 'Oxunmuş' : 'Oxunmamış'}
          </HeaderButton>
        </Space>
      </ActionBar>
    </HeadPortal>
  );
};
