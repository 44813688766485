import { Dispatch } from 'redux';
import { message } from 'antd';
import { tableFetchDataAction } from './table';
import { caller, urlFactory } from '../core/utils/caller';
import { formDataBuilder } from '../core/utils/form-data-builder';
import { UnexpectedError } from '../core/helpers/errors';

export const removeAction = (objectId: (number | string)[]) => async (dispatch: Dispatch) => {
  const messageKey = 'notification-template-remove-message';
  const values = { template_id: objectId };

  const onSuccess = (success) => {
    message.success({ content: 'Şablon(lar) silindi', key: messageKey });
    dispatch(tableFetchDataAction('notificationTemplates', '/api/admin/message_templates'));
    return success;
  };

  const onUnprocessableEntity = async (response) => {
    message.error({ content: 'Şablon(lar) silinə bilmədi', key: messageKey });

    const { errors } = await response.json();

    Object.values(errors as Record<string, string[]>).forEach((error) => {
      message.error({ content: error[0] });
    });

    return response;
  };

  const onError = (error) => {
    message.error({ content: 'Şablon(lar) silinə bilmədi', key: messageKey });
    return error;
  };

  message.loading({ content: 'Əməliyyat aparılır', key: messageKey });
  try {
    const url = urlFactory('/api/admin/ticket_templates/cancel');
    const body = formDataBuilder({ values });

    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      return onSuccess(response);
    } else if (response.status === 422) {
      return onUnprocessableEntity(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};
