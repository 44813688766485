import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Dropdown, Menu, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined, FileImageOutlined } from '@ant-design/icons/lib';

import { useDeclarationsTableColumns as useDeclarationsColumns } from '../../../next/modules/declarations/hooks/use-table-columns';
import { StateCell } from '../../../components/ConflictedDeclarations/Cell/StateCell';
import { StateFilter } from '../../../components/ConflictedDeclarations/Filter/StateFilter';
import { stopPropagation } from '../../../core/helpers/events';
import { removeAction } from '../../../actions/conflictedDeclarations';
import { makePath } from '../../../core/utils/makePath';
import routes from '../../../constants/routes';

export const useColumns = () => {
  const columns = useDeclarationsColumns();
  const history = useHistory();
  const dispatch = useDispatch();

  return useMemo(
    () =>
      columns.map((column) => {
        switch (column.accessor) {
          case 'actions':
            return {
              ...column,
              Cell: ({ row: original }) => {
                const handleDelete = useCallback(() => {
                  Modal.confirm({
                    title: 'Bağlamanı silməyə əminsinizmi?',
                    content: 'Silməni təsdiqlədikdən sonra bağlamanın geri qaytarılması mümkün olmayacaq.',
                    onOk: () => removeAction([original.id])(dispatch).catch(() => null),
                  });
                }, [original.id]);

                const goToEdit = useCallback(() => {
                  history.push(makePath(routes.declarations.conflicted.edit, { id: original.id }));
                }, [original.id]);

                const goToDocument = useCallback(() => {
                  window.open(original.document_file, '_blank');
                }, [original.document_file]);

                return (
                  <span onClick={stopPropagation}>
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item onClick={goToEdit}>
                            <EditOutlined />
                            <span>Düzəliş et</span>
                          </Menu.Item>
                          <Menu.Item disabled={!original.document_file} onClick={goToDocument}>
                            <FileImageOutlined />
                            <span>Sənədə bax</span>
                          </Menu.Item>
                          <Menu.Divider />
                          <Menu.Item onClick={handleDelete}>
                            <DeleteOutlined />
                            <span>Sil</span>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Button className='next-table-action-btn' type='text' icon={<MoreOutlined />} />
                    </Dropdown>
                  </span>
                );
              },
            };
          case 'state_id':
            return { ...column, Cell: StateCell, Filter: StateFilter };
          default:
            return column;
        }
      }),
    [columns, dispatch, history],
  );
};
