import React from 'react';
import { NextTable } from '@core/table';

import { useStatusTable } from '../hooks';
import { StatusTableContext } from '../contexts';

export const StatusTable = () => {
  const { columns } = useStatusTable();

  return <NextTable filterable={false} sortable={false} pagination={false} context={StatusTableContext} columns={columns} />;
};
