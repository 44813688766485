import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { Button } from 'antd';

import { InputField } from '../../../components/ReduxForm/Input';
import { positiveFloatNormalizer } from '../../../core/helpers/normalizers';
import { CheckboxField } from '../../../components/ReduxForm/Checkbox';
import { TextAreaField } from '../../../components/ReduxForm/TextArea';
import { SelectField } from '../../../components/ReduxForm/Select';
import { urlFactory } from '../../../core/utils/caller';

export const useFormFields = () => {
  const dispatch = useDispatch();
  const [maxWeightIsInfinity, setMaxWeightIsInfinity] = useState(false);

  const handleClickMaxWeightInfinity = useCallback(() => {
    if (!maxWeightIsInfinity) {
      dispatch(change('plans', 'to_weight', null));
    }
    setMaxWeightIsInfinity(!maxWeightIsInfinity);
  }, [maxWeightIsInfinity, dispatch]);

  const minimumWeightFieldProps = useMemo(
    () => ({
      name: 'from_weight',
      item: { label: 'Minimal çəki' },
      field: { placeholder: 'Minimal çəki edin', addonAfter: 'KQ' },
      normalize: positiveFloatNormalizer,
      component: InputField,
    }),
    [],
  );

  const maximumWeightFieldProps = useMemo(
    () => ({
      name: 'to_weight',
      item: { label: 'Maksimal çəki' },
      field: {
        placeholder: 'Maksimal çəki edin',
        disabled: maxWeightIsInfinity,
        addonAfter: (
          <div>
            <Button onClick={handleClickMaxWeightInfinity} className='input-addon-button' type='link'>
              &#8734;
            </Button>
            <span>KQ</span>
          </div>
        ),
      },
      normalize: positiveFloatNormalizer,
      component: InputField,
    }),
    [maxWeightIsInfinity, handleClickMaxWeightInfinity],
  );

  const priceFieldProps = useMemo(
    () => ({
      name: 'price',
      item: { label: 'Qiymət' },
      field: { placeholder: 'Qiymət daxil edin', addonAfter: <>&#36;</> },
      normalize: positiveFloatNormalizer,
      component: InputField,
    }),
    [],
  );

  const typeFieldProps = useMemo(
    () => ({
      name: 'type',
      item: { label: <>&nbsp;</> },
      field: { children: 'Mayedir' },
      type: 'checkbox',
      component: CheckboxField,
    }),
    [],
  );

  const isSpecialFieldProps = useMemo(
    () => ({
      name: 'is_special',
      field: { children: 'Xüsusi tarifdir' },
      type: 'checkbox',
      component: CheckboxField,
    }),
    [],
  );

  const categoryFieldProps = useMemo(
    () => ({
      name: 'tariff_category_id',
      item: { label: 'Kateqoriya' },
      field: { placeholder: 'Kateqoriya seçin' },
      type: 'select',
      component: SelectField,
      apiUrl: urlFactory('/api/admin/tariff/category_list'),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => `${object.id} - ${object.name}`,
      responseGetter: (object: any) => object.data.filter((_, index) => index !== object.data.length - 1),
    }),
    [],
  );

  const descriptionsFieldProps = useMemo(
    () => ({
      name: 'descr',
      item: { label: 'Açıqlama', style: { marginBottom: 0 } },
      field: { placeholder: 'Açıqlama daxil edin', rows: 3 },
      component: TextAreaField,
    }),
    [],
  );

  return useMemo(
    () => ({
      minimumWeightFieldProps,
      maximumWeightFieldProps,
      typeFieldProps,
      descriptionsFieldProps,
      priceFieldProps,
      categoryFieldProps,
      isSpecialFieldProps,
    }),
    [minimumWeightFieldProps, maximumWeightFieldProps, typeFieldProps, descriptionsFieldProps, priceFieldProps, categoryFieldProps, isSpecialFieldProps],
  );
};
