import { useCallback, useMemo } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useService } from '@core/inversify-react';
import { message, Modal } from 'antd';

import { IRemoveUsersRepo, RemoveUsersRepoType } from '../repos';
import { useUserAddress, useUserById, useUserRelatedSections } from './';
import * as React from 'react';

export const useUser = (id: string) => {
  const history = useHistory();
  const location = useLocation();
  const { data, isLoading, error } = useUserById(id);
  const [address, { copyAddress }] = useUserAddress(data);
  const userRelatedSectionHandlers = useUserRelatedSections(data);

  const removeUsersRepo = useService<IRemoveUsersRepo>(RemoveUsersRepoType);

  const openUpdate = useCallback(() => {
    history.push(generatePath('/@next/users/:id/update', { id }), { background: location });
  }, [history, id, location]);

  const openDeclaration = useCallback(() => {
    history.push({ pathname: generatePath('/@next/declarations/:id', { id }) });
  }, [history, id]);

  const openUpdatePermissions = React.useCallback(() => {
    history.push(generatePath('/@next/users/:id/update/permissions', { id }), { background: location });
  }, [history, id, location]);

  const remove = useCallback(() => {
    const onOk = async () => {
      const result = await removeUsersRepo.execute([id]);

      if (result.status === 200) {
        message.success('Bağlama silindi.');
      } else {
        message.error(result.response);
      }
    };

    Modal.confirm({ title: 'Diqqət', content: 'İstifadəçini silməyə əminsinizmi?', onOk });
  }, [id, removeUsersRepo]);

  const role = useMemo(() => {
    switch (data?.role) {
      case 'admin':
        return 'Admin';
      case 'back-office':
        return 'Back office';
      case 'warehouseman':
        return 'Anbardar';
      default:
        return 'Müştəri';
    }
  }, [data?.role]);

  return { data, isLoading, error, remove, openUpdate, openDeclaration, openUpdatePermissions, address, copyAddress, role, ...userRelatedSectionHandlers };
};
