import React, { useMemo, useState } from 'react';
import { pixelCol, smallCol } from '../../core/helpers/table';
import CheckCell from '../../components/Table/core/Components/Cell/CheckCell';
import { EyeInvisibleOutlined, EyeOutlined, GlobalOutlined, MobileOutlined } from '@ant-design/icons';
import { blue, grey } from '@ant-design/colors';
import { Button, Modal, Tooltip } from 'antd';
import { stopPropagation } from '../../core/helpers/events';

export const useBonazOffersTable = () => {
  const columns = useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'Ad',
      },
      {
        accessor: 'logo',
        Header: 'Logo',
        Cell: ({ cell: { value }, row: { original } }) => {
          return (
            <div style={{ textAlign: 'center', flex: 1 }}>
              <a target='_blank' rel='noreferrer noopener' href={original.redirect_url}>
                <img src={value} alt='Logo' style={{ maxHeight: 36 }} />
              </a>
            </div>
          );
        },
      },
      {
        accessor: 'country.name',
        Header: 'Ölkə',
      },
      {
        accessor: 'accepts',
        Header: 'Qəbul',
        ...pixelCol(98),
        Cell: ({ cell: { value } }) => {
          return (
            <div style={{ display: 'flex' }}>
              <Tooltip title='Web' mouseLeaveDelay={0}>
                <GlobalOutlined style={{ fontSize: 24, color: value.web ? blue[5] : grey[5], marginRight: 4 }} />
              </Tooltip>
              <Tooltip title='Mobile web' mouseLeaveDelay={0}>
                <div style={{ position: 'relative', color: value.mobile ? blue[5] : grey[5], marginRight: 4 }}>
                  <MobileOutlined style={{ fontSize: 24 }} />
                  <GlobalOutlined style={{ position: 'absolute', top: 0, left: 3, fontSize: 14, backgroundColor: '#fff', padding: 2, borderBottomLeftRadius: '50%', borderBottomRightRadius: '50%' }} />
                </div>
              </Tooltip>
              <Tooltip title='Mobile app' mouseLeaveDelay={0}>
                <MobileOutlined style={{ fontSize: 24, color: value.app ? blue[5] : grey[5] }} />
              </Tooltip>
            </div>
          );
        },
      },
      {
        accessor: 'percent',
        Header: 'Faiz',
        ...smallCol,
      },
      {
        accessor: 'description',
        Header: 'Açıqlama',
        Cell: ({ cell: { value }, row: { original } }) => {
          const [visible, setVisible] = useState(false);

          return (
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }} onClick={stopPropagation}>
              <Button onClick={() => setVisible(true)} icon={!visible ? <EyeOutlined /> : <EyeInvisibleOutlined />} />
              <Modal visible={visible} destroyOnClose={true} footer={null} onCancel={() => setVisible(false)} title={original.name}>
                <div dangerouslySetInnerHTML={{ __html: value }} />
              </Modal>
            </div>
          );
        },
        ...pixelCol(95),
      },
      {
        accessor: 'targets',
        Header: 'Hədəflər',
        Cell: ({ cell: { value } }) => {
          const [visible, setVisible] = useState(false);

          return (
            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }} onClick={stopPropagation}>
              <Button onClick={() => setVisible(true)} icon={!visible ? <EyeOutlined /> : <EyeInvisibleOutlined />} />
              <Modal visible={visible} destroyOnClose={true} footer={null} onCancel={() => setVisible(false)}>
                <div>
                  <div className='bonaz-categories'>
                    {value.map((target) => (
                      <p key={target.id}>
                        <b>
                          {target.name}: {target.percent}
                        </b>
                      </p>
                    ))}
                  </div>
                </div>
              </Modal>
            </div>
          );
        },
        ...pixelCol(95),
      },
      {
        accessor: 'is_global',
        Header: 'Qlobal',
        Cell: CheckCell,
        ...pixelCol(75),
      },
    ],
    [],
  );

  return { columns };
};
