import { useCallback, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { message } from 'antd';

import { useQuery } from '../../../shared/modules/use-query/hooks';

import { StatisticsService } from '../services';
import { StatisticsByCourierRegion } from '../types/courier-region';

export const useCouriersStatisticsByRegion = () => {
  const [dateRange, setDateRange] = useState<[Moment, Moment]>([moment().startOf('month'), moment()]);
  const [stateId, setStateId] = useState('11');

  const handleStateIdChange = useCallback((value) => {
    setStateId(value);
  }, []);

  const handleChangeDateRange = useCallback((value: [Moment | null, Moment | null] | null) => {
    const startDate = value?.[0] || null;
    const endDate = value?.[1] || null;

    if (startDate && endDate) {
      setDateRange([startDate, endDate]);
    }
  }, []);

  const result = useQuery<StatisticsByCourierRegion[]>(
    useCallback(async () => {
      if (!dateRange) {
        return null;
      }

      const result = await StatisticsService.CourierRegion.get(stateId, dateRange[0], dateRange[1]);

      if (result.status === 200) {
        return result.data;
      } else {
        message.error(result.data);
        throw new Error(result.data);
      }
    }, [dateRange, stateId]),
    useMemo(() => ({ initialData: [], enabled: !!dateRange }), [dateRange]),
  );

  return { result, dateRange, handleChangeDateRange, handleStateIdChange, stateId };
};
