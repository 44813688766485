import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import { get } from 'lodash';
import { IAppState } from '../interfaces/app-state.interface';

export const formValuesSelector = (form) =>
  createSelector<IAppState, any, any>(
    (state) => state,
    (state) => {
      return getFormValues(form)(state) || {};
    },
  );

export const formFieldValueSelector = (form: string, field: string, defaultValue?: any) => {
  return createSelector<IAppState, any, any>(
    (state) => state,
    (state) => get(getFormValues(form)(state), field) || defaultValue,
  );
};
