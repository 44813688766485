import { useCallback, useMemo } from 'react';
import { message } from 'antd';

import { addressMaker, IWarehouseSettings } from '@modules/settings';
import { IDetailedUser } from '@modules/users';
import { useWarehousesSettingsData } from '@modules/settings/hooks/use-warehouse-settings-data';

export const useUserAddress = (user?: IDetailedUser) => {
  const { data } = useWarehousesSettingsData();
  const address = useMemo(() => (user ? addressMaker(data.data as IWarehouseSettings, user) : ''), [data.data, user]);

  const copyAddress = useCallback(async () => {
    await navigator.clipboard.writeText(`${address}`);
    message.success('Ünvan kopyalandı.');
  }, [address]);

  return [address, { copyAddress }] as [string, { copyAddress: () => Promise<void> }];
};
