import { Dispatch } from 'redux';
import { caller, urlFactory } from '../core/utils/caller';
import { message } from 'antd';
import { tableFetchDataAction } from './table';
import { UnexpectedError } from '../core/helpers/errors';

export const openFlightAction = (id: number) => async (dispatch: Dispatch) => {
  const messageKey = 'flights-open-message';
  const values = { flight_id: id };

  const onSuccess = (success) => {
    message.success({ content: 'Flight açıldı', key: messageKey });
    dispatch(tableFetchDataAction('flights', '/api/admin/v2/flights/list'));
    return success;
  };

  const onUnprocessableEntity = async (response) => {
    message.error({ content: 'Flight açıla bilmədi', key: messageKey });
    const { errors } = await response.json();

    Object.values(errors as Record<string, string[]>).forEach((error: string[]) => {
      message.error({ content: error[0] });
    });

    return response;
  };

  const onError = (error) => {
    message.error({ content: 'Flight açıla bilmədi', key: messageKey });
    return error;
  };

  message.loading({ content: 'Əməliyyat aparılır', key: messageKey });
  try {
    const url = urlFactory('/api/admin/flights/open', values);

    const response = await caller(url, { method: 'POST' });

    if (response.ok) {
      return onSuccess(response);
    } else if (response.status === 422) {
      return onUnprocessableEntity(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};

export const closeFlightAction = () => async (dispatch: Dispatch) => {
  const messageKey = 'flights-close-message';

  const onSuccess = (success) => {
    message.success({ content: 'Flight bağlandı', key: messageKey });
    dispatch(tableFetchDataAction('flights', '/api/admin/v2/flights/list'));
    return success;
  };

  const onUnprocessableEntity = async (response) => {
    message.error({ content: 'Flight bağlana bilmədi', key: messageKey });
    const { errors } = await response.json();

    Object.values(errors as Record<string, string[]>).forEach((error: string[]) => {
      message.error({ content: error[0] });
    });

    return response;
  };

  const onError = (error) => {
    message.error({ content: 'Flight bağlana bilmədi', key: messageKey });
    return error;
  };

  message.loading({ content: 'Əməliyyat aparılır', key: messageKey });
  try {
    const url = urlFactory('/api/admin/flights/close', { close: true });

    const response = await caller(url, { method: 'POST' });

    if (response.ok) {
      return onSuccess(response);
    } else if (response.status === 422) {
      return onUnprocessableEntity(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};

export const exportExcelFlightAction = async (id: number, partnerId?: number | undefined) => {
  const messageKey = 'flights-export-excel-message';

  const onSuccess = async (response) => {
    message.success({ content: 'Flight-ın excel sənədi yaradıldı və yükləmək üçün hazırlanır.', key: messageKey });

    const blob = await response.blob();

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `flight_export_${id}.xls`;
    a.click();

    return response;
  };

  const onError = (error) => {
    message.error({ content: 'Flight-ın excel sənədi yaradıla bilmədi', key: messageKey });
    return error;
  };

  message.loading({ content: 'Əməliyyat aparılır', key: messageKey });
  try {
    const url = urlFactory('/api/admin/flights/export', { flight_id: id, partner_id: partnerId });

    const response = await caller(url);

    if (response.ok) {
      return onSuccess(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};

export const exportXMLFlightAction = async (id: number, liquid?: boolean | undefined, partnerId?: number | undefined) => {
  const messageKey = 'flights-export-xml-message';

  const onSuccess = async (response) => {
    message.success({ content: 'Flight-ın xml sənədi yaradıldı və yükləmək üçün hazırlanır.', key: messageKey });

    const blob = await response.blob();

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `flight_export_${id}.xml`;
    a.click();

    return response;
  };

  const onError = (error) => {
    message.error({ content: 'Flight-ın xml sənədi yaradıla bilmədi', key: messageKey });
    return error;
  };

  message.loading({ content: 'Əməliyyat aparılır', key: messageKey });
  try {
    const url = urlFactory('/api/admin/declaration/get_xml', { flight_id: id, liquid, partner_id: partnerId });

    const response = await caller(url);

    if (response.ok) {
      return onSuccess(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};

export const fromWarehouseToFlightAction = async () => {
  const url = urlFactory('/api/task/to_flight');

  try {
    const response = await caller(url);

    if (response.ok) {
      return { success: true };
    } else if (response.status === 400 || response.status === 422) {
      const { errors } = await response.json();

      return { success: false, errors: errors };
    } else {
      return { success: false, error: 'Əməliyyat aparıla bilmədi' };
    }
  } catch (e) {
    return { success: false, error: 'Şəbəkə ilə əlaqə qurula bilmədi' };
  }
};
