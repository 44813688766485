import React, { useMemo } from 'react';

import { InputField } from '../../../components/ReduxForm/Input';
import { positiveFloatNormalizer } from '../../../core/helpers/normalizers';
import { TextAreaField } from '../../../components/ReduxForm/TextArea';
import { SelectField } from '../../../components/ReduxForm/Select';
import { urlFactory } from '../../../core/utils/caller';

export const useFormFields = () => {
  const nameFieldProps = useMemo(
    () => ({
      name: 'name',
      item: { label: 'Ad' },
      field: { placeholder: 'Rayonun adını daxil edin' },
      component: InputField,
    }),
    [],
  );

  const stateIdFieldProps = useMemo(
    () => ({
      name: 'state_id',
      item: { label: 'Status' },
      field: { placeholder: 'Status seçin' },
      type: 'select',
      component: SelectField,
      apiUrl: urlFactory('/api/admin/states/getlistbymodelid', { model_id: 5 }),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => object.name,
      responseGetter: (object: any) => object.data,
    }),
    [],
  );

  const priceFieldProps = useMemo(
    () => ({
      name: 'price',
      item: { label: 'Qiymət' },
      field: { placeholder: 'Qiymət daxil edin', addonAfter: <>&#x20bc;</> },
      normalize: positiveFloatNormalizer,
      component: InputField,
    }),
    [],
  );

  const descriptionsFieldProps = useMemo(
    () => ({
      name: 'descr',
      item: { label: 'Açıqlama', style: { marginBottom: 0 } },
      field: { placeholder: 'Açıqlama daxil edin', rows: 3 },
      component: TextAreaField,
    }),
    [],
  );

  return useMemo(
    () => ({
      nameFieldProps,
      priceFieldProps,
      stateIdFieldProps,
      descriptionsFieldProps,
    }),
    [nameFieldProps, priceFieldProps, descriptionsFieldProps, stateIdFieldProps],
  );
};
