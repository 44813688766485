import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import routes from '../../constants/routes';

const ConfigReturnTypesActions = () => {
  const history = useHistory();

  const goToCreate = useCallback(() => {
    history.push(routes.config.returnTypes.create);
  }, [history]);

  return (
    <div className='actions-wrapper'>
      <div className='actions-wrapper-left'>
        <Button onClick={goToCreate} type='primary' icon={<PlusCircleOutlined />}>
          Yeni
        </Button>
      </div>
    </div>
  );
};

export default ConfigReturnTypesActions;
