export const boxQueryKeys = {
  id: 'id',
  name: 'container_name',
  user: {
    id: 'user_id',
    name: 'user_name',
  },
  declarationCount: 'declaration_count',
  createdAt: 'created_at',
};
