import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { HandoverDeclarationMapper } from '../mappers';
import { IHandoverDeclarationDto } from '../dto';

export type IHandoverDeclarationsRepoResponse = IRepoResponse<200> | IRepoResponse<400 | 500, string>;

export type IHandoverDeclarationsRepo = IRepo<[IHandoverDeclarationDto, (number | string)[]], IHandoverDeclarationsRepoResponse>;

export const HandoverDeclarationsRepoType = Symbol.for('HandoverDeclarationRepo');

@bind(HandoverDeclarationsRepoType)
export class HandoverDeclarationsRepo implements IHandoverDeclarationsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto: IHandoverDeclarationDto, declarationIds) {
    try {
      const body = new FormData();
      const mappedBody = HandoverDeclarationMapper.toPersistence(dto, declarationIds);

      appendToFormData(mappedBody, body);

      const url = urlMaker('/api/admin/declaration/pay');

      const result = await this.httpClient.post({ url, body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
