import { caller, urlFactory } from '../core/utils/caller';
import { UnexpectedError } from '../core/helpers/errors';

export const getState = async (id) => {
  const url = urlFactory('/api/admin/archive/sms_status', { sms_id: id });

  const onSuccess = (success) => {
    return success.text();
  };

  const onError = (error) => {
    return error;
  };

  try {
    const response = await caller(url);

    if (response.ok) {
      return onSuccess(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};

export const changeSmsActive = async ({ id, active }) => {
  const url = urlFactory('/api/admin/archive/sms_active', { sms_id: id, active });

  const onSuccess = (success) => {
    return success;
  };

  const onError = (error) => {
    return error;
  };

  try {
    const response = await caller(url);

    if (response.ok) {
      return onSuccess(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};

export const sendAllQueuedSms = async () => {
  const url = urlFactory('/api/admin/archive/send_sms');

  try {
    const response = await caller(url, { method: 'POST' });

    return response.ok;
  } catch (e) {
    return false;
  }
};
