import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { CloseFlight } from '../containers/close';
import { localURLMaker } from '../../../shared/utils/url-maker';

export const CloseFlightPage = () => {
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/flights');
  }, [history]);

  const handleSubmitSucceed = useCallback(() => {
    history.push(localURLMaker('/flights', {}, { reFetchFlights: true }));
  }, [history]);

  return <CloseFlight onCancel={handleClose} title='Uçuşu bağla' onSubmitSucceed={handleSubmitSucceed} />;
};
