import { useMemo } from 'react';
import { useInfo } from '../common/useInfo';
import { urlFactory } from '../../core/utils/caller';

export const useBonazStatistics = () => {
  const { data, loading } = useInfo(
    useMemo(
      () => ({
        apiUrl: urlFactory('/api/admin/bonaz/balance'),
        initialState: {
          rate: { 'azn/try': '0.00' },
          active: { azn: '0.00 AZN', try: '0.00 TL' },
          pending: { azn: '0.00 AZN', try: '0.00 TL' },
          total: { azn: '0.00 AZN' },
          withdraws: [],
        },
      }),
      [],
    ),
  );

  return { data, loading };
};
