import { FormErrors } from '@core/form';

import { CreatePlanDto, CreatePlanDtoPersistence } from '../dtos';
import { IPlan } from '../interfaces';

export class CreatePlanMapper {
  public static toDomain(plan: IPlan): CreatePlanDto {
    return {
      id: plan.id.toString(),
      weightFrom: plan.weight.from.toString(),
      weightTo: plan.weight.to?.toString() || '',
      price: plan.price.toString(),
      description: plan.description,
      isLiquid: plan.type === 'maye',
      isSpecial: plan.tariffCategory.name !== 'Standart',
      categoryId: plan.tariffCategory.id.toString(),
    };
  }

  public static toPersistence(plan: CreatePlanDto): CreatePlanDtoPersistence {
    return {
      tariff_id: plan.id,
      from_weight: plan.weightFrom,
      to_weight: plan.weightTo,
      price: plan.price,
      descr: plan.description,
      from_unit: '1',
      to_unit: '1',
      type: plan.isLiquid ? '1' : '2',
      is_special: plan.isSpecial ? '1' : '0',
      currency: 'USD',
      tariff_category_id: plan.isSpecial ? plan.categoryId : '',
    };
  }
}

export class CreatePlanErrorsMapper {
  public static toDomain(errors): FormErrors<CreatePlanDto> {
    return {
      id: errors.tariff_id?.join('. '),
      weightFrom: errors.from_weight?.join('. '),
      weightTo: errors.to_weight?.join('. '),
      price: errors.price?.join('. '),
      description: errors.descr?.join('. '),
    };
  }
}
