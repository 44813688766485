import { FC, useCallback, useContext, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { Row, Col, Button, Select, Modal, Space } from 'antd';
import * as Icons from '@ant-design/icons';
import { SelectField, TextField, CheckboxField, UploadField, TextAreaField, filterOptionStart } from '@core/form';
import { AntForm, FieldGroup, FieldGroupButtonWrapper } from '@core/ui';
import { useSub } from '@core/event-bus';

import { useLimitedUsers } from '@modules/users';
import { useProductTypes } from '@modules/product-types';
import { useAuth } from '@modules/auth';
import { useShops } from '@modules/shops';

import { CreateUnknownDeclarationDto } from '../dto';
import { useCreateUnknownDeclaration } from '../hooks';
import { CreateUnknownDeclarationContext } from '../context';

export const CreateUnknownDeclarationForm: FC<FormikProps<CreateUnknownDeclarationDto>> = ({ values, submitForm, isSubmitting, setFieldValue }) => {
  const { can } = useAuth();
  const { onClose, acceptRef } = useContext(CreateUnknownDeclarationContext);
  const location = useLocation<any>();

  const users = useLimitedUsers();
  const productTypes = useProductTypes();
  const shops = useShops();

  useSub('@shop-names/create/succeed', (payload) => {
    setFieldValue('shop', payload.name);
  });

  const userOptions = useMemo(
    () =>
      users.data?.map((user) => (
        <Select.Option key={user.id} value={user.id.toString()}>
          #{user.id} - {user.firstname} {user.lastname}
        </Select.Option>
      )),
    [users.data],
  );

  const productTypeOptions = useMemo(
    () =>
      productTypes.data?.map((productType) => (
        <Select.Option key={productType.id} value={productType.id.toString()}>
          {productType.name}
        </Select.Option>
      )),
    [productTypes.data],
  );

  const shopsOptions = useMemo(
    () =>
      shops.data?.map((shop) => (
        <Select.Option key={shop.id} value={shop.name}>
          {shop.name}
        </Select.Option>
      )),
    [shops.data],
  );

  const title = <Space size={8}>{!values.id ? 'Yeni bağlama yarat' : 'Bağlamada düzəliş et'}</Space>;

  const onAccept = useCallback(() => {
    acceptRef.current = true;
    return submitForm();
  }, [acceptRef, submitForm]);

  const onUpdate = useCallback(() => {
    acceptRef.current = false;
    return submitForm();
  }, [acceptRef, submitForm]);

  const footerNode = useMemo(
    () => (
      <Space>
        <Button onClick={onClose}>Bağla</Button>
        <Button type='primary' loading={isSubmitting && acceptRef.current} disabled={isSubmitting} onClick={onAccept}>
          Qəbul et
        </Button>
        <Button type='primary' loading={isSubmitting && !acceptRef.current} disabled={isSubmitting} onClick={onUpdate}>
          Dəyişdir
        </Button>
      </Space>
    ),
    [acceptRef, isSubmitting, onAccept, onClose, onUpdate],
  );

  return (
    <Modal visible={true} width={768} footer={footerNode} onCancel={onClose} title={title}>
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 0]}>
          <Col xs={24} md={8}>
            <SelectField name='userId' item={{ label: 'İstifadəçi' }} input={{ placeholder: 'İstifadəçini seçin...', loading: users.isLoading, disabled: users.isLoading }}>
              {userOptions}
            </SelectField>
          </Col>
          <Col xs={24} md={6}>
            <TextField name='globalTrackCode' item={{ label: 'Qlobal izləmə kodu' }} input={{ placeholder: 'Global izləmə kodunu daxil edin...' }} />
          </Col>
          <Col xs={24} md={10}>
            <FieldGroup>
              <SelectField name='shop' item={{ label: 'Mağaza', style: { flex: 1 } }} input={{ placeholder: 'Mağazanı daxil edin...', filterOption: filterOptionStart }}>
                {shopsOptions}
              </SelectField>
              <FieldGroupButtonWrapper>
                <NavLink to={{ pathname: '/@next/shop-names/create', state: { background: location.state?.background, modal: location } }}>
                  <Button icon={<Icons.PlusCircleOutlined />} />
                </NavLink>
              </FieldGroupButtonWrapper>
            </FieldGroup>
          </Col>
          <Col xs={24} md={9}>
            <SelectField name='productTypeId' item={{ label: 'Məhsulun tipi' }} input={{ placeholder: 'Məhsulun tipini seçin...', loading: productTypes.isLoading, disabled: productTypes.isLoading }}>
              {productTypeOptions}
            </SelectField>
          </Col>
          <Col xs={24} md={5}>
            <TextField name='quantity' format='integer' item={{ label: 'Say' }} input={{ placeholder: 'Sayı daxil edin...', suffix: 'ədəd' }} />
          </Col>
          <Col xs={24} md={5}>
            <TextField name='weight' format='decimal' item={{ label: 'Çəki' }} input={{ placeholder: 'Çəkini daxil edin...', suffix: 'kq', disabled: !can('changeweightdeclaration') }} />
          </Col>
          <Col xs={24} md={6}>
            <TextField name='price' format='decimal' item={{ label: 'Məhsulun dəyəri' }} input={{ placeholder: 'Məhsulun dəyərini daxil edin...', suffix: '₺' }} />
          </Col>
          <Col xs={24} md={6}>
            <TextField
              name='deliveryPrice'
              format='decimal'
              item={{ label: 'Çatdırılma qiyməti' }}
              input={{ placeholder: 'Çatdırılma qiymətini daxil edin...', suffix: '$', disabled: !can('changedeliveryprice') }}
            />
          </Col>
          <Col xs={24}>
            <UploadField item={{ label: 'Sənəd yükə' }} name='file'>
              {() => (
                <Button type='primary' icon={<Icons.DownloadOutlined />}>
                  Sənəd yüklə
                </Button>
              )}
            </UploadField>
          </Col>
          <Col xs={12} md={8}>
            <CheckboxField name='isLiquid' item={{ className: 'ant-form-item-checkbox' }} input={{ children: 'Tərkibində maye var' }} />
          </Col>
          <Col xs={24}>
            <TextAreaField name='description' item={{ label: 'Açıqlama' }} input={{ placeholder: 'Açıqlamanı daxil edin' }} />
          </Col>
        </Row>
      </AntForm>
    </Modal>
  );
};

export const CreateUnknownDeclaration = () => {
  const { initialValues, onSubmit } = useCreateUnknownDeclaration();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit} component={CreateUnknownDeclarationForm} />;
};
