import React, { FC } from 'react';
import ReadyTable from '../../../../components/Table/ReadyTable';
import { useDeclarationsTableColumns } from '../hooks/use-table-columns';

const DeletedDeclarationsTable: FC<any> = () => {
  const columns = useDeclarationsTableColumns();

  return <ReadyTable filterable={false} sortable={false} name='deletedDeclarations' apiUrl='/api/admin/customs/deleteddeclarations' columns={columns} />;
};

export default React.memo(DeletedDeclarationsTable);
