import { appendToFormData } from '../../../shared/utils/append-to-form-data';
import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { Result } from '../../../shared/utils/result';

import { FlightMappers } from '../mappers';
import { FlightBulkManifestFormData, FlightBulkManifestResult } from '../types/bulk-manifest';

export class FlightBulkManifestService {
  public static async upload(formData: FlightBulkManifestFormData): Promise<Result<200, FlightBulkManifestResult> | Result<400 | 500, string>> {
    const url = urlMaker('/api/admin/flights/manifests');
    const body = new FormData();
    appendToFormData(FlightMappers.BulkManifest.uploadToApi(formData), body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        const { data } = await response.json();

        return new Result(200, FlightMappers.BulkManifest.uploadFromApi(data), null);
      } else {
        return new Result(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new Result(500, 'Sorğu göndərilə bilmədi. İnternet bağlantınızı yoxlayın.', null);
    }
  }

  public static async getByParcels(id: string | number): Promise<Result<200, FlightBulkManifestResult> | Result<400 | 500, string>> {
    const url = urlMaker('/api/admin/flights/manifests_box', { flight_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();

        return new Result(200, FlightMappers.BulkManifest.uploadFromApi(data), null);
      } else if (response.status === 422) {
        const { errors } = await response.json();
        return new Result(400, Object.values(errors).flat().join(', '), null);
      } else {
        return new Result(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new Result(500, 'Sorğu göndərilə bilmədi. İnternet bağlantınızı yoxlayın.', null);
    }
  }
}
