import React, { useMemo } from 'react';

import { InputField } from '../../../components/ReduxForm/Input';
import { TextAreaField } from '../../../components/ReduxForm/TextArea';
import { SelectField } from '../../../components/ReduxForm/Select';
import { urlFactory } from '../../../core/utils/caller';
import { UploadField } from '../../../components/ReduxForm/Upload';
import { Typography } from 'antd';

export const useFormFields = () => {
  const nameFieldProps = useMemo(
    () => ({
      name: 'label',
      item: { label: 'Mağaza' },
      field: { placeholder: 'Mağazanın adını daxil edin' },
      component: InputField,
    }),
    [],
  );

  const urlFieldProps = useMemo(
    () => ({
      name: 'url',
      item: { label: 'Mağaza websaytı' },
      field: { placeholder: 'Mağaza websaytını daxil edin' },
      component: InputField,
    }),
    [],
  );

  const categoryFieldProps = useMemo(
    () => ({
      name: 'category_id',
      item: { label: 'Kateqoriya' },
      field: { placeholder: 'Kateqoriya seçin', mode: 'multiple' },
      type: 'select-multiple',
      component: SelectField,
      apiUrl: urlFactory('/api/admin/shop/categories'),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => object.name,
      responseGetter: (object: any) => object.data,
    }),
    [],
  );

  const logoFieldProps = useMemo(
    () => ({
      name: 'logo',
      item: {
        label: (
          <>
            Mağazanın logosu&nbsp;
            <Typography.Paragraph style={{ marginBottom: 0 }} type='secondary'>
              (Tövsiyyə olunan ölçü: 256x256)
            </Typography.Paragraph>
          </>
        ),
      },
      component: UploadField.Simple,
    }),
    [],
  );

  const descriptionsFieldProps = useMemo(
    () => ({
      name: 'descr',
      item: { label: 'Açıqlama', style: { marginBottom: 0 } },
      field: { placeholder: 'Açıqlama daxil edin', rows: 3 },
      component: TextAreaField,
    }),
    [],
  );

  return useMemo(
    () => ({
      nameFieldProps,
      urlFieldProps,
      categoryFieldProps,
      descriptionsFieldProps,
      logoFieldProps,
    }),
    [nameFieldProps, urlFieldProps, categoryFieldProps, descriptionsFieldProps, logoFieldProps],
  );
};
