import { useCallback, useEffect, useMemo, useState } from 'react';
import { caller } from '../../core/utils/caller';
import { UnexpectedError } from '../../core/helpers/errors';

export const useInfo = ({ apiUrl, method = 'GET', responseGetter, onValidationError, initialState, delay = 0, disabled }: any) => {
  const [loading, setLoading] = useState(!disabled);
  const [error, setError] = useState<string | undefined>();
  const [data, setData] = useState(initialState);

  const reset = useCallback(() => {
    setError(undefined);
    setData(initialState);
  }, [initialState]);

  const fetch = useCallback(async () => {
    try {
      setError(undefined);
      setLoading(true);
      const response = await caller(apiUrl, { method });

      if (response.ok) {
        const data = await response.json();

        if (responseGetter) {
          const result = responseGetter(data);
          if (result) {
            setData(responseGetter(data));
          } else {
            UnexpectedError();
          }
        } else {
          if (data) {
            setData(data);
          } else {
            UnexpectedError();
          }
        }
      } else if (response.status === 422 || response.status === 400) {
        const { errors } = await response.json();

        if (onValidationError) {
          onValidationError(errors);
        } else {
          UnexpectedError();
        }
      } else {
        UnexpectedError();
      }
    } catch (e) {
      setError('Məlumatlar əldə edilə bilmədi');
    } finally {
      setLoading(false);
    }
  }, [apiUrl, method, responseGetter, onValidationError]);

  useEffect(() => {
    setTimeout(() => {
      if (!disabled) {
        fetch().then();
      }
    }, delay);
  }, [fetch, delay, disabled]);

  return useMemo(() => ({ data, loading, error, fetch, reset, setData }), [data, loading, error, fetch, reset]);
};
