import React, { FC, useCallback, useMemo } from 'react';
import { Button } from 'antd';
import { useQuery } from '../../../shared/modules/use-query/hooks';
import { FlightService } from '../services';

export const FlightWeightInteraction: FC<{ id: string | number }> = ({ id }) => {
  const { data, loading, reFetchRef } = useQuery<any>(
    useCallback(async () => {
      const result = await FlightService.WeightCalculator.getById(id);

      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error(result.data);
      }
    }, [id]),
    useMemo(() => ({ initialData: null, enabled: false }), []),
  );

  if (data) {
    return data;
  }

  return (
    <Button size='small' onClick={() => reFetchRef.current()} loading={loading}>
      Hesabla
    </Button>
  );
};
