import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Form, message, Modal, Spin } from 'antd';

import { useFormFields } from '../../hooks/flights/useForm';
import { useForm } from '../../hooks/common/useForm';
import { useClose } from '../../hooks/common';
import { urlFactory } from '../../core/utils/caller';
import { Dispatch } from 'redux';
import { tableFetchDataAction } from '../../actions/table';
import routes from '../../constants/routes';
import moment from 'moment';

const FlightsForm: FC<InjectedFormProps<any> & RouteComponentProps<any> & { dispatch: Dispatch }> = ({
  handleSubmit: baseHandleSubmit,
  submitting,
  initialize,
  dispatch,
  initialized,
  match: { params },
}) => {
  const handleClose = useClose(routes.flights.index);

  const handleSubmitSuccess = useCallback(() => {
    dispatch(tableFetchDataAction('flights', '/api/admin/flights/getlist'));
  }, [dispatch]);

  const formOptions = useMemo(
    () => ({
      handleClose,
      handleSubmit: baseHandleSubmit,
      initialize,
      createUrl: routes.flights.create,
      createApiUrl: urlFactory('/api/admin/flights/create'),
      editApiUrl: urlFactory('/api/admin/flights/edit'),
      onSuccess: handleSubmitSuccess,
      onError: async (errors) => {
        if (errors.open_flights_error) {
          message.warning(errors.open_flights_error.join('. '));
        }
      },
      initializeUrl: urlFactory('/api/admin/flights/info', { flight_id: params.id }),
      additionalParams: { flight_id: params.id },
    }),
    [params.id, handleSubmitSuccess, baseHandleSubmit, initialize, handleClose],
  );

  const { handleSubmit, type } = useForm(formOptions);

  const title = useMemo(() => {
    return type === 'create' ? 'Yeni flight əlavə et' : 'Flight-da düzəliş et';
  }, [type]);

  const { nameFieldProps, startDateFieldProps, endDateFieldProps, stateFieldProps } = useFormFields();

  useEffect(() => {
    initialize({
      name: moment().format('LLLL'),
      start_date: moment().format('YYYY-MM-DD'),
      state_id: 29,
    });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal maskTransitionName='' transitionName='' width={592} visible={true} onCancel={handleClose} confirmLoading={submitting} onOk={handleSubmit} title={title}>
      <Spin spinning={type === 'edit' && !initialized}>
        <Form layout='vertical'>
          <Field {...nameFieldProps} />
          <Field {...startDateFieldProps} />
          <Field {...endDateFieldProps} />
          <Field {...stateFieldProps} />
        </Form>
      </Spin>
    </Modal>
  );
};

export default reduxForm<any, any>({ form: 'flights' })(
  React.memo<InjectedFormProps<any> & RouteComponentProps & { dispatch: Dispatch }>(FlightsForm, (prev, curr) => {
    return prev.handleSubmit === curr.handleSubmit && prev.submitting === curr.submitting && prev.match.params === curr.match.params && prev.initialized === curr.initialized;
  }),
);
