import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { IBranch } from '../interfaces';
import { BranchMapper } from '../mappers';

export type IGetBranchesRepoResponse = IRepoResponse<200, IBranch[]> | IRepoResponse<400 | 500, string>;

export type IGetBranchesRepo = IRepo<[any], IGetBranchesRepoResponse>;

export const GetBranchesRepoType = Symbol.for('GetBranchesRepo');

@bind(GetBranchesRepoType)
export class GetBranchesRepo implements IGetBranchesRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(query: object) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/branches', query) });

      if (result.status === 200) {
        const data: IBranch[] = result.data.data.map((branch) => BranchMapper.toDomain(branch));

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}
