import React from 'react';
import ContentCard from '../../containers/Layout/Sidebar/Content/Card';
import NotificationTemplatesTable from '../../containers/NotificationTemplates/Table';
import NotificationTemplateActions from '../../containers/NotificationTemplates/Actions';

const NotificationTemplates = () => {
  return (
    <ContentCard>
      <NotificationTemplateActions />
      <NotificationTemplatesTable />
    </ContentCard>
  );
};

export default NotificationTemplates;
