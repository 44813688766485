import { useMemo } from 'react';

import { InputField } from '../../../components/ReduxForm/Input';
import { SelectField } from '../../../components/ReduxForm/Select';
import { urlFactory } from '../../../core/utils/caller';

export const useFormFields = () => {
  const nameFieldProps = useMemo(
    () => ({
      name: 'name',
      item: { label: 'Ad' },
      field: { placeholder: 'Adını daxil edin' },
      component: InputField,
    }),
    [],
  );

  const stateFieldProps = useMemo(
    () => ({
      name: 'state_id',
      item: { label: 'Status' },
      field: { placeholder: 'Status seçin' },
      type: 'select',
      component: SelectField,
      apiUrl: urlFactory('/api/admin/states/getlistbymodelid', { model_id: 6 }),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => object.name,
      responseGetter: (object: any) => object.data,
    }),
    [],
  );

  const nameTrFieldProps = useMemo(
    () => ({
      name: 'name_tr',
      item: { label: 'Türkçe' },
      field: { placeholder: 'Türk dilində adını daxil edin' },
      component: InputField,
    }),
    [],
  );

  const nameEnFieldProps = useMemo(
    () => ({
      name: 'name_en',
      item: { label: 'English' },
      field: { placeholder: 'Enter name in english' },
      component: InputField,
    }),
    [],
  );

  const nameRuFieldProps = useMemo(
    () => ({
      name: 'name_ru',
      item: { label: 'По-русски' },
      field: { placeholder: 'Введите имя на русском языке' },
      component: InputField,
    }),
    [],
  );

  return useMemo(
    () => ({
      nameFieldProps,
      stateFieldProps,
      nameTrFieldProps,
      nameEnFieldProps,
      nameRuFieldProps,
    }),
    [nameFieldProps, stateFieldProps, nameTrFieldProps, nameEnFieldProps, nameRuFieldProps],
  );
};
