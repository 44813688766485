import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { xor } from 'lodash';
import { message } from 'antd';

import { useCloseModal } from '@core/router';
import { GroupedOperations, useAuth } from '@modules/auth';
import { useService } from '@core/inversify-react';
import { GetOperationsRepoType, GetUserPermissionsRepoType, IGetOperationsRepo, IGetUserPermissionsRepo, IUpdateUserPermissionsRepo, UpdateUserPermissionsRepoType, useUserById } from '@modules/users';

export const useUpdateUserPermissions = () => {
  const close = useCloseModal();
  const params = useParams<{ id: string }>();
  const auth = useAuth();
  const user = useUserById(params.id);

  const [operationIds, setOperationIds] = useState<number[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [cashRegisterId, setCashRegisterId] = useState<number>();
  const [branchId, setBranchId] = useState<number>();

  const getOperationsRepo = useService<IGetOperationsRepo>(GetOperationsRepoType);
  const getUserPermissions = useService<IGetUserPermissionsRepo>(GetUserPermissionsRepoType);
  const updateUserPermissions = useService<IUpdateUserPermissionsRepo>(UpdateUserPermissionsRepoType);

  const isSelf = useMemo(() => auth.user.data?.id.toString() === params.id, [params.id, auth.user.data?.id]);

  const onClose = useCallback(() => close('/@next/users'), [close]);

  const operations = useQuery<GroupedOperations[], Error, GroupedOperations[]>(['operations'], async () => {
    const result = await getOperationsRepo.execute();

    if (result.status === 200) {
      return result.response;
    } else {
      throw new Error(result.response);
    }
  });

  const permissions = useQuery<number[], Error, number[], ['users', string, 'permissions']>(['users', params.id, 'permissions'], async ({ queryKey }) => {
    const [, userId] = queryKey;

    const result = await getUserPermissions.execute({ userId });

    if (result.status === 200) {
      return result.response;
    } else {
      throw new Error(result.response);
    }
  });

  const onSwitchChange = useCallback(
    (value: number) => {
      const flatOperations = operations.data?.map(({ operations }) => operations).flat() || [];
      const flatOperationIds = flatOperations.map(({ id }) => id);
      const changeUserPermissionsOperationId = flatOperations.find(({ codeName }) => codeName === 'changeuserpermissions')?.id;

      if (changeUserPermissionsOperationId === value && !operationIds.includes(value)) {
        setOperationIds(flatOperationIds);
      } else {
        setOperationIds((operationIds) => xor<number>(operationIds, [value]));
      }
    },
    [operationIds, operations.data],
  );

  const onCashRegisterIdChange = useCallback((value: number | undefined) => {
    setCashRegisterId(value);
  }, []);

  const onBranchIdChange = useCallback((value: number | undefined) => {
    setBranchId(value);
  }, []);

  const onSubmit = useCallback(async () => {
    setSubmitting(true);

    const result = await updateUserPermissions.execute({ userId: params.id, operationIds, cashRegisterId, admin_branch_id: branchId });

    if (result.status === 200) {
      onClose();
    } else {
      message.error(result.response);
    }

    await setSubmitting(false);
  }, [cashRegisterId, onClose, operationIds, branchId, params.id, updateUserPermissions]);

  useEffect(() => {
    if (permissions.data) {
      setOperationIds(permissions.data);
    }
  }, [permissions.data]);

  useEffect(() => {
    if (user.data && user.data.cashRegisterId) setCashRegisterId(user.data.cashRegisterId);
    if (user.data && user.data.adminBranch.id) setBranchId(user.data.adminBranch.id);
  }, [user.data]);

  return { operations, permissions, onSubmit, onClose, submitting, isSelf, onSwitchChange, operationIds, cashRegisterId, onCashRegisterIdChange, branchId, onBranchIdChange };
};
