import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
import { RouteComponentProps } from 'react-router-dom';
import { useTicketTemplatesFormFields } from './useFormFields';
import { useClose } from '../common';
import { useForm } from '../common/useForm';
import { tableFetchDataAction } from '../../actions/table';
import { urlFactory } from '../../core/utils/caller';
import routes from '../../constants/routes';
import { message } from 'antd';

export const useTicketTemplatesForm = (props: InjectedFormProps & RouteComponentProps<any>) => {
  const dispatch = useDispatch();
  const close = useClose(routes.ticketTemplates.index);
  const fields = useTicketTemplatesFormFields();

  const handleSubmitSuccess = useCallback(
    async (response: Response) => {
      message.success('Bildiriş şablonu müvəffəqiyyətlə dəyişdirildi.');
      dispatch(tableFetchDataAction('messageTemplates', '/api/admin/message_templates'));
      const { data: id } = await response.json();
      close({ id });
    },
    [dispatch, close],
  );

  const { handleSubmit, type } = useForm(
    useMemo(
      () => ({
        handleClose: close,
        handleSubmit: props.handleSubmit,
        initialize: props.initialize,
        createUrl: [routes.ticketTemplates.create],
        createApiUrl: urlFactory('/api/admin/message_templates/create'),
        editApiUrl: urlFactory('/api/admin/message_templates/edit'),
        onSuccess: handleSubmitSuccess,
        initializeUrl: urlFactory('/api/admin/message_templates/info', { message_template_id: props.match.params?.id }),
        initializeMiddlewares: [(response) => ({ ...response, message_template_id: response.id })],
      }),
      [close, props.handleSubmit, handleSubmitSuccess, props.initialize, props.match.params],
    ),
  );

  const title = useMemo(() => (type === 'create' ? 'Yeni mürəciət şablonu yarat' : 'Mürəciət şablonunda dəyişiklik et'), [type]);
  const initializing = useMemo(() => type === 'edit' && !props.initialized, [props.initialized, type]);

  return { fields, title, handleSubmit, close, initializing };
};
