import React, { FC, memo, PropsWithChildren, useCallback, useMemo } from 'react';
import { FieldConfig, FieldMetaProps, useField } from 'formik';
import { Form, Upload } from 'antd';

import { FormItemProps } from 'antd/es/form';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadChangeParam, UploadProps } from 'antd/es/upload';

import { twoLevelShallowEqualObjects } from '../../../utils/two-level-shallow-equal-objects';

const MemoizedUploadField = memo<MemoizedUploadFieldProps>(({ item, input, field, meta, children }) => {
  return (
    <Form.Item {...item} validateStatus={meta.touched && !!meta.error ? 'error' : undefined} help={meta.error}>
      <Upload beforeUpload={() => false} {...field} {...input}>
        {children}
      </Upload>
    </Form.Item>
  );
}, twoLevelShallowEqualObjects);

export const UploadField: FC<UploadFieldProps> = ({ item, input, children, ...props }) => {
  const [{ onChange: baseOnChange, ...field }, meta] = useField<File | null>(props);
  const fileList = useMemo(() => (field.value ? [(field.value as unknown) as UploadFile] : []), [field.value]);

  const onChange = useCallback(
    ({ file }: UploadChangeParam) => {
      if (file.status === 'removed') {
        baseOnChange(field.name)({ target: { type: 'text', value: null } } as any);
      } else {
        baseOnChange(field.name)({ target: { type: 'text', value: file } } as any);
      }
    },
    [baseOnChange, field.name],
  );

  const newField = useMemo(() => ({ onChange, fileList }), [onChange, fileList]);

  return <MemoizedUploadField field={newField} meta={meta} input={input} item={item} children={children} />;
};

export type UploadFieldProps = FieldConfig & {
  item?: FormItemProps;
  input?: UploadProps;
};

type MemoizedUploadFieldProps = PropsWithChildren<{
  item?: FormItemProps;
  input?: UploadProps;
  field: any;
  meta: FieldMetaProps<any>;
}>;
