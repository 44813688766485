import { Moment } from 'moment';

import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { Result } from '../../../shared/utils/result';
import { Constants } from '../../../shared/constants';

import { StatisticsMappers } from '../mappers';

export class DeclarationExecutiveService {
  public static async getAll(startDate: Moment, endDate: Moment) {
    try {
      const query = { start_date: startDate.format(Constants.DATE), end_date: endDate.format(Constants.DATE) };
      const url = urlMaker('/api/admin/declaration/executives', query);

      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        return new Result(200, StatisticsMappers.DeclarationExecutive.manyFromApi(data), null);
      } else {
        return new Result(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new Result(500, 'Sorğu göndərilə bilmədi. İnternet bağlantınızı yoxlayın.', null);
    }
  }
}
