import React, { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { localURLMaker } from '../../../shared/utils/url-maker';
import { FlightBulkManifestForm } from '../containers/bulk-manifest-form';

export const FlightsBulkManifestPage = () => {
  const history = useHistory();
  const routeParams = useParams<{ flightId: string }>();
  const params = useMemo(() => ({ flightId: routeParams.flightId }), [routeParams.flightId]);

  const handleClose = useCallback(() => {
    history.push(localURLMaker('/flights', {}, { reFetchFlights: true }));
  }, [history]);

  return <FlightBulkManifestForm params={params} onClose={handleClose} />;
};
