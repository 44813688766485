export const branchQueryKeys = {
  id: 'id',
  name: 'name',
  description: 'descr',
  phone: 'phone',
  address: 'address',
  mapAddress: 'map_address',
  workingHours: 'workinghours',
  isBranch: 'is_branch',
};
