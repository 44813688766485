import { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { declarationKeys } from '@modules/declarations';
import { orderQueryKeys } from '@modules/orders';
import { customsDeclarationPersistenceKeys } from '@modules/customs/utils';
import { transactionKeys } from '@modules/transactions';
import { IDetailedUser } from '@modules/users';
import { emailNotificationQueryKeys, smsNotificationQueryKeys } from '@modules/notifier/utils';

export const useUserRelatedSections = (data?: IDetailedUser) => {
  const history = useHistory();

  const openDeclarations = useCallback(() => {
    history.push('/@next/declarations', { declarationsTable: { mergeState: { filters: [{ id: declarationKeys.user.id, value: data?.id }] } } });
  }, [history, data?.id]);

  const openCurrentMonthDeclarations = useCallback(() => {
    if (!data?.id) {
      return null;
    }

    history.push(generatePath('/@next/declarations/users/:userId/current-month', { userId: data.id }));
  }, [data?.id, history]);

  const openOrders = useCallback(() => {
    history.push('/@next/orders', { ordersTable: { mergeState: { filters: [{ id: orderQueryKeys.user.id, value: data?.id }] } } });
  }, [history, data?.id]);

  const openSMS = useCallback(() => {
    history.push('/@next/notifier/sms', { smsNotificationsTable: { mergeState: { filters: [{ id: smsNotificationQueryKeys.userId, value: data?.id }] } } });
  }, [history, data?.id]);

  const openEmail = useCallback(() => {
    history.push('/@next/notifier/email', { emailNotificationsTable: { mergeState: { filters: [{ id: emailNotificationQueryKeys.userId, value: data?.id }] } } });
  }, [history, data?.id]);
  const openCustomsDeclarations = useCallback(() => {
    history.push('/@next/customs/declarations', {
      customsDeclarationsTable: { mergeState: { filters: [{ id: customsDeclarationPersistenceKeys.importer.passportSecret, value: data?.passport.secret }] } },
    });
  }, [data?.passport.secret, history]);

  const openCouriers = useCallback(() => {
    history.push('/@next/couriers', { couriersTable: { mergeState: { filters: [{ id: orderQueryKeys.user.id, value: data?.id }] } } });
  }, [history, data?.id]);

  const openTransactions = useCallback(() => {
    history.push('/@next/transactions', { transactionsTable: { mergeState: { filters: [{ id: transactionKeys.user.id, value: data?.id }] } } });
  }, [history, data?.id]);

  const openTickets = useCallback(() => {
    history.push('/@next/supports', { supportsTable: { mergeState: { filters: [{ id: transactionKeys.user.id, value: data?.id }] } } });
  }, [history, data?.id]);

  const openTicketsForm = useCallback(() => {
    history.push('/@next/supports/create', { defaultValues: { userId: data?.id.toString() } });
  }, [history, data?.id]);

  const openWhatsapp = useCallback(() => {
    window.open('https://wa.me/' + data?.phoneNumber, '_blank');
  }, [data?.phoneNumber]);

  return { openDeclarations, openCurrentMonthDeclarations, openOrders, openCustomsDeclarations, openCouriers, openTransactions, openTickets, openTicketsForm, openWhatsapp, openSMS, openEmail };
};
