import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { useTitle } from '@core/utils';

import { BranchesPage, BranchDetailsPage } from '../pages';

const BranchesRouter: FC = () => {
  useTitle('Filiallar');

  return (
    <Route>
      <Switch>
        <Route path='/@next/branches/:id/details' component={BranchDetailsPage} />
        <Route path='/@next/branches' component={BranchesPage} />
      </Switch>
    </Route>
  );
};

export default BranchesRouter;
