import React, { FC, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { Form, Modal, Spin } from 'antd';

import { useFormFields } from '../../hooks/states/useForm';
import { useClose } from '../../hooks/common';
import { urlFactory } from '../../core/utils/caller';
import { useForm } from '../../hooks/common/useForm';
import { tableFetchDataAction } from '../../actions/table';
import routes from '../../constants/routes';

type IProps = InjectedFormProps<any> & RouteComponentProps<any> & { dispatch: Dispatch };

const StatesForm: FC<IProps> = ({ handleSubmit: baseHandleSubmit, initialize, match: { params }, dispatch, submitting, initialized }) => {
  const handleClose = useClose(routes.states.index);

  const handleSubmitSuccess = useCallback(() => {
    dispatch(tableFetchDataAction('states', '/api/admin/states/getlist'));
  }, [dispatch]);

  const formOptions = useMemo(
    () => ({
      handleClose,
      handleSubmit: baseHandleSubmit,
      initialize,
      createUrl: routes.states.create,
      createApiUrl: urlFactory('/api/admin/states/create'),
      editApiUrl: urlFactory('/api/admin/states/edit', {
        state_id: params.id,
      }),
      onSuccess: handleSubmitSuccess,
      initializeUrl: urlFactory('/api/admin/states/getinfobystateid', {
        state_id: params.id,
      }),
    }),
    [params.id, handleSubmitSuccess, baseHandleSubmit, initialize, handleClose],
  );

  const { handleSubmit, type } = useForm(formOptions);

  const title = useMemo(() => {
    return type === 'create' ? 'Yeni status əlavə et' : 'Statusda düzəliş et';
  }, [type]);

  const { nameFieldProps, modelFieldProps, parentFieldProps, descriptionsFieldProps } = useFormFields();

  return (
    <Modal maskTransitionName='' transitionName='' width={592} visible={true} onCancel={handleClose} confirmLoading={submitting} onOk={handleSubmit} title={title}>
      <Spin spinning={type === 'edit' && !initialized}>
        <Form layout='vertical'>
          <Field {...nameFieldProps} />
          <Field {...modelFieldProps} />
          <Field {...parentFieldProps} />
          <Field {...descriptionsFieldProps} />
        </Form>
      </Spin>
    </Modal>
  );
};

export default reduxForm<any, any>({ form: 'states' })(React.memo<IProps>(StatesForm));
