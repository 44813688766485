import React, { useCallback, useContext } from 'react';
import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';

import { HeaderButton, HeadPortal } from '@modules/layout';
import { useAuth } from '@modules/auth';

import { useCashFlowTransactionsExcel, useCashFlowTransactionsTableActionBar } from '../hooks';
import { CashFlowTransactionsTableContext, CashFlowTransactionSumContext } from '../contexts';

export const CashFlowTransactionsTableActionBar = () => {
  const { can } = useAuth();
  const { state } = useContext(CashFlowTransactionsTableContext);
  const { sum } = useContext(CashFlowTransactionSumContext);

  const { selection, resetSelection, create, selectAll, reset, fetch } = useCashFlowTransactionsTableActionBar();
  const exportAsExcelFactory = useCashFlowTransactionsExcel();

  const exportAsExcel = useCallback(() => {
    return exportAsExcelFactory(state.filters);
  }, [exportAsExcelFactory, state.filters]);

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir seçilib
    </HeaderButton>
  );

  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <div>
          <HeaderButton disabled={!can('cashbox_operations.cud')} onClick={create} icon={<Icons.PlusCircleOutlined />}>
            Yeni
          </HeaderButton>
          {!selection.length ? selectAllButton : resetSelectionButton}
          <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
            Yenilə
          </HeaderButton>
          <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
            Sıfırla
          </HeaderButton>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ color: '#fff', marginBottom: 0, marginRight: '15px' }}>Cəmi: {sum}</p>
          <HeaderButton onClick={exportAsExcel} icon={<Icons.FileExcelOutlined />}>
            Excel
          </HeaderButton>
        </div>
      </ActionBar>
    </HeadPortal>
  );
};
