import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { Form, Modal, Spin } from 'antd';

import { useFormFields } from '../../hooks/tickets/useForm';
import { useClose } from '../../hooks/common';
import { urlFactory } from '../../core/utils/caller';
import { useForm } from '../../hooks/common/useForm';
import routes from '../../constants/routes';
import { tableFetchDataAction } from '../../actions/table';
import { search2Object } from '../../core/utils/search-to-object';

type IProps = InjectedFormProps<any> & RouteComponentProps<any> & { dispatch: Dispatch };

const TicketsForm: FC<IProps> = ({ handleSubmit: baseHandleSubmit, initialize, match: { params }, location, dispatch, submitting, initialized }) => {
  const handleClose = useClose(routes.tickets.index);

  const handleSubmitSuccess = useCallback(() => {
    dispatch(tableFetchDataAction('tickets', '/api/admin/tickets/list'));
  }, [dispatch]);

  const formOptions = useMemo(
    () => ({
      handleClose,
      handleSubmit: baseHandleSubmit,
      initialize,
      createUrl: routes.tickets.create,
      createApiUrl: urlFactory('/api/admin/tickets/create'),
      editApiUrl: urlFactory('/api/admin/tickets/edit', { tickets_id: params.id }),
      onSuccess: handleSubmitSuccess,
      initializeUrl: urlFactory('/api/admin/tickets/getinfo', { tickets_id: params.id }),
      initializeMiddlewares: [(values) => ({ ...values, document_file: undefined })],
    }),
    [params.id, handleSubmitSuccess, baseHandleSubmit, initialize, handleClose],
  );

  const { handleSubmit, type } = useForm(formOptions);

  const title = useMemo(() => {
    return type === 'create' ? 'Yeni müraciət əlavə et' : 'Müraciətdə düzəliş et';
  }, [type]);

  const { userFieldProps, categoryFieldProps, messageFieldProps, fileUploadFieldProps } = useFormFields();

  useEffect(() => {
    if (type === 'create') {
      const query = search2Object(location.search);
      if (query.user_id) {
        initialize({ user_id: parseInt(query.user_id, 10) });
      }
    }
  }, [location.search, type]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal maskTransitionName='' transitionName='' width={592} visible={true} onCancel={handleClose} confirmLoading={submitting} onOk={handleSubmit} title={title}>
      <Spin spinning={type === 'edit' && !initialized}>
        <Form layout='vertical'>
          <Field {...userFieldProps} />
          <Field {...categoryFieldProps} />
          <Field {...messageFieldProps} />
          <Field {...fileUploadFieldProps} />
        </Form>
      </Spin>
    </Modal>
  );
};

export default reduxForm<any, any>({ form: 'tickets' })(React.memo<IProps>(TicketsForm));
