import { FormErrors } from '@core/form';
import { formDataFlat } from '@core/utils';
import { CashbackSettingsDto, CashflowSettingsDto, IBranchesSettingsDto, IOthersSettingsDto, IWarehousesSettingsDto } from '../dtos';
import {
  ISettings,
  ISettingsPersistence,
  ISettingsCashflowPersistent,
  ISettingsCashflow,
  ISettingsCashbackPersistent,
  ISettingsCashback,
  IOthersSettings,
  IOthersSettingsPersistent,
  IBranchesSettingsPersistent,
  IBranchesSettings,
  IWarehouseSettings,
  IWarehouseSettingsPersistent,
  IOthersSettingsApi,
} from '../interfaces';

export class SettingsMapper {
  public static toDomain(settings: ISettingsPersistence): ISettings {
    return {
      configs: {
        sendDeclarationsToCustoms: !!settings.customs,
        orders: {
          cashRegisterId: settings.cashbox_id,
        },
        terminal: {
          cashRegisterId: settings.terminal_cashbox_id,
        },
      },
      contact: settings.contact,
      workingHours: settings.workinghours || '',
      bonPercent: settings.bon_percent || '',
      foreignWarehouse: {
        address: settings.warehouse?.address || '',
        addressHeading: settings.warehouse?.address_heading || '',
        city: settings.warehouse?.city || '',
        country: settings.warehouse?.country || '',
        district: settings.warehouse?.district || '',
        passportIdentity: settings.warehouse?.passport_identity || '',
        phone: settings.warehouse?.phone || '',
        postalCode: settings.warehouse?.postal_code || '',
        province: settings.warehouse?.province || '',
      },
    };
  }
  public static cashflowToDomain(cashflowSettings: ISettingsCashflowPersistent): ISettingsCashflow {
    return {
      cashboxId: cashflowSettings.cashbox_id,
      terminalCashboxId: cashflowSettings.terminal_cashbox_id,
    };
  }
  public static cashflowDtoToPersistence(cashflowSettings: ISettingsCashflow): ISettingsCashflowPersistent {
    return {
      cashbox_id: cashflowSettings.cashboxId,
      terminal_cashbox_id: cashflowSettings.terminalCashboxId,
      group_id: 'cashflow',
    };
  }
  public static cashbackToDomain(cashbackSettings: ISettingsCashbackPersistent): ISettingsCashback {
    return {
      cashbackBalance: cashbackSettings.cashback_balance,
      cashbackMax: cashbackSettings.cashback_max,
      cashbackMin: cashbackSettings.cashback_min,
      cashbackPercent: cashbackSettings.cashback_percent,
      expired: cashbackSettings.cashback_expired,
    };
  }
  public static cashbackDtoToPersistence(cashbackSettings: ISettingsCashback): ISettingsCashbackPersistent {
    return {
      cashback_balance: cashbackSettings.cashbackBalance,
      cashback_max: cashbackSettings.cashbackMax,
      cashback_percent: cashbackSettings.cashbackPercent,
      cashback_expired: cashbackSettings.expired,
      cashback_min: cashbackSettings.cashbackMin,
      group_id: 'cashback',
    };
  }
  public static othersToDomain(othersSettings: IOthersSettingsApi): IOthersSettings {
    return {
      bonPercent: othersSettings.bon_percent,
      customs: othersSettings.customs,
      orderPercent: othersSettings.order_percent,
      workinghours: othersSettings.workinghours,
      big_package: othersSettings.package_price.big_package,
      medium_package: othersSettings.package_price.medium_package,
      small_package: othersSettings.package_price.small_package,
    };
  }
  public static othersDtoToPersistence(othersSettings: IOthersSettingsDto): IOthersSettingsPersistent {
    return {
      bon_percent: othersSettings.bonPercent,
      customs: othersSettings.customs ? '1' : '0',
      order_percent: othersSettings.orderPercent,
      workinghours: othersSettings.workinghours,
      big_package: othersSettings.big_package,
      medium_package: othersSettings.medium_package,
      small_package: othersSettings.small_package,
      group_id: 'others',
    };
  }
  public static branchesToDomain(branchesSettings: IBranchesSettingsPersistent): IBranchesSettings {
    return {
      numbers: branchesSettings.numbers,
      emails: branchesSettings.emails,
      address: branchesSettings.address,
      map: branchesSettings.map,
    };
  }
  public static branchesDtoToPersistence(branchesSettings: IBranchesSettingsDto): IBranchesSettingsPersistent {
    return { group_id: 'contact', numbers: branchesSettings.numbers, emails: branchesSettings.emails, address: branchesSettings.address, map: branchesSettings.map };
  }
  public static warehousesDtoManyToPersistence(warehousesSettings: IWarehouseSettingsPersistent[]): any {
    const items = formDataFlat({ group_id: 'warehouse', items: warehousesSettings });
    return items;
  }
  public static warehousesToDomain(warehousesSettings: IWarehouseSettingsPersistent): IWarehouseSettings {
    return {
      city: warehousesSettings.city,
      addressHeading: warehousesSettings.address_heading,
      postalCode: warehousesSettings.postal_code,
      passportIdentity: warehousesSettings.passport_identity,
      address: warehousesSettings.address,
      province: warehousesSettings.province,
      district: warehousesSettings.district,
      country: warehousesSettings.country,
      phone: warehousesSettings.phone,
    };
  }
  public static warehousesDtoToPersistence(warehousesSettings: IWarehousesSettingsDto): IWarehouseSettingsPersistent {
    return {
      group_id: 'warehouse',
      city: warehousesSettings.city,
      address_heading: warehousesSettings.addressHeading,
      postal_code: warehousesSettings.postalCode,
      passport_identity: warehousesSettings.passportIdentity,
      address: warehousesSettings.address,
      province: warehousesSettings.province,
      district: warehousesSettings.district,
      country: warehousesSettings.country,
      phone: warehousesSettings.phone,
    };
  }
}

export class SettingsErrorsMapper {
  public static cashbackError(errors): FormErrors<CashbackSettingsDto> {
    return {
      cashbackBalance: errors.cashback_balance?.join(', '),
      cashbackMax: errors.cashback_max?.join(', '),
      cashbackMin: errors.cashback_min?.join(', '),
      cashbackPercent: errors.cashback_percent?.join(', '),
      expired: errors.cashback_expired?.join(', '),
    };
  }

  public static cashflowError(errors): FormErrors<CashflowSettingsDto> {
    return {
      cashboxId: errors.cashbox_id?.join(', '),
      terminalCashboxId: errors.terminal_cashbox_id?.join(', '),
    };
  }
  public static othersError(errors): FormErrors<IOthersSettingsDto> {
    return {
      bonPercent: errors.bon_percent?.join(', '),
      customs: errors.customs?.join(', '),
      orderPercent: errors.order_percent?.join(', '),
      workinghours: errors.workinghours?.join(', '),
    };
  }
  public static branchesError(errors): FormErrors<IBranchesSettingsDto> {
    return {
      numbers: errors.numbers?.join(', '),
      emails: errors.emails?.join(', '),
      address: errors.address?.join(', '),
      map: errors.map?.join(', '),
    };
  }
  public static warehousesError(errors): FormErrors<IWarehousesSettingsDto> {
    return {
      city: errors.city?.join(', '),
      addressHeading: errors.address_heading?.join(', '),
      postalCode: errors.postalCode?.join(', '),
      passportIdentity: errors.passport_identity?.join(', '),
      address: errors.address?.join(', '),
      province: errors.province?.join(', '),
      district: errors.district?.join(', '),
      country: errors.country?.join(', '),
      phone: errors.phone?.join(', '),
    };
  }
}
