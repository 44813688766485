import { useCallback, useMemo } from 'react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';

import { useQuery } from '../../../shared/modules/use-query/hooks';
import { UseQueryOptions } from '../../../shared/modules/use-query/types';

import { SettingsService } from '../services';
import { SettingsFormData } from '../types';

export const useSettingsForm = () => {
  const initialValues = useQuery(
    useCallback(async () => {
      const result = await SettingsService.get();

      if (result.status === 200) {
        return result.data as SettingsFormData;
      } else {
        throw new Error(result.data);
      }
    }, []),
    useMemo<UseQueryOptions>(
      () => ({
        initialData: {
          configs: {
            sendDeclarationsToCustoms: false,
            orders: {
              cashRegisterId: null,
            },
          },
          contact: {
            address: '',
            email: '',
            map: '',
            number: '',
          },
          workingHours: '',
          bonPercent: '',
          foreignWarehouse: {
            address: '',
            addressHeading: '',
            city: '',
            country: '',
            district: '',
            passportIdentity: '',
            phone: '',
            postalCode: '',
            province: '',
          },
        },
      }),
      [],
    ),
  );

  const onSubmit = useCallback(async (values: SettingsFormData, helpers: FormikHelpers<SettingsFormData>) => {
    helpers.setStatus(null);
    const result = await SettingsService.update(values);

    if (result.status === 200) {
      message.success('Məlumatlar müvəffəqiyyətlə yeniləndi.');
    } else if (result.status === 422) {
      helpers.setErrors(result.data);
    } else {
      helpers.setStatus({ type: 'error', message: result.data });
    }
  }, []);

  return { initialValues, onSubmit };
};
