import { useMemo } from 'react';
import { InjectedFormProps } from 'redux-form';
import { useSelector } from 'react-redux';

import { InputField } from '../../components/ReduxForm/Input';
import { SelectField, StaticSelectField } from '../../components/ReduxForm/Select';
import { TextAreaField } from '../../components/ReduxForm/TextArea';
import { urlFactory } from '../../core/utils/caller';
import { formFieldValueSelector } from '../../selectors/form';
import { positiveFloatNormalizer } from '../../core/helpers/normalizers';
import { SwitchField } from '../../components/ReduxForm/Switch';
import { RichTextField } from '../../components/ReduxForm/CKE';

export const useNotificationTemplatesFormFields = ({ change, form }: InjectedFormProps) => {
  const modelId = useSelector(formFieldValueSelector(form, 'model_id'));
  const templateType = useSelector(formFieldValueSelector(form, 'template_type_id'));

  const name = useMemo(
    () => ({
      name: 'name',
      item: { label: 'Şablonun adı' },
      field: { placeholder: 'Şablonun adını daxil edin.' },
      component: InputField,
    }),
    [],
  );

  const type = useMemo(
    () => ({
      name: 'template_type_id',
      item: { label: 'Bildirişin növü' },
      field: { placeholder: 'Bildirişin növünü seçin' },
      options: [
        { value: 1, label: 'SMS' },
        { value: 2, label: 'E-Mail' },
        { value: 3, label: 'Mobile' },
      ],
      onChange: (value) => {
        if (value === 1) {
          change('title', 'SMS');
        } else {
          change('title', null);
        }
        change('body', null);
      },
      component: StaticSelectField,
    }),
    [change],
  );

  const title = useMemo(
    () => ({
      name: 'title',
      item: { label: 'Başlıq' },
      field: { placeholder: 'Başlıq daxil edin.', disabled: !templateType || templateType === 1 },
      component: InputField,
    }),
    [templateType],
  );

  const body = useMemo(
    () => ({
      name: 'body',
      component: TextAreaField,
      item: { label: 'Mətn' },
      field: {
        disabled: !templateType,
        rows: 4,
        placeholder: 'Bildiriş mətnini daxil edin.',
      },
    }),
    [templateType],
  );

  const bodyRich = useMemo(
    () => ({
      name: 'body',
      component: RichTextField,
      item: { label: 'Mətn' },
    }),
    [],
  );

  const plansCategory = useMemo(
    () => ({
      name: 'tariff_category_id',
      item: { label: 'Tarif' },
      field: { placeholder: 'Tarif kateqoriyasını seçin' },
      type: 'select',
      component: SelectField,
      apiUrl: urlFactory('/api/admin/tariff/category_list'),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => `${object.id} - ${object.name}`,
      responseGetter: (object: any) => object.data,
    }),
    [],
  );

  const model = useMemo(
    () => ({
      name: 'model_id',
      onChange: () => change('state_id', null),
      item: { label: 'Model' },
      field: { placeholder: 'Modeli seçin' },
      type: 'select',
      component: SelectField,
      apiUrl: urlFactory('/api/admin/models/getlist'),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => object.name,
      responseGetter: (object: any) => object.data,
    }),
    [], //eslint-disable-line react-hooks/exhaustive-deps
  );

  const state = useMemo(
    () => ({
      name: 'state_id',
      item: { label: 'Status' },
      field: { placeholder: 'Status seçin', disabled: !modelId },
      type: 'select',
      component: SelectField,
      apiUrl: modelId ? urlFactory('/api/admin/states/getlistbymodelid', { model_id: modelId }) : undefined,
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => object.name,
      responseGetter: (object: any) => object.data,
    }),
    [modelId],
  );

  const isActive = useMemo(
    () => ({
      name: 'active',
      item: { style: { marginBottom: 0 } },
      field: { checkedChildren: 'İşləkdir', unCheckedChildren: 'İşlək deyil', style: { width: 100 } },
      type: 'checkbox',
      component: SwitchField,
    }),
    [],
  );

  const delay = useMemo(
    () => ({
      name: 'delay',
      item: { label: 'Gecikmə' },
      field: { placeholder: 'Gecikməni daxil edin', addonAfter: 'saniyə' },
      normalize: positiveFloatNormalizer,
      component: InputField,
    }),
    [],
  );

  return { name, type, title, body, bodyRich, plansCategory, model, state, isActive, delay };
};
