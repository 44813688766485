import React, { useMemo } from 'react';
import { Column } from 'react-table';
import ReadyTable from '../../components/Table/ReadyTable';
import { dateCol, smallCol } from '../../core/helpers/table';

const ModelsTable = () => {
  const columns = useMemo<Column<any>[]>(
    () => [
      {
        accessor: 'id',
        Header: 'Kod',
        ...smallCol,
      },
      {
        accessor: 'name',
        Header: 'Status',
      },
      {
        accessor: 'sort',
        Header: 'Sıra',
        ...smallCol,
      },
      {
        accessor: 'created_at',
        Header: 'Yaradılıb',
        ...dateCol,
      },
      {
        accessor: 'descr',
        Header: 'Açıqlama',
      },
    ],
    [],
  );

  return <ReadyTable name='models' apiUrl={'/api/admin/models/getlist'} columns={columns} sortable={false} pagination={false} filterable={false} />;
};

export default React.memo(ModelsTable);
