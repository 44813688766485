import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, message } from 'antd';
import { FieldTimeOutlined, SendOutlined } from '@ant-design/icons';
import routes from '../../constants/routes';
import Can from '../Permissions/Can';
import { useDispatch } from 'react-redux';
import { sendAllQueuedSms } from '../../actions/smsArchive';
import { tableFetchDataAction } from '../../actions/table';

const SmsArchiveActions = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [sendAllQueuedLoading, setSendAllQueuedLoading] = useState(false);

  const goToBulk = useCallback(() => {
    history.push(routes.archive.sms.bulk.index);
  }, [history]);

  const sendAllQueued = useCallback(async () => {
    setSendAllQueuedLoading(true);
    const result = await sendAllQueuedSms();

    if (result) {
      dispatch(tableFetchDataAction('archive.sms', '/api/admin/archive/sms'));
    } else {
      message.error('Əməliyyat aparıla bilmədi');
    }
    setSendAllQueuedLoading(false);
  }, [dispatch]);

  return (
    <Can
      yes={
        <div className='actions-wrapper'>
          <div className='actions-wrapper-left'>
            <Button onClick={goToBulk} icon={<SendOutlined />}>
              Toplu sms göndər
            </Button>
          </div>
          <div className='actions-wrapper-right'>
            <Button type='primary' onClick={sendAllQueued} loading={sendAllQueuedLoading} icon={<FieldTimeOutlined />}>
              Növbədəkiləri göndər
            </Button>
          </div>
        </div>
      }
      role='bulksms'
    />
  );
};

export default SmsArchiveActions;
