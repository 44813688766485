import { useCallback, useContext, useMemo } from 'react';
import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';

import { useOrderById } from './';
import { CreateOrderDto } from '../dtos';
import { CreateOrderMapper } from '../mappers';
import { CreateOrderContext } from '../contexts';
import { CreateOrderRepo, CreateOrderRepoType } from '../repos';

export const useCreateOrder = () => {
  const { id, onSucceed } = useContext(CreateOrderContext);
  const order = useOrderById(id, { enabled: !!id });

  const createOrderRepo = useService<CreateOrderRepo>(CreateOrderRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<CreateOrderDto | undefined>(() => {
    if (id) {
      return order.data ? CreateOrderMapper.toDomain(order.data) : undefined;
    }

    return {
      userId: '',
      product: {
        url: '',
        shop: '',
        typeId: '',
        color: '',
        size: '',
        quantity: '',
        price: '',
        internalShippingPrice: '',
      },
      isUrgent: false,
      description: '',
    };
  }, [id, order.data]);

  const onSubmit = useCallback(
    async (values: CreateOrderDto, helpers: FormikHelpers<CreateOrderDto>) => {
      helpers.setStatus(null);
      const result = await createOrderRepo.execute(values, id || undefined);

      if (result.status === 200) {
        if (!id) {
          eventBus.publish({ type: '@orders/create/succeed' });
        } else {
          eventBus.publish({ type: '@orders/update/succeed' });
        }

        onSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createOrderRepo, eventBus, id, onSucceed],
  );

  return { onSubmit, initialValues };
};
