import { TableState } from '../types';
import { TableActions, TableActionTypes } from './action-types';

export const nextTableState: TableState = {
  pageIndex: 0,
  pageSize: 50,
  sortBy: [],
  filters: [],
  hiddenColumns: [],
  selectedRowIds: {},
  total: 0,
  loading: false,
  error: null,
  globalFilter: '',
  data: [],
  meta: {},
};

export const nextTableReducer = (state: TableState = nextTableState, action: TableActions): TableState => {
  switch (action.type) {
    case TableActionTypes.RESET:
      return { ...nextTableState, data: state.data, total: state.total };
    case TableActionTypes.SET_FILTERS:
      return { ...state, filters: action.filters };
    case TableActionTypes.MERGE_STATE:
      return { ...state, ...action.state };
    case TableActionTypes.SET_FILTER_BY_ID:
      const foundedFilterIndex = state.filters.findIndex((filter) => filter.id === action.id);
      if (foundedFilterIndex !== -1) {
        return { ...state, filters: state.filters.map((filter, index) => (index === foundedFilterIndex ? { ...filter, value: action.value } : filter)) };
      } else {
        return { ...state, filters: [...state.filters, { id: action.id, value: action.value }] };
      }
    case TableActionTypes.SET_SORT_BY:
      return { ...state, sortBy: action.sortBy };
    case TableActionTypes.SET_PAGE_INDEX:
      return { ...state, pageIndex: action.pageIndex };
    case TableActionTypes.SET_PAGE_SIZE:
      return { ...state, pageSize: action.pageSize };
    case TableActionTypes.SET_HIDDEN_COLUMNS:
      return { ...state, hiddenColumns: action.hiddenColumns };
    case TableActionTypes.SET_SELECTED_ROW_IDS:
      return { ...state, selectedRowIds: action.selectedRowIds };
    case TableActionTypes.SELECT_ALL:
      return { ...state, selectedRowIds: state.data.map(({ id }) => id).reduce((acc, val) => ({ ...acc, [val]: true }), {}) };
    case TableActionTypes.SET_GLOBAL_FILTER:
      return { ...state, globalFilter: action.value };

    case TableActionTypes.FETCHING_STARTED:
      return { ...state, loading: true, error: null };
    case TableActionTypes.FETCHING_SUCCEED:
      return { ...state, loading: false, error: null, data: action.data, total: action.total };
    case TableActionTypes.FETCHING_FAILED:
      return { ...state, loading: false, error: action.error || null };

    default:
      return state;
  }
};
