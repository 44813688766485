import React from 'react';
import { Col, Row } from 'antd';
import SpecialBalanceStatistics from '../../containers/Dashboard/Special/Balance';
import SpecialDeclarationsStatistics from '../../containers/Dashboard/Special/Declarations';

const SpecialStatistics = () => {
  return (
    <Row gutter={[8, 0]}>
      <Col span={12}>
        <SpecialBalanceStatistics />
      </Col>
      <Col span={12}>
        <SpecialDeclarationsStatistics />
      </Col>
    </Row>
  );
};

export default SpecialStatistics;
