import { FormErrors } from '@core/form';

import { CreateBoxDto, CreateBoxDtoPersistence } from '../dtos';
import { IBox } from '../interfaces';

export class CreateBoxMapper {
  public static toDomain(courier: IBox): CreateBoxDto {
    return {
      id: courier.id.toString(),
      name: courier.name,
    };
  }

  public static toPersistence(courier: CreateBoxDto): CreateBoxDtoPersistence {
    return {
      container_id: courier.id,
      container_name: courier.name,
    };
  }
}

export class CreateBoxErrorsMapper {
  public static toDomain(errors): FormErrors<CreateBoxDto> {
    return {
      id: errors.id?.join('. '),
      name: errors.container_name?.join('. '),
    };
  }
}
