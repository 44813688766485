import { useMemo } from 'react';

import { InputField } from '../../../components/ReduxForm/Input';
import { StaticSelectField } from '../../../components/ReduxForm/Select';
import { UploadField } from '../../../components/ReduxForm/Upload';
import { CheckboxField } from '../../../components/ReduxForm/Checkbox';

export const useFormFields = () => {
  const nameFieldProps = useMemo(
    () => ({
      name: 'name',
      item: { label: 'Ad' },
      field: { placeholder: 'Adı daxil edin...' },
      component: InputField,
    }),
    [],
  );

  const typeFieldProps = useMemo(
    () => ({
      name: 'type',
      item: { label: 'Bölmə' },
      field: { placeholder: 'Bölməni seçin' },
      options: [
        { value: 1, label: 'Ana səhifə (Masaüstü)' },
        { value: 2, label: 'Ana səhifə (Mobil)' },
        { value: 3, label: 'Xəbərlər' },
        { value: 4, label: 'Diqqət' },
        { value: 5, label: 'Xidmətlər' },
        { value: 6, label: 'Qeydiyyat' },
      ],
      component: StaticSelectField,
    }),
    [],
  );

  const fileUploadFieldProps = useMemo(
    () => ({
      name: 'document_file',
      component: UploadField.Simple,
    }),
    [],
  );

  const isActiveFieldProps = useMemo(
    () => ({
      name: 'active',
      field: { children: 'Aktivdir' },
      type: 'checkbox',
      component: CheckboxField,
    }),
    [],
  );

  return { nameFieldProps, typeFieldProps, fileUploadFieldProps, isActiveFieldProps };
};
