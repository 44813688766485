import React, { FC } from 'react';
import ContentCard from '../../containers/Layout/Sidebar/Content/Card';
import BonazConversionsTable from '../../containers/Bonaz/ConversionsTable';
import BonazBalance from '../../containers/Bonaz/Balance';

const BonazConversionsPage: FC = () => {
  return (
    <ContentCard>
      <BonazBalance />
      <BonazConversionsTable />
    </ContentCard>
  );
};

export default BonazConversionsPage;
