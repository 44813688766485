import { useCallback, useMemo } from 'react';
import { useQuery } from '../../../shared/modules/use-query/hooks';
import { RegionService } from '../services';
import { Region } from '../types';

export const useRegions = () => {
  return useQuery<Region[]>(
    useCallback(async () => {
      const result = await RegionService.getAll();

      if (result.status === 200) {
        return result.data;
      } else {
        throw new Error(result.data);
      }
    }, []),
    useMemo(() => ({ initialData: [] }), []),
  );
};
