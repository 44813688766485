import uniq from 'lodash/uniq';

import { IDeclaration, IDeclarationPersistence } from '../interfaces';

export class DeclarationMapper {
  public static toDomain(declaration: IDeclarationPersistence): IDeclaration {
    return {
      id: declaration.id,
      globalTrackCode: declaration.global_track_code,
      trackCode: declaration.track_code,
      branch: {
        id: declaration.branch_id,
        name: declaration.branch_name,
      },
      weight: declaration.weight ? parseFloat(declaration.weight) : null,
      height: declaration.height ? parseFloat(declaration.height) : null,
      width: declaration.width ? parseFloat(declaration.width) : null,
      depth: declaration.depth ? parseFloat(declaration.depth) : null,
      price: declaration.price ? parseFloat(declaration.price) : null,
      voen: declaration.voen,
      deliveryPrice: declaration.delivery_price ? parseFloat(declaration.delivery_price) : null,
      parcel: declaration.box ? { id: declaration.box } : null,
      basket: declaration.basket_id ? { id: declaration.basket_id, name: declaration.basket_name || '' } : null,
      isYourBranch: !!declaration.is_your_branch,
      box: declaration.container_id && declaration.container_name ? { id: declaration.container_id, name: declaration.container_name } : null,
      lastBox: declaration.container_id_tmp && declaration.container_name_tmp ? { id: declaration.container_id_tmp, name: declaration.container_name_tmp } : null,
      quantity: declaration.quantity,
      partner: declaration.partner_id && declaration.partner_name ? { id: declaration.partner_id, name: declaration.partner_name } : null,
      type: declaration.type === 1 ? 'liquid' : 'other',
      shop: declaration.shop_name,
      file: declaration.document_file,
      planCategory: { id: declaration.tariff_category_id, name: declaration.tariff_category_name },
      status: { id: declaration.state_id, name: declaration.state_name },
      productType: { id: declaration.product_type_id, name: declaration.product_type_name },
      user: { id: declaration.user_id, name: declaration.user_name },
      editedBy: { id: declaration.causer_id, name: declaration.causer_name },
      description: declaration.descr || '',
      read: !!declaration.is_new,
      createdAt: declaration.created_at,
      paid: !!declaration.payed,
      approved: !!declaration.customs,
      returned: !!declaration.return,
      document: declaration.document_file,
      relatedWardrobeNumbers: uniq(declaration.wardrobe_numbers?.map((item) => item.wardrobe_number) || []),
      flight: declaration.flight_name ? { id: 0, name: declaration.flight_name } : null,
      wardrobeNumber: declaration.wardrobe_number,
    };
  }
}
