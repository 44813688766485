import React, { useCallback, useMemo } from 'react';
import { Button, Col, Form, List, message, Modal, Row } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useInfo } from '../../hooks/common/useInfo';
import { caller, urlFactory } from '../../core/utils/caller';
import { reduxForm, Field } from 'redux-form';
import { useFormFields } from '../../hooks/plansCategories/useForm/useFormFields';
import { formDataBuilder } from '../../core/utils/form-data-builder';
import { UnexpectedError } from '../../core/helpers/errors';
import { useSelector } from 'react-redux';
import { formFieldValueSelector } from '../../selectors/form';
import { useClose } from '../../hooks/common';
import routes from '../../constants/routes';

const PlansCategories = ({ handleSubmit: baseHandleSubmit, submitting, initialize, form }) => {
  const handleClose = useClose(routes.plans.index);
  const selectedId = useSelector(formFieldValueSelector(form, 'tariff_category_id'));
  const { nameFieldProps, descriptionsFieldProps } = useFormFields();

  const handleEdit = useCallback(
    (item) => {
      initialize({ tariff_category_id: item.id, name: item.name, descr: item.descr });
    },
    [initialize],
  );

  const handleReset = useCallback(() => {
    initialize({});
  }, [initialize]);

  const { data, loading, fetch } = useInfo(
    useMemo(
      () => ({
        apiUrl: urlFactory('/api/admin/tariff/category_list'),
        initialState: [],
        responseGetter: (response) => response.data,
      }),
      [],
    ),
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(
    baseHandleSubmit(async (values) => {
      try {
        const formValues = formDataBuilder({ values });
        const url = values.tariff_category_id ? urlFactory('/api/admin/tariff/category_edit') : urlFactory('/api/admin/tariff/category_create');
        const response = await caller(url, { method: 'POST', body: formValues });

        if (response.ok) {
          await fetch();
          handleReset();
        } else {
          UnexpectedError();
        }
      } catch (e) {
        message.error('Xəta baş verdi');
      }
    }),
    [baseHandleSubmit, fetch, handleReset],
  );

  return (
    <Modal maskTransitionName='' transitionName='' footer={null} width={768} onCancel={handleClose} visible={true} title='Xüsusi tariflər'>
      <Row gutter={[16, 16]}>
        <Col span={24} sm={{ span: 12 }}>
          <List
            style={{ maxHeight: 300, overflow: 'auto' }}
            loading={loading}
            dataSource={data}
            renderItem={(item: any) => (
              <List.Item actions={[<EditOutlined onClick={() => handleEdit(item)} key='edit-action' />]} key={item.id}>
                <List.Item.Meta title={item.name} description={item.descr} />
              </List.Item>
            )}
          />
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form layout='vertical'>
            <Field {...nameFieldProps} />
            <Field {...descriptionsFieldProps} />
            <Button loading={submitting} onClick={handleSubmit} block={true} type='primary'>
              {selectedId ? 'Dəyişdir' : 'Yarat'}
            </Button>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default reduxForm({ form: 'plans/categories' })(PlansCategories);
