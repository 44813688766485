import { BalanceStatisticsByPaymentTypes, BalanceStatisticsByPaymentTypesApi, BalanceStatisticsPaymentTypeItem } from '../types/balance';

export class BalanceStatisticsMappers {
  public static byPaymentTypesResultFromApi(data: BalanceStatisticsByPaymentTypesApi): BalanceStatisticsByPaymentTypes {
    const rawIncome = data.in.map((item) => ({
      id: item.payment_type,
      name: item.name,
      amount: parseFloat(item.amount),
      count: item.count,
      currency: item.currency,
    }));

    const rawOutcome = data.out.map((item) => ({
      id: item.payment_type,
      name: item.name,
      amount: parseFloat(item.amount),
      count: item.count,
      currency: item.currency,
    }));

    const income: BalanceStatisticsPaymentTypeItem[] = rawIncome.reduce<BalanceStatisticsPaymentTypeItem[]>((acc, income) => {
      const { id, name, amount, count, currency } = income;

      const defaultItem: BalanceStatisticsPaymentTypeItem = {
        id,
        name,
        currencies: [
          {
            name: 'usd',
            amount: currency.toLocaleLowerCase() === 'usd' ? amount : 0,
            count: currency.toLocaleLowerCase() === 'usd' ? count : 0,
          },
          {
            name: 'try',
            amount: currency.toLocaleLowerCase() === 'try' ? amount : 0,
            count: currency.toLocaleLowerCase() === 'try' ? count : 0,
          },
        ],
      };

      const foundItem = acc.find((item) => item.id === id);
      const foundItemIndex = acc.findIndex((item) => item.id === id);

      if (foundItem && foundItemIndex !== -1) {
        const copiedAcc = [...acc];
        copiedAcc[foundItemIndex] = {
          ...foundItem,
          currencies: foundItem.currencies.map((itemCurrency) => {
            if (itemCurrency.name === currency.toLowerCase()) {
              return {
                ...itemCurrency,
                amount: amount,
                count: count,
              };
            } else {
              return itemCurrency;
            }
          }),
        };

        return copiedAcc;
      } else {
        return [...acc, defaultItem];
      }
    }, []);

    const outcome: BalanceStatisticsPaymentTypeItem[] = rawOutcome.reduce<BalanceStatisticsPaymentTypeItem[]>((acc, outcome) => {
      const { id, name, amount, count, currency } = outcome;

      const defaultItem: BalanceStatisticsPaymentTypeItem = {
        id,
        name,
        currencies: [
          {
            name: 'usd',
            amount: currency.toLocaleLowerCase() === 'usd' ? amount : 0,
            count: currency.toLocaleLowerCase() === 'usd' ? count : 0,
          },
          {
            name: 'try',
            amount: currency.toLocaleLowerCase() === 'try' ? amount : 0,
            count: currency.toLocaleLowerCase() === 'try' ? count : 0,
          },
        ],
      };

      const foundItem = acc.find((item) => item.id === id);
      const foundItemIndex = acc.findIndex((item) => item.id === id);

      if (foundItem && foundItemIndex !== -1) {
        const copiedAcc = [...acc];
        copiedAcc[foundItemIndex] = {
          ...foundItem,
          currencies: foundItem.currencies.map((itemCurrency) => {
            if (itemCurrency.name === currency.toLowerCase()) {
              return {
                ...itemCurrency,
                amount: amount,
                count: count,
              };
            } else {
              return itemCurrency;
            }
          }),
        };

        return copiedAcc;
      } else {
        return [...acc, defaultItem];
      }
    }, []);

    return { income, outcome };
  }
}
