import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReadyTable from '../../components/Table/ReadyTable';
import { tableSetFiltersAction } from '../../actions/table';
import { useColumns } from '../../hooks/conflictedDeclarations/useTable/useColumns';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';

const ConflictedDeclarationsTable: FC<any> = () => {
  const dispatch = useDispatch();
  const columns = useColumns();
  const history = useHistory();

  useEffect(() => {
    dispatch(tableSetFiltersAction('conflictedDeclarations', [{ id: 'state_id', value: 26 }]));
  }, [dispatch]);

  const rowProps = useCallback(
    (id) => ({
      onDoubleClick: () => {
        history.push(makePath(routes.declarations.conflicted.edit, { id }));
      },
    }),
    [history],
  );

  return <ReadyTable getRowProps={rowProps} name='conflictedDeclarations' apiUrl='/api/admin/v2/conflicted_declaration/list' columns={columns} />;
};

export default React.memo(ConflictedDeclarationsTable);
