import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { urlFactory } from '../../core/utils/caller';
import { useInfo as useBaseInfo } from '../common/useInfo';
import { changeReadAction, changeStateAction, removeAction } from '../../actions/tickets';
import { useDispatch } from 'react-redux';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';

export const useInfo = (id: number) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const useInfoOptions = useMemo(
    () => ({
      apiUrl: urlFactory('/api/admin/tickets/info', { ticket_id: id }),
      initialState: { ticket: null, data: [] },
    }),
    [id],
  );
  const { data: rawData, loading, fetch, setData } = useBaseInfo(useInfoOptions);

  const data = useMemo(() => {
    const ticket = rawData.ticket;
    const messages = rawData.data;

    return { messages, ticket };
  }, [rawData]);

  const changeState = useCallback(
    (stateId: number) => {
      changeStateAction(
        [id],
        stateId,
        false,
      )(dispatch)
        .then(async () => {
          await fetch();
        })
        .catch(() => {});
    },
    [id, dispatch, fetch],
  );

  const goToStateHistory = useCallback(() => {
    history.push(makePath(routes.tickets.states.history, { id }));
  }, [history, id]);

  const remove = useCallback(() => {
    removeAction(
      [id],
      false,
    )(dispatch)
      .then(async () => {
        history.push('/tickets');
      })
      .catch(() => {});
  }, [id, dispatch, history]);

  const changeRead = useCallback(() => {
    changeReadAction(
      [id],
      !data?.ticket.is_new_admin,
      false,
    )(dispatch)
      .then(() => {
        setData((value) => ({ ...value, ticket: { ...value.ticket, is_new_admin: !data?.ticket.is_new_admin } }));
      })
      .catch(() => {});
  }, [id, data, dispatch, setData]);

  return { ...data, loading, fetch, remove, changeState, changeRead, goToStateHistory };
};
