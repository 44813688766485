import React, { FC } from 'react';
import { Button, Card, Col, Empty, Form, PageHeader, Row, Table, Typography } from 'antd';
import { InjectedFormProps, Field, reduxForm, FieldArray } from 'redux-form';
import { RouteComponentProps } from 'react-router-dom';
import { useBulkNotificationForm } from '../../hooks/notificationArchive/useBulkNotificationForm';
import ContentCard from '../Layout/Sidebar/Content/Card';
import { PlusOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

const NotificationArchiveBulkForm: FC<InjectedFormProps & RouteComponentProps<any>> = (props) => {
  const { handleSubmit, fields, values, userTable, userResult, goToCreateTemplate } = useBulkNotificationForm(props);

  const renderFilters = () => {
    switch (values.search_id) {
      case 'user_names':
        return (
          <Card size='small' hoverable={true} title='Müştərilər'>
            <Field {...fields.users} />
          </Card>
        );
      case 'user_mails':
        return (
          <Card size='small' hoverable={true} title='Müştəri emailləri'>
            <FieldArray {...fields.userEmails} />
          </Card>
        );
      case 'declarations_state':
        return (
          <Card size='small' hoverable={true} title='Bağlama statusu'>
            <Field {...fields.declarationState} />
            <Field {...fields.customsState} />
          </Card>
        );
      case 'orders_state':
        return (
          <Card size='small' hoverable={true} title='Sifariş statusu'>
            <Field {...fields.orderState} />
          </Card>
        );
      case 'couriers_state':
        return (
          <Card size='small' hoverable={true} title='Kuryer statusu'>
            <Field {...fields.courierState} />
          </Card>
        );
      case 'customs_state':
        return (
          <Card size='small' hoverable={true} title='Bəyan statusu'>
            <Field {...fields.declareState} />
          </Card>
        );
      case 'user_limit':
        return (
          <Card size='small' hoverable={true} title='Cari ay limiti'>
            <Field {...fields.monthlyLimitFieldProps} />
          </Card>
        );
      case 'flight':
        return (
          <Card size='small' hoverable={true} title='Flight'>
            <Field {...fields.flights} />
          </Card>
        );
      default:
        return null;
    }
  };

  return (
    <ContentCard>
      <PageHeader className='base-page-header' title='Toplu email göndər' />
      <div style={{ padding: 8 }}>
        <Row gutter={[16, 0]}>
          <Col span={24} sm={{ span: 12 }}>
            <Form layout='vertical'>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Card size='small' hoverable={true} title='Axtarış tipi'>
                    <Field {...fields.searchType} />
                  </Card>
                </Col>
                <Col span={24}>{renderFilters()}</Col>
                <Col span={24}>
                  <Card size='small' hoverable={true} title='Şablon'>
                    <Field {...fields.template} />
                    <Button onClick={goToCreateTemplate} style={{ marginTop: 8 }} block={true} icon={<PlusOutlined />}>
                      Şablon əlavə et
                    </Button>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card size='small' hoverable={true} title='Planlanmış vaxt'>
                    <Field {...fields.plannedAt} />
                  </Card>
                </Col>
              </Row>
              <Button disabled={props.submitting} loading={props.submitting} onClick={handleSubmit} block={true} type='primary'>
                Göndər
              </Button>
            </Form>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Table style={{ marginBottom: 16 }} locale={{ emptyText: <Empty description='Müştəri tapılmadı' /> }} {...userTable} />
            <Card size='small'>
              <Paragraph type='secondary' className='bottom-margin-free'>
                Göndəriləcək bildiriş sayı: {typeof userResult.data === 'number' ? userResult.data : userResult.data.length}
              </Paragraph>
              <Paragraph type='secondary' className='bottom-margin-free'>
                Göndəriləcək: {values.planned_at ? `${values.planned_at}-dan(dən) başlayaraq` : 'Planlanmış vaxt seçilməyib'}
              </Paragraph>
            </Card>
          </Col>
        </Row>
      </div>
    </ContentCard>
  );
};

export default reduxForm<any, any, any>({ form: 'bulkNotification' })(NotificationArchiveBulkForm);
