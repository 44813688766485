import { useInfo } from '../common/useInfo';
import { urlFactory } from '../../core/utils/caller';
import { useMemo } from 'react';

export const useByStateStatistics = (delay = 0) => {
  const useInfoOptions = useMemo(
    () => ({
      method: 'POST',
      apiUrl: urlFactory('/api/admin/charts/bystatus', { model_id: [1, 2, 3] }),
      responseGetter: (response) => response.data,
      delay,
    }),
    [delay],
  );

  const { data: rawData, loading } = useInfo(useInfoOptions);

  const data = useMemo(() => {
    if (rawData) {
      const { orders: ordersData, declarations: declarationsData, couriers: couriersData } = rawData;
      const orders = {
        labels: ordersData.map((state) => state.state_name),
        datasets: [
          {
            data: ordersData.map((state) => state.count),
            backgroundColor: ['#8dace7', '#71deb9', '#ef869e', '#6a73ef'],
            hoverBackgroundColor: ['#8dace7', '#71deb9', '#ef869e', '#6a73ef'],
          },
        ],
      };

      const declarations = {
        labels: declarationsData.map((state) => state.state_name),
        datasets: [
          {
            data: declarationsData.map((state) => state.count),
            backgroundColor: ['#8dace7', '#71deb9', '#ef869e', '#e74992', '#277bde', '#efab32', '#7def7a'],
            hoverBackgroundColor: ['#8dace7', '#71deb9', '#ef869e', '#e74992', '#277bde', '#efab32', '#7def7a'],
          },
        ],
      };

      const couriers = {
        labels: couriersData.map((state) => state.state_name),
        datasets: [
          {
            data: couriersData.map((state) => state.count),
            backgroundColor: ['#e74992', '#277bde', '#efab32', '#7def7a'],
            hoverBackgroundColor: ['#e74992', '#277bde', '#efab32', '#7def7a'],
          },
        ],
      };

      return { couriers, orders, declarations };
    } else {
      return {};
    }
  }, [rawData]);

  return useMemo(() => ({ data, loading }), [data, loading]);
};
