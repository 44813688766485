import { CreatePlanCategoryDto, CreatePlanCategoryDtoPersistence } from '../dtos';
import { IPlanCategory } from '@modules/plans';

export class CreatePlanCategoryDtoMapper {
  public static toDomain(data: IPlanCategory): CreatePlanCategoryDto {
    return {
      id: data.id.toString(),
      name: data.name,
      description: data.description,
    };
  }

  public static toPersistence(dto: CreatePlanCategoryDto): CreatePlanCategoryDtoPersistence {
    return {
      name: dto.name,
      tariff_category_id: dto.id,
      descr: dto.description,
    };
  }
}
