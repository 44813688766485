import React, { FC, useCallback, useMemo } from 'react';
import { Avatar, Button, Dropdown, Layout, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import LayoutHeader from './Header';
import LayoutContent from './Sidebar/Content';
import LayoutSidebar from './Sidebar';
import SidebarHeader from './Sidebar/Header';
import SidebarMenu from './Sidebar/Menu';
import { IThemeState } from '../../interfaces/theme/model.interface';
import { layoutSelector } from '../../selectors/layout';
import { IAppState } from '../../interfaces/app-state.interface';
import { IAuthState } from '../../interfaces/auth/state';
import { LogoutOutlined } from '@ant-design/icons/lib';
import { handleLogoutAction } from '../../actions/auth';
import { ICounterState } from '../../interfaces/counter/state.interface';
import { CustomsPing } from '../../next/modules/customs/containers/ping';

const MainLayout: FC<{ children?: JSX.Element; header?: JSX.Element; headerLeft?: JSX.Element }> = ({ children, header, headerLeft }) => {
  const dispatch = useDispatch();
  const { theme, auth } = useSelector<IAppState, { theme: IThemeState; auth: IAuthState; counter: ICounterState }>(layoutSelector);

  const sidebarProps = useMemo(
    () => ({
      header: <SidebarHeader />,
      menu: <SidebarMenu />,
    }),
    [],
  );

  const handleLogout = useCallback(() => {
    dispatch(handleLogoutAction());
  }, [dispatch]);

  return (
    <Layout id='mainLayout' className={classNames({ 'layout-collapsed': theme.collapsed })}>
      <LayoutSidebar {...sidebarProps} />
      <Layout>
        <LayoutHeader>
          <div className='header-left'>{headerLeft}</div>
          <div className='header-right'>
            {header}
            <CustomsPing />
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item onClick={handleLogout}>
                    <LogoutOutlined />
                    <span>Çıxış</span>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button type='text' className='header-button'>
                <span>
                  {auth.data?.firstName} {auth.data?.lastName}
                </span>
                <Avatar>{auth.data?.firstName?.charAt(0)}</Avatar>
              </Button>
            </Dropdown>
          </div>
        </LayoutHeader>
        <LayoutContent>{children}</LayoutContent>
      </Layout>
    </Layout>
  );
};

export default React.memo(MainLayout);
