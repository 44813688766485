import React, { useCallback, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { urlFactory } from '../../core/utils/caller';
import { CommonConstants } from '../../constants/common';
import { useInfo } from '../common/useInfo';
import { NavLink } from 'react-router-dom';
import { makePath } from '../../core/utils/makePath';

export const useModelStatistics = ({ defaultState = '-1', apiUrl, redirectUrl }) => {
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment());
  const [stateId, setStateId] = useState(defaultState);

  const date = useMemo<[Moment, Moment]>(() => [startDate, endDate], [startDate, endDate]);

  const handleChangeDate = useCallback((value) => {
    setStartDate((value || [])[0] || moment().startOf('month'));
    setEndDate((value || [])[1] || moment());
  }, []);

  const useInfoOptions = useMemo(
    () => ({
      apiUrl: urlFactory(apiUrl, {
        start_date: startDate.format(CommonConstants.DATE),
        end_date: endDate.format(CommonConstants.DATE),
        state_id: stateId !== '-1' ? stateId : undefined,
      }),
    }),
    [startDate, endDate, stateId, apiUrl],
  );

  const { data: rawData, loading } = useInfo(useInfoOptions);

  const data = useMemo(() => {
    if (rawData) {
      return Object.entries(rawData.by_date).map(([_, counts]) => {
        const items = Object.entries(counts as any).map(([date, count], index) => {
          return { key: index, date, count };
        });
        return {
          items,
          total: items.reduce((acc, val) => acc + parseInt(val.count as string, 10), 0),
        };
      });
    } else {
      return [];
    }
  }, [rawData]);

  const columns = useMemo(
    () => [
      { key: 'date', dataIndex: 'date', title: 'Tarix' },
      {
        key: 'count',
        dataIndex: 'count',
        title: 'Say',
        render: (value, row) =>
          value ? (
            <NavLink
              to={makePath(
                redirectUrl,
                {},
                {
                  created_at:
                    moment(row.date, CommonConstants.DATE).startOf('day').format(CommonConstants.DATE_TIME) +
                    ',' +
                    moment(row.date, CommonConstants.DATE).endOf('day').format(CommonConstants.DATE_TIME),
                  state_id: stateId !== '-1' ? stateId : undefined,
                },
              )}
            >
              {value}
            </NavLink>
          ) : (
            <span>0</span>
          ),
      },
    ],
    [redirectUrl, stateId],
  );

  return useMemo(() => ({ date, stateId, setStateId, handleChangeDate, loading, data, columns }), [date, stateId, setStateId, handleChangeDate, loading, data, columns]);
};
