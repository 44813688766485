import { useState, useCallback, ChangeEvent } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Input, message } from 'antd';
import * as Icons from '@ant-design/icons';

import { useService } from '@core/inversify-react';

import { HeaderButton } from '../components/header';

import { GetDeclarationByTrackCodeRepoType, IGetDeclarationByTrackCodeRepo } from '@modules/declarations';

export const Form = styled.form`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 10px;
`;

export const QuickSearch = () => {
  const [isInputShown, setIsInputShown] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const history = useHistory();

  const getDeclarationByTrackCodeRepo = useService<IGetDeclarationByTrackCodeRepo>(GetDeclarationByTrackCodeRepoType);

  const checkDeclaration = useCallback(
    async (trackCode: string) => {
      const result = await getDeclarationByTrackCodeRepo.execute({ trackCode });

      if (result.status === 200) {
        if (result.response.id) {
          const declerationId = result.response.id;
          history.push(generatePath('/@next/declarations/:declerationId', { declerationId }));
        }
      } else {
        message.error(result.response);
      }
    },
    [getDeclarationByTrackCodeRepo, history],
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      checkDeclaration(searchInput);
    },
    [checkDeclaration, searchInput],
  );

  const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    setIsInputShown(false);
  }, []);

  const onButtonClick = useCallback(() => {
    setIsInputShown(true);
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      {isInputShown && <Input autoFocus={true} placeholder='İzləmə kodu' value={searchInput} onChange={onInputChange} onBlur={handleBlur} />}
      <HeaderButton onClick={onButtonClick} icon={<Icons.SearchOutlined />}></HeaderButton>
    </Form>
  );
};
