import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';
import { Tag, Tooltip } from 'antd';
import * as Icons from '@ant-design/icons';

import { GetCustomsStatusRepoType, IGetCustomsStatusRepo } from '../repos';
import { ICustomsStatus } from '../interfaces';

export const CustomsStatus = () => {
  const getCustomsStatusRepo = useService<IGetCustomsStatusRepo>(GetCustomsStatusRepoType);

  const { data, isLoading } = useQuery<ICustomsStatus>(
    ['customs', 'status'],
    async () => {
      const result = await getCustomsStatusRepo.execute();

      return result.response;
    },
    { refetchInterval: 30000 },
  );

  const status = useMemo(() => (data?.status ? data.status : isLoading ? 'processing' : 'warning'), [data?.status, isLoading]);

  const icon = useMemo(() => {
    switch (status) {
      case 'success':
        return <Icons.CheckCircleOutlined />;
      case 'error':
        return <Icons.CloseCircleOutlined />;
      case 'warning':
        return <Icons.ExclamationCircleOutlined />;
      default:
        return <Icons.SyncOutlined spin={true} />;
    }
  }, [status]);

  const title = useMemo(() => {
    switch (status) {
      case 'success':
        return 'İşləkdir';
      case 'error':
        return 'İşlək deyil';
      case 'warning':
        return 'Təyin olunmayıb';
      default:
        return 'Gözlənilir';
    }
  }, [status]);

  return (
    <Tooltip title={title}>
      <Tag icon={icon} color={status}>
        DGK
      </Tag>
    </Tooltip>
  );
};
