import React, { useCallback, useMemo } from 'react';
import { Form, Switch } from 'antd';
import { WrappedFieldProps } from 'redux-form';
import { FormItemProps } from 'antd/lib/form';
import { SwitchProps } from 'antd/es/switch';

export const SwitchField = React.memo<WrappedFieldProps & { item: FormItemProps; field: SwitchProps; checkedValue: any; uncheckedValue: any }>(
  ({ input: { onBlur, ...input }, item, field, checkedValue = true, uncheckedValue = false, meta: { touched, error, warning } }) => {
    const errorText = useMemo(() => touched && error, [touched, error]);
    const warningText = useMemo(() => touched && warning, [touched, warning]);
    const validateStatus = useMemo(() => {
      if (errorText) {
        return 'error';
      } else if (warningText) {
        return 'warning';
      }
    }, [errorText, warningText]);

    const findNextValue = useCallback(
      (value: any) => {
        switch (value) {
          case checkedValue:
            return uncheckedValue;
          case uncheckedValue:
            return checkedValue;
          default:
            return checkedValue;
        }
      },
      [checkedValue, uncheckedValue],
    );

    const handleChange = useCallback(() => {
      input.onChange(findNextValue(input.value));
    }, [input, findNextValue]);

    const checked = useMemo(() => {
      switch (input.value) {
        case checkedValue:
          return true;
        default:
          return false;
      }
    }, [input.value, checkedValue]);

    return (
      <Form.Item validateStatus={validateStatus} help={errorText || warningText} {...item}>
        <Switch {...input} {...field} onChange={handleChange} checked={checked} />
      </Form.Item>
    );
  },
);
