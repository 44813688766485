import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { PlusCircleOutlined, UnorderedListOutlined } from '@ant-design/icons/lib';
import routes from '../../constants/routes';

const PlansActions = () => {
  const history = useHistory();

  const goToCreate = useCallback(() => {
    history.push(routes.plans.create);
  }, [history]);

  const goToCategories = useCallback(() => {
    history.push(routes.plans.categories);
  }, [history]);

  return (
    <div className='actions-wrapper'>
      <div className='actions-wrapper-left'>
        <Button type='primary' onClick={goToCreate} icon={<PlusCircleOutlined />}>
          Yeni
        </Button>
        <Button type='primary' className='actions-wrapper-additional-button' onClick={goToCategories} icon={<UnorderedListOutlined />}>
          Xüsusi tariflər
        </Button>
      </div>
    </div>
  );
};

export default PlansActions;
