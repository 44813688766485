import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { CreateCashFlowTransactionErrorMapper, CreateCashFlowTransactionDtoMapper } from '../mappers';
import { CreateCashFlowTransactionDto } from '../dtos';

export type ICreateCashFlowTransactionRepoResponse = IRepoResponse<200> | IRepoResponse<422, object> | IRepoResponse<400 | 500, string>;

export type ICreateCashFlowTransactionRepo = IRepo<[CreateCashFlowTransactionDto, string | number | undefined], ICreateCashFlowTransactionRepoResponse>;

export const CreateCashFlowTransactionRepoType = Symbol.for('CreateCashFlowTransactionRepo');

@bind(CreateCashFlowTransactionRepoType)
export class CreateCashFlowTransactionRepo implements ICreateCashFlowTransactionRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto: CreateCashFlowTransactionDto, id?: string | number) {
    try {
      const body = new FormData();
      const mappedBody = CreateCashFlowTransactionDtoMapper.toPersistence(dto);

      appendToFormData(mappedBody, body);

      const url = (() => {
        if (id) {
          return urlMaker('/api/admin/cashbox_operations/edit');
        }

        if (dto.type === 'transfer') {
          return urlMaker('/api/admin/cashbox_operations/transfer');
        }

        return urlMaker('/api/admin/cashbox_operations/create');
      })();

      const result = await this.httpClient.post({ url, body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400 || result.status === 422) {
        if (result.data.errors.not_enough_amount) {
          return {
            status: 400 as 400,
            response: result.data.errors.not_enough_amount.join(', '),
          };
        }

        const errors = CreateCashFlowTransactionErrorMapper.toDomain(result.data.errors, dto.type);

        return {
          status: 422 as 422,
          response: errors,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      console.error(e);
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
