import React from 'react';
import { Card, Col, DatePicker, Row, Select, Spin, Table } from 'antd';
import { useCouriersStatisticsByRegion } from '../hooks/use-couriers-by-regions';

const { RangePicker } = DatePicker;

export const CouriersStatisticsByRegions = () => {
  const { result, dateRange, handleChangeDateRange, handleStateIdChange, stateId } = useCouriersStatisticsByRegion();

  return (
    <Card size='small' title='Regionlar üzrə kuryer statistikası'>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <RangePicker allowClear={false} value={dateRange} onChange={handleChangeDateRange} style={{ marginBottom: 12 }} />
        <Select value={stateId} onChange={handleStateIdChange}>
          <Select.Option value='11'>Kuryer sifariş verildi</Select.Option>
          <Select.Option value='12'>Sifariş hazırlanır</Select.Option>
          <Select.Option value='13'>Kuryerə təhvil verildi</Select.Option>
          <Select.Option value='14'>Bağlama təhvil verildi</Select.Option>
        </Select>
      </div>
      <Spin spinning={result.loading}>
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Card type='inner' size='small' title='Mədaxil'>
              <Table rowKey='id' dataSource={result.data} loading={result.loading}>
                <Table.Column key='id' dataIndex='id' title='Kod' />
                <Table.Column key='name' dataIndex='name' title='Ad' />
                <Table.Column key='price.azn' dataIndex={['price', 'azn']} title='Qiymət (AZN)' />
                <Table.Column key='price.usd' dataIndex={['price', 'usd']} title='Qiymət (USD)' />
              </Table>
            </Card>
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};
