import React, { useCallback, useMemo } from 'react';
import { actionsCol, dateCol, idCol } from '../../core/helpers/table';
import { OverflowCell } from '../../components/Table/core/Components/Cell/OverflowCell';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';
import { Button, Dropdown, Menu, message, Modal } from 'antd';
import * as Icons from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { deleteConfigShops } from '../../actions/configShops';
import { useDispatch } from 'react-redux';
import { tableFetchDataAction } from '../../actions/table';

export const useConfigShopsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const columns = useMemo(
    () => [
      {
        ...actionsCol,
        Cell: ({ row: { original } }) => {
          const gotoEdit = useCallback(() => {
            history.push(makePath(routes.config.shops.edit, { id: original.id }));
          }, [original.id]);

          const handleDelete = useCallback(async () => {
            const action = async () => {
              message.loading({ content: 'Mağaza silinir...', key: 'config-shops-remove-message' });
              const result = await deleteConfigShops([original.id]);
              if (result) {
                message.success({ content: 'Mağaza silindi...', key: 'config-shops-remove-message' });
                dispatch(tableFetchDataAction('configShops', '/api/admin/shop_names'));
              } else {
                message.destroy();
              }
            };

            Modal.confirm({
              title: 'Təsdiqlə',
              content: `Mağazanı silməyə əminsinizmi?`,
              onOk: action,
            });
          }, [original.id]);

          return (
            <span onClick={(e) => e.stopPropagation()}>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item onClick={gotoEdit} icon={<Icons.EditOutlined />}>
                      Dəyişdir
                    </Menu.Item>
                    <Menu.Item onClick={handleDelete} icon={<Icons.DeleteOutlined />}>
                      Sil
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button className='next-table-action-btn' type='text' icon={<Icons.MoreOutlined />} />
              </Dropdown>
            </span>
          );
        },
      },
      {
        ...idCol,
      },
      {
        accessor: 'name',
        Header: 'Mağaza',
        Cell: OverflowCell,
      },
      {
        accessor: 'created_at',
        Header: 'Yaradılıb',
        ...dateCol,
      },
    ],
    [history, dispatch],
  );

  return { columns };
};
