import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { InjectedFormProps } from 'redux-form';
import { message } from 'antd';

import { useClose } from '../../common';
import routes from '../../../constants/routes';
import { useBulkEmailFormFields } from './useFormFields';
import { formValuesSelector } from '../../../selectors/form';
import { urlFactory } from '../../../core/utils/caller';
import { useForm } from '../../common/useForm';
import { useDebounce } from 'use-debounce';
import { useInfo } from '../../common/useInfo';
import { TableProps } from 'antd/lib/table';
import moment from 'moment';
import { CommonConstants } from '../../../constants/common';
import { makePath } from '../../../core/utils/makePath';

export const useBulkEmailForm = (props: InjectedFormProps & RouteComponentProps<any>) => {
  const handleClose = useClose(routes.archive.email.index);
  const fields = useBulkEmailFormFields(props);
  const values = useSelector(formValuesSelector(props.form));

  const goToCreateTemplate = useCallback(() => {
    props.history.push(makePath(routes.archive.email.bulk.templates.create, {}, { next: makePath(routes.archive.email.bulk.index, {}, { reFetchTemplates: true }) }));
  }, [props.history]);

  const [filterValues] = useDebounce(
    useMemo(
      () => ({
        search_id: values.search_id,
        state_id: values.state_id,
        user_id: values.user_id,
        email: values.email,
        amount: values.amount,
        flight_id: values.flight_id,
        customs: values.customs,
        d: values.d,
      }),
      [values.search_id, values.state_id, values.user_id, values.email, values.amount, values.flight_id, values.customs, values.d],
    ),
    1000,
  );

  const userResult = useInfo(
    useMemo(
      () => ({
        apiUrl: urlFactory('/api/admin/bulk_mail/getlist', filterValues),
        responseGetter: (response) => (typeof response === 'number' ? response : response.data),
        initialState: [],
        disabled: !filterValues.search_id,
      }),
      [filterValues],
    ),
  );

  const userTable = useMemo<TableProps<any>>(
    () => ({
      dataSource: typeof userResult.data === 'number' || filterValues.search_id === 'user_mails' ? [] : userResult.data,
      loading: userResult.loading,
      bordered: true,
      rowKey: (row) => row.user_id,
      size: 'small',
      columns: [
        {
          key: 'user_id',
          dataIndex: 'user_id',
          title: 'Istifadəçi kodu',
        },
        {
          key: 'name',
          dataIndex: 'name',
          title: 'Ad',
        },
        {
          key: 'surname',
          dataIndex: 'surname',
          title: 'Soyad',
        },
        {
          key: 'number',
          dataIndex: 'number',
          title: 'Telefon',
        },
      ],
    }),
    [userResult, filterValues],
  );

  const { handleSubmit } = useForm(
    useMemo(
      () => ({
        handleClose,
        handleSubmit: props.handleSubmit,
        initialize: props.initialize,
        createUrl: routes.archive.email.bulk.index,
        createApiUrl: urlFactory('/api/admin/bulk_mail/send'),
        onSuccess: () => {
          handleClose();
          message.success('Toplu email yaradıldı');
        },
      }),
      [handleClose, props.handleSubmit, props.initialize],
    ),
  );

  const totalSmsCount = useMemo(() => Math.ceil((values.body?.length || 0) / 160) || 1, [values.body]);
  const characterRemains = useMemo(() => totalSmsCount * 160 - (values.body?.length || 0), [values.body, totalSmsCount]);

  useEffect(() => {
    userResult.reset();
  }, [filterValues]); // eslint-disable-line

  useEffect(() => {
    userResult.reset();
    switch (values.search_id) {
      case 'user_names':
        props.change('user_id', []);
        break;
      case 'user_mails':
        props.change('email', ['']);
        break;
      case 'declarations_state':
        props.change('state_id', [5]);
        break;
      case 'orders_state':
        props.change('state_id', [1]);
        break;
      case 'couriers_state':
        props.change('state_id', [11]);
        break;
      case 'customs_state':
        props.change('d', '0');
        break;
    }
  }, [values.search_id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.initialize({ search_id: 'user_names', planned_at: moment().add(25, 'minutes').format(CommonConstants.DATE_TIME) });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    handleClose,
    fields,
    values,
    totalSmsCount,
    characterRemains,
    handleSubmit,
    userTable,
    userResult,
    goToCreateTemplate,
  };
};
