import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Button, Form, Modal, Select, Space } from 'antd';
import * as Icons from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { NavLink } from 'react-router-dom';

import ReadyTable from '../../../../components/Table/ReadyTable';
import PriceCell from '../../../../components/Table/core/Components/Cell/PriceCell';
import ContentCard from '../../../../containers/Layout/Sidebar/Content/Card';
import { midCol } from '../../../../core/helpers/table';
import { TextField } from '../../../shared/modules/form/containers/text';
import { SelectField } from '../../../shared/modules/form/containers/select';
import { RangeField } from '../../../shared/modules/form/containers/date-range';
import { tableResetAction, tableSetFiltersAction } from '../../../../actions/table';
import { Constants } from '../../../shared/constants';
import { useRegions } from '../../regions/hooks/use-regions';
import { localURLMaker } from '../../../shared/utils/url-maker';

export const CourierHandoverFilters = () => {
  const dispatch = useDispatch();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const regions = useRegions();

  const initialValues = useMemo(
    () => ({
      employee_id: '',
      employee_name: '',
      region_id: '',
      employee_created_at: null,
    }),
    [],
  );

  const onSubmit = useCallback(
    (values: Record<string, any>) => {
      const filters = Object.entries(values).map(([key, value]) => {
        if (key === 'employee_created_at' && !!value) {
          return { id: key, value: value.map((moment) => moment.format(Constants.DATE_TIME)).join(',') };
        }

        return { id: key, value };
      });

      setModalIsVisible(false);

      dispatch(tableSetFiltersAction('courier_handover', filters));
    },
    [dispatch],
  );

  const resetTable = useCallback(() => {
    dispatch(tableResetAction('courier_handover'));
  }, [dispatch]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, resetForm }) => (
        <Fragment>
          <Space size={8}>
            <Button
              onClick={() => {
                resetForm();
                resetTable();
              }}
              icon={<Icons.ClearOutlined />}
            >
              Sıfırla
            </Button>
            <Button type='primary' onClick={() => setModalIsVisible(true)} icon={<Icons.FilterOutlined />}>
              Filterlə
            </Button>
          </Space>
          <Modal width={576} visible={modalIsVisible} onCancel={() => setModalIsVisible(false)} onOk={() => handleSubmit()}>
            <Form size='large' layout='vertical'>
              <TextField name='employee_id' item={{ label: 'Kuryer kodu' }} input={{ placeholder: 'Kuryer kodunu daxil edin' }} />
              <TextField name='employee_name' item={{ label: 'Kuryer adı' }} input={{ placeholder: 'Kuryer adını daxil edin' }} />
              <SelectField name='region_id' item={{ label: 'Rayon' }} input={{ placeholder: 'Rayon seçin...' }}>
                {regions.data.map((region) => (
                  <Select.Option key={region.id} value={region.id.toString()}>
                    {region.name}
                  </Select.Option>
                ))}
              </SelectField>
              <RangeField name='employee_created_at' item={{ label: 'Tarix aralığı' }} />
            </Form>
          </Modal>
        </Fragment>
      )}
    </Formik>
  );
};

export const CourierHandoverTable = () => {
  const columns = useMemo(
    () => [
      {
        ...midCol,
        accessor: 'employee_id',
        Header: 'Ə. kodu',
      },
      {
        accessor: 'employee_name',
        Header: 'Əməkdaş',
      },
      {
        accessor: 'region_name',
        Header: 'Region',
      },
      {
        accessor: 'courier_count',
        Header: 'Kuryer sayı',
        ...midCol,
        Cell: ({ row: { original } }) => {
          return (
            <NavLink
              to={localURLMaker(
                '/dashboard/couriers/handovers/details',
                {},
                {
                  employee_id: original.employee_id,
                  region_id: original.region_id,
                },
              )}
            >
              {original.courier_count}
            </NavLink>
          );
        },
      },
      {
        accessor: 'courier_sum',
        Header: 'Kuryer məbləği',
        Cell: ({ row: { original } }) => {
          return (
            <NavLink
              to={localURLMaker(
                '/dashboard/couriers/handovers/declarations',
                {},
                {
                  employee_id: original.employee_id,
                  region_id: original.region_id,
                },
              )}
            >
              {original.courier_sum} ₼
            </NavLink>
          );
        },
      },
      {
        accessor: 'courier_sum_cash',
        Header: 'Kuryerin əlinə aldığı məbləğ',
        Cell: PriceCell.AZN,
      },
      {
        accessor: 'declaration_sum',
        Header: 'Bağlama məbləği',
        Cell: PriceCell.USD,
      },
    ],
    [],
  );

  return (
    <ContentCard>
      <div className='actions-wrapper'>
        <div className='actions-wrapper-right'>
          <CourierHandoverFilters />
        </div>
      </div>
      <ReadyTable name='courier_handover' apiUrl='/api/admin/stats/couriers' filterable={false} pagination={false} columns={columns} />
    </ContentCard>
  );
};
