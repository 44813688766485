import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { useCloseModal } from '@core/router';

import { CreateBranchProvider } from '../contexts';
import { CreateBranch } from '../containers';

export const CreateBranchPage: FC = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id?: string }>();

  const onClose = React.useCallback(() => {
    closeModal('/@next/branches');
  }, [closeModal]);

  return (
    <CreateBranchProvider id={id} onCancel={onClose} onSucceed={onClose}>
      <CreateBranch />
    </CreateBranchProvider>
  );
};
