import React from 'react';
import { Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useNotificationTemplatesActions } from '../../hooks/notificationTemplates/useActions';

const NotificationTemplateActions = () => {
  const { goToCreate } = useNotificationTemplatesActions();

  return (
    <div className='actions-wrapper'>
      <div className='actions-wrapper-left'>
        <Button type='primary' onClick={goToCreate} icon={<PlusCircleOutlined />}>
          Yeni
        </Button>
      </div>
    </div>
  );
};

export default NotificationTemplateActions;
