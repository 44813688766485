import { useContext } from 'react';
import { useSub } from '@core/event-bus';

import { useBranchesTableColumns } from '.';
import { BranchesTableContext } from '../../contexts';

export const useBranchesTable = () => {
  const { fetch } = useContext(BranchesTableContext);

  const columns = useBranchesTableColumns();

  useSub(['@branches/create/succeed', '@branches/update/succeed', '@branches/remove/succeed'], () => {
    fetch();
  });

  return { columns };
};
