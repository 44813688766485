import React from 'react';

export const renderCell = (node: string | number | undefined, suffix: string | number | React.ReactNode = '') => {
  if (node || typeof node === 'number') {
    return (
      <>
        {node} {suffix}
      </>
    );
  }

  return 'Qeyd olunmayıb';
};
