import React from 'react';
import { Dropdown, Menu, Space } from 'antd';
import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';

import { useStatusByModelId } from '@modules/status';
import { HeaderButton, HeadPortal } from '@modules/layout';

import { useOrdersActionBar } from '../hooks';

export const OrdersActionBar = () => {
  const { selection, resetSelection, updateStatus, bulkUpdateStatus, exportAsExcel, create, selectAll, reset, fetch, openCountsByStatus, totalPrice } = useOrdersActionBar();

  const status = useStatusByModelId('1');

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir ({totalPrice.toFixed(2)} ₺)
    </HeaderButton>
  );

  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <Space size={0}>
          <HeaderButton onClick={create} icon={<Icons.PlusCircleOutlined />}>
            Yeni
          </HeaderButton>
          {!selection.length ? selectAllButton : resetSelectionButton}
          <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
            Yenilə
          </HeaderButton>
          <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
            Sıfırla
          </HeaderButton>
        </Space>
        {!selection.length ? (
          <Space size={0}>
            <HeaderButton onClick={openCountsByStatus} icon={<Icons.BarChartOutlined />} />
            <HeaderButton onClick={exportAsExcel} icon={<Icons.FileExcelOutlined />}>
              Export
            </HeaderButton>
            <Space size={0}>
              <Dropdown
                overlay={
                  <Menu>
                    {status.data?.map((status) => (
                      <Menu.Item key={status.id} onClick={() => bulkUpdateStatus(status.id)}>
                        {status.name}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <HeaderButton icon={<Icons.AppstoreOutlined />}>Toplu status dəyiş</HeaderButton>
              </Dropdown>
            </Space>
          </Space>
        ) : (
          <Space size={0}>
            <Dropdown
              overlay={
                <Menu>
                  {status.data?.map((status) => (
                    <Menu.Item key={status.id} onClick={() => updateStatus(status.id)}>
                      {status.name}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <HeaderButton icon={<Icons.AppstoreOutlined />}>Statusu dəyiş</HeaderButton>
            </Dropdown>
          </Space>
        )}
      </ActionBar>
    </HeadPortal>
  );
};
