import moment from 'moment';
import { FormErrors } from '@core/form';
import { Constants } from '@core/constants';

import { CreateUserDto, CreateOrderDtoPersistence } from '../dtos';
import { IDetailedUser } from '../interfaces';

export class CreateUserMapper {
  public static toDomain(user: IDetailedUser): CreateUserDto {
    return {
      id: user.id.toString(),
      firstname: user.firstname,
      lastname: user.lastname,
      branchId: user.branchId,
      email: user.email,
      gender: user.gender,
      phoneNumber: user.phoneNumber,
      address: user.address || '',
      birthDate: user.birthDate ? moment(user.birthDate, Constants.DATE) : null,
      discount: {
        percent: user.discount.percent.toString(),
        dateUntil: user.discount.dateUntil ? moment(user.discount.dateUntil, Constants.DATE_TIME) : null,
      },
      passport: {
        number: user.passport.number || '',
        secret: user.passport.secret || '',
      },
      password: '',
      passwordConfirmation: '',
    };
  }

  public static toPersistence(user: CreateUserDto): CreateOrderDtoPersistence {
    return {
      user_id: user.id,
      name: user.firstname,
      surname: user.lastname,
      email: user.email,
      gender: user.gender,
      number: user.phoneNumber,
      address: user.address,
      branch_id: user.branchId,
      discount: user.discount.percent,
      discount_date: user.discount.dateUntil?.format(Constants.DATE_TIME) || '',
      birth_date: user.birthDate?.format(Constants.DATE) || '',
      passport_number: user.passport.number,
      passport_secret: user.passport.secret,
      password: user.password,
      password_confirmation: user.passwordConfirmation,
    };
  }
}

export class CreateOrderErrorsMapper {
  public static toDomain(errors): FormErrors<CreateUserDto> {
    return {
      id: errors.user_id?.join(', '),
      firstname: errors.name?.join(', '),
      lastname: errors.surname?.join(', '),
      email: errors.email?.join(', '),
      branchId: errors.branch_id?.join(', '),
      birthDate: errors.birth_date?.join(', '),
      phoneNumber: errors.number?.join(', '),
      address: errors.address?.join(', '),
      discount: {
        percent: errors.discount?.join(', '),
        dateUntil: errors.discount_date?.join(', '),
      },
      passport: {
        number: errors.passport_number?.join(', '),
        secret: errors.passport_secret?.join(', '),
      },
      password: errors.password?.join(', '),
      passwordConfirmation: errors.password_confirmation?.join(', '),
    };
  }
}
