import { NavLink } from 'react-router-dom';
import React, { useMemo } from 'react';
import { dateCol, midCol, normalCol } from '../../core/helpers/table';
import { makePath } from '../../core/utils/makePath';
import { Tooltip } from 'antd';
import { stopPropagation } from '../../core/helpers/events';
import { StateCell } from '../../components/Couriers/Cell/StateCell';
import { StateFilter } from '../../components/Couriers/Filter/StateFilter';
import PriceCell from '../../components/Table/core/Components/Cell/PriceCell';
import CheckCell from '../../components/Table/core/Components/Cell/CheckCell';
import { CheckboxFilter } from '../../components/Table/core/Components/Filters/CheckboxFilter';
import { OverflowCell } from '../../components/Table/core/Components/Cell/OverflowCell';
import { UserCell } from '@modules/users';

export const useCouriersTableColumns = () => {
  return useMemo<any[]>(
    () => [
      { accessor: 'user_id', Header: 'M. Kodu', ...midCol },
      {
        accessor: 'user_name',
        Header: 'Müştəri',
        width: 200,
        Cell: ({ row: { original } }) => <UserCell id={original.user_id} title={original.user_name} />,
      },
      {
        accessor: 'declarations',
        Header: 'Bağlamalar',
        Cell: ({ cell: { value } }) => {
          const declarations = useMemo(() => (value ? value.split(',') : []), [value]);

          const node = useMemo(() => {
            if (declarations.length === 1) {
              return <NavLink to={makePath('/@next/declarations/:id', { id: declarations[0] })}>#{declarations[0]}</NavLink>;
            } else if (declarations.length > 1) {
              return (
                <Tooltip
                  placement='bottom'
                  title={
                    <div className='link-list'>
                      {declarations.map((declaration) => (
                        <NavLink key={declaration} className='link-list-item' to={makePath('/@next/declarations/:id', { id: declaration })}>
                          #{declaration}
                        </NavLink>
                      ))}
                    </div>
                  }
                >
                  <span>{declarations.length} bağlama</span>
                </Tooltip>
              );
            } else {
              return null;
            }
          }, [declarations]);

          return <span onClick={stopPropagation}>{node}</span>;
        },
        ...midCol,
      },
      { accessor: 'state_id', Header: 'Status', Cell: StateCell, Filter: StateFilter, ...normalCol },
      { accessor: 'price', Header: 'Qiymət', Cell: PriceCell.AZN, ...midCol },
      { accessor: 'total_price', Header: 'Toplam qiymət', Cell: PriceCell.AZN, ...midCol },
      { accessor: 'payed', Header: 'Ödənilmə statusu', Cell: CheckCell, ...midCol, Filter: CheckboxFilter },
      { accessor: 'recipient', Header: 'Qəbul edən' },
      { accessor: 'phone', Header: 'Telefon', ...normalCol },
      { accessor: 'region_name', Header: 'Rayon', ...normalCol },
      { accessor: 'address', Header: 'Ünvan', Cell: OverflowCell, ...normalCol },
      { accessor: 'created_at', Header: 'Yaradılıb', ...dateCol },
    ],
    [],
  );
};
