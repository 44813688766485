import { Fragment, FC } from 'react';
import { Details, SuspenseSpin, Theme } from '@core/ui';
import { Button, Card, Result, Space, Statistic, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import * as Icons from '@ant-design/icons';

import { useUser } from '@modules/users';

export const UserDetails: FC<{ id: string }> = ({ id }) => {
  const { go } = useHistory();

  const {
    data,
    address,
    copyAddress,
    role,
    isLoading,
    error,
    remove,
    openUpdate,
    openWhatsapp,
    openDeclarations,
    openOrders,
    openSMS,
    openEmail,
    openCustomsDeclarations,
    openCouriers,
    openTransactions,
    openTickets,
    openTicketsForm,
    openCurrentMonthDeclarations,
    openUpdatePermissions,
  } = useUser(id);

  if (isLoading) {
    return (
      <Details.Wrapper>
        <SuspenseSpin />
      </Details.Wrapper>
    );
  }

  if (error) {
    return (
      <Details.Wrapper>
        <Result status='500' title='Xəta baş verdi' subTitle={error.message} />
      </Details.Wrapper>
    );
  }

  if (!data) {
    return null;
  }

  const transactionCounts = (
    <div>
      <div>
        <b>Mədaxil sayı: </b> {data?.counts.transactions.income}
      </div>
      <div>
        <b>Məxaric sayı: </b> {data?.counts.transactions.outcome}
      </div>
    </div>
  );

  const courierCounts = (
    <div>
      <div>
        <b>Ümumi say: </b> {data.counts.couriers.all}
      </div>
      <div>
        <b>Təhvil verilmiş: </b> {data.counts.couriers.handedOver}
      </div>
    </div>
  );

  const orderCounts = (
    <div>
      <div>
        <b>Ümumi say: </b> {data.counts.orders.all}
      </div>
      <div>
        <b>Təhvil verilmiş: </b> {data.counts.orders.handedOver}
      </div>
    </div>
  );

  const declarationCounts = (
    <div>
      <div>
        <b>Ümumi say: </b> {data.counts.declarations.all}
      </div>
      <div>
        <b>Təhvil verilmiş: </b> {data.counts.declarations.handedOver}
      </div>
    </div>
  );

  const extra = (
    <Fragment>
      {data.role !== 'user' && (
        <Tooltip title='Səlahiyyətlər'>
          <Button type='link' onClick={openUpdatePermissions} icon={<Icons.SettingOutlined />} />
        </Tooltip>
      )}
      <Tooltip title='Düzəliş et'>
        <Button type='link' onClick={openUpdate} icon={<Icons.EditOutlined />} />
      </Tooltip>
      <Tooltip title='Sil'>
        <Button type='link' onClick={remove} danger={true} icon={<Icons.DeleteOutlined />} />
      </Tooltip>
    </Fragment>
  );

  const title = (
    <Space size={8}>
      <Icons.LeftCircleOutlined onClick={() => go(-1)} />
      <span>#{data.id}</span>
    </Space>
  );

  return (
    <Details.Wrapper>
      <Details.Row>
        <Details.Col xs={24}>
          <Details.PageHeader title={title} subTitle={data.fullName} extra={extra} />
        </Details.Col>
        <Details.Col xs={24}>
          <Space size={8}>
            <Tooltip placement='bottom' overlay={orderCounts}>
              <Button ghost={true} type='primary' onClick={openOrders}>
                Sifarişlər
              </Button>
            </Tooltip>
            <Tooltip placement='bottom' overlay={declarationCounts}>
              <Button.Group>
                <Button ghost={true} type='primary' onClick={openDeclarations}>
                  Bağlamalar
                </Button>
                <Button ghost={true} type='primary' onClick={openCurrentMonthDeclarations}>
                  Cari ay
                </Button>
              </Button.Group>
            </Tooltip>
            <Tooltip placement='bottom' overlay={courierCounts}>
              <Button ghost={true} type='primary' onClick={openCouriers}>
                Kuryerlər
              </Button>
            </Tooltip>
            <Tooltip placement='bottom' overlay={transactionCounts}>
              <Button ghost={true} type='primary' onClick={openTransactions}>
                Balans əməliyyatları
              </Button>
            </Tooltip>
            <Button.Group>
              <Button ghost={true} type='primary' onClick={openTickets}>
                Müraciətlər
              </Button>
              <Button ghost={true} type='primary' onClick={openTicketsForm} icon={<Icons.PlusCircleOutlined />} />
            </Button.Group>
            <Button ghost={true} type='primary' onClick={openEmail}>
              Emaillər
            </Button>
            <Button ghost={true} type='primary' onClick={openSMS}>
              SMSlər
            </Button>
            <Button ghost={true} type='primary' onClick={openCustomsDeclarations}>
              DGK məlumatları
            </Button>
          </Space>
        </Details.Col>
        <Details.Col xs={24}>
          <Details.Row>
            <Details.Col xs={24} lg={5}>
              <Card size='small'>
                <Statistic title='Balans' value={data.balance.usd} prefix='$' precision={2} />
              </Card>
            </Details.Col>
            <Details.Col xs={24} lg={5}>
              <Card size='small'>
                <Statistic title='Balans' value={data.balance.try} prefix='₺' precision={2} />
              </Card>
            </Details.Col>
            <Details.Col xs={24} lg={5}>
              <Card size='small'>
                <Statistic title='Borc' value={data.debt.try} prefix='₺' precision={2} />
              </Card>
            </Details.Col>
            <Details.Col xs={24} lg={5}>
              <Card size='small'>
                <Statistic title='Cari ay' value={data.spending.currentMonth.usd} prefix='$' precision={2} />
              </Card>
            </Details.Col>
            <Details.Col xs={24} lg={4}>
              <Card size='small'>
                <Statistic title='Endirim' value={data.discount.percent} prefix={<Icons.ArrowDownOutlined />} suffix='%' precision={2} />
              </Card>
            </Details.Col>
          </Details.Row>
        </Details.Col>
        <Details.Col xs={24}>
          <Details.Card size='small' title='Türkiyə ünvanı'>
            {address} <Icons.CopyOutlined onClick={copyAddress} />
          </Details.Card>
        </Details.Col>
        <Details.Col xs={24} lg={8}>
          <Details.Card title='Şəxsi məlumatlar'>
            <Details.Descriptions>
              <Details.Descriptions.Item label='Ad'>
                {data.firstname} {data.lastname}
              </Details.Descriptions.Item>
              <Details.Descriptions.Item label='Təvəllüd'>{data.birthDate}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Cinsi'>{data.gender === 'male' ? 'Kişi' : 'Qadın'}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Ş.V nömrəsi'>{data.passport.number}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='FİN kod'>{data.passport.secret}</Details.Descriptions.Item>
            </Details.Descriptions>
          </Details.Card>
        </Details.Col>
        <Details.Col xs={24} lg={8}>
          <Details.Card title='Sistem məlumatları'>
            <Details.Descriptions>
              <Details.Descriptions.Item label='Səlahiyyət'>{role}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='SMS xidməti'>{data.smsServiceIsActive ? 'Aktivdir' : 'Aktiv deyil'}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Email xidməti'>{data.emailServiceIsActive ? 'Aktivdir' : 'Aktiv deyil'}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Bloklanma statusu'>{data.isBlocked ? 'Bloklanıb' : 'Aktivdir'}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Yaradılma tarixi'>{data.createdAt}</Details.Descriptions.Item>
            </Details.Descriptions>
          </Details.Card>
        </Details.Col>
        <Details.Col xs={24} lg={8}>
          <Details.Card title='Əlaqə məlumatları'>
            <Details.Descriptions>
              <Details.Descriptions.Item label='Telefon'>
                <Space size={8}>
                  <span>{data.phoneNumber}</span>
                  <Icons.WhatsAppOutlined onClick={openWhatsapp} style={{ color: Theme.colors.success }} />
                </Space>
              </Details.Descriptions.Item>
              <Details.Descriptions.Item label='Email'>{data.email}</Details.Descriptions.Item>
              <Details.Descriptions.Item label='Ünvan'>{data.address}</Details.Descriptions.Item>
            </Details.Descriptions>
          </Details.Card>
        </Details.Col>
      </Details.Row>
    </Details.Wrapper>
  );
};
