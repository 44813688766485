import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReadyTable from '../../../../components/Table/ReadyTable';
import { tableSetFiltersAction } from '../../../../actions/table';
import { useDeclarationsTableColumns } from '../hooks/use-table-columns';
import { makePath } from '../../../../core/utils/makePath';
import routes from '../../../../constants/routes';

const DeclarationsTable: FC<any> = ({ flightId, defaultFilters }) => {
  const dispatch = useDispatch();
  const columns = useDeclarationsTableColumns();
  const history = useHistory();

  const apiUrl = useMemo(() => (flightId ? '/api/admin/flights/info' : '/api/admin/v2/declaration/list'), [flightId]);

  useEffect(() => {
    if (flightId) {
      dispatch(tableSetFiltersAction('flightDeclarations', [{ id: 'flight_id', value: flightId }]));
    } else if (defaultFilters) {
      dispatch(tableSetFiltersAction('declarations', defaultFilters));
    }
  }, [flightId, defaultFilters, dispatch]);

  const rowProps = useCallback(
    (id) => ({
      onDoubleClick: () => {
        history.push(makePath(routes.declarations.info, { id }));
      },
    }),
    [history],
  );

  return <ReadyTable getRowProps={rowProps} name={flightId ? 'flightDeclarations' : 'declarations'} apiUrl={apiUrl} columns={columns} />;
};

export default React.memo(DeclarationsTable);
