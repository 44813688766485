import { createContext, FC, useState } from 'react';

export type CashFlowTransactionSumProps = {
  sum?: number;
  setSumState?: (sum: number) => void;
};

export const CashFlowTransactionSumContext = createContext<CashFlowTransactionSumProps>({
  sum: 0,
  setSumState: () => {},
});

export const CashFlowTransactionSumProvider: FC<CashFlowTransactionSumProps> = ({ children }) => {
  const [sumState, setSumState] = useState<number>(0);

  return <CashFlowTransactionSumContext.Provider value={{ sum: sumState, setSumState }}>{children}</CashFlowTransactionSumContext.Provider>;
};
