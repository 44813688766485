import React, { useCallback, useMemo } from 'react';
import { Col, Row, Tabs } from 'antd';
import GeneralStatistics from './General';
import SpecialStatistics from './Special';
import OrdersStatistics from './Orders';
import DeclarationsStatistics from './Declarations';
import ClientsStatistics from './Clients';
import CouriersStatistics from './Couriers';
import { useTitle } from '../../hooks/common/useTitle';
import ProductTypesStatistics from './BarChart';
import { BalanceStatisticsByPaymentTypes } from '../../next/modules/statistics/containers/balance-statistics-by-payment-types';
import { DeclarationExecutives } from '../../next/modules/statistics/containers/declaration-executives';
import { DeclarationsPaymentTypesStatistics } from '../../next/modules/statistics/containers/declarations-payment-types';
import { CouriersPaymentTypesStatistics } from '../../next/modules/statistics/containers/couriers-payment-types';
import { CouriersStatisticsByRegions } from '../../next/modules/statistics/containers/couriers-by-regions';
import { CourierHandoverTable } from '../../next/modules/statistics/containers/courier-handover';
import { useHistory, useLocation } from 'react-router-dom';
import { search2Object } from '../../core/utils/search-to-object';
import { localURLMaker } from '../../next/shared/utils/url-maker';

const { TabPane } = Tabs;

const DashboardPage = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = useMemo(() => search2Object(location.search), [location.search]);
  const currentTab = useMemo(() => searchParams.tab || 'general', [searchParams.tab]);

  const onTabChange = useCallback(
    (tab) => {
      history.push(localURLMaker('/dashboard', {}, { tab }));
    },
    [history],
  );

  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - Statistika`);

  return (
    <div>
      <Tabs className='dashboard-tabs' defaultActiveKey='general' onChange={onTabChange} activeKey={currentTab} animated={false}>
        <TabPane key='general' tab='Ümumi statistika'>
          <GeneralStatistics />
        </TabPane>
        <TabPane key='special' tab='Xüsusi statistika'>
          <SpecialStatistics />
        </TabPane>
        <TabPane key='orders' tab='Sifarişlər'>
          <OrdersStatistics />
        </TabPane>
        <TabPane key='declarations' tab='Bağlamalar'>
          <DeclarationsStatistics />
        </TabPane>
        <TabPane key='clients' tab='Müştərilər'>
          <ClientsStatistics />
        </TabPane>
        <TabPane key='couriers' tab='Kuryerlər'>
          <CouriersStatistics />
        </TabPane>
        <TabPane key='couriers-handover' tab='Kuryer təhvil'>
          <CourierHandoverTable />
        </TabPane>
        <TabPane key='couriers-by-regions' tab='Rayonlar üzrə kuryerlər'>
          <CouriersStatisticsByRegions />
        </TabPane>
        <TabPane key='product-types' tab='Məhsul tipləri'>
          <ProductTypesStatistics />
        </TabPane>
        <TabPane key='payment-types' tab='Ödəniş metodları - Ümumi'>
          <BalanceStatisticsByPaymentTypes />
        </TabPane>
        <TabPane key='payment-types-2' tab='Ödəniş metodları - Xüsusi'>
          <Row gutter={8}>
            <Col xs={24} sm={12}>
              <DeclarationsPaymentTypesStatistics />
            </Col>
            <Col xs={24} sm={12}>
              <CouriersPaymentTypesStatistics />
            </Col>
          </Row>
        </TabPane>
        <TabPane key='declaration-executives' tab='Anbardarlar'>
          <DeclarationExecutives />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default React.memo(DashboardPage);
