import { message } from 'antd';
import { Dispatch } from 'redux';
import { caller, urlFactory } from '../core/utils/caller';
import { UnexpectedError, ValidationMessage } from '../core/helpers/errors';
import { tableFetchDataAction } from './table';
import { formDataBuilder } from '../core/utils/form-data-builder';

import { tableFilterParser } from '../core/helpers/table-filter-parser';

export const changeReadAction = (objectId: number[], read: boolean, reFetchTableData: boolean = true) => async (dispatch: Dispatch) => {
  const messageKey = 'tickets-read-change-message';
  const values = { ticket_id: objectId, value: read };

  const onSuccess = (success) => {
    message.success({
      content: 'Müraciətin oxunma statusu dəyişdirildi',
      key: messageKey,
    });
    if (reFetchTableData) {
      dispatch(tableFetchDataAction('tickets', '/api/admin/tickets/list'));
    }
    return success;
  };

  const onError = (error) => {
    message.error({
      content: 'Müraciətin oxunma statusu dəyişdirilə bilmədi',
      key: messageKey,
    });

    return error;
  };

  try {
    message.loading({ content: 'Əməliyyat aparılır', key: messageKey });

    const url = urlFactory('/api/admin/tickets/is_new');
    const body = formDataBuilder({ values });
    const response = await caller(url, { body, method: 'POST' });

    if (response.ok) {
      return onSuccess(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};

export const removeAction = (objectId: (number | string)[], reFetchTableData: boolean = true) => async (dispatch: Dispatch) => {
  const messageKey = 'tickets-remove-message';
  const values = { ticket_id: objectId };

  const onSuccess = (success) => {
    message.success({ content: 'Müraciət(lər) silindi', key: messageKey });
    if (reFetchTableData) {
      dispatch(tableFetchDataAction('tickets', '/api/admin/tickets/list'));
    }
    return success;
  };

  const onUnprocessableEntity = async (response) => {
    message.error({ content: 'Müraciət(lər) silinə bilmədi', key: messageKey });

    const { errors } = await response.json();

    Object.values(errors as Record<string, string[]>).forEach((error) => {
      message.error({ content: error[0] });
    });

    return response;
  };

  const onError = (error) => {
    message.error({ content: 'Müraciət(lər) silinə bilmədi', key: messageKey });
    return error;
  };

  message.loading({ content: 'Əməliyyat aparılır', key: messageKey });
  try {
    const url = urlFactory('/api/admin/tickets/cancel');
    const body = formDataBuilder({ values });

    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      return onSuccess(response);
    } else if (response.status === 400) {
      return onUnprocessableEntity(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};

export const changeStateAction = (objectId: (number | string)[], stateId: number, reFetchTableData: boolean = true) => async (dispatch: Dispatch) => {
  const messageKey = 'tickets-state-change-message';
  const values = { ticket_id: objectId, state_id: stateId };

  try {
    message.loading({ content: 'Əməliyyat aparılır', key: messageKey });

    const url = urlFactory('/api/admin/tickets/changestate');
    const body = formDataBuilder({ values });

    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      message.success({
        content: 'Status dəyişdirildi',
        key: messageKey,
      });
      if (reFetchTableData) {
        dispatch(tableFetchDataAction('tickets', '/api/admin/tickets/list'));
      }
    } else if (response.status === 422) {
      const { errors } = await response.json();

      ValidationMessage(errors);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    message.error({
      content: 'Status dəyişdirilə bilmədi',
      key: messageKey,
    });
  }
};

export const changeStateBulkAction = (filters: { id: string | number; value: any }[], stateId: number) => async (dispatch: Dispatch) => {
  const messageKey = 'tickets-state-change-message';
  const values = { ...tableFilterParser(filters), new_state_id: stateId };

  try {
    message.loading({ content: 'Əməliyyat aparılır', key: messageKey });

    const url = urlFactory('/api/admin/tickets/edit/state');
    const body = formDataBuilder({ values });

    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      message.success({
        content: 'Status dəyişdirildi',
        key: messageKey,
      });
      dispatch(tableFetchDataAction('tickets', '/api/admin/tickets/list'));
    } else if (response.status === 422) {
      const { errors } = await response.json();

      ValidationMessage(errors);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    message.error({
      content: 'Status dəyişdirilə bilmədi',
      key: messageKey,
    });
  }
};
