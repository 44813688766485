import React, { FC, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { Col, Form, Modal, Row, Spin } from 'antd';

import { useFormFields, useFormLayout } from '../../hooks/plans/useForm';
import { useClose } from '../../hooks/common';
import { urlFactory } from '../../core/utils/caller';
import { useForm } from '../../hooks/common/useForm';
import { tableFetchDataAction } from '../../actions/table';
import routes from '../../constants/routes';
import { useSelector } from 'react-redux';
import { formFieldValueSelector } from '../../selectors/form';
import { validateAction } from '../../actions/plans';

type IProps = InjectedFormProps<any> & RouteComponentProps<any> & { dispatch: Dispatch };

const PlansForm: FC<IProps> = ({ handleSubmit: baseHandleSubmit, initialize, match: { params }, dispatch, submitting, initialized, form }) => {
  const handleClose = useClose(routes.plans.index);
  const isSpecial = useSelector(formFieldValueSelector(form, 'is_special', false));

  const handleSubmitSuccess = useCallback(() => {
    dispatch(tableFetchDataAction('plans', '/api/client/v2/tariff/list'));
  }, [dispatch]);

  const formOptions = useMemo(
    () => ({
      handleClose,
      handleSubmit: baseHandleSubmit,
      initialize,
      createUrl: routes.plans.create,
      createApiUrl: urlFactory('/api/admin/tariff/create'),
      editApiUrl: urlFactory('/api/admin/tariff/edit', { tariff_id: params.id }),
      initializeUrl: urlFactory('/api/admin/tariff/getinfo', { tariff_id: params.id }),
      onSuccess: handleSubmitSuccess,
      initializeMiddlewares: [(values) => ({ ...values, type: values.type === '1', is_special: values.tariff_category_id !== 1 })],
      submitMiddlewares: [
        (values) => ({
          ...values,
          type: values.type ? 1 : 2,
          tariff_category_id: values.is_special ? values.tariff_category_id : undefined,
          currency: 'USD',
          from_unit: 1,
          to_unit: 1,
        }),
      ],
    }),
    [params.id, handleSubmitSuccess, baseHandleSubmit, initialize, handleClose],
  );

  const { handleSubmit, type } = useForm(formOptions);

  const title = useMemo(() => {
    return type === 'create' ? 'Yeni tarif əlavə et' : 'Tarifdə düzəliş et';
  }, [type]);

  const { minimumWeightFieldProps, maximumWeightFieldProps, typeFieldProps, priceFieldProps, categoryFieldProps, descriptionsFieldProps, isSpecialFieldProps } = useFormFields();
  const { baseRowProps, baseColProps, halfColProps } = useFormLayout();

  return (
    <Modal maskTransitionName='' transitionName='' width={592} visible={true} onCancel={handleClose} confirmLoading={submitting} onOk={handleSubmit} title={title}>
      <Spin spinning={type === 'edit' && !initialized}>
        <Form layout='vertical'>
          <Row {...baseRowProps}>
            <Col {...halfColProps}>
              <Field {...minimumWeightFieldProps} />
            </Col>
            <Col {...halfColProps}>
              <Field {...maximumWeightFieldProps} />
            </Col>
            <Col {...halfColProps}>
              <Field {...priceFieldProps} />
            </Col>
            <Col {...halfColProps}>
              <Field {...typeFieldProps} />
            </Col>
            <Col {...baseColProps}>
              <Field {...isSpecialFieldProps} />
            </Col>
            {isSpecial && (
              <Col {...baseColProps}>
                <Field {...categoryFieldProps} />
              </Col>
            )}
            <Col {...baseColProps}>
              <Field {...descriptionsFieldProps} />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default reduxForm<any, any>({ form: 'plans', validate: validateAction })(React.memo<IProps>(PlansForm));
