import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { Card, Col, Form, Modal, Radio, Row, Select } from 'antd';
import { Formik, FormikProps } from 'formik';
import { CascaderField, DateField, RadioField, SelectField, TextAreaField, TextField } from '@core/form';
import { Constants } from '@core/constants';
import { AntForm } from '@core/ui';

import { useCashRegisterById, useCashRegisters } from '@modules/cash-flow/cash-registers';
import { useCashRegisterOperationsWithSub } from '@modules/cash-flow/operations';

import { useCreateCashFlowTransaction } from '../hooks';
import { CreateCashFlowTransactionDto } from '../dtos';
import { CreateCashFlowTransactionContext } from '../contexts';

const FormikComponent: FC<FormikProps<CreateCashFlowTransactionDto>> = ({ handleSubmit, values, setFieldValue, isSubmitting }) => {
  const { onClose } = useContext(CreateCashFlowTransactionContext);

  const cashRegisters = useCashRegisters();
  const cashFlowOperations = useCashRegisterOperationsWithSub();
  const cashRegister = useCashRegisterById(values.cashRegisterId, { enabled: !!values.cashRegisterId });
  const targetCashRegister = useCashRegisterById(values.target.cashRegisterId, { enabled: !!values.target.cashRegisterId });

  useEffect(() => {
    const isCreate = !values.id;
    const isTransfer = values.type === 'transfer';
    const isSameCurrency = cashRegister.data?.currency.id === targetCashRegister.data?.currency.id;

    if (isCreate && isTransfer && isSameCurrency) {
      setFieldValue('target.amount', values.amount);
    }
  }, [cashRegister.data?.currency.id, setFieldValue, targetCashRegister.data?.currency.id, values.amount, values.id, values.type]);

  const onCashRegisterIdSelect = useCallback(() => {
    setFieldValue('amount', '');
  }, [setFieldValue]);

  const onTargetCashRegisterIdSelect = useCallback(() => {
    setFieldValue('target.amount', '');
  }, [setFieldValue]);

  const cashRegisterOptions = useMemo(
    () =>
      cashRegisters.data?.data.map((item) => {
        const isSelected = values.type === 'transfer' ? item.id.toString() === values.cashRegisterId || item.id.toString() === values.target.cashRegisterId : false;

        return (
          <Select.Option disabled={isSelected} key={item.id} value={item.id.toString()}>
            {item.name}
          </Select.Option>
        );
      }),
    [cashRegisters.data?.data, values.cashRegisterId, values.target.cashRegisterId, values.type],
  );

  const operationOptions = useMemo(
    () =>
      cashFlowOperations.data?.data.map((item) => ({
        value: item.id.toString(),
        label: item.name,
        children: item.children.map((subItem) => ({ value: subItem.id.toString(), label: subItem.name })),
      })) || [],
    [cashFlowOperations.data?.data],
  );

  return (
    <Modal width={992} visible={true} onCancel={onClose} confirmLoading={isSubmitting} onOk={() => handleSubmit()} title='Tranzaksiya əlavə et'>
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[12, 12]}>
          <Col xs={24}>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 12 }}>
              <RadioField name='type'>
                <Radio.Button disabled={!!values.id} value='income'>
                  Mədaxil
                </Radio.Button>
                <Radio.Button disabled={!!values.id} value='outcome'>
                  Məxaric
                </Radio.Button>
                <Radio.Button disabled={!!values.id} value='transfer'>
                  Transfer
                </Radio.Button>
              </RadioField>
            </div>
          </Col>
          {values.type === 'transfer' && (
            <Col xs={12}>
              <Card size='small' title='Kassadan' bodyStyle={{ paddingBottom: 0 }}>
                <Row gutter={12}>
                  <Col xs={24}>
                    <SelectField name='cashRegisterId' item={{ label: 'Kassa' }} input={{ placeholder: 'Kassa seçin...', onSelect: onCashRegisterIdSelect, disabled: !!values.id }}>
                      {cashRegisterOptions}
                    </SelectField>
                  </Col>
                  <Col xs={24} md={12}>
                    <TextField name='amount' item={{ label: 'Məbləğ' }} input={{ placeholder: 'Məbləği daxil edin...', disabled: !values.cashRegisterId || !!values.id }} />
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label='Valyuta'>
                      <Select disabled={true} value={cashRegister.data?.currency.code} placeholder='Valyuta seçin...' />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
          {values.type === 'transfer' && (
            <Col xs={12}>
              <Card size='small' title='Kassaya' bodyStyle={{ paddingBottom: 0 }}>
                <Row gutter={12}>
                  <Col xs={24}>
                    <SelectField name='target.cashRegisterId' item={{ label: 'Kassa' }} input={{ placeholder: 'Kassa seçin...', disabled: !!values.id }}>
                      {cashRegisterOptions}
                    </SelectField>
                  </Col>
                  <Col xs={24} md={12}>
                    <TextField
                      name='target.amount'
                      item={{ label: 'Məbləğ' }}
                      input={{
                        onSelect: onTargetCashRegisterIdSelect,
                        placeholder: 'Məbləği daxil edin...',
                        disabled: !values.target.cashRegisterId || cashRegister.data?.currency.id === targetCashRegister.data?.currency.id || !!values.id,
                      }}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label='Valyuta'>
                      <Select placeholder='Valyuta seçin...' disabled={true} value={targetCashRegister.data?.currency.code} />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
          {values.type !== 'transfer' && (
            <Col xs={12}>
              <Card size='small' title='Kassa' bodyStyle={{ paddingBottom: 0 }}>
                <Row gutter={12}>
                  <Col xs={24}>
                    <SelectField
                      name='cashRegisterId'
                      item={{ label: 'Kassa' }}
                      input={{
                        onSelect: onCashRegisterIdSelect,
                        placeholder: 'Kassa seçin...',
                        disabled: !!values.id,
                      }}
                    >
                      {cashRegisterOptions}
                    </SelectField>
                  </Col>
                  <Col xs={24} md={12}>
                    <TextField name='amount' item={{ label: 'Məbləğ' }} input={{ placeholder: 'Məbləği daxil edin...', disabled: !values.cashRegisterId || !!values.id }} />
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item label='Valyuta'>
                      <Select placeholder='Valyuta seçin...' disabled={true} value={cashRegister.data?.currency.code} />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
          <Col xs={24} md={values.type === 'transfer' ? 24 : 12}>
            <Row gutter={12}>
              <Col xs={24} md={12}>
                <CascaderField name='operationIds' input={{ options: operationOptions, placeholder: 'Kateqoriyanı seçin...' }} item={{ label: 'Kateqoriya' }} />
              </Col>
              <Col xs={24} md={12}>
                <DateField name='operatedAt' input={{ placeholder: 'Tarix seçin...', format: Constants.DATE_TIME, showTime: true }} item={{ label: 'Əməliyyat tarixi' }} />
              </Col>
              <Col xs={24}>
                <TextAreaField name='description' item={{ label: 'Qeyd' }} input={{ placeholder: 'Qeydinizi daxil edin...', rows: 4 }} />
              </Col>
            </Row>
          </Col>
        </Row>
      </AntForm>
    </Modal>
  );
};

export const CreateCashFlowTransaction = () => {
  const { onSubmit, initialValues } = useCreateCashFlowTransaction();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} component={FormikComponent} />;
};
