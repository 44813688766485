import { isOfType } from 'typesafe-actions';
import { concat, from, Observable, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { ActionsObservable, Epic } from 'redux-observable';

import { caller, urlFactory } from '../core/utils/caller';
import { shopsFetchFailedAction, shopsFetchStartedAction, shopsFetchSucceedAction } from '../actions/shops';
import { ShopsActionTypes } from '../constants/shops';
import { IAppState } from '../interfaces/app-state.interface';
import { IShopsActions } from '../interfaces/shops/actions';

const fetchData = async () => {
  const response = await caller(urlFactory('/api/client/shop'));

  if (response.ok) {
    const { data } = await response.json();

    return shopsFetchSucceedAction(data);
  } else {
    throw new Error('Unexpected error');
  }
};

export const shopsFetchDataEpic: Epic<IShopsActions, IShopsActions, IAppState> = (action$: ActionsObservable<IShopsActions>): Observable<any> => {
  return action$.pipe(
    filter(isOfType(ShopsActionTypes.FETCH)),
    switchMap(() => {
      return concat(of(shopsFetchStartedAction()), from(fetchData()).pipe(catchError(() => of(shopsFetchFailedAction('Məlumatlar əldə edilə bilmədi')))));
    }),
  );
};
