import React, { FC } from 'react';

import ReadyTable from '../../components/Table/ReadyTable';
import { useDnsQueuesTable } from '../../hooks/dnsQueues/useTable';

const DnsQueuesTable: FC = () => {
  const { columns } = useDnsQueuesTable();

  return <ReadyTable columns={columns} name='dnsQueues' apiUrl='/api/admin/customs/queues' sortable={false} pagination={false} filterable={false} />;
};

export default DnsQueuesTable;
