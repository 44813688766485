import React, { FC } from 'react';

import ReadyTable from '../../components/Table/ReadyTable';
import { useConfigReturnTypesTable } from '../../hooks/configReturnTypes/useTable';

const ConfigReturnTypesTable: FC = () => {
  const { columns } = useConfigReturnTypesTable();

  return <ReadyTable columns={columns} name='configReturnTypes' apiUrl='/api/admin/return_reasons' />;
};

export default ConfigReturnTypesTable;
