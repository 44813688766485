import React, { FC } from 'react';
import { Card, Spin, Statistic } from 'antd';
import { useBonazStatistics } from '../../hooks/bonaz/useBonazStatistics';

const BonazBalance: FC = () => {
  const { data, loading } = useBonazStatistics();

  return (
    <Spin spinning={loading}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gridGap: 8,
          marginBottom: 8,
          alignContent: 'stretch',
        }}
      >
        <Card size='small' hoverable={true}>
          <Statistic title='Məzənnə' value={data.rate['azn/try']} />
        </Card>
        <Card size='small' hoverable={true}>
          <Statistic title='Aktiv' value={data.active.azn} />
          <Statistic value={data.active.try} />
        </Card>
        <Card size='small' hoverable={true}>
          <Statistic title='Gözlənilən' value={data.pending.azn} />
          <Statistic value={data.pending.try} />
        </Card>
        <Card size='small' hoverable={true}>
          <Statistic title='Ümumi' value={`${data.total.azn}`} />
        </Card>
      </div>
    </Spin>
  );
};

export default BonazBalance;
