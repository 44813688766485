import { useInfo } from '../common/useInfo';
import { urlFactory } from '../../core/utils/caller';
import { useMemo } from 'react';

export const useBalanceStatistics = (delay = 0) => {
  const useInfoOptions = useMemo(
    () => ({
      apiUrl: urlFactory('/api/admin/charts/bybalancespend'),
      responseGetter: (response) => response.data,
      delay,
    }),
    [delay],
  );

  const { data: rawData, loading } = useInfo(useInfoOptions);

  const data = useMemo(() => {
    if (rawData) {
      return {
        labels: rawData.labels,
        datasets: [
          {
            label: 'Artırılmış',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            borderCapStyle: 'round',
            data: rawData.datasets[0][0],
          },
          {
            label: 'Ödənilmiş',
            backgroundColor: 'rgba(55,152,215,0.2)',
            borderColor: 'rgb(55,152,215)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(55,152,215,0.4)',
            hoverBorderColor: 'rgb(55,152,215)',
            borderCapStyle: 'round',
            data: rawData.datasets[0][1],
          },
        ],
      };
    } else {
      return {};
    }
  }, [rawData]);

  return useMemo(() => ({ data, loading }), [data, loading]);
};
