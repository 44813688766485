import React, { FC, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { search2Object } from '../../../core/utils/search-to-object';
import { urlFactory } from '../../../core/utils/caller';
import { useInfo } from '../../../hooks/common/useInfo';
import { Modal, Table } from 'antd';
import { useClose } from '../../../hooks/common';
import routes from '../../../constants/routes';

const DashboardSpecialDeclarationsDetails: FC<RouteComponentProps<any>> = ({ location: { search } }) => {
  const params = useMemo(() => search2Object(search), [search]);
  const handleClose = useClose(routes.dashboard.index);

  const useInfoOptions = useMemo(
    () => ({
      apiUrl: urlFactory('/api/admin/stats/declaration_details', params),
      responseGetter: (response) => response.data,
      initialState: [],
    }),
    [params],
  );

  const { data: rawData, loading } = useInfo(useInfoOptions);

  const data = useMemo(() => rawData.map((item, index) => ({ ...item, key: index })), [rawData]);

  const columns = useMemo(
    () => [
      { dataIndex: 'id', key: 'id', title: '#' },
      { dataIndex: 'user_name', key: 'user_name', title: 'Müştəri' },
      { dataIndex: 'track_code', key: 'track_code', title: 'İzləmə kodu' },
      { dataIndex: 'global_track_code', key: 'global_track_code', title: 'Qlobal izləmə kodu' },
      { dataIndex: 'state_name', key: 'state_name', title: 'Status' },
      { dataIndex: 'payed', key: 'payed', title: 'Ödənilmə statusu' },
      { dataIndex: 'flight_name', key: 'flight_name', title: 'Flight' },
      { dataIndex: 'price', key: 'price', title: 'Qiymət', render: (value) => (value ? `${value} TRY` : '') },
      { dataIndex: 'weight', key: 'weight', title: 'Çəki', render: (value) => (value ? `${value} KQ` : '') },
      { dataIndex: 'quantity', key: 'quantity', title: 'Say' },
      { dataIndex: 'delivery_price', key: 'delivery_price', title: 'Çatdırılma qiyməti', render: (value) => (value ? `${value} USD` : '') },
      { dataIndex: 'shop_name', key: 'shop_name', title: 'Mağaza' },
      { dataIndex: 'product_type_name', key: 'product_type_name', title: 'Məhsul tipi' },
      { dataIndex: 'wardrobe_number', key: 'wardrobe_number', title: 'Şkaf nömrəsi' },
      { dataIndex: 'causer_name', key: 'causer_name', title: 'Düzəliş edən' },
      { dataIndex: 'created_at', key: 'created_at', title: 'Yaradılıb' },
    ],
    [],
  );

  return (
    <Modal maskTransitionName='' transitionName='' width={992} visible={true} onCancel={handleClose} footer={null} closable={false}>
      <Table scroll={{ x: true }} rowKey={(data) => data.id} bordered={true} loading={loading} dataSource={data} columns={columns} />
    </Modal>
  );
};

export default React.memo(DashboardSpecialDeclarationsDetails);
