import { Card, Col, Form, Modal, Row, Select, Switch, Typography } from 'antd';

import { AntForm, MasonryCol, MasonryGrid } from '@core/ui';
import { useCashRegisters } from '@modules/cash-flow/cash-registers';

import { useUpdateUserPermissions } from '../hooks';
import { useBranches } from '@modules/branches';

export const UpdateUserPermissions = () => {
  const { operations, permissions, onSubmit, onClose, submitting, isSelf, onSwitchChange, operationIds, cashRegisterId, onCashRegisterIdChange, branchId, onBranchIdChange } =
    useUpdateUserPermissions();
  const cashRegisters = useCashRegisters();

  const branches = useBranches();

  if (!operations.data || !permissions.data) {
    return null;
  }

  return (
    <Modal title='İcazələr' visible={true} width={768} onOk={onSubmit} onCancel={onClose} confirmLoading={submitting}>
      <AntForm component='div' layout='vertical' size='large'>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item label='Kassa'>
              <Select allowClear={true} value={cashRegisterId} onChange={onCashRegisterIdChange} placeholder='Kassa seçin...'>
                {cashRegisters.data?.data.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label='Admin filial'>
              <Select allowClear={true} value={branchId} onChange={onBranchIdChange} placeholder='Admin filial seçin...'>
                {branches.data &&
                  branches.data.map((item) => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <MasonryGrid>
          {operations.data.map((model) => (
            <MasonryCol key={model.id}>
              <Card hoverable={true} title={model.name} size='small' bodyStyle={{ marginBottom: -16 }}>
                {model.operations.map((operation) => (
                  <div key={operation.id} style={{ display: 'flex' }}>
                    <Typography.Paragraph style={{ flex: 1 }}>{operation.name}</Typography.Paragraph>
                    <Switch disabled={isSelf && operation.codeName === 'changeuserpermissions'} onChange={() => onSwitchChange(operation.id)} checked={operationIds.includes(operation.id)} />
                  </div>
                ))}
              </Card>
            </MasonryCol>
          ))}
        </MasonryGrid>
      </AntForm>
    </Modal>
  );
};
