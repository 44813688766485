import { Region, RegionApi } from '../types';

export class RegionMapper {
  public static oneFromApi(raw: RegionApi): Region {
    return {
      id: raw.id,
      name: raw.name,
      createdAt: raw.created_at,
    };
  }

  public static manyFromApi(raw: RegionApi[]): Region[] {
    return raw.map((item) => RegionMapper.oneFromApi(item));
  }
}
