import React, { FC, useCallback, useMemo } from 'react';
import { Select } from 'antd';
import { useInfo } from '../../../../../hooks/common/useInfo';
import { urlFactory } from '../../../../../core/utils/caller';

export const ProductTypeFilter: FC<any> = ({ column: { filterValue, setFilter } }) => {
  const { data, loading } = useInfo(
    useMemo(
      () => ({
        apiUrl: urlFactory('/api/admin/product-types/getlist'),
        initialState: [],
        responseGetter: (response) => response.data,
      }),
      [],
    ),
  );

  const handleChange = useCallback(
    (value) => {
      setFilter(value !== '-1' ? value : undefined);
    },
    [setFilter],
  );
  const value = useMemo(() => (filterValue || -1).toString(), [filterValue]);

  const selectProps = useMemo(
    () => ({
      onChange: handleChange,
      value,
      className: 'next-table-filter',
      loading,
      showSearch: true,
      filterOption: (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    }),
    [handleChange, value, loading],
  );

  return (
    <Select {...selectProps}>
      <Select.Option value='-1'>Bütün</Select.Option>
      {data.map(({ id, name }) => (
        <Select.Option key={id} value={id.toString()}>
          {name}
        </Select.Option>
      ))}
    </Select>
  );
};
