import { useDispatch, useSelector } from 'react-redux';
import { tableSelector } from '../../selectors/table';
import { useHistory } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import routes from '../../constants/routes';
import { fromWarehouseToFlightAction } from '../../actions/flights';
import { tableFetchDataAction, tableResetAction, tableSetSelectedRowIdsAction } from '../../actions/table';
import { message, Modal } from 'antd';

export const useFlightActions = () => {
  const dispatch = useDispatch();
  const table = useSelector(tableSelector('flights'));
  const history = useHistory();

  const selection = useMemo(() => {
    return Object.keys(table.selectedRowIds);
  }, [table.selectedRowIds]);

  const goToCreate = useCallback(() => {
    history.push(routes.flights.create);
  }, [history]);

  const clearSelection = useCallback(() => {
    dispatch(tableSetSelectedRowIdsAction('flights', {}));
  }, [dispatch]);

  const resetTable = useCallback(() => {
    dispatch(tableResetAction('flights'));
  }, [dispatch]);

  const fetchTableData = useCallback(() => {
    dispatch(tableFetchDataAction('flights', '/api/admin/v2/flights/list'));
  }, [dispatch]);

  const fromWarehouseToFlight = useCallback(() => {
    const onOk = async () => {
      const result = await fromWarehouseToFlightAction();
      if (result.success) {
        message.success('Əməliyyat müvəffəqiyyətlə başa çatdı.');
        fetchTableData();
      } else {
        if (result.errors) {
          Object.values<string[]>(result.errors).forEach(([error]) => {
            message.error(error);
          });
        } else {
          message.error(result.error);
        }
      }
    };

    Modal.confirm({
      title: 'Əməliyyatı icra etməyinizdən əminsinizmi?',
      content: 'Təsqilədikdən sonra xarici anbarda olan bütün bağlamalar son açıq flisghtsa əlavə olunacaq.',
      onOk,
    });
  }, [fetchTableData]);

  return { selection, goToCreate, clearSelection, resetTable, fetchTableData, fromWarehouseToFlight };
};
