import React, { FC } from 'react';
import { Tag } from 'antd';

export const DeclarationsStateTag: FC<any> = ({ id }) => {
  switch (id) {
    case 5:
      return <Tag color='orange'>Öncədən bəyan</Tag>;
    case 7:
      return <Tag color='purple'>Xarici anbarda</Tag>;
    case 8:
      return <Tag color='blue'>Yolda</Tag>;
    case 9:
      return <Tag color='lime'>Yerli anbarda</Tag>;
    case 10:
      return <Tag color='green'>Təhvil verilib</Tag>;
    default:
      return null;
  }
};
