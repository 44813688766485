import React, { useCallback, useMemo } from 'react';
import { DatePicker, Form } from 'antd';
import { WrappedFieldProps } from 'redux-form';
import { FormItemProps } from 'antd/lib/form';
import { DatePickerProps } from 'antd/lib/date-picker';
import { CommonConstants } from '../../constants/common';
import moment from 'moment';

export const DateField = React.memo<WrappedFieldProps & { item: FormItemProps; field: DatePickerProps }>(({ input, item, field, meta: { touched, error, warning } }) => {
  const errorText = useMemo(() => touched && error, [touched, error]);
  const warningText = useMemo(() => touched && warning, [touched, warning]);
  const validateStatus = useMemo(() => {
    if (errorText) {
      return 'error';
    } else if (warningText) {
      return 'warning';
    }
  }, [errorText, warningText]);

  const finalFormat = useMemo(() => (field?.format as string) || CommonConstants.DATE, [field]);
  const value = useMemo(() => (input.value ? moment(input.value, finalFormat) : undefined), [input.value, finalFormat]);

  const handleChange = useCallback(
    (value) => {
      if (value) {
        input.onChange(value.format(finalFormat));
      } else {
        input.onChange(null);
      }
    },
    [input, finalFormat],
  );

  const style = useMemo(() => ({ width: '100%' }), []);

  return (
    <Form.Item {...item} validateStatus={validateStatus} help={errorText || warningText}>
      <DatePicker {...input} value={value} onChange={handleChange} format={finalFormat} style={style} {...field} />
    </Form.Item>
  );
});
