import { notificationQueryKeys } from './notification-query-keys';

export const emailNotificationQueryKeys = {
  ...notificationQueryKeys,
  userId: 'user_id',
  email: 'email',
  provider: {
    status: 'provider_state',
    error: 'provider_error',
  },
};
