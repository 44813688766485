import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useCloseModal } from '@core/router';
import { pathMaker } from '@core/utils';

import { HandoverDeclarationsProvider } from '../context';
import { HandoverDeclarations } from '../containers';

export const HandoverDeclarationsPage = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id: string }>();
  const ids = React.useMemo(() => id.split('/'), [id]);

  const handleClose = React.useCallback(() => {
    closeModal(pathMaker('/@next/declarations/:id', { id }));
  }, [closeModal, id]);

  return (
    <HandoverDeclarationsProvider ids={ids} onClose={handleClose} onSubmitSucceed={handleClose}>
      <HandoverDeclarations />
    </HandoverDeclarationsProvider>
  );
};
