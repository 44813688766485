import React, { FC } from 'react';
import { Layout } from 'antd';
import { BasicProps as HeaderProps } from 'antd/lib/layout/layout';

const { Header } = Layout;

const LayoutHeader: FC<HeaderProps> = (props) => {
  return <Header id='mainHeader' {...props} />;
};

export default React.memo(LayoutHeader);
