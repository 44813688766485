import React from 'react';
import { Button, Space } from 'antd';
import { ClearOutlined, CloseCircleOutlined, PlusCircleOutlined, ReloadOutlined, RocketOutlined } from '@ant-design/icons';

import { useFlightActions } from '../../hooks/flights/useActions';

const FlightsActions = () => {
  const { selection, goToCreate, clearSelection, resetTable, fetchTableData, fromWarehouseToFlight } = useFlightActions();

  return (
    <div className='actions-wrapper'>
      <div className='actions-wrapper-left'>
        <Button type='primary' onClick={goToCreate} icon={<PlusCircleOutlined />}>
          Yeni
        </Button>
        {!!selection.length && (
          <Button className='actions-wrapper-additional-button actions-wrapper-selection-button' icon={<CloseCircleOutlined />} onClick={clearSelection}>
            {selection.length} sətir seçilib
          </Button>
        )}
        <Button className='actions-wrapper-additional-button' icon={<ClearOutlined />} onClick={resetTable}>
          Sıfırla
        </Button>
        <Button className='actions-wrapper-additional-button' icon={<ReloadOutlined />} onClick={fetchTableData}>
          Yenilə
        </Button>
      </div>
      <div className='actions-wrapper-right'>
        <Space size={8}>
          <Button type='primary' disabled={true} onClick={fromWarehouseToFlight} icon={<RocketOutlined />}>
            Xarici anbardan flight-a
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default FlightsActions;
