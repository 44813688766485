import React, { FC } from 'react';

type ITableWrapperProps = {
  getTableProps: Function;
  paginationArea: React.ReactNode;
  children: React.ReactNode;
};

const TableWrapper: FC<ITableWrapperProps> = ({
  getTableProps,
  paginationArea,
  ...rest
}) => {
  return (
    <div className='next-table-outer'>
      <div className='next-table-wrapper'>
        <div {...getTableProps({ className: 'next-table' })} {...rest} />
      </div>
      {paginationArea}
    </div>
  );
};

export default React.memo<ITableWrapperProps>(TableWrapper);
