import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DatePicker } from 'antd';

import { tableSetFiltersAction } from '../../../../actions/table';
import moment, { Moment } from 'moment';
import { Constants } from '../../../shared/constants';

const { RangePicker } = DatePicker;

const DeletedDeclarationsActions = () => {
  const dispatch = useDispatch();

  const handleDateRangeChange = useCallback(
    (range: [Moment | null, Moment | null] | null = null) => {
      const [from, to] = range || [null, null];

      if (!from || !to) {
        dispatch(tableSetFiltersAction('deletedDeclarations', []));
        return false;
      }

      dispatch(
        tableSetFiltersAction('deletedDeclarations', [
          {
            id: 'dateFrom',
            value: moment(from).format(Constants.DATE_TIME),
          },
          {
            id: 'dateTo',
            value: moment(to).format(Constants.DATE_TIME),
          },
        ]),
      );
    },
    [dispatch],
  );

  return (
    <div className='actions-wrapper'>
      <div className='actions-wrapper-left'>
        <RangePicker onChange={handleDateRangeChange} />
      </div>
    </div>
  );
};

export default DeletedDeclarationsActions;
