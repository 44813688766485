import React, { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useCloseModal } from '@core/router';
import { pathMaker } from '@core/utils';
import { TableFilter } from '@core/table';

import { DeclarationsStuckAtCustomsProvider } from '../context';
import { DeclarationsStuckAtCustoms } from '../containers';

export const DeclarationsStuckAtCustomsPage: FC = () => {
  const closeModal = useCloseModal();
  const location = useLocation<{ bulkUpdateDeclarationsStatus: { filters: TableFilter[] } | undefined }>();
  const { id } = useParams<{ id?: string }>();
  const ids = id?.split('/');

  const onClose = React.useCallback(() => {
    if (id) {
      closeModal(pathMaker('/@next/declarations/:id', { id }));
    } else {
      closeModal('/@next/declarations');
    }
  }, [closeModal, id]);

  return (
    <DeclarationsStuckAtCustomsProvider ids={ids} filters={location.state?.bulkUpdateDeclarationsStatus?.filters} onCancel={onClose} onSucceed={onClose}>
      <DeclarationsStuckAtCustoms />
    </DeclarationsStuckAtCustomsProvider>
  );
};
