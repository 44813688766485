import React, { useMemo } from 'react';
import { Card, Col, DatePicker, Row, Spin, Typography } from 'antd';
import { useBalanceStatisticsByPaymentTypes } from '../hooks/use-balance-statistics-by-payment-types';

const { RangePicker } = DatePicker;

export const BalanceStatisticsByPaymentTypes = () => {
  const { result, dateRange, handleChangeDateRange } = useBalanceStatisticsByPaymentTypes();

  const tableStyle = useMemo(
    () => ({
      table: {
        width: '100%',
        border: '1px solid #f0f0f0',
      },
      cell: {
        padding: 8,
        border: '1px solid #f0f0f0',
      },
      headCell: {
        backgroundColor: '#fafafa',
        border: '1px solid #f0f0f0',
        padding: 8,
      },
    }),
    [],
  );

  return (
    <Card size='small' title='Ödəniş metodları üzrə statistika'>
      <RangePicker allowClear={false} value={dateRange} onChange={handleChangeDateRange} style={{ marginBottom: 12 }} />
      <Spin spinning={result.loading}>
        <Row gutter={[16, 0]}>
          <Col span={24} md={12}>
            <Card type='inner' size='small' title='Mədaxil'>
              {!!result.data?.income.length ? (
                <table style={tableStyle.table}>
                  <tbody>
                    {result.data.income.map((item) =>
                      item.currencies.map((currency, index) => (
                        <tr key={item.id + currency.name}>
                          {index === 0 && (
                            <td style={tableStyle.headCell} rowSpan={item.currencies.length}>
                              {item.name}
                            </td>
                          )}
                          <td style={tableStyle.cell}>
                            {currency.amount.toFixed(2)} {currency.name}
                          </td>
                        </tr>
                      )),
                    )}
                  </tbody>
                </table>
              ) : (
                <Typography.Text>Məlumat tapılmadı...</Typography.Text>
              )}
            </Card>
          </Col>
          <Col span={24} md={12}>
            <Card type='inner' size='small' title='Məxaric'>
              {!!result.data?.outcome.length ? (
                <table style={tableStyle.table}>
                  <tbody>
                    {result.data.outcome.map((item) =>
                      item.currencies.map((currency, index) => (
                        <tr key={item.id + currency.name}>
                          {!index && (
                            <td style={tableStyle.headCell} rowSpan={item.currencies.length}>
                              {item.name}
                            </td>
                          )}
                          <td style={tableStyle.cell}>
                            {currency.amount.toFixed(2)} {currency.name}
                          </td>
                        </tr>
                      )),
                    )}
                  </tbody>
                </table>
              ) : (
                <Typography.Text>Məlumat tapılmadı...</Typography.Text>
              )}
            </Card>
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};
