import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { CreateDeclarationErrorMapper, CreateDeclarationMapper } from '../mappers';
import { CreateDeclarationDto } from '../dto';

export type ICreateDeclarationRepoResponse = IRepoResponse<200> | IRepoResponse<422, object> | IRepoResponse<400 | 500, string>;

export type ICreateDeclarationRepo = IRepo<[CreateDeclarationDto, string | number | undefined], ICreateDeclarationRepoResponse>;

export const CreateDeclarationRepoType = Symbol.for('CreateDeclarationRepo');

@bind(CreateDeclarationRepoType)
export class CreateDeclarationRepo implements ICreateDeclarationRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto: CreateDeclarationDto, id?: string | number) {
    try {
      const body = new FormData();
      const mappedBody = CreateDeclarationMapper.toPersistence(dto);

      appendToFormData(mappedBody, body);

      const url = id ? urlMaker('/api/admin/declaration/edit') : urlMaker('/api/admin/declaration/create');

      const result = await this.httpClient.post({ url, body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400) {
        const errors = CreateDeclarationErrorMapper.toDomain(result.data.errors);

        return {
          status: 422 as 422,
          response: errors,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
