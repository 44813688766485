import React from 'react';
import ContentCard from '../../containers/Layout/Sidebar/Content/Card';
import Table from '../../containers/Tickets/Table';
import Actions from '../../containers/Tickets/Actions';

const TicketsPage = () => {
  return (
    <ContentCard>
      <Actions />
      <Table />
    </ContentCard>
  );
};

export default TicketsPage;
