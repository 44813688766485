import React, { useMemo } from 'react';
import { Form, Input } from 'antd';
import { WrappedFieldArrayProps, WrappedFieldProps, Field } from 'redux-form';
import { FormItemProps } from 'antd/lib/form';
import { InputProps } from 'antd/lib/input';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { red } from '@ant-design/colors';

export const InputField = React.memo<WrappedFieldProps & { item: FormItemProps; field: InputProps }>(({ input, item, field, meta: { touched, error, warning } }) => {
  const errorText = useMemo(() => touched && error, [touched, error]);
  const warningText = useMemo(() => touched && warning, [touched, warning]);
  const validateStatus = useMemo(() => {
    if (errorText) {
      return 'error';
    } else if (warningText) {
      return 'warning';
    }
  }, [errorText, warningText]);

  return (
    <Form.Item style={{ marginBottom: 8 }} validateStatus={validateStatus} help={errorText || warningText} {...item}>
      <Input {...input} {...field} />
    </Form.Item>
  );
});

export const MultipleInputField = React.memo<WrappedFieldArrayProps & { item: FormItemProps; fieldProps?: Record<string, any> }>(({ fields, item, fieldProps: { normalize, ...fieldProps } = {} }) => {
  return (
    <Form.Item {...item}>
      {fields.map((field, index) => (
        <Field
          key={index}
          name={field}
          field={{
            ...fieldProps,
            addonAfter:
              index === fields.length - 1 ? (
                <PlusOutlined style={{ padding: '8px 12px' }} onClick={() => fields.push('')} />
              ) : (
                <DeleteOutlined style={{ color: red[5], padding: '8px 12px' }} onClick={() => fields.remove(index)} />
              ),
          }}
          component={InputField}
          {...fieldProps}
        />
      ))}
    </Form.Item>
  );
});
