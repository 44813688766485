import { Route, Switch } from 'react-router-dom';
import { useTitle } from '@core/utils';

import { CustomsDeclarationsPage, CustomsPostsPage, DnsQueuesPage } from '../pages';

export const CustomsRouter = () => {
  useTitle('Bəyannamələr');

  return (
    <Switch>
      <Route path='/@next/customs/posts' component={CustomsPostsPage} />
      <Route path='/@next/customs/declarations' component={CustomsDeclarationsPage} />
      <Route path='/@next/customs/dns-queues' component={DnsQueuesPage} />
    </Switch>
  );
};

export default CustomsRouter;
