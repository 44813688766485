import { useMemo } from 'react';
import { InputField } from '../../../components/ReduxForm/Input';
import { TextAreaField } from '../../../components/ReduxForm/TextArea';

export const useFormFields = () => {
  const nameFieldProps = useMemo(
    () => ({
      name: 'name',
      item: { label: 'Ad' },
      field: { placeholder: 'Xüsusi tarifin adını qeyd edin' },
      component: InputField,
    }),
    [],
  );

  const descriptionsFieldProps = useMemo(
    () => ({
      name: 'descr',
      item: { label: 'Açıqlama' },
      field: { placeholder: 'Açıqlama daxil edin', rows: 3 },
      component: TextAreaField,
    }),
    [],
  );

  return useMemo(() => ({ nameFieldProps, descriptionsFieldProps }), [nameFieldProps, descriptionsFieldProps]);
};
