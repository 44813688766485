import React, { FC } from 'react';
import { Col, Form, Row, Spin } from 'antd';
import { InjectedFormProps, reduxForm, Field } from 'redux-form';
import { RouteComponentProps } from 'react-router-dom';

import FormModal from '../../components/Common/FormModal';

import { useNotificationTemplatesForm } from '../../hooks/notificationTemplates/useForm';
import { useFormLayout } from '../../hooks/common/useFormLayout';

const NotificationTemplatesForm: FC<InjectedFormProps & RouteComponentProps<any>> = (props) => {
  const { fields, handleSubmit, title, close, initializing, values } = useNotificationTemplatesForm(props);
  const layout = useFormLayout();

  return (
    <FormModal title={title} onCancel={close} confirmLoading={props.submitting} onOk={handleSubmit}>
      <Spin spinning={initializing}>
        <Form {...layout.form}>
          <Row {...layout.row}>
            <Col {...layout.col[12]}>
              <Field {...fields.name} />
            </Col>
            <Col {...layout.col[12]}>
              <Field {...fields.type} />
            </Col>
            <Col {...layout.col[16]}>
              <Field {...fields.title} />
            </Col>
            <Col {...layout.col[8]}>
              <Field {...fields.delay} />
            </Col>
            {values.notificationTypeId !== 2 ? (
              <Col {...layout.col[0]}>
                <Field {...fields.body} />
              </Col>
            ) : (
              <Col {...layout.col[0]}>
                <Field {...fields.bodyRich} />
              </Col>
            )}
            <Col {...layout.col[8]}>
              <Field {...fields.model} />
            </Col>
            <Col {...layout.col[8]}>
              <Field {...fields.state} />
            </Col>
            {values.modelId === 2 && (
              <Col {...layout.col[8]}>
                <Field {...fields.plansCategory} />
              </Col>
            )}
            <Col {...layout.col[0]}>
              <Field {...fields.isActive} />
            </Col>
          </Row>
        </Form>
      </Spin>
    </FormModal>
  );
};

export default reduxForm<any, any, any>({ form: 'notificationTemplates' })(NotificationTemplatesForm);
