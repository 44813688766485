import { IHandoverDeclarationsDetails, IHandoverDeclarationsDetailsPersistence } from '../interfaces';

export class HandoverDeclarationDetailsMapper {
  public static toDomain(data: IHandoverDeclarationsDetailsPersistence): IHandoverDeclarationsDetails {
    return {
      package: data.converted.package_amount,
      user: {
        id: data.user_id,
        name: data.user_name,
      },
      ordersPrice: {
        try: data.values.credit_try,
        azn: data.converted.credit_try,
      },
      deliveryPrice: {
        usd: data.values.credit_delivery,
        azn: data.converted.credit_delivery,
      },
      balance: {
        try: data.values.balance_try,
        usd: data.values.balance_usd,
      },
      convertedBalance: {
        try: data.converted.balance_try,
        usd: data.converted.balance_usd,
      },
      debt: {
        total: {
          usd: data.values.credit_usd,
          azn: data.converted.credit_usd,
        },
        minimum: {
          azn: data.converted.minimal,
        },
        all: {
          azn: data.converted.all_credit,
        },
      },
    };
  }
}
