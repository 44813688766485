import React from 'react';
import ReadyTable from '../../components/Table/ReadyTable';
import { useNotificationTemplatesTable } from '../../hooks/notificationTemplates/useTable';

const NotificationTemplatesTable = () => {
  const { columns } = useNotificationTemplatesTable();

  return <ReadyTable name='notificationTemplates' apiUrl='/api/admin/templates/list' columns={columns} />;
};

export default NotificationTemplatesTable;
