import React, { FC } from 'react';

import ReadyTable from '../../components/Table/ReadyTable';
import { useStateArchiveTable } from '../../hooks/stateArchive/useTable';

const StateArchiveTable: FC = () => {
  const { columns } = useStateArchiveTable();

  return <ReadyTable columns={columns} name='stateArchive' apiUrl='/api/admin/states/archive' />;
};

export default StateArchiveTable;
