import React, { FC } from 'react';
import { Tag } from 'antd';

export const ConflictedDeclarationsStateTag: FC<any> = ({ id }) => {
  switch (id) {
    case 26:
      return <Tag color='orange'>Bilinməyən</Tag>;
    case 27:
      return <Tag color='purple'>Qəbul olunmuş</Tag>;
    case 31:
      return <Tag color='green'>Həll olunmuş</Tag>;
    default:
      return null;
  }
};
