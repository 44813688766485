import { useCallback, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { message } from 'antd';
import { NavLink } from 'react-router-dom';

import { useQuery } from '../../../shared/modules/use-query/hooks';

import { StatisticsService } from '../services';
import { PaymentTypeStatisticsByModel } from '../types/payment-types';
import { localURLMaker } from '../../../shared/utils/url-maker';
import { Constants } from '../../../shared/constants';

export const usePaymentTypesStatisticsByModel = (modelId: number | string) => {
  const [dateRange, setDateRange] = useState<[Moment, Moment]>([moment().startOf('month'), moment()]);

  const handleChangeDateRange = useCallback((value: [Moment | null, Moment | null] | null) => {
    const startDate = value?.[0] || null;
    const endDate = value?.[1] || null;

    if (startDate && endDate) {
      setDateRange([startDate, endDate]);
    }
  }, []);

  const result = useQuery<PaymentTypeStatisticsByModel[]>(
    useCallback(async () => {
      if (!dateRange) {
        throw new Error('Tarix aralığı seçilməyib');
      }

      const result = await StatisticsService.PaymentTypes.getByModelId(modelId, dateRange[0], dateRange[1]);

      if (result.status === 200) {
        return result.data;
      } else {
        message.error(result.data);
        throw new Error(result.data);
      }
    }, [dateRange, modelId]),
    useMemo(() => ({ initialData: [], enabled: !!dateRange }), [dateRange]),
  );

  const columns = useMemo(
    () => [
      { dataIndex: 'id', key: 'id', title: 'Kod' },
      { dataIndex: 'name', key: 'name', title: 'Ödəniş üsulu' },
      { dataIndex: 'amount', key: 'amount', title: 'Ümumi məbləğ' },
      {
        dataIndex: 'count',
        key: 'count',
        title: 'Say',
        render: (value, record) => {
          if (!(modelId === 2 || modelId === 3)) {
            return value;
          }

          return (
            <NavLink
              to={localURLMaker(
                '/dashboard/transactions/:model',
                {
                  model: modelId === 2 ? 'declarations' : 'couriers',
                },
                {
                  start_date: dateRange[0].format(Constants.DATE_TIME),
                  end_date: dateRange[1].format(Constants.DATE_TIME),
                  model_id: modelId,
                  payment_type: record.id,
                },
              )}
            >
              {value}
            </NavLink>
          );
        },
      },
    ],
    [dateRange, modelId],
  );
  return { result, dateRange, columns, handleChangeDateRange };
};
