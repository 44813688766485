import { Details } from '@core/ui';
import { Spin } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useBranchById } from '../hooks/use-branch-by-id';

export const BranchDetailsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isFetching } = useBranchById(id);
  return (
    <Spin spinning={isFetching}>
      <Details.Col xs={24} lg={12}>
        <Details.Row>
          <Details.Col xs={24}>
            <Details.Card title='Ümumi məlumat'>
              <Details.Descriptions bordered={true} column={1} size='small'>
                <Details.Descriptions.Item label='İzləmə kodu'>#{data?.id}</Details.Descriptions.Item>
                <Details.Descriptions.Item label='Ad'>{data?.name}</Details.Descriptions.Item>
                <Details.Descriptions.Item label='Adres'>{data?.address}</Details.Descriptions.Item>
                <Details.Descriptions.Item label='Şərh'>{data?.descr}</Details.Descriptions.Item>
                <Details.Descriptions.Item label='Email'>{data?.email}</Details.Descriptions.Item>
                <Details.Descriptions.Item label='Filial adı'>{data?.branchName}</Details.Descriptions.Item>
                <Details.Descriptions.Item label='Xəritə'>{data?.mapAddress}</Details.Descriptions.Item>
                <Details.Descriptions.Item label='Telefon'>{data?.phone}</Details.Descriptions.Item>
                <Details.Descriptions.Item label='İş saatları'>{data?.workingHours}</Details.Descriptions.Item>
                <Details.Descriptions.Item label='Filial'>{data?.isBranch ? 'İşləyir' : 'İşləmir'}</Details.Descriptions.Item>
              </Details.Descriptions>
            </Details.Card>
          </Details.Col>
        </Details.Row>
      </Details.Col>
    </Spin>
  );
};
