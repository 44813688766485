import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type GetUserPermissionsResponse = IRepoResponse<200, number[]> | IRepoResponse<400 | 500, string>;

export type IGetUserPermissionsRepo = IRepo<[{ userId: number | string }], GetUserPermissionsResponse>;

export const GetUserPermissionsRepoType = Symbol.for('GetUserPermissions');

@bind(GetUserPermissionsRepoType)
export class GetUserPermissionsRepo implements IGetUserPermissionsRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ userId }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/permissions', { user_id: userId }) });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: result.data.data.map((item) => item.operation_id),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
