import { IDnsQueue, IDnsQueuePersistence } from '../interfaces';

export class DnsQueueMapper {
  public static toDomain(queue: IDnsQueuePersistence): IDnsQueue {
    return {
      id: queue.id,
      action: queue.action,
      query: queue.json,
      statusCode: queue.status_code,
      response: queue.response ? JSON.parse(queue.response) : null,
      attempts: queue.attempts,
      createdAt: queue.created_at,
      updatedAt: queue.updated_at,
      retriedAt: queue.retry_at,
    };
  }
}
