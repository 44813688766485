import React, { FC, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { search2Object } from '../../../core/utils/search-to-object';
import { urlFactory } from '../../../core/utils/caller';
import { useInfo } from '../../../hooks/common/useInfo';
import { Modal, Table } from 'antd';
import { useClose } from '../../../hooks/common';
import routes from '../../../constants/routes';

const DashboardSpecialBalanceDetails: FC<RouteComponentProps<any>> = ({ location: { search } }) => {
  const params = useMemo(() => search2Object(search), [search]);
  const handleClose = useClose(routes.dashboard.index);

  const useInfoOptions = useMemo(
    () => ({
      apiUrl: urlFactory('/api/admin/stats/balance_details', params),
      responseGetter: (response) => response.data,
      initialState: [],
    }),
    [params],
  );

  const { data: rawData, loading } = useInfo(useInfoOptions);

  const data = useMemo(() => rawData.map((item, index) => ({ ...item, key: index })), [rawData]);

  const columns = useMemo(
    () => [
      { dataIndex: 'key', key: 'key', title: '#' },
      { dataIndex: 'user_id', key: 'user_id', title: 'Müştəri kodu' },
      { dataIndex: 'user_name', key: 'user_name', title: 'Müştəri' },
      { dataIndex: 'amount', key: 'amount', title: 'Məbləğ', render: (value, row) => (value ? `${value} ${row.currency}` : '') },
      { dataIndex: 'confirmed_by_name', key: 'confirmed_by_name', title: 'Qəbul edən' },
    ],
    [],
  );

  return (
    <Modal maskTransitionName='' transitionName='' width={992} visible={true} onCancel={handleClose} footer={null} closable={false}>
      <Table bordered={true} loading={loading} dataSource={data} columns={columns} />
    </Modal>
  );
};

export default React.memo(DashboardSpecialBalanceDetails);
