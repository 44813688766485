import { useMemo } from 'react';
import { urlFactory } from '../../core/utils/caller';
import { useInfo } from '../common/useInfo';

export const useDeliveredStatistics = (delay = 0) => {
  const useDeliveredInfoOptions = useMemo(
    () => ({
      apiUrl: urlFactory('/api/admin/states/delivery'),
      responseGetter: (r) => r.data,
      delay,
    }),
    [delay],
  );
  const { data: rawData, loading } = useInfo(useDeliveredInfoOptions);

  const data = useMemo(() => {
    if (rawData) {
      return {
        couriers: {
          delivered: rawData.couriers.delivered,
          notDelivered: rawData.couriers.notdelivered,
          percent: (rawData.couriers.delivered / (rawData.couriers.delivered + rawData.couriers.notdelivered)) * 100,
        },
        declarations: {
          delivered: rawData.declarations.delivered,
          notDelivered: rawData.declarations.notdelivered,
          percent: (rawData.declarations.delivered / (rawData.declarations.delivered + rawData.declarations.notdelivered)) * 100,
        },
        orders: {
          delivered: rawData.orders.delivered,
          notDelivered: rawData.orders.notdelivered,
          percent: (rawData.orders.delivered / (rawData.orders.delivered + rawData.orders.notdelivered)) * 100,
        },
      };
    } else {
      return {
        couriers: {
          delivered: 0,
          notDelivered: 0,
          percent: 0,
        },
        declarations: {
          delivered: 0,
          notDelivered: 0,
          percent: 0,
        },
        orders: {
          delivered: 0,
          notDelivered: 0,
          percent: 0,
        },
      };
    }
  }, [rawData]);

  return useMemo(() => ({ data, loading }), [data, loading]);
};
