import { createContext, FC } from 'react';
import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';

import { ISettings } from '../interfaces';
import { GetSettingsRepoType, IGetSettingsRepo } from '../repos';

const initialValues: ISettings = {
  configs: {
    sendDeclarationsToCustoms: false,
    orders: {
      cashRegisterId: null,
    },
    terminal: {
      cashRegisterId: null,
    },
  },
  contact: [],
  workingHours: '',
  bonPercent: '',
  foreignWarehouse: {
    city: '',
    addressHeading: '',
    postalCode: '',
    passportIdentity: '',
    address: '',
    province: '',
    district: '',
    country: '',
    phone: '',
  },
};

export const SettingsContext = createContext<ISettings>(initialValues);

export const SettingsProvider: FC = ({ children }) => {
  const getSettingsRepo = useService<IGetSettingsRepo>(GetSettingsRepoType);

  const { data = initialValues } = useQuery<ISettings, Error>(
    ['settings'],
    async () => {
      const result = await getSettingsRepo.execute();

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {
      initialData: initialValues,
      refetchInterval: 1000 * 60 * 10,
    },
  );

  return <SettingsContext.Provider value={data}>{children}</SettingsContext.Provider>;
};
