import React from 'react';

const BarcodeScan = () => {
  return (
    <div className='barcode-scan-container'>
      <div className='barcode-scan'>
        <div className='lines'>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className='values'>xxxx xxxx xxxx</div>
        <div className='scanner'>
          <div className='laser' />
        </div>
      </div>
    </div>
  );
};

export default BarcodeScan;
