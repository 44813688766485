import React, { useCallback, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { urlFactory } from '../../core/utils/caller';
import { CommonConstants } from '../../constants/common';
import { useInfo } from '../common/useInfo';
import { NavLink } from 'react-router-dom';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';

export const useCouriersStatistics = () => {
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment());
  const [stateId, setStateId] = useState('-1');

  const date = useMemo<[Moment, Moment]>(() => [startDate, endDate], [startDate, endDate]);

  const handleChangeDate = useCallback((value) => {
    setStartDate((value || [])[0] || moment().startOf('month'));
    setEndDate((value || [])[1] || moment());
  }, []);

  const useInfoOptions = useMemo(
    () => ({
      apiUrl: urlFactory('/api/admin/stats/courier_by_month', {
        start_date: startDate.format(CommonConstants.DATE),
        end_date: endDate.format(CommonConstants.DATE),
        state_id: stateId !== '-1' ? stateId : undefined,
      }),
    }),
    [startDate, endDate, stateId],
  );

  const { data: rawData, loading } = useInfo(useInfoOptions);

  const data = useMemo(() => {
    if (rawData) {
      return Object.entries(rawData.by_date.count).map(([month, counts]) => {
        const items = Object.entries(counts as any).map(([date, count], index) => {
          return {
            key: index,
            date,
            count,
            price_usd: rawData.by_date.price[month][date],
            price_azn: rawData.by_date.price_azn[month][date],
            price_transaction: rawData.by_date.price_transaction[month][date],
            price_transaction_other: rawData.by_date.price_transaction_other[month][date],
          };
        });
        return {
          items,
          total_count: items.reduce((acc, val) => acc + parseInt(val.count as string, 10), 0),
          total_usd: items.reduce((acc, val) => acc + parseFloat(val.price_usd as string), 0),
          total_azn: items.reduce((acc, val) => acc + parseFloat(val.price_azn as string), 0),
          price_transaction: items.reduce((acc, val) => acc + parseFloat(val.price_transaction as string), 0),
          price_transaction_other: items.reduce((acc, val) => acc + parseFloat(val.price_transaction_other as string), 0),
        };
      });
    } else {
      return [];
    }
  }, [rawData]);

  const columns = useMemo(
    () => [
      { key: 'date', dataIndex: 'date', title: 'Tarix' },
      {
        key: 'count',
        dataIndex: 'count',
        title: 'Say',
        render: (value, row) =>
          value ? (
            <NavLink
              to={makePath(
                routes.dashboard.couriers.info,
                {},
                {
                  created_at:
                    moment(row.date, CommonConstants.DATE).startOf('day').format(CommonConstants.DATE_TIME) +
                    ',' +
                    moment(row.date, CommonConstants.DATE).endOf('day').format(CommonConstants.DATE_TIME),
                  state_id: stateId !== '-1' ? stateId : undefined,
                },
              )}
            >
              {value}
            </NavLink>
          ) : (
            <span>0</span>
          ),
      },
      {
        key: 'price_usd',
        dataIndex: 'price_usd',
        title: 'Qiymət',
        width: 85,
        render: (value) => <>{value} &#8380;</>,
      },
      {
        key: 'price_transaction',
        dataIndex: 'price_transaction',
        title: 'Nağd',
        width: 85,
        render: (value) => <>{value} &#8380;</>,
      },
      {
        key: 'price_transaction_other',
        dataIndex: 'price_transaction_other',
        title: 'Kuryerə ödənilənlər',
        width: 85,
        render: (value) => <>{value} &#8380;</>,
      },
    ],
    [stateId],
  );

  return useMemo(() => ({ date, stateId, setStateId, handleChangeDate, loading, data, columns }), [date, stateId, setStateId, handleChangeDate, loading, data, columns]);
};
