import { Moment } from 'moment';

import { urlMaker } from '../../../shared/utils/url-maker';
import { Constants } from '../../../shared/constants';
import { caller } from '../../../shared/utils/caller';
import { Result } from '../../../shared/utils/result';

import { StatisticsMappers } from '../mappers';
import { BalanceStatisticsByPaymentTypes } from '../types/balance';

export class BalanceStatisticsService {
  public static async getByPaymentTypes(startDate: Moment, endDate: Moment): Promise<Result<200, BalanceStatisticsByPaymentTypes> | Result<400 | 500, string>> {
    try {
      const url = urlMaker('/api/admin/stats/payment_types', {
        start_date: startDate.format(Constants.DATE),
        end_date: endDate.format(Constants.DATE),
      });

      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();

        return new Result(200, StatisticsMappers.Balance.byPaymentTypesResultFromApi(data), null);
      } else {
        return new Result(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new Result(500, 'Sorğu göndərilə bilmədi. İnternet bağlantınızı yoxlayın.', null);
    }
  }
}
