import React, { FC } from 'react';
import { Tag } from 'antd';

export const CouriersStateTag: FC<any> = ({ id }) => {
  switch (id) {
    case 11:
      return <Tag color='orange'>Kuryer sifariş verildi</Tag>;
    case 12:
      return <Tag color='purple'>Sifariş hazırlanır</Tag>;
    case 13:
      return <Tag color='blue'>Kuryerə təhvil verildi</Tag>;
    case 14:
      return <Tag color='green'>Bağlama təhvil verildi</Tag>;
    default:
      return null;
  }
};
