import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

export type IUpdateUsersRoleRepoResponse = IRepoResponse<200> | IRepoResponse<400 | 500, string>;

export type IUpdateUsersRoleRepo = IRepo<[{ userIds: (string | number)[]; role: string }], IUpdateUsersRoleRepoResponse>;

export const UpdateUsersRoleRepoType = Symbol.for('UpdateUsersRoleRepo');

@bind(UpdateUsersRoleRepoType)
export class UpdateUsersRoleRepo implements IUpdateUsersRoleRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ userIds, role }) {
    try {
      const getRole = () => {
        switch (role) {
          case 'admin':
            return '1';
          case 'warehouseman':
            return '2';
          case 'back-office':
            return '3';
          default:
            return '0';
        }
      };

      const result = await this.httpClient.post({ url: urlMaker('/api/admin/client/set_admin', { user_id: userIds, admin: getRole() }) });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400 || result.status === 422) {
        return {
          status: 400 as 400,
          response: Object.values(result.data.errors).flat().join('. '),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
