import React, { FC, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { Form, Modal, Spin } from 'antd';

import { useConfigShopsFields } from '../../hooks/configShops/useFields';
import { urlFactory } from '../../core/utils/caller';
import { useForm } from '../../hooks/common/useForm';
import { tableFetchDataAction } from '../../actions/table';
import routes from '../../constants/routes';
import { useCloseModal } from '@core/router';
import { useBus } from '@core/event-bus';

type IProps = InjectedFormProps<any> & RouteComponentProps<any> & { dispatch: Dispatch };

const ConfigShopsForm: FC<IProps> = ({ handleSubmit: baseHandleSubmit, initialize, dispatch, match: { params }, submitting, initialized }) => {
  const close = useCloseModal();
  const eventBus = useBus();

  const handleSubmitSuccess = useCallback(
    async (response: Response) => {
      dispatch(tableFetchDataAction('configShops', '/api/admin/shop_names'));
      const {
        data: { id, name },
      } = await response.json();
      close(routes.config.shops.index);
      eventBus.publish({ type: '@shop-names/create/succeed', payload: { id, name } });
    },
    [close, dispatch, eventBus],
  );

  const { handleSubmit, type } = useForm(
    useMemo(
      () => ({
        handleClose: close,
        handleSubmit: baseHandleSubmit,
        initialize,
        createUrl: [routes.config.shops.create, routes.declarations.create.shop, routes.declarations.edit.shop, '/@next/shop-names/create'],
        createApiUrl: urlFactory('/api/admin/shop_names/create'),
        editApiUrl: urlFactory('/api/admin/shop_names/edit'),
        initializeUrl: urlFactory('/api/admin/shop_names/info', { shop_name_id: params.id }),
        additionalParams: { shop_name_id: params.id },
        onSuccess: handleSubmitSuccess,
      }),
      [close, baseHandleSubmit, initialize, params.id, handleSubmitSuccess],
    ),
  );

  const title = useMemo(() => {
    return type === 'create' ? 'Yeni mağaza əlavə et' : 'Mağazada düzəliş et';
  }, [type]);

  const { nameFieldProps } = useConfigShopsFields();

  return (
    <Modal maskTransitionName='' transitionName='' width={592} visible={true} onCancel={close} confirmLoading={submitting} onOk={handleSubmit} title={title}>
      <Spin spinning={type === 'edit' && !initialized}>
        <Form layout='vertical'>
          <Field {...nameFieldProps} />
        </Form>
      </Spin>
    </Modal>
  );
};

export default reduxForm<any, any>({ form: 'configShops' })(React.memo<IProps>(ConfigShopsForm));
