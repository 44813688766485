import React, { Fragment, FC, useCallback, useEffect, useMemo } from 'react';
import { Button, Dropdown, Menu, Modal } from 'antd';
import { Column } from 'react-table';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { AppstoreAddOutlined, DeleteOutlined, EditOutlined, HistoryOutlined, MoreOutlined, SettingOutlined, SwapOutlined } from '@ant-design/icons';

import ReadyTable from '../../components/Table/ReadyTable';
import { actionsCol, dateCol, midCol, normalCol, smallCol } from '../../core/helpers/table';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';
import { blockAction, changePermissionAction, deleteAction } from '../../actions/users';
import { OverflowCell } from '../../components/Table/core/Components/Cell/OverflowCell';
import { tableSetFiltersAction } from '../../actions/table';
import { stopPropagation } from '../../core/helpers/events';
import PriceCell from '../../components/Table/core/Components/Cell/PriceCell';
import { usePermissions } from '../../hooks/permissions/usePermissions';
import { localURLMaker } from '../../next/shared/utils/url-maker';
import { UserCell } from '@modules/users';

const UsersTable: FC<{ defaultFilters?: any[] }> = ({ defaultFilters }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<any>();
  const { can } = usePermissions();

  useEffect(() => {
    if (defaultFilters) {
      dispatch(tableSetFiltersAction('users', defaultFilters));
    } else {
      let admin;

      switch (params.type) {
        case 'admins':
          admin = 1;
          break;
        case 'warehouseman':
          admin = 2;
          break;
        case 'back_office':
          admin = 3;
          break;
      }

      dispatch(tableSetFiltersAction('users', admin ? [{ id: 'admin', value: admin }] : []));
    }
  }, [params.type, dispatch, defaultFilters]);

  const baseColumns = useMemo<Column<any>[]>(
    () => [
      {
        ...actionsCol,
        Cell: ({ row: { original } }) => {
          const gotoEdit = useCallback(() => {
            history.push(makePath(routes.users.edit, { type: params.type, id: original.id }));
          }, [original.id]);

          const goToLogs = useCallback(() => {
            dispatch(
              tableSetFiltersAction('logs', [
                {
                  id: 'model_name',
                  value: 'Müştərilər',
                },
                {
                  id: 'object_id',
                  value: original.id,
                },
              ]),
            );
            history.push(routes.logs.index);
          }, [original.id]);

          const handleDelete = useCallback(() => {
            const action = () => {
              deleteAction([original.id])(dispatch)
                .then(() => null)
                .catch(() => null);
            };

            Modal.confirm({
              title: 'İstifadəçiləri silməyə əminsinizmi?',
              content: 'Silməni təsdiqlədikdən sonra istifadəçilərin geri qaytarılması mümkün olmayacaq.',
              onOk: action,
            });
          }, [original.id]);

          const handleChangePermission = useCallback(
            (permission: 0 | 1 | 2 | 3) => {
              const action = () => {
                changePermissionAction(
                  [original.id],
                  permission,
                )(dispatch)
                  .then(() => null)
                  .catch(() => null);
              };

              Modal.confirm({
                title: 'Təsdiqlə',
                content: 'İstifadəçinin səlahiyyətlərini dəyişməyə əminsinizmi?',
                onOk: action,
              });
            },
            [original.id],
          );

          const goToPermissions = useCallback(() => {
            history.push(makePath(routes.users.permissions, { type: params.type, id: original.id }));
          }, [original.id]);

          const handleBlock = useCallback(() => {
            const nextBlock = !original.blocked;

            const action = () => {
              blockAction(
                [original.id],
                nextBlock,
              )(dispatch)
                .then(() => null)
                .catch(() => null);
            };

            const messageContent = nextBlock ? 'İstifadəçinin bloklanmasına əminsinizmi?' : 'İstifadəçinin blokdan azad edilməsinə əminsinizmi?';

            Modal.confirm({
              title: 'Təsdiqlə',
              content: messageContent,
              onOk: action,
            });
          }, [original.id, original.blocked]);

          const goToWithdraw = useCallback(() => {
            history.push(localURLMaker('/users/:type/:id/withdraw', { type: params.type, id: original.id }));
          }, [original.id]);

          const goToDeposit = useCallback(() => {
            history.push(localURLMaker('/users/:type/:id/deposit', { type: params.type, id: original.id }));
          }, [original.id]);

          return (
            <span onClick={stopPropagation}>
              <Dropdown
                placement='bottomLeft'
                overlay={
                  <Menu>
                    <Menu.Item onClick={gotoEdit}>
                      <EditOutlined />
                      <span>Düzəliş et</span>
                    </Menu.Item>
                    {params.type === 'admins' && can('changeuserpermissions') && (
                      <Menu.Item onClick={goToPermissions}>
                        <SettingOutlined />
                        <span>Icazələr</span>
                      </Menu.Item>
                    )}
                    <Menu.SubMenu
                      title={
                        <>
                          <AppstoreAddOutlined />
                          <span>Səlahiyyətlər</span>
                        </>
                      }
                    >
                      <Menu.Item onClick={() => handleChangePermission(0)}>Müştəri</Menu.Item>
                      <Menu.Item onClick={() => handleChangePermission(1)}>Admin</Menu.Item>
                      <Menu.Item onClick={() => handleChangePermission(2)}>Anbardar</Menu.Item>
                      <Menu.Item onClick={() => handleChangePermission(3)}>Back Office</Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item onClick={handleBlock}>
                      <EditOutlined />
                      <span>{!original.blocked ? 'Blokla' : 'Blokdan azad et'}</span>
                    </Menu.Item>
                    {params.type === 'back_office' && (
                      <Fragment>
                        <Menu.Divider />
                        <Menu.Item icon={<SwapOutlined />} onClick={goToWithdraw}>
                          Kuryer withdraw
                        </Menu.Item>
                        <Menu.Item icon={<SwapOutlined />} onClick={goToDeposit}>
                          Kuryer depozit
                        </Menu.Item>
                      </Fragment>
                    )}
                    <Menu.Divider />
                    <Menu.Item icon={<HistoryOutlined />} onClick={goToLogs}>
                      Əməliyyat tarixçəsi
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item onClick={handleDelete}>
                      <DeleteOutlined />
                      <span>Sil</span>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button className='next-table-action-btn' type='text' icon={<MoreOutlined />} />
              </Dropdown>
            </span>
          );
        },
      },
      {
        accessor: 'id',
        Header: 'Kod',
        ...smallCol,
      },
      {
        accessor: 'user_name',
        Header: 'Ad soyad',
        Cell: ({ row: { original } }) => <UserCell id={original.id} title={`${original.name} ${original.surname}`} />,
        width: 220,
      },
      {
        accessor: 'email',
        Header: 'Email',
        width: 220,
        Cell: ({ cell: { value } }) => <div className='overflow-cell'>{value}</div>,
      },
      {
        accessor: 'number',
        Header: 'Telefon',
        width: 150,
      },
      {
        accessor: 'gender',
        Header: 'Cins',
        Cell: ({ cell: { value } }) => (value === 'male' ? 'Kişi' : 'Qadın'),
        ...midCol,
      },
      {
        accessor: 'birth_date',
        Header: 'Doğum günü',
        ...dateCol,
      },
      {
        accessor: 'discount',
        Header: 'Endirim',
        Cell: ({ cell: { value } }) => `${value}%`,
      },
      {
        accessor: 'balance_try',
        Header: 'Balans (TRY)',
        Cell: PriceCell.TRY,
      },
      {
        accessor: 'balance_usd',
        Header: 'Balans (USD)',
        Cell: PriceCell.USD,
      },
      {
        accessor: 'last_30_days',
        Header: 'Cari ay (USD)',
        Cell: PriceCell.USD,
      },
      {
        accessor: 'passport_number',
        Header: 'Ş.V seriyası',
        ...normalCol,
      },
      {
        accessor: 'passport_secret',
        Header: 'FİN kod',
        ...normalCol,
      },
      {
        accessor: 'address',
        Header: 'Ünvan',
        Cell: OverflowCell,
      },
    ],
    [dispatch, history, can, params.type],
  );

  const courierBalanceColumn = useMemo(
    () => ({
      accessor: 'balance_courier',
      Header: 'Kuryer balansı',
      Cell: PriceCell.AZN,
    }),
    [],
  );

  const columns = useMemo(() => {
    const copiedColumns = [...baseColumns];
    const discountIndex = copiedColumns.findIndex(({ accessor }) => accessor === 'discount');

    if (params.type === 'back_office') {
      copiedColumns.splice(discountIndex, 0, courierBalanceColumn);
    }

    return copiedColumns;
  }, [baseColumns, courierBalanceColumn, params.type]);

  return <ReadyTable name='users' apiUrl='/api/admin/v2/client/list' columns={columns} />;
};

export default React.memo(UsersTable);
