import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Input, message, Modal } from 'antd';
import uniq from 'lodash/uniq';
import flat from 'flat';
import { ValidationError } from '@core/form';
import { useService } from '@core/inversify-react';

import { useBoxes, useCloseBox, useMyBox, useSelectBox, useTransferBox } from '@modules/boxes';

import { GetDeclarationByTrackCodeRepoType, IGetDeclarationByTrackCodeRepo } from '../repos';

export const useDeclarationsBoxAcceptance = () => {
  const boxes = useBoxes();
  const myBox = useMyBox({ refetchInterval: 10000 });
  const selectBoxMutation = useSelectBox();
  const closeBoxMutation = useCloseBox();
  const transferBoxMutation = useTransferBox();
  const getDeclarationByTrackCodeRepo = useService<IGetDeclarationByTrackCodeRepo>(GetDeclarationByTrackCodeRepoType);

  const barcodeInputRef = useRef<Input>(null);

  const [barcodes, setBarcodes] = useState<string[]>([]);
  const [duplicatedTrackCodes, setDuplicatedTrackCodes] = useState<string[]>([]);
  const [type, setType] = useState<'acceptance' | 'transfer'>('acceptance');

  const tableData = useMemo(
    () =>
      barcodes.map((barcode, index) => ({
        id: index + 1,
        barcode,
      })),
    [barcodes],
  );

  const closeBoxMutationError = useMemo(() => {
    if (closeBoxMutation.error instanceof ValidationError) {
      const errors = flat.unflatten(closeBoxMutation.error.errors);
      const message =
        errors.trackCode
          ?.map((error, index) => (error ? error.join(' ').replace(` seçilmiş track_code.${index}`, barcodes[index]) : undefined))
          .filter((error) => !!error)
          .join('. ') || '';

      const failedBarcodeIndexes: number[] = [];
      errors.trackCode?.forEach((error, index: number) => {
        if (error) failedBarcodeIndexes.push(index);
      });

      return {
        message: `${closeBoxMutation.error.message}: ${message}`,
        failedBarcodeIndexes: failedBarcodeIndexes,
      };
    }

    if (closeBoxMutation.error instanceof Error) {
      return {
        message: `${closeBoxMutation.error.message}`,
      };
    }
  }, [barcodes, closeBoxMutation.error]);

  const checkDuplication = useCallback(
    async (trackCode: string) => {
      const result = await getDeclarationByTrackCodeRepo.execute({ trackCode });

      if (result.status === 200) {
        if (!!result.response.box) {
          setDuplicatedTrackCodes((prevState) => [...prevState, trackCode]);
        }
      }
    },
    [getDeclarationByTrackCodeRepo],
  );

  const resetBarcodes = useCallback(() => {
    setBarcodes([]);
    closeBoxMutation.reset();
    setDuplicatedTrackCodes([]);
  }, [closeBoxMutation]);

  const removeBarcode = useCallback((index: number) => {
    setBarcodes((barcodes) => {
      const barcode = barcodes.find((_, _index) => _index === index);

      if (barcode) {
        setDuplicatedTrackCodes((prevState) => prevState.filter((trackCode) => trackCode !== barcode));
      }

      return barcodes.filter((barcode, _index) => index !== _index);
    });
  }, []);

  const onBarcodeSearch = useCallback(
    async (value: string) => {
      if (!value) {
        return null;
      }

      setBarcodes((prev) => uniq([value, ...prev]));
      if (barcodeInputRef.current) {
        barcodeInputRef.current.setValue('');
      }
      if (type === 'acceptance') {
        await checkDuplication(value);
      }
    },
    [checkDuplication, type],
  );

  const onCloseBox = useCallback(() => {
    const mutation = () => {
      closeBoxMutation.mutate(
        { trackCodes: barcodes },
        {
          onSuccess: async () => {
            setBarcodes([]);
            setDuplicatedTrackCodes([]);
            message.success('Əməliyyat müvəffəqiyyətlə başa çatdı.');
          },
          onError: async (error) => {
            message.error(error.message);
          },
        },
      );
    };

    if (!!duplicatedTrackCodes.length) {
      Modal.confirm({
        title: 'Diqqət',
        content: `${duplicatedTrackCodes.length} ədəd təkrar izləmə kodu aşkarlandır. Əməliyyatı davam etməyə əminsinizmi?`,
        onOk: mutation,
      });
    } else {
      mutation();
    }
  }, [barcodes, closeBoxMutation, duplicatedTrackCodes.length]);

  const onTransferBox = useCallback(() => {
    transferBoxMutation.mutate(
      { trackCodes: barcodes },
      {
        onSuccess: async () => {
          setBarcodes([]);
          message.success('Əməliyyat müvəffəqiyyətlə başa çatdı.');
        },
        onError: async (error) => {
          message.error(error.message);
        },
      },
    );
  }, [barcodes, transferBoxMutation]);

  const onSelectBox = useCallback(
    (value) => {
      selectBoxMutation.mutate(
        { id: value },
        {
          onError: async (error) => {
            message.error(error.message);
          },
        },
      );
    },
    [selectBoxMutation],
  );

  const onTypeSwitch = useCallback(() => {
    setType((type) => (type === 'transfer' ? 'acceptance' : 'transfer'));
  }, []);

  useEffect(() => {
    setBarcodes(JSON.parse(localStorage.getItem('boxAcceptance.barcodes') || '[]'));
  }, []);

  useEffect(() => {
    localStorage.setItem('boxAcceptance.barcodes', JSON.stringify(barcodes));
  }, [barcodes]);

  return {
    boxes,
    myBox,
    barcodeInputRef,
    tableData,
    onBarcodeSearch,
    onTransferBox,
    transferBoxMutation,
    onSelectBox,
    onCloseBox,
    canCloseBox: !!barcodes.length,
    canClearBarcodes: !!barcodes.length,
    selectBoxMutation,
    closeBoxMutation,
    resetBarcodes,
    removeBarcode,
    closeBoxMutationError,
    type,
    onTypeSwitch,
    duplicatedTrackCodes,
  };
};
