import { TicketsCounts, TicketsCountsApi } from '../types';
import { tableFilterParser } from '../../../../core/helpers/table-filter-parser';
import { formDataFlatter } from '../../../shared/utils/form-data-flatter';

export class TicketMappers {
  public static countsFromApi(data: TicketsCountsApi): TicketsCounts {
    return {
      all: data.all,
      read: data.readed,
      unread: data.unreaded,
      status: {
        32: data.states['32'],
        33: data.states['33'],
        34: data.states['34'],
        35: data.states['35'],
      },
    };
  }

  public static countsQueryToApi(filters) {
    return formDataFlatter(tableFilterParser(filters));
  }
}
