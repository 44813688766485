import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { Result } from '../../../shared/utils/result';

export class FlightWeightCalculatorService {
  public static async getById(id: string | number) {
    const url = urlMaker('/api/admin/flights/calculate', { flight_id: id });

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();

        return new Result(200, data, null);
      } else {
        return new Result(400, 'Məlumatlar əldə edilə bilmədi', null);
      }
    } catch (e) {
      return new Result(500, 'Şəbəkə ilə əlaqə qurula bilmədi', null);
    }
  }
}
