import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Button, Form, List, message, Modal } from 'antd';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

import { useInfo } from '../../hooks/common/useInfo';
import { caller, urlFactory } from '../../core/utils/caller';
import { InputField } from '../../components/ReduxForm/Input';
import { CheckboxField } from '../../components/ReduxForm/Checkbox';
import { formFieldValueSelector } from '../../selectors/form';
import { EditOutlined } from '@ant-design/icons';
import { formDataBuilder } from '../../core/utils/form-data-builder';
import { UnexpectedError } from '../../core/helpers/errors';
import { useClose } from '../../hooks/common';
import routes from '../../constants/routes';

const TicketCategories: FC<InjectedFormProps<any, any>> = ({ handleSubmit: baseHandleSubmit, submitting, initialize, form }) => {
  const close = useClose(routes.tickets.index);
  const selectedId = useSelector(formFieldValueSelector(form, 'ticket_category_id'));

  const useInfoOptions = useMemo(
    () => ({
      apiUrl: urlFactory('/api/admin/tickets/categories'),
      initialState: [],
      responseGetter: (response) => response.data,
    }),
    [],
  );
  const { data, loading, fetch } = useInfo(useInfoOptions);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(
    baseHandleSubmit(async (values) => {
      try {
        const url = values.ticket_category_id ? urlFactory('/api/admin/tickets/edit_category') : urlFactory('/api/admin/tickets/create_category');
        const body = formDataBuilder({ values });
        const response = await caller(url, { method: 'POST', body });

        if (response.ok) {
          await fetch();
        } else {
          UnexpectedError();
        }
      } catch (e) {
        message.error('Xəta baş verdi');
      }
    }),
    [baseHandleSubmit, fetch],
  );

  const initializeSelectedItem = useCallback(({ id, name, hidden }) => {
    initialize({ ticket_category_id: id, name, hidden: !!hidden });
  }, []); //eslint-disable-line

  const resetForm = useCallback(() => {
    initialize({ hidden: false });
  }, []); //eslint-disable-line

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <Modal maskTransitionName='' transitionName='' onCancel={close} closable={false} footer={null} width={576} visible={true}>
      <Form style={{ display: 'flex' }}>
        <Field name='name' item={{ style: { flex: 1 } }} field={{ placeholder: 'Kateqoriya' }} component={InputField} />
        <Field name='hidden' item={{ style: { margin: '0 12px' } }} type='checkbox' field={{ children: 'Gizli' }} component={CheckboxField} />
        <Button loading={submitting} onClick={handleSubmit} type='primary'>
          {!!selectedId ? 'Dəyişdir' : 'Yarat'}
        </Button>
        {selectedId && (
          <Button onClick={resetForm} type='link'>
            Ləğv et
          </Button>
        )}
      </Form>
      <List
        style={{ maxHeight: 300, overflow: 'auto' }}
        loading={loading}
        dataSource={data}
        renderItem={(item: any) => (
          <List.Item actions={[<EditOutlined onClick={() => initializeSelectedItem(item)} key='edit-action' />]} key={item.id}>
            <List.Item.Meta title={item.name} description={item.hidden ? 'Gizlidir' : 'Gizli deyil'} />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default reduxForm<any, any>({ form: 'ticketCategories' })(TicketCategories);
