import styled from 'styled-components';
import { Row as BaseRow, Col as BaseCol, PageHeader as BasePageHeader, Space, Card as BaseCard, Descriptions as BaseDescriptions, Typography } from 'antd';
import { PageContent } from '@core/ui';

const GUTTER = [18, 18] as [number, number];

const Wrapper = styled((props) => <PageContent {...props} />)`
  & > .ant-card-body {
    padding: 18px;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Row = styled(BaseRow).attrs({ gutter: GUTTER })``;
const Col = styled(BaseCol)``;

const PageHeader = styled(BasePageHeader)`
  padding: 0;

  .ant-page-header-heading-left {
    display: flex;
  }

  .ant-page-header-heading-tags {
    display: flex;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > *:not(:last-child) {
    margin-right: 18px;
  }
`;

const ActionCol = styled(Space).attrs({ size: 18, wrap: true })``;

const Card = styled(BaseCard).attrs({ size: 'small', type: 'inner' })`
  .ant-table.ant-table-small .ant-table-title,
  .ant-table.ant-table-small .ant-table-footer,
  .ant-table.ant-table-small .ant-table-thead > tr > th,
  .ant-table.ant-table-small .ant-table-tbody > tr > td,
  .ant-table.ant-table-small tfoot > tr > th,
  .ant-table.ant-table-small tfoot > tr > td {
    padding: 8px 12px;
  }
`;

const Descriptions = styled(BaseDescriptions).attrs({ bordered: true, column: 1, size: 'small' })`
  margin: -12px -13px -13px -13px;
`;

const SectionTitle = styled(Typography.Title).attrs({ level: 4 })`
  margin-bottom: 0;
  text-align: center;
`;

const StepsWrapper = styled.div`
  padding: 24px 0;
`;

export const Details = {
  Wrapper,
  Row,
  Col,
  PageHeader,
  Actions,
  ActionCol,
  Card,
  Descriptions,
  SectionTitle,
  StepsWrapper,
};
