import { IDetailedUser, IDetailedUserPersistence } from '../interfaces';

export class DetailedUserMapper {
  public static toDomain(user: IDetailedUserPersistence): IDetailedUser {
    const getRole = () => {
      switch (user.data.admin) {
        case 1:
          return 'admin';
        case 2:
          return 'warehouseman';
        case 3:
          return 'back-office';
        default:
          return 'user';
      }
    };

    return {
      id: user.data.id,
      firstname: user.data.name,
      lastname: user.data.surname,
      adminBranch: {
        id: user.data.admin_branch_id,
        name: user.data.admin_branch_name,
      },
      fullName: user.data.user_name,
      branchId: user.data.branch_id ? user.data.branch_id.toString() : '',
      phoneNumber: user.data.number,
      email: user.data.email,
      gender: user.data.gender,
      birthDate: user.data.birth_date,
      address: user.data.address,
      discount: {
        percent: user.data.discount,
        dateUntil: user.data.discount_date,
      },
      cashRegisterId: user.data.cashbox_id,
      passport: {
        number: user.data.passport_number,
        secret: user.data.passport_secret,
      },
      balance: {
        usd: user.widget.balance.usd,
        try: user.widget.balance.try,
      },
      spending: {
        currentMonth: {
          usd: user.widget.this.usd,
          try: user.widget.this.try,
        },
      },
      debt: {
        usd: user.credit_usd,
        try: user.credit,
      },
      counts: {
        declarations: {
          all: user.data.declaration.all,
          handedOver: user.data.declaration.delivered,
        },
        couriers: {
          all: user.data.courier.all,
          handedOver: user.data.courier.delivered,
        },
        orders: {
          all: user.data.order.all,
          handedOver: user.data.order.delivered,
        },
        transactions: {
          income: user.data.in,
          outcome: user.data.out,
        },
      },
      role: getRole(),
      emailServiceIsActive: !!user.data.send_mail,
      smsServiceIsActive: !!user.data.send_sms,
      isBlocked: !!user.data.blocked,
      createdAt: user.data.created_at,
    };
  }
}
