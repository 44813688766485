import React, { FC, useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { Col, Modal, Row } from 'antd';

import { AntForm } from '@core/ui';
import { TextField, TextAreaField, CheckboxField } from '@core/form';

import { CreateBranchContext } from '../contexts';
import { useCreateBranch } from '../hooks';
import { CreateBranchDto } from '../dtos';

const FormikComponent: FC<FormikProps<CreateBranchDto>> = ({ handleSubmit, isSubmitting }) => {
  const { id, onCancel } = useContext(CreateBranchContext);

  return (
    <Modal width={768} visible={true} onOk={() => handleSubmit()} onCancel={onCancel} confirmLoading={isSubmitting} title={!id ? 'Yeni filial əlavə et' : 'Filialda düzəliş et'}>
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 24]}>
          <Col xl={12} xs={24}>
            <TextField name='name' item={{ label: 'Ad' }} input={{ placeholder: 'Filial adını daxil edin...' }} />
          </Col>
          <Col xl={12} xs={24}>
            <TextField name='email' item={{ label: 'Email' }} input={{ placeholder: 'Email daxil edin...' }} />
          </Col>
          <Col xl={12} xs={24}>
            <TextField name='phone' item={{ label: 'Telefon' }} input={{ placeholder: 'Telefon daxil edin...' }} />
          </Col>
          <Col xl={12} xs={24}>
            <TextField name='mapAddress' item={{ label: 'Xəritə ünvanı' }} input={{ placeholder: 'Xəritə ünvanı adını daxil edin...' }} />
          </Col>
        </Row>
        <TextAreaField name='address' item={{ label: 'Adres' }} input={{ placeholder: 'Adres daxil edin...' }} />
        <TextAreaField name='descr' item={{ label: 'Şərh' }} input={{ placeholder: 'Şərh daxil edin...' }} />
        <TextAreaField name='workingHours' item={{ label: 'İş saatları' }} input={{ placeholder: 'İş saatları daxil edin...' }} />
        <CheckboxField name='isBranch' input={{ children: 'Filial' }} />
      </AntForm>
    </Modal>
  );
};

export const CreateBranch: FC = () => {
  const { initialValues, onSubmit } = useCreateBranch();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
