import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import { DeleteOutlined, EditOutlined, FileSearchOutlined, MoreOutlined } from '@ant-design/icons';

import { actionsCol, dateCol, idCol } from '../../core/helpers/table';
import { stopPropagation } from '../../core/helpers/events';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';
import { useDispatch } from 'react-redux';
import { removeAction } from '../../actions/ticketTemplates';

export const useTicketTemplatesTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        ...actionsCol,
        Cell: ({ row: { original } }) => {
          const goToEdit = useCallback(() => {
            history.push(makePath(routes.ticketTemplates.edit, { id: original.id }));
          }, [original.id]);

          const goToInfo = useCallback(() => {
            history.push(makePath(routes.ticketTemplates.info, { id: original.id }));
          }, [original.id]);

          const handleDelete = useCallback(() => {
            removeAction([original.id])(dispatch)
              .then()
              .catch(() => {});
          }, [original.id]);

          return (
            <span onClick={stopPropagation}>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item onClick={goToInfo}>
                      <FileSearchOutlined />
                      <span>Ətraflı</span>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item onClick={goToEdit}>
                      <EditOutlined />
                      <span>Düzəliş et</span>
                    </Menu.Item>
                    <Menu.Item onClick={handleDelete}>
                      <DeleteOutlined />
                      <span>Sil</span>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button className='next-table-action-btn' type='text' icon={<MoreOutlined />} />
              </Dropdown>
            </span>
          );
        },
      },
      { ...idCol },
      { accessor: 'title', Header: 'Ad', width: 200 },
      { accessor: 'created_at', Header: 'Yaradılıb', ...dateCol },
    ],
    [history, dispatch],
  );

  return { columns };
};
