import { useInfo } from '../common/useInfo';
import { urlFactory } from '../../core/utils/caller';
import { useCallback, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { CommonConstants } from '../../constants/common';
import { getRandomInt } from '../../core/utils/get-random-int';
import { ChartData } from 'react-chartjs-2';
import Chart from 'chart.js';

export const useDeclarationProductTypesStatistics = (delay = 0) => {
  const [[startDate, endDate], setDate] = useState<(null | Moment)[]>([moment().startOf('month'), moment()]);
  const [stateId, setStateId] = useState<string[]>([]);

  const { data: rawData, loading } = useInfo(
    useMemo(
      () => ({
        apiUrl: urlFactory('/api/admin/declaration/product_type_stats', {
          start_date: startDate?.format(CommonConstants.DATE),
          end_date: endDate?.format(CommonConstants.DATE),
          state_id: stateId,
        }),
        initialState: [],
        responseGetter: (response) => response.data,
        delay,
      }),
      [delay, startDate, endDate, stateId],
    ),
  );

  const data = useMemo<ChartData<Chart.ChartData>>(() => {
    if (rawData) {
      const top20 = rawData.sort((a, b) => b.count - a.count).filter((_, index) => index < 20);
      const colors = top20.map(() => [getRandomInt(0, 255), getRandomInt(0, 255), getRandomInt(0, 255)].join(','));
      const borderColors = colors.map((color) => `rgba(${color},1)`);
      const backgroundColors = colors.map((color) => `rgba(${color},0.2)`);
      const hoverBackgroundColor = colors.map((color) => `rgba(${color},0.4)`);
      const data = top20.map(({ count }) => count);

      return {
        labels: top20.map(({ product_type_name }) => product_type_name),
        datasets: [
          {
            label: 'Bağlama sayı',
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
            hoverBackgroundColor: hoverBackgroundColor,
            hoverBorderColor: borderColors,
            borderCapStyle: 'round',
            data,
          },
        ],
      };
    } else {
      return {
        labels: [],
        datasets: [],
      };
    }
  }, [rawData]);

  const onDateChange = useCallback((value) => {
    setDate(() => value || [null, null]);
  }, []);

  const onStateChange = useCallback((value) => {
    setStateId(value);
  }, []);

  return { data, rawData, loading, date: [startDate, endDate], stateId, onDateChange, onStateChange };
};
