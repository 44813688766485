import React, { FC, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { Form, Modal, Spin } from 'antd';

import { useFormFields } from '../../hooks/regions/useForm';
import { useClose } from '../../hooks/common';
import { urlFactory } from '../../core/utils/caller';
import { useForm } from '../../hooks/common/useForm';
import { tableFetchDataAction } from '../../actions/table';
import routes from '../../constants/routes';

type IProps = InjectedFormProps<any> & RouteComponentProps<any> & { dispatch: Dispatch };

const RegionsForm: FC<IProps> = ({ handleSubmit: baseHandleSubmit, initialize, match: { params }, dispatch, submitting, initialized }) => {
  const handleClose = useClose(routes.regions.index);

  const handleSubmitSuccess = useCallback(() => {
    dispatch(tableFetchDataAction('regions', '/api/admin/regions/getlist'));
  }, [dispatch]);

  const formOptions = useMemo(
    () => ({
      handleClose,
      handleSubmit: baseHandleSubmit,
      initialize,
      createUrl: routes.regions.create,
      createApiUrl: urlFactory('/api/admin/regions/create'),
      editApiUrl: urlFactory('/api/admin/regions/edit', {
        region_id: params.id,
      }),
      onSuccess: handleSubmitSuccess,
      initializeUrl: urlFactory('/api/admin/regions/getinfo', {
        region_id: params.id,
      }),
    }),
    [params.id, handleSubmitSuccess, baseHandleSubmit, initialize, handleClose],
  );

  const { handleSubmit, type } = useForm(formOptions);

  const title = useMemo(() => {
    return type === 'create' ? 'Yeni rayon əlavə et' : 'Rayonda düzəliş et';
  }, [type]);

  const { nameFieldProps, priceFieldProps, stateIdFieldProps, descriptionsFieldProps } = useFormFields();

  return (
    <Modal maskTransitionName='' transitionName='' width={592} visible={true} onCancel={handleClose} confirmLoading={submitting} onOk={handleSubmit} title={title}>
      <Spin spinning={type === 'edit' && !initialized}>
        <Form layout='vertical'>
          <Field {...nameFieldProps} />
          <Field {...priceFieldProps} />
          <Field {...stateIdFieldProps} />
          <Field {...descriptionsFieldProps} />
        </Form>
      </Spin>
    </Modal>
  );
};

export default reduxForm<any, any>({ form: 'regions' })(React.memo<IProps>(RegionsForm));
