import { useCallback, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';

import { useQuery } from '../../../shared/modules/use-query/hooks';

import { StatisticsService } from '../services';
import { IDeclarationExecutive } from '../types/declaration-executive';

export const useDeclarationExecutives = () => {
  const [dateRange, setDateRange] = useState<[Moment, Moment]>([moment().startOf('month'), moment()]);

  const handleChangeDateRange = useCallback((value: [Moment | null, Moment | null] | null) => {
    const startDate = value?.[0] || null;
    const endDate = value?.[1] || null;

    if (startDate && endDate) {
      setDateRange([startDate, endDate]);
    }
  }, []);

  const result = useQuery<IDeclarationExecutive[]>(
    useCallback(async () => {
      if (!dateRange) {
        return null;
      }

      const result = await StatisticsService.DeclarationExecutives.getAll(dateRange[0], dateRange[1]);

      if (result.status === 200) {
        return result.data;
      } else {
        return [];
      }
    }, [dateRange]),
    useMemo(() => ({ initialData: [], enabled: !!dateRange }), [dateRange]),
  );

  const columns = useMemo(
    () => [
      { dataIndex: 'id', key: 'id', title: 'Kod' },
      { dataIndex: 'firstname', key: 'firstname', title: 'Ad' },
      { dataIndex: 'lastname', key: 'lastname', title: 'Soyad' },
      { dataIndex: 'count', key: 'count', title: 'Say' },
    ],
    [],
  );

  return { result, dateRange, handleChangeDateRange, columns };
};
