import { useMemo } from 'react';

import { InputField } from '../../../components/ReduxForm/Input';
import { StaticSelectField } from '../../../components/ReduxForm/Select';
import { DateField } from '../../../components/ReduxForm/DateField';

export const useFormFields = () => {
  const nameFieldProps = useMemo(
    () => ({
      name: 'name',
      item: { label: 'Ad' },
      field: { placeholder: 'Flight-ın adını daxil edin' },
      component: InputField,
    }),
    [],
  );

  const startDateFieldProps = useMemo(
    () => ({
      name: 'start_date',
      item: { label: 'Başlama vaxtı' },
      field: { placeholder: 'Başlama vaxtını daxil edin', disabled: true },
      component: DateField,
    }),
    [],
  );

  const endDateFieldProps = useMemo(
    () => ({
      name: 'end_date',
      item: { label: 'Bitmə vaxtı' },
      field: { placeholder: 'Bitmə vaxtını daxil edin', disabled: true },
      component: DateField,
    }),
    [],
  );

  const stateFieldProps = useMemo(
    () => ({
      name: 'state_id',
      item: { label: 'Status' },
      field: { placeholder: 'Status seçin' },
      options: [
        { value: 29, label: 'Açıq' },
        { value: 30, label: 'Bağlı' },
      ],
      component: StaticSelectField,
    }),
    [],
  );

  return useMemo(
    () => ({
      nameFieldProps,
      startDateFieldProps,
      endDateFieldProps,
      stateFieldProps,
    }),
    [nameFieldProps, startDateFieldProps, endDateFieldProps, stateFieldProps],
  );
};
