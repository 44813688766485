import DateFilter from '../../components/Table/core/Components/Filters/DateFilter';

export const smallCol = { width: 75, minWidth: 75, maxWidth: 75, unit: 'px' };
export const midCol = { width: 100, minWidth: 100, maxWidth: 100, unit: 'px' };
export const normalCol = { width: 150, minWidth: 150, maxWidth: 150, unit: 'px' };
export const largeCol = { width: 200, minWidth: 200, maxWidth: 200, unit: 'px' };

export const actionsCol = { accessor: 'actions', width: 39, minWidth: 39, maxWidth: 39, filterable: false, sortable: false, unit: 'px' };
export const idCol = { accessor: 'id', Header: 'Kod', width: 75, minWidth: 75, maxWidth: 75, unit: 'px' };
export const dateCol = { ...largeCol, Filter: DateFilter };

export const pixelCol = (width) => ({ width, minWidth: width, maxWidth: width, unit: 'px' });
