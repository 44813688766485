import React, { useEffect } from 'react';

import ReadyTable from '../../components/Table/ReadyTable';
import { useColumns } from '../../hooks/customsDelcarations/useColumns';
import { tableSetFiltersByIdAction } from '../../actions/table';
import moment from 'moment';
import { Constants } from '../../next/shared/constants';
import { useDispatch } from 'react-redux';

const CustomsDeclarationsTable = () => {
  const columns = useColumns();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tableSetFiltersByIdAction('customs.carriersPosts', 'dateFrom', moment().startOf('day').format(Constants.DATE_TIME)));
    dispatch(tableSetFiltersByIdAction('customs.carriersPosts', 'dateTo', moment().endOf('day').format(Constants.DATE_TIME)));
  }, [dispatch]);

  return <ReadyTable sortable={false} name='customs.carriersPosts' filterable={false} source='customs.carriersPosts' apiUrl='/api/admin/customs/carriersposts' columns={columns} />;
};

export default React.memo(CustomsDeclarationsTable);
