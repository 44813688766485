import { PaymentTypeStatisticsByModel, PaymentTypeStatisticsByModelApi } from '../types/payment-types';

export class PaymentTypesStatisticsMappers {
  public static oneFromApi(data: PaymentTypeStatisticsByModelApi): PaymentTypeStatisticsByModel {
    return {
      id: data.payment_type,
      name: data.name,
      amount: parseFloat(data.amount),
      count: data.count,
    };
  }

  public static manyFromApi(data: PaymentTypeStatisticsByModelApi[]): PaymentTypeStatisticsByModel[] {
    return data.map(this.oneFromApi);
  }
}
