import React, { FC, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm, SubmissionError } from 'redux-form';
import { Button, Col, Form, message, Modal, Row, Spin } from 'antd';

import { useDeclarationFormFields, useDeclarationFormLayout } from '../../next/modules/declarations/hooks';
import { useType } from '../../hooks/common/useForm';
import { useClose } from '../../hooks/common';
import { caller, urlFactory } from '../../core/utils/caller';
import { Dispatch } from 'redux';
import { tableFetchDataAction } from '../../actions/table';
import routes from '../../constants/routes';
import { useInitialize } from '../../hooks/common/useForm/useInitialize';
import { formDataBuilder } from '../../core/utils/form-data-builder';
import { UnexpectedError, ValidationError } from '../../core/helpers/errors';

const ConflictedDeclarationsForm: FC<InjectedFormProps<any> & RouteComponentProps<any> & { dispatch: Dispatch }> = ({
  handleSubmit: baseHandleSubmit,
  submitting,
  initialize,
  dispatch,
  initialized,
  match: { params },
}) => {
  const handleClose = useClose(routes.declarations.conflicted.index);
  const type = useType(routes.declarations.conflicted.create);

  useInitialize({
    middlewares: [(values) => ({ ...values, document_file: undefined, uploaded_file: values.document_file, type: values.type === '1' })],
    url: urlFactory('/api/admin/conflicted_declaration/info', { conflicted_declaration_id: params.id }),
    onSuccess: initialize,
  });

  const handleSubmit = useCallback(
    (accepted = false) =>
      baseHandleSubmit(async (values) => {
        try {
          const url = urlFactory(!accepted ? '/api/admin/conflicted_declaration/edit' : '/api/admin/conflicted_declaration/accept');
          const body = formDataBuilder({
            values: {
              ...values,
              type: values.type ? 1 : 2,
              conflicted_declaration_id: params.id,
            },
          });
          const response = await caller(url, { method: 'POST', body });

          if (response.ok) {
            message.success(accepted ? 'Mübahisəli bağlama həll edildi.' : 'Mübahisəli bağlamada düzəliş edildi.');
            dispatch(tableFetchDataAction('conflictedDeclarations', '/api/admin/v2/conflicted_declaration/list'));
            handleClose();
          } else if (response.status === 400) {
            const { errors } = await response.json();
            ValidationError(errors);
          } else {
            UnexpectedError();
          }
        } catch (e) {
          if (e instanceof SubmissionError) {
            throw e;
          } else {
            message.error('Xəta baş verdi');
          }
        }
      }),
    [baseHandleSubmit, params.id, handleClose, dispatch],
  );

  const title = useMemo(() => {
    return type === 'create' ? 'Yeni mübahisəli bağlama əlavə et' : 'Mübahisəli bağlamada düzəliş et';
  }, [type]);

  const { baseRowProps, baseColProps, xsColProps, smColProps, mdColProps, xlColProps } = useDeclarationFormLayout();

  const {
    userFieldProps,
    productTypesFieldProps,
    shopNameFieldProps,
    quantityFieldProps,
    globalTrackCode,
    priceFieldProps,
    deliveryPriceFieldProps,
    descriptionsFieldProps,
    weightFieldProps,
    liquidFieldProps,
    documentFieldProps,
  } = useDeclarationFormFields();

  return (
    <Modal
      maskTransitionName=''
      transitionName=''
      width={768}
      visible={true}
      onCancel={handleClose}
      title={title}
      footer={[
        <Button onClick={handleClose} key='cancel-button'>
          Ləğv et
        </Button>,
        <Button loading={submitting} onClick={handleSubmit(true)} type='primary' key='accept-button'>
          Həll et
        </Button>,
        <Button loading={submitting} onClick={handleSubmit(false)} type='primary' key='edit-button'>
          Düzəliş et
        </Button>,
      ]}
    >
      <Spin spinning={type === 'edit' && !initialized}>
        <Form layout='vertical'>
          <Row {...baseRowProps}>
            <Col {...xlColProps}>
              <Field {...userFieldProps} />
            </Col>
            <Col {...xlColProps}>
              <Field {...globalTrackCode} />
            </Col>
            <Col {...mdColProps}>
              <Field {...shopNameFieldProps} />
            </Col>
            <Col {...mdColProps}>
              <Field {...productTypesFieldProps} />
            </Col>
            <Col {...xsColProps}>
              <Field {...quantityFieldProps} />
            </Col>
            <Col {...xsColProps}>
              <Field {...weightFieldProps} />
            </Col>
            <Col {...smColProps}>
              <Field {...priceFieldProps} />
            </Col>
            <Col {...smColProps}>
              <Field {...deliveryPriceFieldProps} />
            </Col>
            <Col {...mdColProps}>
              <Field {...liquidFieldProps} />
            </Col>
            <Col {...baseColProps}>
              <Field {...documentFieldProps} />
            </Col>
            <Col {...baseColProps}>
              <Field {...descriptionsFieldProps} />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default reduxForm<any, any>({ form: 'conflictedDeclarations' })(
  React.memo<InjectedFormProps<any> & RouteComponentProps & { dispatch: Dispatch }>(ConflictedDeclarationsForm, (prev, curr) => {
    return prev.handleSubmit === curr.handleSubmit && prev.submitting === curr.submitting && prev.match.params === curr.match.params && prev.initialized === curr.initialized;
  }),
);
