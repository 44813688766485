import React from 'react';
import { Card, DatePicker, Table } from 'antd';
import { usePaymentTypesStatisticsByModel } from '../hooks/use-payment-types-by-model';

const { RangePicker } = DatePicker;

export const DeclarationsPaymentTypesStatistics = () => {
  const { result, dateRange, handleChangeDateRange, columns } = usePaymentTypesStatisticsByModel(2);

  return (
    <Card size='small' title='Bağlamalar'>
      <RangePicker allowClear={false} value={dateRange} onChange={handleChangeDateRange} style={{ marginBottom: 12 }} />
      <Table dataSource={result.data} size='small' bordered={true} loading={result.loading} columns={columns} rowKey='id' />
    </Card>
  );
};
