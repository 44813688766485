import { IShop, IShopPersistence } from '../interfaces';

export class ShopMapper {
  public static toDomain(shop: IShopPersistence): IShop {
    return {
      id: shop.id,
      name: shop.name,
      createdAt: shop.created_at,
    };
  }
}
