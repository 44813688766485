import React, { useCallback, useEffect, useMemo } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ReadyTable from '../../components/Table/ReadyTable';
import { Column } from 'react-table';
import { actionsCol, dateCol, idCol, midCol, normalCol } from '../../core/helpers/table';
import PriceCell from '../../components/Table/core/Components/Cell/PriceCell';
import { stopPropagation } from '../../core/helpers/events';
import { Button, Dropdown, Menu, message } from 'antd';
import { FileSearchOutlined, MoreOutlined, FileExcelOutlined, FileTextOutlined, MenuOutlined, EditOutlined, OrderedListOutlined } from '@ant-design/icons/lib';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';
import { exportExcelFlightAction, exportXMLFlightAction } from '../../actions/flights';
import { printWaybill } from '../../next/modules/declarations/actions/declarations';
import CountCell from '../../components/Flights/Cell/Count';
import { FlightService } from '../../next/modules/flights/services';
import { useSearchParams } from '../../next/shared/hooks/use-search-params';
import { tableFetchDataAction } from '../../actions/table';
import { localURLMaker } from '../../next/shared/utils/url-maker';
import { CloseCircleOutlined } from '@ant-design/icons';
import { FlightWeightInteraction } from '../../next/modules/flights/containers/weight-calculator';

const FlightsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        Cell: ({ row: { original } }) => {
          const goToEdit = useCallback(() => {
            history.push(makePath(routes.flights.info, { id: original.id }));
          }, [original.id]);

          const goToUpdateThisMonth = useCallback(() => {
            history.push(makePath(routes.flights.updateThisMonth, { id: original.id }));
          }, [original.id]);

          const goToBulkManifest = useCallback(() => {
            history.push(makePath(routes.flights.bulkManifest, { id: original.id }));
          }, [original.id]);

          const goToAirWaybills = useCallback(() => {
            history.push(makePath('/@next/flights/:id/air-waybills', { id: original.id }));
          }, [original.id]);

          const exportManifestByParcels = useCallback(async () => {
            message.loading({ key: '1', content: 'Əməliyyat aparılır.', duration: null });
            const result = await FlightService.BulkManifest.getByParcels(original.id);

            if (result.status === 200) {
              message.destroy();
              window.open(result.data.file, '_blank');
            } else {
              message.error({ key: '1', content: result.data });
            }
          }, [original.id]);

          const handleExportExcel = useCallback(
            (partnerId?: number) => {
              exportExcelFlightAction(original.id, partnerId).catch(() => null);
            },
            [original.id],
          );

          const handleExportXML = useCallback(
            (liquid?: boolean | undefined, partnerId?: number) => {
              exportXMLFlightAction(original.id, liquid, partnerId).catch(() => null);
            },
            [original.id],
          );

          const handlePrintManifest = useCallback(
            (partnerId?: number) => {
              printWaybill({ flightId: original.id, partnerId }).catch(() => null);
            },
            [original.id],
          );

          const closeFlight = useCallback(
            (type: string) => {
              history.push(localURLMaker(routes.flights.close, { id: original.id, type }));
            },
            [original.id],
          );

          const updateAirWaybill = useCallback(() => {
            history.push(generatePath('/@next/flights/:id/air-waybills/update', { id: original.id }), { background: location });
          }, [original.id]);

          const goToPackages = useCallback(() => {
            history.push(localURLMaker('/@next/flights/:id/packages', { id: original.id }));
          }, [original.id]);

          return (
            <span onClick={stopPropagation}>
              <Dropdown
                placement='bottomLeft'
                overlay={
                  <Menu>
                    <Menu.Item onClick={goToEdit}>
                      <FileSearchOutlined />
                      <span>Ətraflı</span>
                    </Menu.Item>
                    <Menu.Item onClick={goToPackages}>
                      <OrderedListOutlined />
                      <span>Bağlanma prosesi</span>
                    </Menu.Item>
                    <Menu.Item onClick={goToAirWaybills}>
                      <OrderedListOutlined />
                      <span>Göndərilən depeshlər</span>
                    </Menu.Item>
                    <Menu.SubMenu icon={<CloseCircleOutlined />} title='Flight-ı bağla'>
                      <Menu.Item onClick={() => closeFlight('withDispatch')}>Depeshli</Menu.Item>
                      <Menu.Item onClick={() => closeFlight('withoutDispatch')}>Depeshsiz</Menu.Item>
                      <Menu.Item onClick={() => closeFlight('all')}>Hamısı</Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item onClick={updateAirWaybill} disabled={original.state_id !== 30}>
                      <OrderedListOutlined />
                      <span>Aviaqaimə nömrəsini dəyiş</span>
                    </Menu.Item>
                    <Menu.Item onClick={goToUpdateThisMonth}>
                      <EditOutlined />
                      <span>Cari ayı dəyiş</span>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item onClick={() => handleExportExcel()}>
                      <FileExcelOutlined />
                      <span>Manifest</span>
                    </Menu.Item>
                    <Menu.Item onClick={goToBulkManifest}>
                      <FileExcelOutlined />
                      <span>Toplu manifest (Kisələrlə excel ilə)</span>
                    </Menu.Item>
                    <Menu.Item onClick={exportManifestByParcels}>
                      <FileExcelOutlined />
                      <span>Toplu manifest (Kisələrlə) 2</span>
                    </Menu.Item>
                    <Menu.Item onClick={() => handlePrintManifest()}>
                      <FileExcelOutlined />
                      <span>Yol vərəqi</span>
                    </Menu.Item>
                    <Menu.SubMenu
                      title={
                        <>
                          <FileTextOutlined />
                          <span>XML Export</span>
                        </>
                      }
                    >
                      <Menu.Item onClick={() => handleExportXML(true)}>Yalnız maye</Menu.Item>
                      <Menu.Item onClick={() => handleExportXML(false)}>Yalnız maye olmayan</Menu.Item>
                      <Menu.Item onClick={() => handleExportXML()}>Bütün bağlamalar</Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu
                      title={
                        <>
                          <MenuOutlined />
                          <span>Modafun</span>
                        </>
                      }
                    >
                      <Menu.Item onClick={() => handleExportExcel(1)}>Manifest</Menu.Item>
                      <Menu.Item onClick={() => handlePrintManifest(1)}>Yol vərəqi</Menu.Item>
                      <Menu.SubMenu
                        title={
                          <>
                            <FileTextOutlined />
                            <span>XML Export</span>
                          </>
                        }
                      >
                        <Menu.Item onClick={() => handleExportXML(true, 1)}>Yalnız maye</Menu.Item>
                        <Menu.Item onClick={() => handleExportXML(false, 1)}>Yalnız maye olmayan</Menu.Item>
                        <Menu.Item onClick={() => handleExportXML(undefined, 1)}>Bütün bağlamalar</Menu.Item>
                      </Menu.SubMenu>
                    </Menu.SubMenu>
                  </Menu>
                }
              >
                <Button className='next-table-action-btn' type='text' icon={<MoreOutlined />} />
              </Dropdown>
            </span>
          );
        },
        ...actionsCol,
      },
      { ...idCol },
      { accessor: 'name', Header: 'Ad' },
      { accessor: 'start_date', Header: 'Başlanğıc tarix', ...dateCol },
      { accessor: 'end_date', Header: 'Son tarix', ...dateCol },
      { accessor: 'state_name', Header: 'Status', ...midCol },
      { accessor: 'count', Header: 'Bağlama sayı', Cell: CountCell, ...midCol },
      {
        ...midCol,
        id: 'counts',
        Header: 'Tamamlanma',
        Cell: ({ row: { original } }) => (original.state_id === 29 ? `${original.count}` : `${original.finished}/${original.total}`),
      },
      { accessor: 'weight', Header: 'Çəki', ...midCol },
      {
        ...midCol,
        accessor: 'dimensional_weight',
        Header: 'Həcmi çəki',
        Cell: ({ row: { original } }) => {
          return (
            <span onClick={stopPropagation}>
              <FlightWeightInteraction id={original.id} />
            </span>
          );
        },
      },
      { accessor: 'price', Header: 'Məbləğ', Cell: PriceCell.TRY, ...midCol },
      { accessor: 'delivery_price', Header: 'Çatdırılma məbləği', Cell: PriceCell.USD, ...normalCol },
    ],
    [history, location],
  );

  const rowProps = useCallback(
    (id) => ({
      onDoubleClick: () => {
        history.push(makePath(routes.flights.info, { id }));
      },
    }),
    [history],
  );

  const { searchParams, remove } = useSearchParams<{ reFetchFlights?: string }>();

  useEffect(() => {
    (async () => {
      if (searchParams.reFetchFlights) {
        remove.current('reFetchFlights');
        dispatch(tableFetchDataAction('flights', '/api/admin/v2/flights/list'));
      }
    })();
  }, [dispatch, remove, searchParams.reFetchFlights]);

  return <ReadyTable getRowProps={rowProps} name='flights' columns={columns} apiUrl='/api/admin/v2/flights/list' />;
};

export default React.memo(FlightsTable);
