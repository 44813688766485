import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, message } from 'antd';
import { FieldTimeOutlined, SendOutlined } from '@ant-design/icons';
import routes from '../../constants/routes';
import { useDispatch } from 'react-redux';
import { sendAllQueuedEmails } from '../../actions/emailArchive';
import { tableFetchDataAction } from '../../actions/table';

const EmailArchiveActions = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [sendAllQueuedLoading, setSendAllQueuedLoading] = useState(false);

  const goToBulk = useCallback(() => {
    history.push(routes.archive.email.bulk.index);
  }, [history]);

  const sendAllQueued = useCallback(async () => {
    setSendAllQueuedLoading(true);
    const result = await sendAllQueuedEmails();

    if (result) {
      dispatch(tableFetchDataAction('archive.email', '/api/admin/archive/mail'));
    } else {
      message.error('Əməliyyat aparıla bilmədi');
    }
    setSendAllQueuedLoading(false);
  }, [dispatch]);

  return (
    <div className='actions-wrapper'>
      <div className='actions-wrapper-left'>
        <Button onClick={goToBulk} icon={<SendOutlined />}>
          Toplu email göndər
        </Button>
      </div>
      <div className='actions-wrapper-right'>
        <Button type='primary' onClick={sendAllQueued} loading={sendAllQueuedLoading} icon={<FieldTimeOutlined />}>
          Növbədəkiləri göndər
        </Button>
      </div>
    </div>
  );
};

export default EmailArchiveActions;
