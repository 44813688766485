import { useMemo } from 'react';
import { dateCol, idCol, midCol, smallCol } from '../../core/helpers/table';
import { OverflowCell } from '../../components/Table/core/Components/Cell/OverflowCell';

export const useDnsQueuesTable = () => {
  const columns = useMemo(
    () => [
      {
        ...idCol,
      },
      {
        ...midCol,
        accessor: 'action',
        Header: 'Əməliyyat',
      },
      {
        ...smallCol,
        accessor: 'attempts',
        Header: 'Cəhd sayı',
      },
      {
        accessor: 'json',
        Header: 'Sorğu',
        Cell: OverflowCell,
      },
      {
        accessor: 'response',
        Header: 'Cavab',
        Cell: OverflowCell,
      },
      {
        ...dateCol,
        accessor: 'deleted_at',
        Header: 'Silinib',
      },
      {
        ...dateCol,
        accessor: 'created_at',
        Header: 'Yaradılıb',
      },
    ],
    [],
  );

  return { columns };
};
