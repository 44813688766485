import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons/lib';
import { Column } from 'react-table';

import ReadyTable from '../../components/Table/ReadyTable';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';
import { actionsCol, smallCol } from '../../core/helpers/table';

const PlansTable = () => {
  const history = useHistory();

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        ...actionsCol,
        Cell: ({ row: { original } }) => {
          const gotoEdit = useCallback(() => {
            history.push(makePath(routes.plans.edit, { id: original.id }));
          }, [original.id]);

          return (
            <span onClick={(e) => e.stopPropagation()}>
              <Button className='next-table-action-btn' type='text' onClick={gotoEdit} icon={<EditOutlined />} />
            </span>
          );
        },
      },
      {
        accessor: 'id',
        Header: 'Kod',
        ...smallCol,
      },
      {
        accessor: 'from_weight',
        Header: 'Minimal çəki',
      },
      {
        accessor: 'to_weight',
        Header: 'Maksimal çəki',
      },
      {
        accessor: 'price',
        Header: 'Qiymət',
      },
      {
        accessor: 'type',
        Header: 'Tip',
      },
      {
        accessor: 'tariff_category_name',
        Header: 'Tarif',
      },
      {
        accessor: 'descr',
        Header: 'Açıqlama',
      },
    ],
    [history],
  );

  return <ReadyTable name='plans' apiUrl={'/api/client/v2/tariff/list'} columns={columns} sortable={false} pagination={false} filterable={false} />;
};

export default React.memo(PlansTable);
