import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';

import { IGetBranchesRepo, GetBranchesRepoType } from '../repos';
import { IBranch } from '../interfaces';

export const useBranches = () => {
  const getBranchesRepo = useService<IGetBranchesRepo>(GetBranchesRepoType);

  return useQuery<IBranch[], Error, IBranch[], 'Branches'>(
    'Branches',
    async () => {
      const result = await getBranchesRepo.execute({ page: 1, per_page: 10000 });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { staleTime: 1000 * 60 * 15 },
  );
};
