import React from 'react';
import ReadyTable from '../../components/Table/ReadyTable';
import { useTicketTemplatesTable } from '../../hooks/ticketTemplates/useTable';

const TicketTemplatesTable = () => {
  const { columns } = useTicketTemplatesTable();

  return <ReadyTable name='ticketTemplates' apiUrl='/api/admin/message_templates' columns={columns} />;
};

export default TicketTemplatesTable;
