import React, { FC } from 'react';

import { useBonazConversionsTable } from '../../hooks/bonaz/useBonazConversionsTable';
import ReadyTable from '../../components/Table/ReadyTable';

const BonazConversionsTable: FC = () => {
  const { columns } = useBonazConversionsTable();

  return <ReadyTable sortable={false} columns={columns} source='bonaz' name='bonaz.conversions' apiUrl='/api/admin/bonaz/conversions' />;
};

export default BonazConversionsTable;
