import { IPartner, IPartnerPersistence } from '../interfaces';

export class PartnerMapper {
  public static toDomain(partner: IPartnerPersistence): IPartner {
    return {
      id: partner.id,
      name: partner.name,
    };
  }
}
