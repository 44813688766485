import React, { FC } from 'react';

import ContentCard from '../../../../containers/Layout/Sidebar/Content/Card';
import Table from '../../../../containers/CustomsDeclarations/Table';
import Actions from '../../../../containers/CustomsDeclarations/Actions';
import { useTitle } from '../../../../hooks/common/useTitle';

const CustomsDeclarationsPage: FC = () => {
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - Bağlamalar`);

  return (
    <ContentCard>
      <Actions />
      <Table />
    </ContentCard>
  );
};

export default React.memo(CustomsDeclarationsPage);
