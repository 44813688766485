import React, { FC } from 'react';

import { useTitle } from '../hooks/common/useTitle';
import ContentCard from '../containers/Layout/Sidebar/Content/Card';
import Table from '../containers/SmsArchive/Table';
import Actions from '../containers/SmsArchive/Actions';

const SmsArchivePage: FC = () => {
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - SMS Arxivi`);

  return (
    <ContentCard>
      <Actions />
      <Table />
    </ContentCard>
  );
};

export default React.memo(SmsArchivePage);
