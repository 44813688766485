import { useCallback, useMemo, useState } from 'react';
import { FormikHelpers } from 'formik';

import { FormOptions } from '../../../shared/modules/form/types';

import { FlightService } from '../services';
import { FlightBulkManifestFormData, FlightBulkManifestResult } from '../types/bulk-manifest';

export const useFlightBulkManifestForm = ({ onSubmitSucceed, params }: FormOptions<{ flightId: string }>) => {
  const [result, setResult] = useState<FlightBulkManifestResult | null>(null);
  const initialValues = useMemo<FlightBulkManifestFormData>(() => ({ flightId: params.flightId, file: null }), [params.flightId]);

  const onSubmit = useCallback(
    async (values: FlightBulkManifestFormData, helpers: FormikHelpers<FlightBulkManifestFormData>) => {
      helpers.setStatus(null);

      const result = await FlightService.BulkManifest.upload(values);

      if (result.status === 200) {
        setResult(result.data);
        helpers.setStatus({ type: 'success' });
        onSubmitSucceed?.();
      } else {
        helpers.setStatus({ type: 'error', message: result.data });
      }
    },
    [onSubmitSucceed],
  );

  return { onSubmit, initialValues, result };
};
