import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../../constants/routes';

export const useTicketTemplatesActions = () => {
  const history = useHistory();
  const goToCreate = useCallback(() => {
    history.push(routes.ticketTemplates.create);
  }, [history]);

  return { goToCreate };
};
