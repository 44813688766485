import React, { useMemo, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Descriptions, Popover, Spin } from 'antd';
import { renderCell } from '../../../core/helpers/render-description-cell';
import { useInfo } from '../../../hooks/common/useInfo';
import { urlFactory } from '../../../core/utils/caller';

const CountCell = ({ cell: { value }, row: { original } }) => {
  const [visible, setVisible] = useState(false);

  const { data, loading } = useInfo(
    useMemo(
      () => ({
        apiUrl: urlFactory('/api/admin/flights/stats', { flight_id: original.id }),
        responseGetter: (response) => ({ sum: parseFloat(response.data.sum), count: response.data.count }),
        initialState: {},
        disabled: !visible,
      }),
      [original.id, visible],
    ),
  );

  return (
    <div className='table-info-cell'>
      <span className='table-info-cell-text'>{value}</span>
      <Popover
        placement='bottom'
        trigger={['hover']}
        onVisibleChange={setVisible}
        content={
          !loading ? (
            <Descriptions size='small' bordered={true} column={1}>
              <Descriptions.Item label='Təhvil verilən'>{renderCell(data?.count, 'ədəd')}</Descriptions.Item>
              <Descriptions.Item label='Ödənilmiş məbləğ'>{renderCell(data?.sum?.toFixed(2), '$')}</Descriptions.Item>
            </Descriptions>
          ) : (
            <Spin />
          )
        }
      >
        <InfoCircleOutlined className='table-info-cell-icon' />
      </Popover>
    </div>
  );
};

export default CountCell;
