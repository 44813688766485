import { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory, generatePath, useLocation } from 'react-router-dom';
import { Button, Dropdown, Menu, message, Modal, Select, Tag } from 'antd';
import { Column } from 'react-table';
import * as Icons from '@ant-design/icons';
import { StopPropagation } from '@core/ui';
import { useService } from '@core/inversify-react';
import { OverCell, tableColumn, tableColumns, TagCell } from '@core/table';
import { filterOption } from '@core/form';

import { useStatusByModelId } from '@modules/status';
import { useProductTypes } from '@modules/product-types';

import { UserCell } from '@modules/users';

import { IRemoveUnknownDeclarationsRepo, RemoveUnknownDeclarationsRepoType } from '../../repos';
import { UnknownDeclarationsTableContext } from '../../context';
import { DeclarationStatusTag } from '../../components';
import { IUnknownDeclaration } from '../../interfaces';
import { declarationKeys } from '../../utils';

export const useUnknownDeclarationsTableColumns = () => {
  const history = useHistory();
  const location = useLocation();

  const { fetch } = useContext(UnknownDeclarationsTableContext);

  const status = useStatusByModelId('7');
  const productTypes = useProductTypes();

  const removeUnknownDeclarationsRepo = useService<IRemoveUnknownDeclarationsRepo>(RemoveUnknownDeclarationsRepoType);

  const actionsColumn = useMemo<Column<IUnknownDeclaration>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openEdit = useCallback(() => {
          history.push(generatePath('/@next/declarations/unknowns/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const openDocument = useCallback(() => {
          window.open(original.document, '_blank');
        }, [original.document]);

        const remove = useCallback(() => {
          const onOk = async () => {
            const result = await removeUnknownDeclarationsRepo.execute([original.id]);

            if (result.status === 200) {
              fetch();
            } else {
              message.error(result.response);
            }
          };

          Modal.confirm({ title: 'Diqqət', content: 'Bağlamanı silməyə əminsinizmi?', onOk });
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
              Düzəliş et
            </Menu.Item>
            <Menu.Item disabled={!original.document} onClick={openDocument} icon={<Icons.FileOutlined />}>
              Sənəd
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={remove} icon={<Icons.DeleteOutlined />}>
              Sil
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [history, location, removeUnknownDeclarationsRepo, fetch],
  );

  const baseColumns = useMemo<Column<IUnknownDeclaration>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.user?.id,
        id: declarationKeys.user.id,
        Header: 'M. kodu',
      },
      {
        accessor: (row) => row.user?.name,
        id: declarationKeys.user.name,
        Header: 'Müştəri',
        Cell: ({ row: { original } }) => (original.user ? <UserCell id={original.user.id} title={original.user.name} /> : null),
      },
      {
        ...tableColumns.smaller,
        accessor: (row) => row.trackCode,
        id: declarationKeys.trackCode,
        Header: 'İzləmə kodu',
        Cell: ({ cell: { value }, row: { original } }) => <Tag color={!original.read ? 'green' : 'default'}>{value}</Tag>,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.globalTrackCode,
        id: declarationKeys.globalTrackCode,
        Header: 'Q.İ kodu',
        Cell: TagCell,
      },
      {
        ...tableColumn(144, 'px'),
        accessor: (row) => row.status.name,
        id: declarationKeys.status.id,
        Header: 'Status',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {status.data?.map((status) => (
                <Select.Option key={status.id} value={status.id.toString()}>
                  {status.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
        Cell: ({ row: { original } }) => <DeclarationStatusTag id={original.status.id} name={original.status.name} />,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.weight,
        id: declarationKeys.weight,
        Header: 'Çəki',
        Cell: ({ cell: { value } }) => !!value && `${value.toFixed(2)} kq`,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.price,
        id: declarationKeys.price,
        Header: 'Məhsulun qiyməti',
        Cell: ({ cell: { value } }) => !!value && `${value.toFixed(2)} ₺`,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.deliveryPrice,
        id: declarationKeys.deliveryPrice,
        Header: 'Çatdırılma qiyməti',
        Cell: ({ cell: { value } }) => !!value && `${value.toFixed(2)} $`,
      },
      {
        ...tableColumns.smaller,
        accessor: (row) => row.quantity,
        id: declarationKeys.quantity,
        Header: 'Say',
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.type,
        id: declarationKeys.type,
        Header: 'Tərkibi',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              <Select.Option value='1'>Maye</Select.Option>
              <Select.Option value='2'>Digər</Select.Option>
            </Select>
          );
        },
        Cell: ({ cell: { value } }) => (value === 'liquid' ? 'Maye' : 'Digər'),
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.shop,
        id: declarationKeys.shop.name,
        Header: 'Mağaza',
        Cell: OverCell,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.productType?.name,
        id: declarationKeys.productType.id,
        Header: 'Məhsulun tipi',
        Filter: ({ column: { filterValue, setFilter } }) => {
          return (
            <Select showSearch={true} filterOption={filterOption} allowClear={true} style={{ width: '100%' }} onChange={setFilter} value={filterValue}>
              {productTypes.data?.map((productType) => (
                <Select.Option key={productType.id} value={productType.id.toString()}>
                  {productType.name}
                </Select.Option>
              ))}
            </Select>
          );
        },
        Cell: OverCell,
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: declarationKeys.createdAt,
        Header: 'Yaradılma tarixi',
      },
    ],
    [productTypes.data, status.data],
  );

  return useMemo(() => {
    return [actionsColumn, ...baseColumns];
  }, [actionsColumn, baseColumns]);
};
