import { useCallback, useContext, useMemo } from 'react';

import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';

import { usePlanById } from './use-plan-by-id';
import { CreatePlanDto } from '../dtos';
import { CreatePlanMapper } from '../mappers';
import { CreatePlanRepoType, CreatePlanRepo } from '../repos';
import { CreatePlanContext } from '../contexts';

export const useCreatePlan = () => {
  const { id, onSucceed } = useContext(CreatePlanContext);
  const plan = usePlanById(id, { enabled: !!id });

  const createCourierRepo = useService<CreatePlanRepo>(CreatePlanRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<CreatePlanDto | undefined>(() => {
    if (id) {
      return plan.data ? CreatePlanMapper.toDomain(plan.data) : undefined;
    }

    return {
      id: '',
      weightFrom: '',
      weightTo: '',
      price: '',
      description: '',
      unitFrom: '',
      unitTo: '',
      isLiquid: false,
      isSpecial: false,
      categoryId: '',
    };
  }, [plan.data, id]);

  const onSubmit = useCallback(
    async (values: CreatePlanDto, helpers: FormikHelpers<CreatePlanDto>) => {
      helpers.setStatus(null);
      const result = await createCourierRepo.execute(values);

      if (result.status === 200) {
        if (!id) {
          eventBus.publish({ type: '@plans/create/succeed' });
        } else {
          eventBus.publish({ type: '@plans/update/succeed' });
        }

        onSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createCourierRepo, eventBus, id, onSucceed],
  );

  return { onSubmit, initialValues };
};
