import React, { FC } from 'react';
import { Button, Card, Col, Empty, Form, PageHeader, Row, Table, Typography } from 'antd';
import { InjectedFormProps, Field, reduxForm, FieldArray } from 'redux-form';
import { useBulkSmsForm } from '../../hooks/smsArchive/useBulkSmsForm';
import ContentCard from '../Layout/Sidebar/Content/Card';

const { Paragraph } = Typography;

const SmsArchiveBulkForm: FC<InjectedFormProps> = (props) => {
  const { handleClose, handleSubmit, fields, values, totalSmsCount, characterRemains, userTable, userResult, smsBalance, smsBalanceRemain } = useBulkSmsForm(props);

  const renderFilters = () => {
    switch (values.search_id) {
      case 'user_names':
        return (
          <Card size='small' hoverable={true} title='Müştərilər'>
            <Field {...fields.users} />
          </Card>
        );
      case 'user_numbers':
        return (
          <Card size='small' hoverable={true} title='Müştəri nömrələri'>
            <FieldArray {...fields.userPhoneNumbers} />
          </Card>
        );
      case 'declarations_state':
        return (
          <Card size='small' hoverable={true} title='Bağlama statusu'>
            <Field {...fields.declarationState} />
            <Field {...fields.customsState} />
          </Card>
        );
      case 'orders_state':
        return (
          <Card size='small' hoverable={true} title='Sifariş statusu'>
            <Field {...fields.orderState} />
          </Card>
        );
      case 'couriers_state':
        return (
          <Card size='small' hoverable={true} title='Kuryer statusu'>
            <Field {...fields.courierState} />
          </Card>
        );
      case 'customs_state':
        return (
          <Card size='small' hoverable={true} title='Bəyan statusu'>
            <Field {...fields.declareState} />
          </Card>
        );
      case 'user_limit':
        return (
          <Card size='small' hoverable={true} title='Cari ay limiti'>
            <Field {...fields.monthlyLimitFieldProps} />
          </Card>
        );
      case 'flight':
        return (
          <Card size='small' hoverable={true} title='Flight'>
            <Field {...fields.flights} />
          </Card>
        );
      default:
        return null;
    }
  };

  return (
    <ContentCard>
      <PageHeader onBack={handleClose} className='base-page-header' title='Toplu SMS göndər' />
      <div style={{ padding: 8 }}>
        <Row gutter={[16, 0]}>
          <Col span={24} sm={{ span: 12 }}>
            <Form layout='vertical'>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Card size='small' hoverable={true} title='Axtarış tipi'>
                    <Field {...fields.searchType} />
                  </Card>
                </Col>
                <Col span={24}>{renderFilters()}</Col>
                <Col span={24}>
                  <Card size='small' hoverable={true} title='Mətn'>
                    <Field {...fields.body} />
                    <div className='textarea-additional'>
                      {values.body?.length || 0} simvol istifadə edilib ({totalSmsCount}-ci sms-in bitməsinə {characterRemains} simvol qalıb)
                    </div>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card size='small' hoverable={true} title='Planlanmış vaxt'>
                    <Field {...fields.plannedAt} />
                  </Card>
                </Col>
              </Row>
              <Button disabled={props.submitting} loading={props.submitting} onClick={handleSubmit} block={true} type='primary'>
                Göndər
              </Button>
            </Form>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Table style={{ marginBottom: 16 }} locale={{ emptyText: <Empty description='Müştəri tapılmadı' /> }} {...userTable} />
            <Card size='small'>
              <Paragraph type='secondary' className='bottom-margin-free'>
                Göndəriləcək sms sayı: {typeof userResult.data === 'number' ? userResult.data : userResult.data.length}
              </Paragraph>
              <Paragraph type='secondary' className='bottom-margin-free'>
                SMS balansı: {smsBalance.data}
              </Paragraph>
              <Paragraph type='secondary' className='bottom-margin-free'>
                Qalıq balans: {smsBalanceRemain > 0 ? smsBalanceRemain : 0}
              </Paragraph>
              <Paragraph type='secondary' className='bottom-margin-free'>
                Göndəriləcək: {values.planned_at ? `${values.planned_at}-dan(dən) başlayaraq` : 'Planlanmış vaxt seçilməyib'}
              </Paragraph>
              {smsBalanceRemain < 0 && (
                <Paragraph type='danger' className='bottom-margin-free'>
                  Diqqət: Sms balansınız yetərsizdir. Bütün mesajlar göndərilməyəcək.
                </Paragraph>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </ContentCard>
  );
};

export default reduxForm({ form: 'bulkSms' })(SmsArchiveBulkForm);
