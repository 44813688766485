import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Input, Select, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment, { Moment } from 'moment';

import { tableSetFiltersAction } from '../../actions/table';
import { Constants } from '../../next/shared/constants';
import { tableSelector } from '../../selectors/table';

const { RangePicker } = DatePicker;

const CustomsDeclarationsActions = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector(tableSelector('customs.carriersPosts'));
  const [dateRange, setDateRange] = useState<[Moment, Moment] | null>([moment().startOf('day'), moment().endOf('day')]);
  const [documentNumber, setDocumentNumber] = useState(filters.find((filter) => filter.id === 'documentNumber')?.value || '');
  const [trackingNumber, setTrackingNumber] = useState('');
  const [status, setStatus] = useState<string | undefined>();

  const handleSearch = useCallback(() => {
    const filters: any[] = [];

    if (dateRange) {
      const [dateFrom, dateTo] = dateRange;

      filters.push({ id: 'dateFrom', value: dateFrom.format(Constants.DATE_TIME) });
      filters.push({ id: 'dateTo', value: dateTo.format(Constants.DATE_TIME) });
    }

    if (documentNumber) {
      filters.push({ id: 'documentNumber', value: documentNumber });
    }

    if (trackingNumber) {
      filters.push({ id: 'trackingNumber', value: trackingNumber });
    }

    if (status) {
      filters.push({ id: 'status', value: status });
    }

    dispatch(tableSetFiltersAction('customs.carriersPosts', filters));
  }, [dateRange, dispatch, documentNumber, status, trackingNumber]);

  useEffect(() => {}, []);

  return (
    <div className='actions-wrapper'>
      <div className='actions-wrapper-left'>
        <Space size={8}>
          <RangePicker value={dateRange} allowClear={false} onChange={(value) => setDateRange(value as any)} />
          <Input placeholder='FİN kod' value={documentNumber} style={{ width: 100 }} onChange={(e) => setDocumentNumber(e.target.value)} />
          <Input placeholder='İzləmə kodu' value={trackingNumber} style={{ width: 150 }} onChange={(e) => setTrackingNumber(e.target.value)} />
          <Select placeholder='Status' value={status} allowClear={true} style={{ minWidth: 200 }} onChange={(value) => setStatus(value)}>
            <Select.Option value='0'>Gömrükdə</Select.Option>
            <Select.Option value='1'>Bəyan edilmiş</Select.Option>
            <Select.Option value='3'>Qutuya əlavə edilmiş</Select.Option>
            <Select.Option value='4'>Depesh göndərilmiş</Select.Option>
          </Select>
          <Button onClick={handleSearch} type='primary' icon={<SearchOutlined />}>
            Axtar
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default CustomsDeclarationsActions;
