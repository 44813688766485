import React, { useCallback, useMemo, useState } from 'react';
import { Card, DatePicker, Spin, Table } from 'antd';
import moment, { Moment } from 'moment';
import { urlFactory } from '../../../core/utils/caller';
import { useInfo } from '../../../hooks/common/useInfo';
import { CommonConstants } from '../../../constants/common';
import { NavLink } from 'react-router-dom';
import { makePath } from '../../../core/utils/makePath';
import routes from '../../../constants/routes';

const { RangePicker } = DatePicker;

const SpecialDeclarationsStatistics = () => {
  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment());
  const date = useMemo<[Moment, Moment]>(() => [startDate, endDate], [startDate, endDate]);
  const useInfoOptions = useMemo(
    () => ({
      apiUrl: urlFactory('/api/admin/stats/declaration', { start_date: startDate.format(CommonConstants.DATE), end_date: endDate.format(CommonConstants.DATE) }),
      responseGetter: (response) => response.data,
    }),
    [startDate, endDate],
  );
  const { data: rawData, loading } = useInfo(useInfoOptions);

  const data = useMemo(() => {
    return rawData
      ? [
          {
            key: 0,
            paymentStateId: '0',
            paymentStateName: 'Balansdan',
            totalAmount: rawData['0'],
          },
          {
            key: 1,
            paymentStateId: '1',
            paymentStateName: 'Yerində',
            totalAmount: rawData['1'],
          },
        ]
      : [];
  }, [rawData]);

  const handleChangeDate = useCallback((value) => {
    setStartDate((value || [])[0] || moment().startOf('month'));
    setEndDate((value || [])[1] || moment());
  }, []);

  const columns = useMemo(
    () => [
      {
        dataIndex: 'paymentStateName',
        key: 'paymentStateName',
        title: 'Ödənilmə statusu',
      },
      {
        dataIndex: 'totalAmount',
        key: 'totalAmount',
        title: 'Ümumi məbləğ (USD)',
        render: (value, row) => {
          return (
            <NavLink
              to={makePath(
                routes.dashboard.special.declarations.details,
                {},
                {
                  start_date: startDate.format(CommonConstants.DATE),
                  end_date: endDate.format(CommonConstants.DATE),
                  pay: row.paymentStateId,
                },
              )}
            >
              {value}
            </NavLink>
          );
        },
      },
    ],
    [startDate, endDate],
  );

  return (
    <Card className='dashboard-special-card' size='small' title='Bağlamalar'>
      <Spin spinning={loading}>
        <RangePicker value={date} onChange={handleChangeDate} />
        <Table className='dashboard-special-card-table' pagination={false} bordered={true} dataSource={data} columns={columns} />
      </Spin>
    </Card>
  );
};

export default SpecialDeclarationsStatistics;
