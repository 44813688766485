import React, { CSSProperties, useMemo } from 'react';
import { Tooltip } from 'antd';

export const OverflowCell = ({ cell: { value } }) => {
  const style = useMemo<CSSProperties>(
    () => ({
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    [],
  );

  return (
    <Tooltip mouseLeaveDelay={0} mouseEnterDelay={0} placement='topRight' title={value}>
      <div style={style}>{value}</div>
    </Tooltip>
  );
};
