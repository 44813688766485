import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { tableSelector } from '../../../../selectors/table';
import { tableSetFiltersAction } from '../../../../actions/table';

export const useTicketsFilterMenu = () => {
  const dispatch = useDispatch();
  const state = useSelector(tableSelector('tickets'));

  const selectedKeys = useMemo(() => {
    const foundState = state.filters.find(({ id }) => id === 'state_id');
    const foundRead = state.filters.find(({ id }) => id === 'is_new_admin');

    if (!state.filters.length) {
      return ['all'];
    }

    if (foundRead) {
      return [!foundRead.value ? 'read' : 'unread'];
    }

    if (foundState) {
      return [`stateId.${foundState.value}`];
    }

    return [];
  }, [state.filters]);

  const handleChange = useCallback(
    ({ key }) => {
      switch (key) {
        case 'all':
          dispatch(tableSetFiltersAction('tickets', []));
          break;
        case 'read':
          dispatch(tableSetFiltersAction('tickets', [{ id: 'is_new_admin', value: false }]));
          break;
        case 'unread':
          dispatch(tableSetFiltersAction('tickets', [{ id: 'is_new_admin', value: true }]));
          break;
      }

      if (/stateId/.test(key)) {
        const [, value] = key.split('.');
        dispatch(tableSetFiltersAction('tickets', [{ id: 'state_id', value }]));
      }
    },
    [dispatch],
  );

  return { handleChange, selectedKeys };
};
