import { IAuthUser, IAuthUserPersistence } from '@modules/auth/interfaces';

export class AuthUserMapper {
  public static toDomain(authUser: IAuthUserPersistence): IAuthUser {
    return {
      id: authUser.data.id,
      firstName: authUser.data.name,
      lastName: authUser.data.surname,
      email: authUser.data.email,
      adminBranchId: authUser.data.admin_branch_id,
      adminBranchName: authUser.data.admin_branch_name,
      role: (() => {
        switch (authUser.data.admin) {
          case 1:
            return 'admin';
          case 2:
            return 'warehouseman';
          case 3:
            return 'back_office';
          default:
            return 'client';
        }
      })(),
      permissions: authUser.permissions.map((permission) => permission.code_name),
    };
  }
}
