import React, { FC, useCallback, useMemo } from 'react';
import { DatePicker } from 'antd';
import { CommonConstants } from '../../../../../constants/common';
import moment from 'moment';

const { RangePicker } = DatePicker;

const DateFilter: FC<any> = ({ column: { filterValue, setFilter } }) => {
  const value = useMemo(() => {
    if (filterValue) {
      return filterValue.split(',').map((date) => moment(date, CommonConstants.DATE_TIME));
    }
  }, [filterValue]);

  const handleChange = useCallback(
    (value) => {
      if (value instanceof Array) {
        setFilter(value.map((date) => moment(date, CommonConstants.DATE_TIME).format(CommonConstants.DATE_TIME)).join(','));
      } else {
        setFilter(undefined);
      }
    },
    [setFilter],
  );

  return <RangePicker showTime={true} onChange={handleChange} value={value} format={CommonConstants.DATE_TIME} />;
};

export default DateFilter;
