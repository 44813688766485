import React, { FC, useCallback, useMemo } from 'react';
import { Select } from 'antd';

export const StateFilter: FC<any> = ({ column: { filterValue, setFilter } }) => {
  const handleChange = useCallback(
    (value) => {
      setFilter(value !== '-1' ? value : undefined);
    },
    [setFilter],
  );
  const value = useMemo(() => (filterValue || -1).toString(), [filterValue]);

  const selectProps = useMemo(
    () => ({
      onChange: handleChange,
      value,
      className: 'next-table-filter',
    }),
    [handleChange, value],
  );

  return (
    <Select {...selectProps}>
      <Select.Option value='-1'>Bütün</Select.Option>
      <Select.Option value='5'>Öncədən bəyan</Select.Option>
      <Select.Option value='7'>Xarici anbarda</Select.Option>
      <Select.Option value='8'>Yolda</Select.Option>
      <Select.Option value='9'>Yerli anbarda</Select.Option>
      <Select.Option value='10'>Təhvil verilib</Select.Option>
    </Select>
  );
};
