import React, { FC, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Card, Col, Descriptions, Modal, PageHeader, Result, Row, Skeleton, Tooltip, Typography } from 'antd';
import { useInfo } from '../../hooks/common/useInfo';
import { urlFactory } from '../../core/utils/caller';
import { EditOutlined } from '@ant-design/icons';
import { DeleteOutlined, LinkOutlined } from '@ant-design/icons/lib';
import { useDispatch } from 'react-redux';
import { removeAction } from '../../actions/notificationTemplates';
import { useClose } from '../../hooks/common';
import routes from '../../constants/routes';
import { makePath } from '../../core/utils/makePath';
import { object2Search } from '../../core/utils/object-to-search';

const NotificationTemplatesInfo: FC<RouteComponentProps<any>> = ({ match: { params }, history }) => {
  const dispatch = useDispatch();
  const close = useClose(routes.notificationTemplates.index);

  const { loading, data, error } = useInfo(
    useMemo(
      () => ({
        apiUrl: urlFactory('/api/admin/templates/info', { template_id: params.id }),
        responseGetter: (response) => response.data,
        initialState: {},
      }),
      [params.id],
    ),
  );

  const renderCell = useCallback((node) => {
    if (node) {
      return node;
    }

    return 'Qeyd olunmayıb';
  }, []);

  const gotoEdit = useCallback(() => {
    const url = makePath(routes.notificationTemplates.edit, { id: data.id });
    const params = object2Search({
      next: makePath(routes.notificationTemplates.info, { id: data.id }),
    });
    history.push(url + params);
  }, [data.id, history]);

  const handleRemove = useCallback(async () => {
    await removeAction(data.id)(dispatch)
      .then(close)
      .catch(() => {});
  }, [dispatch, data.id, close]);

  return (
    <Modal maskTransitionName='' transitionName='' width={992} visible={true} footer={null} closable={false} onCancel={close}>
      {!loading && !error && (
        <PageHeader
          title={`#${data.id} nömrəli şablon`}
          subTitle={data.title}
          avatar={{
            children: <LinkOutlined />,
          }}
          extra={[
            <Tooltip key='edit-button' title='Düzəliş et'>
              <Button type='link' icon={<EditOutlined />} onClick={gotoEdit} />
            </Tooltip>,
            <Tooltip key='delete-button' title='Sil'>
              <Button type='link' danger={true} icon={<DeleteOutlined />} onClick={handleRemove} />
            </Tooltip>,
          ]}
          breadcrumb={{
            routes: [
              { path: '/', breadcrumbName: 'Ana səhifə' },
              { path: routes.notificationTemplates.index, breadcrumbName: 'Bildiriş şablonları' },
              { path: makePath(routes.notificationTemplates.info, { id: params.id }), breadcrumbName: '#' + params.id },
            ],
          }}
        >
          <Card bodyStyle={{ padding: 0 }} style={{ marginBottom: 24 }} title='Ümumi' size='small'>
            <Descriptions column={{ sm: 2, xs: 1 }} colon={true} bordered={true}>
              <Descriptions.Item label='Kod'>{renderCell(data.id)}</Descriptions.Item>
              <Descriptions.Item label='Ad'>{renderCell(data.name)}</Descriptions.Item>
              <Descriptions.Item label='Başlıq'>{renderCell(data.title)}</Descriptions.Item>
              <Descriptions.Item label='Tip'>{renderCell(data.template_type_name)}</Descriptions.Item>
              <Descriptions.Item label='Vəziyyət'>{data.active ? 'İşləkdir' : 'Qeyri işləkdir'}</Descriptions.Item>
              <Descriptions.Item label='Yaradılma vaxtı'>{renderCell(data.created_at)}</Descriptions.Item>
              <Descriptions.Item label='Model'>{renderCell(data.model_name)}</Descriptions.Item>
              <Descriptions.Item label='Status'>{renderCell(data.state_name)}</Descriptions.Item>
              <Descriptions.Item label='Tarif'>{renderCell(data.tariff_category_name)}</Descriptions.Item>
              <Descriptions.Item label='Gecikmə'>{renderCell(data.delay)} saniyə</Descriptions.Item>
            </Descriptions>
          </Card>
          <Card size='small' title='Məzmun'>
            <Typography.Paragraph>
              <div dangerouslySetInnerHTML={{ __html: data.body }} />
            </Typography.Paragraph>
          </Card>
        </PageHeader>
      )}
      {!loading && error && (
        <Result
          status='warning'
          title='Məlumat tapılmadı'
          extra={
            <Button type='primary' onClick={close}>
              Geri qayıt
            </Button>
          }
        />
      )}
      {loading && (
        <div className='info-skeleton-wrapper'>
          <Skeleton active={true} title={false} paragraph={{ rows: 1 }} loading={true} />
          <Skeleton active={true} avatar={true} paragraph={false} loading={true} />
          <Row gutter={[32, 0]}>
            <Col span={24} sm={{ span: 12 }}>
              <Skeleton active={true} paragraph={{ rows: 3 }} loading={true} />
            </Col>
            <Col span={24} sm={{ span: 12 }}>
              <Skeleton active={true} paragraph={{ rows: 3 }} loading={true} />
            </Col>
          </Row>
          <Skeleton active={true} paragraph={{ rows: 2 }} loading={true} />
        </div>
      )}
    </Modal>
  );
};

export default NotificationTemplatesInfo;
