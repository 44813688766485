import React, { FC } from 'react';
import ContentCard from '../../containers/Layout/Sidebar/Content/Card';
import BonazOffersTable from '../../containers/Bonaz/OffersTable';

const BonazOffersPage: FC = () => {
  return (
    <ContentCard>
      <BonazOffersTable />
    </ContentCard>
  );
};

export default BonazOffersPage;
