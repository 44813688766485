import { IBranch, IBranchById, IBranchByIdPersistence, IBranchPersistence } from '../interfaces';

export class BranchMapper {
  public static toDomain(item: IBranchPersistence): IBranch {
    return {
      branchName: item.branch_name,
      descr: item.descr,
      id: item.id,
      isBranch: !!item.is_branch,
      name: item.name,
      parentId: item.parent_id,
      workingHours: item.workinghours,
    };
  }

  public static toDomainById(item: IBranchByIdPersistence): IBranchById {
    return {
      branchName: item.branch_name,
      descr: item.descr,
      id: item.id,
      isBranch: !!item.is_branch,
      name: item.name,
      parentId: item.parent_id,
      workingHours: item.workinghours,
      address: item.address,
      email: item.email,
      mapAddress: item.map_address,
      phone: item.phone,
    };
  }
}
