import React, { FC } from 'react';
import { Card, Progress, Typography } from 'antd';
import classNames from 'classnames';

const { Paragraph, Title } = Typography;

interface IDashboardProgressProps {
  title: string;
  subTitle?: string;
  bottomText?: string;
  progress: number;
  value: number;
  valuePrefix?: any;
  bottomValue?: number;
  className?: string;
}

export const DashboardProgress: FC<IDashboardProgressProps> = React.memo(({ title, subTitle, bottomText, progress, value, bottomValue, valuePrefix, className }) => {
  return (
    <Card className={classNames('dashboard-progress-card', className)} size='small'>
      <div className='dashboard-progress-card-text'>
        <div>
          <Paragraph className='dashboard-progress-card-text-paragraph' strong={true}>
            {title}
          </Paragraph>
          <Paragraph className='dashboard-progress-card-text-paragraph'>{subTitle}</Paragraph>
        </div>
        <div>
          <Title className='dashboard-progress-card-text-title'>
            {value}
            {valuePrefix}
          </Title>
        </div>
      </div>
      <Progress showInfo={false} percent={progress} />
      <div className='dashboard-progress-card-text-last'>
        <div>
          <Paragraph className='dashboard-progress-card-text-paragraph'>{bottomText}</Paragraph>
        </div>
        <div>
          <Paragraph className='dashboard-progress-card-text-paragraph'>{bottomValue}</Paragraph>
        </div>
      </div>
    </Card>
  );
});
