import React, { FC, useCallback, useMemo } from 'react';
import { Select } from 'antd';

export const StateFilter: FC<any> = ({ column: { filterValue, setFilter } }) => {
  const handleChange = useCallback(
    (value) => {
      setFilter(value !== '-1' ? value : undefined);
    },
    [setFilter],
  );
  const value = useMemo(() => (filterValue || -1).toString(), [filterValue]);

  const selectProps = useMemo(
    () => ({
      onChange: handleChange,
      value,
      className: 'next-table-filter',
    }),
    [handleChange, value],
  );

  return (
    <Select {...selectProps}>
      <Select.Option value='-1'>Bütün</Select.Option>
      <Select.Option value='11'>Kuryer sifariş verildi</Select.Option>
      <Select.Option value='12'>Sifariş hazırlanır</Select.Option>
      <Select.Option value='13'>Kuryerə təhvil verildi</Select.Option>
      <Select.Option value='14'>Bağlama təhvil verildi</Select.Option>
    </Select>
  );
};
