import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { BaseDeclarationTable, PostDeclarationsActionBar } from '../containers';
import { DeclarationTableContext } from '../context';
import { usePostDeclarationTableContext } from '../hooks';

export const PostDeclarationsPage = () => {
  const { handleFetch, defaultState } = usePostDeclarationTableContext();

  return (
    <TableProvider name='post-declarations' context={DeclarationTableContext} defaultState={defaultState} onFetch={handleFetch}>
      <PageContent $contain>
        <PostDeclarationsActionBar />
        <BaseDeclarationTable context={DeclarationTableContext} />
      </PageContent>
    </TableProvider>
  );
};
