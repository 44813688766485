import { isOfType } from 'typesafe-actions';
import { concat, from, Observable, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { ActionsObservable, Epic } from 'redux-observable';

import { caller, urlFactory } from '../core/utils/caller';
import { bannersFetchFailedAction, bannersFetchStartedAction, bannersFetchSucceedAction } from '../actions/banners';
import { IAppState } from '../interfaces/app-state.interface';
import { BannersActionTypes } from '../constants/banners';
import { IBannersActions } from '../interfaces/banners/actions';

const fetchData = async () => {
  const response = await caller(urlFactory('/api/admin/sliders'));

  if (response.ok) {
    const { data } = await response.json();

    return bannersFetchSucceedAction(data);
  } else {
    throw new Error('Unexpected error');
  }
};

export const bannersFetchDataEpic: Epic<IBannersActions, IBannersActions, IAppState> = (action$: ActionsObservable<IBannersActions>): Observable<any> => {
  return action$.pipe(
    filter(isOfType(BannersActionTypes.FETCH)),
    switchMap(() => {
      return concat(of(bannersFetchStartedAction()), from(fetchData()).pipe(catchError(() => of(bannersFetchFailedAction('Məlumatlar əldə edilə bilmədi')))));
    }),
  );
};
