import React, { FC, useCallback, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { Form, Modal, Spin } from 'antd';

import { useFormFields } from '../../hooks/faq/useForm';
import { useClose } from '../../hooks/common';
import { urlFactory } from '../../core/utils/caller';
import { useForm } from '../../hooks/common/useForm';
import routes from '../../constants/routes';
import { faqFetchAction } from '../../actions/faq';

type IProps = InjectedFormProps<any> & RouteComponentProps<any> & { dispatch: Dispatch };

const FAQForm: FC<IProps> = ({ handleSubmit: baseHandleSubmit, initialize, match: { params }, dispatch, submitting, initialized }) => {
  const handleClose = useClose(routes.faq.index);

  const handleSubmitSuccess = useCallback(() => {
    dispatch(faqFetchAction());
  }, [dispatch]);

  const formOptions = useMemo(
    () => ({
      handleClose,
      handleSubmit: baseHandleSubmit,
      initialize,
      createUrl: routes.faq.create,
      createApiUrl: urlFactory('/api/admin/faq/create'),
      editApiUrl: urlFactory('/api/admin/faq/edit', { faq_id: params.id }),
      onSuccess: handleSubmitSuccess,
      initializeUrl: urlFactory('/api/admin/faq/getinfo', { faq_id: params.id }),
    }),
    [params.id, handleSubmitSuccess, baseHandleSubmit, initialize, handleClose],
  );

  const { handleSubmit, type } = useForm(formOptions);

  const title = useMemo(() => {
    return type === 'create' ? 'Yeni sual əlavə et' : 'Sualda düzəliş et';
  }, [type]);

  const { questionFieldProps, answerFieldProps, sortFieldProps } = useFormFields();

  return (
    <Modal maskTransitionName='' transitionName='' width={592} visible={true} onCancel={handleClose} confirmLoading={submitting} onOk={handleSubmit} title={title}>
      <Spin spinning={type === 'edit' && !initialized}>
        <Form layout='vertical'>
          <Field {...questionFieldProps} />
          <Field {...answerFieldProps} />
          <Field {...sortFieldProps} />
        </Form>
      </Spin>
    </Modal>
  );
};

export default reduxForm<any, any>({ form: 'faq' })(React.memo<IProps>(FAQForm));
