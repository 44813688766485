import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Descriptions, Popover, Row, Statistic, Skeleton, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { WhatsAppOutlined, WarningOutlined, CheckCircleOutlined, OrderedListOutlined, CopyOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { green, orange, red } from '@ant-design/colors';
import { stopPropagation } from '../../../core/helpers/events';
import { caller, urlFactory } from '../../../core/utils/caller';
import { makePath } from '../../../core/utils/makePath';
import routes from '../../../constants/routes';
import { useDispatch } from 'react-redux';
import { tableSetFiltersAction } from '../../../actions/table';
import { convertToAscii } from '../../../next/shared/utils/convertToAscii';
import { useBus } from '@core/event-bus';

export const UserInfoCell = ({ row: { original } }) => {
  const eventBus = useBus();
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    id: 0,
    fullName: '',
    balance: { usd: 0, try: 0 },
    lastMonth: { usd: '0.00', try: '0.00' },
    thisMonth: { usd: '0.00', try: '0.00' },
    credit: { usd: 0, try: 0 },
    declarations: 0,
    orders: 0,
    couriers: 0,
    transactions: { in: 0, out: 0 },
    phone: '',
    passportNumber: '',
    passportSecret: '',
  });

  const fetchData = useCallback(async () => {
    const url = urlFactory('/api/admin/client/user', { user_id: original.user_id || original.id });

    const response = await caller(url);

    if (response.ok) {
      const rawData = await response.json();

      const parsedData = {
        id: rawData.data.id,
        fullName: rawData.data.user_name,
        balance: { try: rawData.widget.balance.try, usd: rawData.widget.balance.usd },
        lastMonth: { try: parseInt(rawData.widget.last.try, 10).toFixed(2), usd: parseInt(rawData.widget.last.usd, 10).toFixed(2) },
        thisMonth: { try: parseInt(rawData.widget.this.try, 10).toFixed(2), usd: parseInt(rawData.widget.this.usd, 10).toFixed(2) },
        credit: { try: rawData.credit, usd: 0 },
        declarations: rawData.data.declaration.all,
        orders: rawData.data.order.all,
        couriers: rawData.data.courier.all,
        transactions: { in: rawData.data.in, out: rawData.data.out },
        phone: rawData.data.number,
        passportNumber: rawData.data.passport_number,
        passportSecret: rawData.data.passport_secret,
      };

      setData(parsedData);
      setLoading(false);
    }
  }, [original.id, original.user_id]);

  const goToWhatsapp = useCallback(() => {
    window.open('https://wa.me/' + data.phone, '_blank');
  }, [data.phone]);

  const goToEdit = useCallback(() => {
    history.push(makePath(routes.users.edit, { id: data.id, type: 'all' }));
  }, [history, data.id]);

  const goToOrders = useCallback(() => {
    eventBus.publish({ type: 'redirected/orders', payload: { filters: [{ id: 'user_id', value: data.id }] } }, true);
    history.push({ pathname: '/@next/orders' });
  }, [eventBus, data.id, history]);

  const goToDeclarations = useCallback(() => {
    eventBus.publish({ type: 'redirected/declarations', payload: { filters: [{ id: 'user_id', value: data.id }] } }, true);
    history.push({ pathname: '/@next/declarations' });
  }, [data.id, eventBus, history]);

  const goToCustomsDeclarations = useCallback(() => {
    dispatch(tableSetFiltersAction('customs.carriersPosts', [{ id: 'documentNumber', value: data.passportSecret }]));
    history.push(routes.declarations.remote.customs);
  }, [dispatch, data.passportSecret, history]);

  const goToCouriers = useCallback(() => {
    dispatch(tableSetFiltersAction('couriers', [{ id: 'user_id', value: data.id }]));
    history.push(routes.couriers.index);
  }, [history, data.id, dispatch]);

  const goToTransactions = useCallback(() => {
    dispatch(tableSetFiltersAction('transactions', [{ id: 'user_id', value: data.id }]));
    history.push(routes.transactions.index);
  }, [history, data.id, dispatch]);

  const goToTickets = useCallback(() => {
    dispatch(tableSetFiltersAction('tickets', [{ id: 'user_id', value: data.id }]));
    history.push(routes.tickets.index);
  }, [history, data.id, dispatch]);

  const goToTicketsForm = useCallback(() => {
    history.push(makePath(routes.tickets.create, {}, { user_id: data.id }));
  }, [history, data.id]);

  const goToDeclarationThisMonth = useCallback(() => {
    history.push(makePath(routes.declarations.thisMonth.index, { userId: data.id }));
  }, [history, data.id]);

  useEffect(() => {
    if (visible) {
      fetchData().catch(() => null);
    }
  }, [visible, fetchData]);

  const copyToClipboardAddress = useCallback(async () => {
    const usernameOnAscii = convertToAscii(data.fullName);
    await navigator.clipboard.writeText(`Tevfikbey Mah. 2 Çeşme Sok. No:14-16 A-B Blok, ${usernameOnAscii}, ${process.env.REACT_APP_COMPANY_PREFIX} ${data.id}.`);
    message.success('Ünvan kopyalandı.');
  }, [data.fullName, data.id]);

  return (
    <div onClick={stopPropagation} style={{ width: '100%' }}>
      <Popover
        onVisibleChange={setVisible}
        visible={visible}
        overlayClassName='user-info-popover'
        placement='right'
        content={
          <Card title={loading ? <Skeleton.Input active={true} /> : `#${data.id} - ${data.fullName}`} size='small' actions={[<EditOutlined onClick={goToEdit} />]} bodyStyle={{ padding: 8 }}>
            {!loading ? (
              <>
                <Card size='small' style={{ marginBottom: 8 }} bodyStyle={{ background: '#fafafa' }}>
                  <Row gutter={[16, 0]}>
                    <Col span={8}>
                      <Card size='small' hoverable={true}>
                        <Statistic title='Balans' value={data.balance.usd} precision={2} prefix='&#36;' />
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card size='small' hoverable={true}>
                        <Statistic title='Balans' value={data.balance.try} precision={2} prefix='&#8378;' />
                      </Card>
                    </Col>
                    <Col span={8}>
                      <Card size='small' hoverable={true}>
                        <Statistic title='Borc' value={data.credit.try} precision={2} valueStyle={{ color: orange[5] }} prefix='&#8378;' />
                      </Card>
                    </Col>
                  </Row>
                </Card>
                <Button.Group>
                  <Button onClick={goToTickets} type='default'>
                    Müraciətlər
                  </Button>
                  <Button onClick={goToTicketsForm} type='default' icon={<PlusOutlined />} />
                </Button.Group>
                <Descriptions size='small' className='user-info-popover-body' bordered={true} column={1}>
                  <Descriptions.Item label='Ünvan'>
                    Tevfikbey Mah. 2 Çeşme Sok. No:14-16 A-B Blok, {data.fullName}, {process.env.REACT_APP_COMPANY_PREFIX} {data.id}. <CopyOutlined onClick={copyToClipboardAddress} />
                  </Descriptions.Item>
                  <Descriptions.Item label='Cari ay'>
                    {data.thisMonth.usd} &#36; - {data.thisMonth.try} &#8378;
                    <span style={{ marginLeft: 4 }}>{parseFloat(data.thisMonth.usd) > 300 ? <WarningOutlined style={{ color: red[5] }} /> : <CheckCircleOutlined style={{ color: green[5] }} />}</span>
                    <span style={{ marginLeft: 4 }}>
                      <Button size='small' onClick={goToDeclarationThisMonth} icon={<OrderedListOutlined />}>
                        Bağlamalar
                      </Button>
                    </span>
                  </Descriptions.Item>
                  <Descriptions.Item label='Son 30 gün'>
                    {data.lastMonth.usd} &#36; - {data.lastMonth.try} &#8378;
                  </Descriptions.Item>
                  <Descriptions.Item label='Mobil nömrə'>
                    {data.phone}
                    <Button size='small' style={{ height: 22, width: 22 }} onClick={goToWhatsapp} type='link' icon={<WhatsAppOutlined />} />
                  </Descriptions.Item>
                  <Descriptions.Item label='Ş/V Nömrəsi'>{data.passportNumber}</Descriptions.Item>
                </Descriptions>
                <div className='user-info-popover-footer'>
                  <Button onClick={goToOrders} type='link'>
                    Sifarişlər({data.orders})
                  </Button>
                  <Button onClick={goToDeclarations} type='link'>
                    Bağlamalar({data.declarations})
                  </Button>
                  <Button onClick={goToCouriers} type='link'>
                    Kuryerlər({data.couriers})
                  </Button>
                  <Button onClick={goToTransactions} type='link'>
                    Balans əməliyyatları({data.transactions.in}/{data.transactions.out})
                  </Button>
                  <Button onClick={goToCustomsDeclarations} type='link'>
                    DGK məlumat
                  </Button>
                </div>
              </>
            ) : (
              <div style={{ width: 480 }}>
                <Skeleton.Input active={true} />
                <Skeleton.Input active={true} />
                <Skeleton.Input active={true} />
                <Skeleton round={true} loading={true} active={true} avatar={false} title={true} paragraph={{ rows: 4 }} />
                <div className='info-skeleton-button-wrapper no-margin'>
                  <Skeleton.Button active={true} className='info-skeleton-button' />
                  <Skeleton.Button active={true} className='info-skeleton-button' />
                  <Skeleton.Button active={true} className='info-skeleton-button' />
                  <Skeleton.Button active={true} className='info-skeleton-button' />
                </div>
              </div>
            )}
          </Card>
        }
      >
        <Button block={true} className='cell-button'>
          {original.user_name || original.name + ' ' + original.surname}
        </Button>
      </Popover>
    </div>
  );
};
