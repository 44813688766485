import { useMemo } from 'react';

import { InputField } from '../../../components/ReduxForm/Input';
import { positiveIntNormalizer } from '../../../core/helpers/normalizers';
import { TextAreaField } from '../../../components/ReduxForm/TextArea';

export const useFormFields = () => {
  const questionFieldProps = useMemo(
    () => ({
      name: 'question',
      item: { label: 'Sual' },
      field: { placeholder: 'Sualı daxil edin' },
      component: InputField,
    }),
    [],
  );

  const answerFieldProps = useMemo(
    () => ({
      name: 'answer',
      item: { label: 'Cavab' },
      field: { placeholder: 'Cavabı daxil edin', rows: 4 },
      component: TextAreaField,
    }),
    [],
  );

  const sortFieldProps = useMemo(
    () => ({
      name: 'sort',
      item: { label: 'Sıra' },
      field: { placeholder: 'Sıranı daxil edin' },
      component: InputField,
      normalizer: positiveIntNormalizer,
    }),
    [],
  );

  return useMemo(
    () => ({
      questionFieldProps,
      answerFieldProps,
      sortFieldProps,
    }),
    [questionFieldProps, answerFieldProps, sortFieldProps],
  );
};
