import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TableOptions, useFilters, useTable, usePagination, useSortBy, useRowSelect } from 'react-table';
import TableWrapper from './core/Components/TableWrapper';
import TableHead from './core/Components/TableHead';
import TableBody from './core/Components/TableBody';
import DefaultColumnFilter from './core/Components/Filters/DefaultFilter';
import TablePagination from './core/Components/Pagination';
import { Result, Spin } from 'antd';
import { useFlexLayout } from '../../hooks/tables/useFlexLayout';

type INextTableProps = {
  columns: any[];
  data: any[];
  total: number;
  state?: any;
  filterable?: boolean;
  sortable?: boolean;
  pagination?: boolean;
  loading?: boolean;
  error?: string;
  initialized: boolean;
  onChangePage?: Function;
  onChangePageSize?: Function;
  onChangeSort?: Function;
  onChangeFilter?: Function;
  onChangeSelectedRowIds?: Function;
  onFetchData?: Function;
  getRowProps?: Function;
  description?: string;
};

const NextTable: FC<INextTableProps> = ({
  columns,
  data,
  total,
  state: controlledState,
  loading,
  error,
  initialized,
  filterable = true,
  sortable = true,
  pagination = true,
  onChangePage,
  onChangePageSize,
  onChangeSort,
  onChangeFilter,
  onChangeSelectedRowIds,
  onFetchData,
  getRowProps,
  description,
}) => {
  const initialState = useMemo(
    () => ({
      pageIndex: 0,
      pageSize: 20,
      sortBy: [],
      filters: [],
      hiddenColumns: [],
      selectedRowIds: {},
    }),
    [],
  );

  const pageOptions = useMemo(() => ['5', '10', '15', '25', '50', '100'], []);

  const [state, setState] = useState(initialState);
  const finalState = useMemo(() => controlledState || state, [state, controlledState]);
  const finalFilters = useMemo(() => (finalState ? finalState.filters : undefined), [finalState]);
  const finalPageSize = useMemo(() => (finalState ? finalState.pageSize : undefined), [finalState]);
  const finalPageIndex = useMemo(() => (finalState ? finalState.pageIndex : undefined), [finalState]);
  const finalSortBy = useMemo(() => (finalState ? finalState.sortBy : undefined), [finalState]);
  const finalSelectedRowIds = useMemo(() => (finalState ? finalState.selectedRowIds : undefined), [finalState]);
  const gotoPageAction = useCallback((pageIndex = 0) => setTimeout(() => (onChangePage ? onChangePage(pageIndex) : setState({ ...state, pageIndex })), 0), [state, onChangePage]);
  const setPageSizeAction = useCallback((pageSize = 0) => setTimeout(() => (onChangePageSize ? onChangePageSize(pageSize) : setState({ ...state, pageSize })), 0), [state, onChangePageSize]);
  const toggleSortByAction = useCallback((sortBy) => setTimeout(() => (onChangeSort ? onChangeSort(sortBy) : setState({ ...state, sortBy })), 0), [state, onChangeSort]);
  const setFilterAction = useCallback((filters) => setTimeout(() => (onChangeFilter ? onChangeFilter(filters) : setState({ ...state, filters })), 0), [state, onChangeFilter]);

  const setSelectedRowIdsAction = useCallback(
    (selectedRowIds) => {
      setTimeout(() => (onChangeSelectedRowIds ? onChangeSelectedRowIds(selectedRowIds) : setState({ ...state, selectedRowIds })), 0);
    },
    [state, onChangeSelectedRowIds],
  );

  useEffect(() => {
    if (onFetchData && initialized && pagination) {
      onFetchData({});
    }
  }, [finalFilters, finalPageIndex, finalPageSize, finalSortBy, onFetchData, initialized, pagination]);

  useEffect(() => {
    if (onFetchData && initialized && !pagination) {
      onFetchData({});
    }
  }, [finalFilters, finalSortBy, onFetchData, initialized, pagination]);

  const stateReducer = useCallback(
    (state, action) => {
      switch (action.type) {
        case 'setState':
          return { ...action.state };
        case 'resetPage':
          gotoPageAction(action.pageIndex);
          break;
        case 'gotoPage':
          gotoPageAction(action.pageIndex);
          break;
        case 'setPageSize':
          setPageSizeAction(action.pageSize);
          break;
        case 'toggleSortBy':
          toggleSortByAction(state.sortBy);
          break;
        case 'setFilter':
          setFilterAction(state.filters);
          break;
        case 'toggleRowSelected':
          setSelectedRowIdsAction(state.selectedRowIds);
          break;
        case 'resetSelectedRows':
          setSelectedRowIdsAction({});
          break;
        default:
          break;
      }

      return state;
    },
    [gotoPageAction, toggleSortByAction, setFilterAction, setSelectedRowIdsAction, setPageSizeAction],
  );

  const defaultColumn = useMemo<any>(
    () => ({
      Filter: DefaultColumnFilter,
      filterable,
      sortable,
    }),
    [filterable, sortable],
  );

  const handleGetRowId = useCallback((row) => row.id, []);

  const tableOptions = useMemo<TableOptions<any>>(() => {
    return {
      columns,
      data,
      defaultColumn,
      getRowId: handleGetRowId,
      stateReducer: stateReducer,
      useControlledState: () => finalState,
      manualFilters: true,
      manualSortBy: true,
      manualPagination: pagination,
    };
  }, [columns, data, defaultColumn, handleGetRowId, finalState, stateReducer, pagination]);

  const tablePlugins = useMemo(() => [useFilters, useSortBy, usePagination, useRowSelect, useFlexLayout], []);

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page, dispatch, gotoPage, setPageSize }: any = useTable(tableOptions, ...tablePlugins);

  useEffect(() => {
    dispatch({ type: 'setState', state: finalState });
  }, [finalState, dispatch]);

  return (
    <TableWrapper
      getTableProps={getTableProps}
      paginationArea={
        <TablePagination
          description={description}
          total={total}
          pageIndex={finalState.pageIndex}
          pageOptions={pageOptions}
          pageSize={finalState.pageSize}
          gotoPage={gotoPage}
          setPageSize={setPageSize}
        />
      }
    >
      <TableHead filterable={filterable} headerGroups={headerGroups} />
      <TableBody getRowProps={getRowProps} getTableBodyProps={getTableBodyProps} selectedRowIds={finalSelectedRowIds} prepareRow={prepareRow} rows={page} />
      {loading && (
        <div className='next-table-loading-container'>
          <Spin />
        </div>
      )}
      {!loading && error && (
        <div className='next-table-loading-container'>
          <Result status='warning' title={error} />
        </div>
      )}
    </TableWrapper>
  );
};

export default React.memo<INextTableProps>(NextTable);
