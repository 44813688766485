import React, { FC, useCallback, useEffect } from 'react';
import ReadyTable from '../../components/Table/ReadyTable';
import { useHistory } from 'react-router-dom';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';
import { useDispatch } from 'react-redux';
import { tableSetFiltersAction } from '../../actions/table';
import { useCouriersTableColumns } from '../../hooks/couriers/useTableColumns';

const CouriersTable: FC<{ defaultFilters?: any[] }> = ({ defaultFilters }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const columns = useCouriersTableColumns();

  const rowProps = useCallback(
    (id) => ({
      onDoubleClick: () => {
        history.push(makePath(routes.couriers.info, { id }));
      },
    }),
    [history],
  );

  useEffect(() => {
    if (defaultFilters) {
      dispatch(tableSetFiltersAction('couriers', defaultFilters));
    }
  }, [defaultFilters, dispatch]);

  return <ReadyTable getRowProps={rowProps} name='couriers' columns={columns} apiUrl='/api/admin/v2/couriers/getlist' />;
};

export default React.memo(CouriersTable);
