import { useMemo } from 'react';

import { InputField } from '../../components/ReduxForm/Input';
import { TextAreaField } from '../../components/ReduxForm/TextArea';

export const useTicketTemplatesFormFields = () => {
  const title = useMemo(
    () => ({
      name: 'title',
      item: { label: 'Başlıq' },
      field: { placeholder: 'Başlıq daxil edin.' },
      component: InputField,
    }),
    [],
  );

  const body = useMemo(
    () => ({
      name: 'body',
      component: TextAreaField,
      item: { label: 'Mətn' },
      field: {
        rows: 4,
        placeholder: 'Bildiriş mətnini daxil edin.',
      },
    }),
    [],
  );

  return { title, body };
};
