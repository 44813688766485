import React, { FC } from 'react';
import { Pagination } from 'antd';

const TablePagination: FC<any> = ({ pageIndex, total, pageSize, pageOptions, setPageSize, gotoPage, description }) => {
  return (
    <Pagination
      current={pageIndex + 1}
      className='next-table-pagination'
      total={total}
      showTotal={() => description}
      pageSize={pageSize}
      pageSizeOptions={pageOptions}
      onChange={(page) => gotoPage(page - 1)}
      onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
      showSizeChanger
      showQuickJumper
    />
  );
};

export default React.memo(TablePagination);
