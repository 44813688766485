import { useCallback, useContext, useMemo, useState } from 'react';
import { Button, Dropdown, Menu, message, Modal } from 'antd';
import { Column } from 'react-table';
import * as Icons from '@ant-design/icons';
import { StopPropagation } from '@core/ui';
import { OverCell, tableColumn, tableColumns } from '@core/table';

import { UserCell } from '@modules/users';
import {
  IRemoveTransactionsRepo,
  ITransaction,
  RemoveTransactionsRepoType,
  transactionKeys,
  TransactionsStatusTag,
  TransactionsTableAmountCell,
  TransactionsTableAmountFilter,
  TransactionsTableContext,
  TransactionsTablePaymentTypeFilter,
  TransactionsTableStatusFilter,
  TransactionsTableTypeCell,
  TransactionsTableTypeFilter,
  useUpdateTransactionsStatus,
} from '@modules/transactions';
import { useService } from '@core/inversify-react';
import { useStatusByModelId } from '@modules/status';

export const useTransactionsTableColumns = () => {
  const { fetch } = useContext(TransactionsTableContext);
  const status = useStatusByModelId('4');
  const updateStatusFactory = useUpdateTransactionsStatus();
  const removeTransactionsRepo = useService<IRemoveTransactionsRepo>(RemoveTransactionsRepoType);

  const actionsColumn = useMemo<Column<ITransaction>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const updateStatus = useCallback(
          (statusId: string) => {
            return updateStatusFactory({ transactionIds: [original.id], statusId });
          },
          [original.id],
        );

        const remove = useCallback(() => {
          const onOk = async () => {
            const result = await removeTransactionsRepo.execute([original.id]);

            if (result.status === 200) {
              fetch();
            } else {
              message.error(result.response);
            }
          };

          Modal.confirm({ title: 'Diqqət', content: 'Tranzaksiyanı silməyə əminsinizmi?', onOk });
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.SubMenu title='Statusu dəyiş' icon={<Icons.AppstoreOutlined />}>
              {status.data?.map((status) => (
                <Menu.Item onClick={() => updateStatus(status.id.toString())} key={status.id}>
                  {status.name}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
            <Menu.Item onClick={remove} icon={<Icons.DeleteOutlined />}>
              Sil
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [fetch, removeTransactionsRepo, status.data, updateStatusFactory],
  );

  const baseColumns = useMemo<Column<ITransaction>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.user.id,
        id: transactionKeys.user.id,
        Header: 'M. kodu',
      },
      {
        accessor: (row) => row.user.name,
        id: transactionKeys.user.name,
        Header: 'Müştəri',
        Cell: ({ row: { original } }) => <UserCell id={original.user.id} title={original.user.name} />,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.object.model.name,
        id: transactionKeys.object.model.name,
        Header: 'Bölmə',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.amount,
        id: transactionKeys.amount.value,
        Header: 'Məbləğ',
        Filter: TransactionsTableAmountFilter,
        Cell: TransactionsTableAmountCell,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.type.id,
        id: transactionKeys.type.id,
        Header: 'Tip',
        Filter: TransactionsTableTypeFilter,
        Cell: TransactionsTableTypeCell,
      },
      {
        ...tableColumns.small,
        accessor: (row) => row.paymentType.name,
        id: transactionKeys.paymentType.id,
        Header: 'Tip',
        Filter: TransactionsTablePaymentTypeFilter,
      },
      {
        ...tableColumn(122, 'px'),
        accessor: (row) => row.status.id,
        id: transactionKeys.status.id,
        Header: 'Status',
        Filter: TransactionsTableStatusFilter,
        Cell: ({ row: { original } }) => <TransactionsStatusTag id={original.status.id} name={original.status.name} />,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.confirmedBy?.name,
        id: transactionKeys.confirmedBy.name,
        Header: 'Təsdiq edən',
        Cell: OverCell,
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.description,
        id: transactionKeys.description,
        Header: 'Açıqlama',
        Cell: OverCell,
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: transactionKeys.createdAt,
        Header: 'Yaradılma tarixi',
      },
      {
        id: transactionKeys.amount.currency,
      },
    ],
    [],
  );

  return useMemo(() => {
    return [actionsColumn, ...baseColumns];
  }, [actionsColumn, baseColumns]);
};
