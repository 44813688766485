import { FormErrors } from '@core/form';

import { CreateOrderDto, CreateOrderDtoPersistence } from '../dtos';
import { IOrder } from '../interfaces';

export class CreateOrderMapper {
  public static toDomain(order: IOrder): CreateOrderDto {
    return {
      id: order.id.toString(),
      userId: order.user.id.toString(),
      product: {
        url: order.product.url,
        shop: order.product.shop,
        typeId: order.product.type?.id.toString() || '',
        color: order.product.color,
        size: order.product.size,
        quantity: order.product.quantity.toString(),
        price: order.product.price.toString(),
        internalShippingPrice: order.product.internalShippingPrice.toString(),
      },
      isUrgent: order.isUrgent,
      description: order.description,
    };
  }

  public static toPersistence(order: CreateOrderDto): CreateOrderDtoPersistence {
    return {
      order_id: order.id,
      user_id: order.userId,
      product_type_id: order.product.typeId,
      url: order.product.url,
      shop_name: order.product.shop,
      size: order.product.size,
      quantity: order.product.quantity,
      price: order.product.price,
      cargo_price: order.product.internalShippingPrice,
      urgent: Number(order.isUrgent).toString(),
      color: order.product.color,
      descr: order.description,
    };
  }
}

export class CreateOrderErrorsMapper {
  public static toDomain(errors): FormErrors<CreateOrderDto> {
    return {
      id: errors.id?.join(', '),
      userId: errors.user_id?.join(', '),
      product: {
        url: errors.url?.join(', '),
        shop: errors.shop_name?.join(', '),
        typeId: errors.product_type_id?.join(', '),
        color: errors.color?.join(', '),
        size: errors.size?.join(', '),
        quantity: errors.quantity?.join(', '),
        price: errors.price?.join(', '),
        internalShippingPrice: errors.cargo_price?.join(', '),
      },
      isUrgent: errors.urgent?.join(', '),
      description: errors.descr?.join(', '),
    };
  }
}
