import flat from 'flat';

export const formDataFlatter = <Input extends object, Output extends object>(object: Input): Output => {
  const flattened = flat(object);

  const connectorsChanged = Object.entries(flattened).reduce((acc, [key, value]) => {
    const finalKey = key.split('.').reduce((acc, val, index) => {
      return acc + (!!index ? '[' + val + ']' : val);
    }, '');
    return { ...acc, [finalKey]: value };
  }, {});

  return Object.entries(connectorsChanged).reduce((acc, [key, value]) => {
    if (value === undefined || value === null || value instanceof Array) {
      return acc;
    }

    const finalEntry = {};

    if (typeof value === 'number') {
      finalEntry[key] = value.toString();
    } else if (typeof value === 'boolean') {
      finalEntry[key] = Number(value).toString();
    } else {
      finalEntry[key] = value;
    }

    return { ...acc, ...finalEntry };
  }, {}) as Output;
};
