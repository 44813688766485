import * as React from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation, generatePath } from 'react-router-dom';
import { message, Modal } from 'antd';
import { useService } from '@core/inversify-react';

import { IRemoveDeclarationsFromFlightRepo, RemoveDeclarationsFromFlightRepoType } from '../repos';
import {
  CancelDeclarationDispatchRepoType,
  GetDeclarationByIdRepoType,
  GetDeclarationCustomsStatusRepoType,
  GetDeclarationOrderUrlRepoType,
  ICancelDeclarationDispatchRepo,
  IGetDeclarationByIdRepo,
  IGetDeclarationCustomsStatusRepo,
  IGetDeclarationOrderIdsRepo,
  IRemoveDeclarationsRepo,
  RemoveDeclarationsRepoType,
} from '../repos';
import { usePrintWaybill } from './use-print-waybill';
import { usePrintProformaInvoice } from './use-print-proforma-invoice';
import { useUpdateDeclarationsStatus } from './use-update-declarations-status';
import { IDeclaration } from '../interfaces';

export const useDeclaration = (id: string) => {
  const location = useLocation();
  const history = useHistory();

  const printProformaInvoiceFactory = usePrintProformaInvoice();
  const printWaybillFactory = usePrintWaybill();
  const updateStatusFactory = useUpdateDeclarationsStatus();

  const getDeclarationByIdRepo = useService<IGetDeclarationByIdRepo>(GetDeclarationByIdRepoType);
  const getDeclarationCustomsStatusRepo = useService<IGetDeclarationCustomsStatusRepo>(GetDeclarationCustomsStatusRepoType);
  const removeDeclarationRepo = useService<IRemoveDeclarationsRepo>(RemoveDeclarationsRepoType);
  const getDeclarationOrderIdsRepo = useService<IGetDeclarationOrderIdsRepo>(GetDeclarationOrderUrlRepoType);
  const cancelDispatchRepo = useService<ICancelDeclarationDispatchRepo>(CancelDeclarationDispatchRepoType);
  const removeDeclarationFromFlightRepo = useService<IRemoveDeclarationsFromFlightRepo>(RemoveDeclarationsFromFlightRepoType);

  const { data, isLoading, error } = useQuery<IDeclaration, string, IDeclaration, string[]>(
    ['declarations', id],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getDeclarationByIdRepo.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw result.response;
      }
    },
    { enabled: !!id },
  );

  const customsStatus = useQuery<number | null, Error>(
    ['declarations', data?.trackCode, 'customs', 'status'],
    async ({ queryKey }) => {
      const [, trackCode] = queryKey;
      const result = await getDeclarationCustomsStatusRepo.execute({ trackCode });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    { enabled: !!data?.trackCode },
  );

  const removeFromFlight = React.useCallback(() => {
    const onOk = async () => {
      const result = await removeDeclarationFromFlightRepo.execute([id]);

      if (result.status === 200) {
        message.success('Bağlama uçuşdan çıxarıldı.');
      } else {
        message.error(result.response);
      }
    };

    Modal.confirm({ title: 'Diqqət', content: 'Bağlamanı uçuşdan silməyə əminsinizmi?', onOk });
  }, [id, removeDeclarationFromFlightRepo]);

  const handover = React.useCallback(() => {
    history.push(generatePath('/@next/declarations/:id/handover', { id }), { background: location });
  }, [history, id, location]);

  const canHandover = React.useMemo<boolean>(() => data?.status.id !== 10, [data?.status.id]);

  const returnDec = React.useCallback(() => {
    history.push(generatePath('/@next/declarations/:id/return', { id }), { background: location });
  }, [history, id, location]);

  const waybillIsDisabled = React.useMemo(() => !(data?.status.id === 8 || data?.status.id === 9), [data?.status.id]);

  const openOrder = React.useCallback(
    (id: string | number) => {
      history.push({ pathname: generatePath('/@next/orders/:id/info', { id }) });
    },
    [history],
  );

  const openEdit = React.useCallback(() => {
    history.push(generatePath('/@next/declarations/:id/update', { id: id }), { background: location });
  }, [history, id, location]);

  const openTimeline = React.useCallback(() => {
    history.push(generatePath('/@next/declarations/:id/timeline', { id: id }), { background: location });
  }, [history, id, location]);

  const printWaybill = React.useCallback(() => {
    return printWaybillFactory({ declarationId: id });
  }, [id, printWaybillFactory]);

  const printProformaInvoice = React.useCallback(() => {
    return printProformaInvoiceFactory({ declarationIds: [id] });
  }, [id, printProformaInvoiceFactory]);

  const remove = React.useCallback(() => {
    const onOk = async () => {
      const result = await removeDeclarationRepo.execute([id]);

      if (result.status === 200) {
        message.success('Bağlama silindi.');
      } else {
        message.error(result.response);
      }
    };

    Modal.confirm({ title: 'Diqqət', content: 'Bağlamanı silməyə əminsinizmi?', onOk });
  }, [id, removeDeclarationRepo]);

  const cancelDispatch = React.useCallback(() => {
    const onOk = async () => {
      const result = await cancelDispatchRepo.execute(id);

      if (result.status === 200) {
        message.success('Depesh ləğv edildi.');
      } else {
        message.error(result.response);
      }
    };

    Modal.confirm({ title: 'Diqqət', content: 'Depeshi ləğv etməyə əminsinizmi?', onOk });
  }, [cancelDispatchRepo, id]);

  const updateStatus = React.useCallback(
    (statusId: string | number) => {
      return updateStatusFactory({ declarationIds: [id], statusId });
    },
    [id, updateStatusFactory],
  );

  const orderUrls = useQuery<string[], Error, string[], string[]>(
    ['declarations', id, 'orders', 'urls'],
    async ({ queryKey }) => {
      const [, id] = queryKey;

      const result = await getDeclarationOrderIdsRepo.execute({ id });

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    {
      initialData: [],
    },
  );

  return {
    data,
    isLoading,
    error,
    returnDec,
    handover,
    canHandover,
    customsStatus,
    waybillIsDisabled,
    openEdit,
    openTimeline,
    openOrder,
    orderUrls,
    updateStatus,
    printWaybill,
    printProformaInvoice,
    remove,
    cancelDispatch,
    removeFromFlight,
  };
};
