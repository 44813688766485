import React from 'react';
import { Card, DatePicker, Table } from 'antd';

import { useDeclarationExecutives } from '../hooks/use-declaration-executives';

const { RangePicker } = DatePicker;

export const DeclarationExecutives = () => {
  const { result, dateRange, handleChangeDateRange, columns } = useDeclarationExecutives();

  return (
    <Card size='small' title='Bağlama daxil edənlər üzrə statistika'>
      <RangePicker allowClear={false} value={dateRange} onChange={handleChangeDateRange} style={{ marginBottom: 12 }} />
      <Table dataSource={result.data} size='small' bordered={true} loading={result.loading} columns={columns} rowKey='id' />
    </Card>
  );
};
