import { IDeclarationExecutive, IDeclarationExecutiveApi } from '../types/declaration-executive';

export class DeclarationExecutiveMappers {
  public static oneFromApi(data: IDeclarationExecutiveApi): IDeclarationExecutive {
    return {
      id: data.id,
      firstname: data.name,
      lastname: data.surname,
      role: data.admin === 0 ? 'user' : data.admin === 1 ? 'admin' : 'warehouseman',
      count: data.count,
    };
  }

  public static manyFromApi(data: IDeclarationExecutiveApi[]): IDeclarationExecutive[] {
    return data.map((item) => DeclarationExecutiveMappers.oneFromApi(item));
  }
}
