import { caller, urlFactory } from '../../../../core/utils/caller';
import { UnexpectedError, ValidationMessage } from '../../../../core/helpers/errors';
import { message } from 'antd';
import { handlebars } from 'hbs';
import { Dispatch } from 'redux';
import { tableFetchDataAction } from '../../../../actions/table';
import { formDataBuilder } from '../../../../core/utils/form-data-builder';

// @ts-ignore
import manifestTemplate from '../../../../hbs/declarations/manifest.hbs';
// @ts-ignore
import proformaTemplate from '../../../../hbs/declarations/proforma.hbs';
import { tableFilterParser } from '../../../../core/helpers/table-filter-parser';

export const changeReadAction = (objectId: number[], read: boolean) => async (dispatch: Dispatch) => {
  const messageKey = 'declarations-read-change-message';
  const values = { model_id: 2, object_id: objectId, is_new: read };

  const onSuccess = (success) => {
    message.success({
      content: 'Bağlamanın oxunma statusu dəyişdirildi',
      key: messageKey,
    });
    dispatch(tableFetchDataAction('declarations', '/api/admin/v2/declaration/list'));
    return success;
  };

  const onError = (error) => {
    message.error({
      content: 'Bağlamanın oxunma statusu dəyişdirilə bilmədi',
      key: messageKey,
    });

    return error;
  };

  try {
    message.loading({ content: 'Əməliyyat aparılır', key: messageKey });

    const url = urlFactory('/api/admin/isnew');
    const body = formDataBuilder({ values });
    const response = await caller(url, { body, method: 'POST' });

    if (response.ok) {
      return onSuccess(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};

export const removeAction = (objectId: (number | string)[]) => async (dispatch: Dispatch) => {
  const messageKey = 'declarations-remove-message';
  const values = { declaration_id: objectId };

  const onSuccess = (success) => {
    message.success({ content: 'Bağlama(lar) silindi', key: messageKey });
    dispatch(tableFetchDataAction('declarations', '/api/admin/v2/declaration/list'));
    return success;
  };

  const onUnprocessableEntity = async (response) => {
    message.error({ content: 'Bağalama(lar) silinə bilmədi', key: messageKey });

    const { errors } = await response.json();

    Object.values(errors as Record<string, string[]>).forEach((error) => {
      message.error({ content: error[0] });
    });

    return response;
  };

  const onError = (error) => {
    message.error({ content: 'Bağalama(lar) silinə bilmədi', key: messageKey });
    return error;
  };

  message.loading({ content: 'Əməliyyat aparılır', key: messageKey });
  try {
    const url = urlFactory('/api/admin/declaration/cancel');
    const body = formDataBuilder({ values });

    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      return onSuccess(response);
    } else if (response.status === 422) {
      return onUnprocessableEntity(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};

export const printWaybill = async ({ declarationId, flightId, partnerId }: { declarationId?: number; flightId?: number; partnerId?: number }) => {
  const messageKey = 'waybill-print-message';
  const values = { declaration_id: declarationId, flight_id: flightId, partner_id: partnerId };
  const url = urlFactory('/api/admin/declaration/manifesto', values);

  message.loading({ content: 'Yol vərəqəsi hazırlanır', key: messageKey });

  try {
    const dataResponse = await caller(url);

    if (dataResponse.ok) {
      const templateResponse = await fetch(manifestTemplate);

      const data = await dataResponse.json();
      const parsedData = data.map(({ product_price_try, product_price_usd, delivery_price, total_price, ...rest }) => ({
        ...rest,
        product_price_try: Number(product_price_try).toFixed(2),
        product_price_usd: Number(product_price_usd).toFixed(2),
        delivery_price: Number(delivery_price).toFixed(2),
        total_price: Number(total_price).toFixed(2),
      }));

      if (templateResponse.ok) {
        message.success({ content: 'Yol vərəqəsi çapa hazırlanır', key: messageKey });
        const template = handlebars.compile(await templateResponse.text());

        const html = template(parsedData);

        const printWindow = window.open();

        if (printWindow) {
          printWindow.document.write(html);
          printWindow.document.close();
        }
      } else {
        UnexpectedError();
      }
    } else {
      UnexpectedError();
    }
  } catch (e) {
    message.error({ content: 'Yol vərəqəsi hazırlana bilmədi', key: messageKey });
  }
};

export const printProforma = async (declarationId: (number | string)[]) => {
  const messageKey = 'proforma-print-message';
  const values = { declaration_id: declarationId };
  const url = urlFactory('/api/admin/declaration/proforma', values);

  message.loading({ content: 'Proforma invosyu hazırlanır', key: messageKey });

  try {
    const dataResponse = await caller(url);

    if (dataResponse.ok) {
      const templateResponse = await fetch(proformaTemplate);

      const data = await dataResponse.json();

      if (templateResponse.ok) {
        message.success({ content: 'Proforma invoysu çapa hazırlanır', key: messageKey });
        const template = handlebars.compile(await templateResponse.text());

        const html = template(data);

        const printWindow = window.open();

        if (printWindow) {
          printWindow.document.write(html);
          printWindow.document.close();
        }
      } else {
        UnexpectedError();
      }
    } else if (dataResponse.status === 422) {
      message.destroy();
      const data = await dataResponse.json();

      Object.values(data.errors as Record<string, string[]>).forEach((error) => {
        message.error(error[0]);
      });
    } else {
      UnexpectedError();
    }
  } catch (e) {
    message.error({ content: 'Proforma invoysu hazırlana bilmədi', key: messageKey });
  }
};

export const changeStateAction = (objectId: (number | string)[], stateId: number) => async (dispatch: Dispatch) => {
  const messageKey = 'declarations-state-change-message';
  const values = { declaration_id: objectId, state_id: stateId };

  try {
    message.loading({ content: 'Əməliyyat aparılır', key: messageKey });

    const url = urlFactory('/api/admin/declaration/edit/state');
    const body = formDataBuilder({ values });

    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      message.success({
        content: 'Status dəyişdirildi',
        key: messageKey,
      });
      dispatch(tableFetchDataAction('declarations', '/api/admin/v2/declaration/list'));
    } else if (response.status === 422) {
      const { errors } = await response.json();

      ValidationMessage(errors);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    message.error({
      content: 'Status dəyişdirilə bilmədi',
      key: messageKey,
    });
  }
};

export const changeStateBulkAction = (filters: { id: string | number; value: any }[], stateId: number) => async (dispatch: Dispatch) => {
  const messageKey = 'declarations-state-change-message';
  const values = { ...tableFilterParser(filters), new_state_id: stateId };

  try {
    message.loading({ content: 'Əməliyyat aparılır', key: messageKey });

    const url = urlFactory('/api/admin/v2/declaration/edit/state');
    const body = formDataBuilder({ values });

    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      message.success({
        content: 'Status dəyişdirildi',
        key: messageKey,
      });
      dispatch(tableFetchDataAction('declarations', '/api/admin/v2/declaration/list'));
    } else if (response.status === 422) {
      const { errors } = await response.json();

      ValidationMessage(errors);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    message.error({
      content: 'Status dəyişdirilə bilmədi',
      key: messageKey,
    });
  }
};

export const exportAction = async (filters, type: string = 'export') => {
  try {
    const url = urlFactory(`/api/admin/declaration/${type}`, tableFilterParser(filters));
    message.loading({ content: 'Sənəd hazırlanır', key: 'export_message' });
    const response = await caller(url);

    const blob = await response.blob();
    message.success({ content: 'Sənəd yükləməyə hazırdır', key: 'export_message' });

    const objectURL = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = objectURL;
    a.download = `declarations_export_${Math.round(Math.random() * 1000)}.xls`;
    a.click();
  } catch (e) {
    message.error({ content: 'Məlumatlar əldə edilə bilmədi', key: 'export_message' });
  }
};

export const validateAction = (values) => {
  const errors: Record<string, any> = {};

  if (values.is_special && !values.tariff_category_id) {
    errors['tariff_category_id'] = 'Tarif mütləqdir';
  }

  return errors;
};

export const getCombinedDeclarationsAction = async (ids: (number | string)[]) => {
  const url = urlFactory('/api/admin/declaration/combine', { combined_id: ids });

  try {
    const response = await caller(url);

    if (response.ok) {
      const { data } = await response.json();
      return { success: true, data };
    } else if (response.status === 400 || response.status === 404) {
      const { errors } = await response.json();
      return { success: false, errors, error: 'Məlumatlar yalnış daxil edilib.' };
    } else {
      return { success: false, error: 'Məlumatlar əldə edilə bilmədi.' };
    }
  } catch (e) {
    return { success: false, error: 'Şəbəkə ilə əlaqə qurula bilmədi' };
  }
};

export const getDeclarationOrders = async (id: number) => {
  const url = urlFactory('/api/admin/declaration/orders', { declaration_id: id });

  try {
    const response = await caller(url);

    if (response.ok) {
      const { data } = await response.json();

      return data;
    } else {
      return [];
    }
  } catch (e) {
    return [];
  }
};
