import { IBannersFetch, IBannersFetchFailedAction, IBannersFetchStartedAction, IBannersFetchSucceedAction } from '../interfaces/banners/actions';
import { BannersActionTypes } from '../constants/banners';
import { IBanner } from '../interfaces/banners/model';
import { Dispatch } from 'redux';
import { message } from 'antd';
import { caller, urlFactory } from '../core/utils/caller';
import { formDataBuilder } from '../core/utils/form-data-builder';
import { UnexpectedError } from '../core/helpers/errors';

export const bannersFetchAction = (): IBannersFetch => ({
  type: BannersActionTypes.FETCH,
});

export const bannersFetchStartedAction = (): IBannersFetchStartedAction => ({
  type: BannersActionTypes.FETCH_STARTED,
});

export const bannersFetchSucceedAction = (payload: IBanner[]): IBannersFetchSucceedAction => ({
  type: BannersActionTypes.FETCH_SUCCEED,
  payload,
});

export const bannersFetchFailedAction = (payload?: string): IBannersFetchFailedAction => ({
  type: BannersActionTypes.FETCH_FAILED,
  payload,
});

export const activateBannersAction = (objectId: number[], active: boolean) => async (dispatch: Dispatch) => {
  const messageKey = 'banner-active-message';
  const values = { banner_id: objectId, active };

  const onSuccess = (success) => {
    message.success({ content: 'Əməliyyat müvəffəqiyyətlə başa çatdı', key: messageKey });
    dispatch(bannersFetchAction());
    return success;
  };

  const onError = (error) => {
    message.error({ content: 'Əməliyyat aparıla bilmədi', key: messageKey });
    return error;
  };

  message.loading({ content: 'Əməliyyat aparılır', key: messageKey });
  try {
    const url = urlFactory('/api/admin/sliders/active');
    const body = formDataBuilder({ values });

    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      return onSuccess(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};

export const removeBannersAction = (objectId: number[]) => async (dispatch: Dispatch) => {
  const messageKey = 'banner-remove-message';
  const values = { banner_id: objectId };

  const onSuccess = (success) => {
    dispatch(bannersFetchAction());
    return success;
  };

  const onError = (error) => {
    message.error({ content: 'Banner(lər) silinə bilmədi', key: messageKey });
    return error;
  };

  message.loading({ content: 'Əməliyyat aparılır', key: messageKey });
  try {
    const url = urlFactory('/api/admin/sliders/cancel');
    const body = formDataBuilder({ values });

    const response = await caller(url, { method: 'POST', body });

    if (response.ok) {
      return onSuccess(response);
    } else {
      UnexpectedError();
    }
  } catch (e) {
    throw onError(e);
  }
};
