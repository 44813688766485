import { useService } from '@core/inversify-react';
import { AcceptDeclarationRepoType, GetDeclarationByTrackCodeRepoType, IAcceptDeclarationRepo, IGetDeclarationByTrackCodeRepo } from '@modules/declarations';
import { useCallback, useRef, useState } from 'react';
import { Input, message } from 'antd';

export const useDeclarationAcceptance = () => {
  const getDeclarationByTrackCode = useService<IGetDeclarationByTrackCodeRepo>(GetDeclarationByTrackCodeRepoType);
  const acceptDeclarationRepo = useService<IAcceptDeclarationRepo>(AcceptDeclarationRepoType);

  const trackCodeInputRef = useRef<Input | null>(null);
  const [tempTrackCode, setTempTrackCode] = useState<string>('');
  const [trackCode, setTrackCode] = useState<string>('');
  const [autoAccept, setAutoAccept] = useState<boolean>(false);

  const handleReset = useCallback(() => {
    setTrackCode('');
    setTempTrackCode('');
    trackCodeInputRef.current?.focus();
  }, []);

  const handleTrackCodeChange = useCallback((event) => {
    setTempTrackCode(event.target.value);
  }, []);

  const handleAutoAcceptChange = useCallback((event) => {
    setAutoAccept(event.target.checked);
  }, []);

  const handleSearch = useCallback(
    async (value) => {
      if (!autoAccept) {
        setTrackCode(value);
        return;
      }

      const declarationResult = await getDeclarationByTrackCode.execute({ trackCode: value });
      console.log(declarationResult.response);

      if (declarationResult.status !== 200) {
        message.error(declarationResult.response);
        return;
      }
      if (!declarationResult.response.isYourBranch) {
        message.error('Bağlama sizin filiala aid deyil');
        return;
      }
      const acceptDeclarationResult = await acceptDeclarationRepo.execute({
        id: declarationResult.response.id.toString(),
        wardrobeNumber: declarationResult.response.wardrobeNumber,
        description: declarationResult.response.description || '',
        updateStatus: true,
      });

      if (acceptDeclarationResult.status !== 200) {
        message.error(acceptDeclarationResult.response);
        return;
      }

      message.success('Bağlama qəbul olundu.');
      handleReset();
    },
    [acceptDeclarationRepo, autoAccept, getDeclarationByTrackCode, handleReset],
  );

  return { trackCode, tempTrackCode, trackCodeInputRef, handleTrackCodeChange, handleSearch, handleReset, autoAccept, handleAutoAcceptChange };
};
