import * as React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { SuspenseSpin } from '@core/ui';

import { useAuth } from '@modules/auth';
import { AppLayout } from '@modules/layout';

// Auth Router
const AuthRouter = React.lazy(() => import('@modules/auth'));

// Declaration Routers
const DeclarationRouter = React.lazy(() => import('@modules/declarations'));
const DeclarationModalsRouter = React.lazy(() => import('@modules/declarations/router/declaration-modals.router'));

// Orders Routers
const OrdersRouter = React.lazy(() => import('@modules/orders/router/orders.router'));
const OrdersModalRouter = React.lazy(() => import('@modules/orders/router/orders-modal.router'));

// Statistics Routers
const StatisticsRouter = React.lazy(() => import('@modules/statistics/router/statistics.router'));
const StatisticsModalRouter = React.lazy(() => import('@modules/statistics/router/statistics-modal.router'));

// Flights Routers
const FlightsRouter = React.lazy(() => import('@modules/flights/router/flights.router'));
const FlightsModalRouter = React.lazy(() => import('@modules/flights/router/flights.modal-router'));

// Appointment Routers
const AppointmentRouter = React.lazy(() => import('@modules/appointment/router/appointment.router'));

// Warehouse Routers
const WarehouseRouter = React.lazy(() => import('@modules/warehouse/router/warehouse.router'));

// Customs Routers
const CustomsRouter = React.lazy(() => import('@modules/customs/router/customs.router'));
const CustomsModalRouter = React.lazy(() => import('@modules/customs/router/customs.modal-router'));

// Branches Routers
const BranchesRouter = React.lazy(() => import('@modules/branches/router/branches.router'));
const BranchesModalRouter = React.lazy(() => import('@modules/branches/router/branch-modal.router'));

// Transactions Routers
const TransactionsRouter = React.lazy(() => import('@modules/transactions/router/transactions.router'));
const TransactionsModalsRouter = React.lazy(() => import('@modules/transactions/router/transactions-modals.router'));

// Users Routers
const UsersRouter = React.lazy(() => import('@modules/users/router/users.router'));
const UsersModalsRouter = React.lazy(() => import('@modules/users/router/users-modal.router'));

// Couriers Routers
const CouriersRouter = React.lazy(() => import('@modules/couriers/router/couriers.router'));
const CouriersModalsRouter = React.lazy(() => import('@modules/couriers/router/couriers-modal.router'));

//Boxes Routers
const CashRegistersRouter = React.lazy(() => import('@modules/cash-flow/cash-flow/router/cash-flow.router'));
const CashRegistersModalsRouter = React.lazy(() => import('@modules/cash-flow/cash-flow/router/cash-flow.modal-router'));

//Shop Names Routers
const ShopNamesModalRouter = React.lazy(() => import('@modules/shop-names/router/shop-names.modal-router'));

// Supports Routers
const SupportsRouter = React.lazy(() => import('@modules/supports/router/supports.router'));
const SupportsModalRouter = React.lazy(() => import('@modules/supports/router/supports.modal-router'));

// Notifier Routers
const NotifierRouter = React.lazy(() => import('@modules/notifier/router/notifier.router'));
const NotifierModalRouter = React.lazy(() => import('@modules/notifier/router/notifier.modal-router'));

// Return Types Routers
const ReturnTypesRouter = React.lazy(() => import('@modules/return-types/router/return-types.router'));
const ReturnTypesModalRouter = React.lazy(() => import('@modules/return-types/router/return-type-modal.router'));

// Status Routers
const StatusRouter = React.lazy(() => import('@modules/status/router/status.router'));
const StatusModalRouter = React.lazy(() => import('@modules/status/router/status-modal.router'));

// Regions Routers
const RegionsRouter = React.lazy(() => import('@modules/regions/router/regions.router'));
const RegionModalRouter = React.lazy(() => import('@modules/regions/router/region-modal.router'));
// Models Routers
const ModelsRouter = React.lazy(() => import('@modules/models/router/models.router'));
// Shops Routers
const ShopsRouter = React.lazy(() => import('@modules/shops/router/shops.router'));
const ShopsModalRouter = React.lazy(() => import('@modules/shops/router/shops-modal.router'));

// ProductTypes Routers
const ProductTypesRouter = React.lazy(() => import('@modules/product-types/router/product-types.router'));
const ProductTypesModalRouter = React.lazy(() => import('@modules/product-types/router/product-types-modal.router'));

// Plans Routers
const PlansRouter = React.lazy(() => import('@modules/plans/router/plans.router'));
const PlanModalRouter = React.lazy(() => import('@modules/plans/router/plan-modal.router'));

// Popups Routers
const PopupsRouter = React.lazy(() => import('@modules/popups/router/popups.router'));
const PopupsModalRouter = React.lazy(() => import('@modules/popups/router/popups-modal.router'));

// Settings Routers
const SettingsRouter = React.lazy(() => import('@modules/settings/router/settings.router'));

// Refunds Routers
const RefundsRouter = React.lazy(() => import('@modules/refunds/router/refunds.router'));
const RefundsModalRouter = React.lazy(() => import('@modules/refunds/router/refund-modal.router'));

// Coupons Routers
const CouponsRouter = React.lazy(() => import('@modules/coupons/router/coupons.router'));
const CouponModalRouter = React.lazy(() => import('@modules/coupons/router/coupon-modal.router'));

export const NextRouter = () => {
  const location = useLocation<any>();
  const { user, auth, can } = useAuth();

  if (auth.loading || user.loading) {
    return <SuspenseSpin />;
  }

  return (
    <React.Suspense fallback={<SuspenseSpin />}>
      {!!user.data ? (
        <AppLayout>
          <React.Suspense fallback={<SuspenseSpin />}>
            <Switch location={location.state?.background || location}>
              <Route path='/@next/declarations' component={DeclarationRouter} />
              <Route path='/@next/orders' component={OrdersRouter} />
              <Route path='/@next/flights' component={FlightsRouter} />
              <Route path='/@next/customs' component={CustomsRouter} />
              <Route path='/@next/transactions' component={TransactionsRouter} />
              <Route path='/@next/users' component={UsersRouter} />
              <Route path='/@next/couriers' component={CouriersRouter} />
              <Route path='/@next/statistics' component={StatisticsRouter} />
              <Route path='/@next/supports' component={SupportsRouter} />
              <Route path='/@next/notifier' component={NotifierRouter} />
              <Route path='/@next/status' component={StatusRouter} />
              <Route path='/@next/branches' component={BranchesRouter} />
              <Route path='/@next/regions' component={RegionsRouter} />
              <Route path='/@next/return-types' component={ReturnTypesRouter} />
              <Route path='/@next/models' component={ModelsRouter} />
              <Route path='/@next/notifier' component={NotifierRouter} />
              <Route path='/@next/shops' component={ShopsRouter} />
              <Route path='/@next/product-types' component={ProductTypesRouter} />
              <Route path='/@next/plans' component={PlansRouter} />
              <Route path='/@next/popups' component={PopupsRouter} />
              <Route path='/@next/coupons' component={CouponsRouter} />
              <Route path='/@next/settings' component={SettingsRouter} />
              <Route path='/@next/refunds' component={RefundsRouter} />
              {can('cashflow') && <Route path='/@next/cash-flow' component={CashRegistersRouter} />}
              {can('client_appointment') && <Route path='/@next/appointment' component={AppointmentRouter} />}
              {can('local_warehouse') && <Route path='/@next/warehouse' component={WarehouseRouter} />}
            </Switch>
          </React.Suspense>
          <Switch location={location.state?.modal || location}>
            <Route path='/@next/orders' component={OrdersModalRouter} />
            <Route path='/@next/declarations' component={DeclarationModalsRouter} />
            {can('stat') && <Route path='/@next/statistics' component={StatisticsModalRouter} />}
            <Route path='/@next/notifier' component={NotifierModalRouter} />
            <Route path='/@next/transactions' component={TransactionsModalsRouter} />
            <Route path='/@next/flights' component={FlightsModalRouter} />
            <Route path='/@next/users' component={UsersModalsRouter} />
            <Route path='/@next/couriers' component={CouriersModalsRouter} />
            <Route path='/@next/supports' component={SupportsModalRouter} />
            <Route path='/@next/customs' component={CustomsModalRouter} />
            <Route path='/@next/cash-flow' component={CashRegistersModalsRouter} />
            <Route path='/@next/return-types' component={ReturnTypesModalRouter} />
            <Route path='/@next/branches' component={BranchesModalRouter} />
            <Route path='/@next/status' component={StatusModalRouter} />
            <Route path='/@next/regions' component={RegionModalRouter} />
            <Route path='/@next/cash-flow' component={CashRegistersModalsRouter} />
            <Route path='/@next/shops' component={ShopsModalRouter} />
            <Route path='/@next/popups' component={PopupsModalRouter} />
            <Route path='/@next/product-types' component={ProductTypesModalRouter} />
            <Route path='/@next/coupons' component={CouponModalRouter} />
            <Route path='/@next/plans' component={PlanModalRouter} /> <Route path='/@next/refunds' component={RefundsModalRouter} />
          </Switch>
          <Switch>
            <Route path='/@next/shop-names/create' component={ShopNamesModalRouter} />
          </Switch>
        </AppLayout>
      ) : (
        <AuthRouter />
      )}
    </React.Suspense>
  );
};
