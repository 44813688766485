import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { appendToFormData, urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { CreateTransactionDto, CreateTransactionErrorMapper, CreateTransactionMapper } from '@modules/transactions';

export type ICreateTransactionRepoResponse = IRepoResponse<200> | IRepoResponse<422, object> | IRepoResponse<400 | 500, string>;

export type ICreateTransactionRepo = IRepo<[CreateTransactionDto], ICreateTransactionRepoResponse>;

export const CreateTransactionRepoType = Symbol.for('CreateTransactionRepo');

@bind(CreateTransactionRepoType)
export class CreateTransactionRepo implements ICreateTransactionRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(dto: CreateTransactionDto) {
    try {
      const body = new FormData();
      const mappedBody = CreateTransactionMapper.toPersistence(dto);

      appendToFormData(mappedBody, body);

      const url = urlMaker('/api/admin/pay/balance');

      const result = await this.httpClient.post({ url, body });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 400 || result.status === 422) {
        if (result.data.errors.not_selected_cashbox) {
          return {
            status: 400 as 400,
            response: result.data.errors.not_selected_cashbox.join(', '),
          };
        }

        if (result.data.errors.not_enough_amount) {
          return {
            status: 400 as 400,
            response: result.data.errors.not_enough_amount.join(', '),
          };
        }

        const errors = CreateTransactionErrorMapper.toDomain(result.data.errors);

        return {
          status: 422 as 422,
          response: errors,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
