import React, { FC } from 'react';

const SidebarHeader: FC = () => {
  return (
    <div className='logo-wrapper'>
      <div className='logo'>
        <img className='logo-wide' src='/logo-light.svg' alt='Buta Express' />
        <img className='logo-compact' src='/logo-compact.svg' alt='Buta Express' />
      </div>
    </div>
  );
};

export default SidebarHeader;
