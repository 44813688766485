import { urlMaker } from '../../../shared/utils/url-maker';
import { appendToFormData } from '../../../shared/utils/append-to-form-data';
import { formDataFlatter } from '../../../shared/utils/form-data-flatter';
import { caller } from '../../../shared/utils/caller';
import { Result } from '../../../shared/utils/result';
import { FlightMappers } from '../mappers';

export class FlightActionsService {
  public static async close({ id, airWaybillNumber, type, packagingLimit }) {
    const url = urlMaker('/api/admin/flights/close', { close: true });
    const body = new FormData();

    const depesh = (() => {
      switch (type) {
        case 'withoutDispatch':
          return 0;
        case 'withDispatch':
          return 1;
        case 'all':
          return 2;
        default:
          return 0;
      }
    })();

    appendToFormData(formDataFlatter({ flight_id: id, airWaybill: airWaybillNumber, depesh, limit: packagingLimit }), body);

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new Result(200, null, null);
      } else if (response.status === 422 || response.status === 400) {
        const { errors } = await response.json();
        return new Result(400, FlightMappers.flightGeneralErrorsFromApi(errors), null);
      } else {
        return new Result(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new Result(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }
}
