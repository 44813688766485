import React, { FC, useCallback, useMemo } from 'react';
import { Button, Checkbox, Divider, Form, Input, message, Space, Typography } from 'antd';
import { Formik } from 'formik';
import { useQuery } from 'react-query';
import { useService } from '@core/inversify-react';
import { Details, PageContent, SuspenseSpin } from '@core/ui';
import { CheckboxField, TextAreaField, TextField } from '@core/form';

import BarcodeScan from '../../../../components/Common/BarcodeScan';

import { AcceptDeclarationRepoType, GetDeclarationByTrackCodeRepoType, IAcceptDeclarationRepo, IGetDeclarationByTrackCodeRepo } from '../repos';
import { AcceptDeclarationDto } from '../dto';
import { IDeclaration } from '../interfaces';
import { useDeclarationAcceptance } from '@modules/declarations';

const DeclarationAccept: FC<{ trackCode: string; onClose: () => void; onReset(): void }> = ({ trackCode, onClose, onReset }) => {
  const getDeclarationByTrackCode = useService<IGetDeclarationByTrackCodeRepo>(GetDeclarationByTrackCodeRepoType);
  const acceptDeclarationRepo = useService<IAcceptDeclarationRepo>(AcceptDeclarationRepoType);

  const declaration = useQuery<IDeclaration, Error, IDeclaration, string[]>(
    ['declaration', 'track_code', trackCode],
    async ({ queryKey }) => {
      const [, , trackCode] = queryKey;
      const result = await getDeclarationByTrackCode.execute({ trackCode });

      if (result.status === 200) {
        if (!result.response.isYourBranch) {
          message.error('Bağlama sizin filiala aid deyil');
          onReset();
          throw new Error('Bağlama sizin filiala aid deyil');
        } else {
          return result.response;
        }
      } else {
        message.error(result.response);
        throw new Error(result.response);
      }
    },
    { enabled: !!trackCode },
  );

  const initialValues = useMemo<AcceptDeclarationDto | undefined>(() => {
    if (declaration.data) {
      return {
        id: declaration.data.id.toString() || '',
        wardrobeNumber: declaration.data.wardrobeNumber || '',
        description: declaration.data.description || '',
        updateStatus: true,
      };
    }
  }, [declaration.data]);

  const onSubmit = useCallback(
    async (values: AcceptDeclarationDto) => {
      const result = await acceptDeclarationRepo.execute(values);

      if (result.status === 200) {
        onClose();
        message.success('Bağlama qəbul edildi.');
      } else {
        message.error(result.response);
      }
    },
    [acceptDeclarationRepo, onClose],
  );

  if (declaration.isLoading) {
    return <SuspenseSpin />;
  }

  if (!initialValues || !declaration.data) {
    return null;
  }

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true}>
        {({ handleSubmit }) => (
          <form style={{ maxWidth: 300, margin: '0 auto 24px auto' }} onSubmit={handleSubmit}>
            <Form layout='vertical' component='div'>
              {!!declaration.data?.relatedWardrobeNumbers.length && (
                <Typography style={{ marginBottom: 18 }}>
                  <b>Əlaqəli şkaflar:</b> {declaration.data.relatedWardrobeNumbers.join(', ')}
                </Typography>
              )}
              <TextField name='wardrobeNumber' input={{ placeholder: 'Şkaf nömrəsini daxil edin', autoFocus: true }} />
              <TextAreaField name='description' input={{ placeholder: 'Qeydinizi daxil edin...' }} />
              <CheckboxField name='updateStatus' input={{ children: 'Status dəyişsin' }} />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Space size={12}>
                  <Button onClick={onClose}>Ləğv et</Button>
                  <Button type='primary'>Qəbul et</Button>
                </Space>
              </div>
            </Form>
          </form>
        )}
      </Formik>
      <Details.Row>
        <Details.Col xs={24} lg={12}>
          <Details.Row>
            <Details.Col xs={24}>
              <Details.Card title='Ümumi məlumat'>
                <Details.Descriptions bordered={true} column={1} size='small' style={{ margin: -1 }}>
                  <Details.Descriptions.Item label='İzləmə kodu'>
                    {process.env.REACT_APP_COMPANY_PREFIX} {declaration.data.trackCode}
                  </Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Qlobal izləmə kodu'>{declaration.data.globalTrackCode}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Məhsul tipi'>{declaration.data.productType.name}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Mağaza'>{declaration.data.shop}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Tərkibi'>{declaration.data.type === 'liquid' ? 'Maye' : 'Digər'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Sənəd'>
                    {declaration.data.file ? (
                      <a href={declaration.data.file} target='_blank' rel='noreferrer noopener'>
                        Sənədə bax
                      </a>
                    ) : (
                      'Mövcud deyil'
                    )}
                  </Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Yaradılma tarixi'>{declaration.data.createdAt}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
            <Details.Col xs={24}>
              <Details.Card title='Anbar məlumatları'>
                <Details.Descriptions bordered={true} column={1} size='small' style={{ margin: -1 }}>
                  <Details.Descriptions.Item label='Səbət'>{declaration.data.basket?.name || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Koli'>{declaration.data.parcel?.id || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Şkaf (Yerli)'>{declaration.data.wardrobeNumber || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
          </Details.Row>
        </Details.Col>
        <Details.Col xs={24} lg={12}>
          <Details.Row>
            <Details.Col xs={24}>
              <Details.Card title='Qiymətlər'>
                <Details.Descriptions bordered={true} column={1} size='small' style={{ margin: -1 }}>
                  <Details.Descriptions.Item label='Məhsulun qiyməti'>{declaration.data.price ? `${declaration.data.price.toFixed(2)}₺` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Çatdırılma qiyməti'>
                    {declaration.data.deliveryPrice ? `${declaration.data.deliveryPrice.toFixed(2)}$` : 'Qeyd olunmayıb'}
                  </Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
            <Details.Col xs={24}>
              <Details.Card title='Ölçülər'>
                <Details.Descriptions bordered={true} column={1} size='small' style={{ margin: -1 }}>
                  <Details.Descriptions.Item label='Çəki'>{declaration.data.weight ? `${declaration.data.weight.toFixed(2)} kq` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='En'>{declaration.data.depth ? `${declaration.data.depth.toFixed(2)} sm` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Uzunluq'>{declaration.data.width ? `${declaration.data.width.toFixed(2)} sm` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Hündürlük'>{declaration.data.height ? `${declaration.data.height.toFixed(2)} sm` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
          </Details.Row>
        </Details.Col>
      </Details.Row>
    </div>
  );
};

export const DeclarationAcceptance = () => {
  const { trackCode, tempTrackCode, trackCodeInputRef, handleTrackCodeChange, handleSearch, handleReset, autoAccept, handleAutoAcceptChange } = useDeclarationAcceptance();

  return (
    <PageContent>
      <div style={{ minWidth: 300, margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <BarcodeScan />
        <Input.Search
          autoFocus={true}
          style={{ marginTop: 24 }}
          ref={trackCodeInputRef}
          value={tempTrackCode}
          onChange={handleTrackCodeChange}
          onSearch={handleSearch}
          placeholder='İzləmə kodunu daxil edin...'
        />
        <Checkbox checked={autoAccept} onChange={handleAutoAcceptChange} style={{ marginTop: 18 }}>
          Avto qəbul
        </Checkbox>
        <Divider />
      </div>
      <DeclarationAccept onReset={handleReset} trackCode={trackCode} onClose={handleReset} />
    </PageContent>
  );
};
