import * as React from 'react';
import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';

import { useAuth } from '@modules/auth';
import { HeaderButton, HeadPortal } from '@modules/layout';

import { useDeclarationsActionBar } from '../hooks';

export const DeclarationsActionBar = () => {
  const { can } = useAuth();

  const {
    totalPrice,
    selection,
    resetSelection,
    selectAll,
    fetch,
    reset,
    updateStatus,
    bulkUpdateStatus,
    combine,
    create,
    handover,
    bulkHandover,
    exportAsExcel,
    remove,
    printProforma,
    openAcceptance,
    openCountsByStatus,
  } = useDeclarationsActionBar();

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir (${totalPrice.usd} / ₼{totalPrice.azn})
    </HeaderButton>
  );

  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <Space size={0}>
          <HeaderButton onClick={create} icon={<Icons.PlusCircleOutlined />}>
            Yeni
          </HeaderButton>
          {!selection.length ? selectAllButton : resetSelectionButton}
          <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
            Yenilə
          </HeaderButton>
          <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
            Sıfırla
          </HeaderButton>
        </Space>
        {!selection.length && (
          <Space size={0}>
            <HeaderButton onClick={openCountsByStatus} icon={<Icons.BarChartOutlined />} />
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item onClick={exportAsExcel} icon={<Icons.FileExcelOutlined />}>
                    Export
                  </Menu.Item>
                  {can('bulkdeclarationhandover') && (
                    <Menu.Item onClick={bulkHandover} icon={<Icons.CheckCircleOutlined />}>
                      Toplu təhvil
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <HeaderButton icon={<Icons.DownCircleOutlined />}>Digər</HeaderButton>
            </Dropdown>
            <HeaderButton onClick={openAcceptance} icon={<Icons.LoginOutlined />}>
              Qəbul
            </HeaderButton>
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item onClick={() => bulkUpdateStatus(9)}>Yerli anbarda</Menu.Item>
                  <Menu.Item onClick={() => bulkUpdateStatus(36)}>Gömrükdə saxlanılıb</Menu.Item>
                </Menu>
              }
            >
              <HeaderButton icon={<Icons.AppstoreOutlined />}>Toplu status dəyiş</HeaderButton>
            </Dropdown>
          </Space>
        )}
        {!!selection.length && (
          <Space size={0}>
            <HeaderButton onClick={combine} disabled={selection.length < 2} icon={<Icons.SwapOutlined />}>
              Birləşdir
            </HeaderButton>
            <HeaderButton onClick={handover} icon={<Icons.CheckCircleOutlined />}>
              Təhvil ver
            </HeaderButton>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={() => updateStatus(9)}>Yerli anbarda</Menu.Item>
                  <Menu.Item onClick={() => updateStatus(36)}>Gömrükdə saxlanılıb</Menu.Item>
                </Menu>
              }
            >
              <HeaderButton icon={<Icons.AppstoreOutlined />}>Status dəyiş</HeaderButton>
            </Dropdown>
            <HeaderButton onClick={printProforma} icon={<Icons.PrinterOutlined />} />
            <HeaderButton onClick={remove} danger={true} icon={<Icons.DeleteOutlined />} />
          </Space>
        )}
      </ActionBar>
    </HeadPortal>
  );
};
