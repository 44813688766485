import { IFAQFetch, IFAQFetchFailedAction, IFAQFetchStartedAction, IFAQFetchSucceedAction } from '../interfaces/faq/actions';
import { FAQActionTypes } from '../constants/faq';
import { IFAQ } from '../interfaces/faq/model';
import { urlMaker } from '../next/shared/utils/url-maker';
import { caller } from '../core/utils/caller';
import { message } from 'antd';

export const faqFetchAction = (): IFAQFetch => ({
  type: FAQActionTypes.FETCH,
});

export const faqFetchStartedAction = (): IFAQFetchStartedAction => ({
  type: FAQActionTypes.FETCH_STARTED,
});

export const faqFetchSucceedAction = (payload: IFAQ[]): IFAQFetchSucceedAction => ({
  type: FAQActionTypes.FETCH_SUCCEED,
  payload,
});

export const faqFetchFailedAction = (payload?: string): IFAQFetchFailedAction => ({
  type: FAQActionTypes.FETCH_FAILED,
  payload,
});

export const faqRemoveAction = (id) => async (dispatch) => {
  const url = urlMaker('/admin/faq/cancel', { faq_id: id });

  try {
    const response = await caller(url, { method: 'POST' });

    if (response.ok) {
      dispatch(faqFetchAction());
    } else {
      message.error('Əməliyyat aparıla bilmədi');
    }
  } catch (e) {
    message.error('Əməliyyat aparıla bilmədi');
  }
};
