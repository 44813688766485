import * as React from 'react';
import { Formik, FormikProps } from 'formik';
import { Row, Col, Button, Select, Modal } from 'antd';
import * as Icons from '@ant-design/icons';
import { SelectField, TextField, CheckboxField, UploadField, TextAreaField, filterOptionStart } from '@core/form';
import { AntForm } from '@core/ui';

import { useProductTypes } from '@modules/product-types';
import { useLimitedUsers } from '@modules/users';
import { usePartners } from '@modules/partners';
import { usePlanTypes } from '@modules/plans';
import { useShops } from '@modules/shops';
import { useAuth } from '@modules/auth';

import { CreateDeclarationDto } from '../dto';
import { useCreateDeclaration } from '../hooks';
import { CreateDeclarationContext } from '../context';
import { useBranches } from '@modules/branches';

const FormikComponent: React.FC<FormikProps<CreateDeclarationDto>> = ({ values, handleSubmit, isSubmitting }) => {
  const { can } = useAuth();
  const { onClose } = React.useContext(CreateDeclarationContext);

  const users = useLimitedUsers();
  const productTypes = useProductTypes();
  const planTypes = usePlanTypes();
  const shops = useShops();
  const partners = usePartners();
  const branches = useBranches();

  const userOptions = React.useMemo(
    () =>
      users.data?.map((user) => (
        <Select.Option key={user.id} value={user.id.toString()}>
          #{user.id} - {user.firstname} {user.lastname}
        </Select.Option>
      )),
    [users.data],
  );
  const branchOptions = React.useMemo(
    () =>
      branches.data?.map((branch) => (
        <Select.Option key={branch.id} value={branch.id.toString()}>
          #{branch.id} - {branch.name}
        </Select.Option>
      )),
    [branches.data],
  );

  const productTypeOptions = React.useMemo(
    () =>
      productTypes.data?.map((productType) => (
        <Select.Option key={productType.id} value={productType.id.toString()}>
          {productType.name}
        </Select.Option>
      )),
    [productTypes.data],
  );

  const shopsOptions = React.useMemo(
    () =>
      shops.data?.map((shop) => (
        <Select.Option key={shop.id} value={shop.name}>
          {shop.name}
        </Select.Option>
      )),
    [shops.data],
  );

  const planTypeOptions = React.useMemo(
    () =>
      planTypes.data?.map((planType) => (
        <Select.Option key={planType.id} value={planType.id.toString()}>
          {planType.name}
        </Select.Option>
      )),
    [planTypes.data],
  );

  const partnerOptions = React.useMemo(
    () =>
      partners.data?.map((partner) => (
        <Select.Option key={partner.id} value={partner.id.toString()}>
          {partner.name}
        </Select.Option>
      )),
    [partners.data],
  );

  return (
    <Modal visible={true} width={768} onCancel={onClose} onOk={() => handleSubmit()} confirmLoading={isSubmitting} title={!values.id ? 'Yeni bağlama yarat' : 'Bağlamada düzəliş et'}>
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 0]}>
          <Col xs={24} md={8}>
            <SelectField name='userId' item={{ label: 'İstifadəçi' }} input={{ placeholder: 'İstifadəçini seçin...', loading: users.isLoading, disabled: users.isLoading }}>
              {userOptions}
            </SelectField>
          </Col>
          <Col xs={24} md={8}>
            <TextField name='globalTrackCode' item={{ label: 'Qlobal izləmə kodu' }} input={{ placeholder: 'Global izləmə kodunu daxil edin...' }} />
          </Col>
          <Col xs={24} md={8}>
            <SelectField name='shop' item={{ label: 'Mağaza' }} input={{ placeholder: 'Mağazanı daxil edin...', filterOption: filterOptionStart }}>
              {shopsOptions}
            </SelectField>
          </Col>
          <Col xs={24} md={9}>
            <SelectField name='productTypeId' item={{ label: 'Məhsulun tipi' }} input={{ placeholder: 'Məhsulun tipini seçin...', loading: productTypes.isLoading, disabled: productTypes.isLoading }}>
              {productTypeOptions}
            </SelectField>
          </Col>
          <Col xs={24} md={5}>
            <TextField name='wardrobeNumber' item={{ label: 'Şkaf nömrəsi' }} input={{ placeholder: 'Şkaf nömrəsini daxil edin...' }} />
          </Col>
          <Col xs={24} md={5}>
            <TextField name='quantity' format='integer' item={{ label: 'Say' }} input={{ placeholder: 'Sayı daxil edin...', suffix: 'ədəd' }} />
          </Col>
          <Col xs={24} md={5}>
            <TextField name='weight' format='decimal' item={{ label: 'Çəki' }} input={{ placeholder: 'Çəkini daxil edin...', suffix: 'kq', disabled: !can('changeweightdeclaration') }} />
          </Col>
          <Col xs={24} md={6}>
            <TextField name='price' format='decimal' item={{ label: 'Məhsulun dəyəri' }} input={{ placeholder: 'Məhsulun dəyərini daxil edin...', suffix: '₺' }} />
          </Col>
          <Col xs={24} md={6}>
            <TextField
              name='deliveryPrice'
              format='decimal'
              item={{ label: 'Çatdırılma qiyməti' }}
              input={{ placeholder: 'Çatdırılma qiymətini daxil edin...', suffix: '$', disabled: !can('changedeliveryprice') }}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectField name='branchId' item={{ label: 'Filial' }} input={{ placeholder: 'Filial seçin...', loading: branches.isLoading, disabled: branches.isLoading }}>
              {branchOptions}
            </SelectField>
          </Col>
          <Col xs={24} md={6}>
            <SelectField name='partnerId' item={{ label: 'Partnyor' }} input={{ placeholder: 'Partnyor seçin...', loading: partners.isLoading, disabled: partners.isLoading }}>
              {partnerOptions}
            </SelectField>
          </Col>
          <Col xs={12} md={6}>
            <CheckboxField name='isLiquid' item={{ className: 'ant-form-item-checkbox' }} input={{ children: 'Tərkibində maye var' }} />
          </Col>
          <Col xs={12} md={6}>
            <CheckboxField name='isSpecial' item={{ className: 'ant-form-item-checkbox' }} input={{ children: 'Xüsusi tarifdir' }} />
          </Col>
          <Col xs={12}>
            <UploadField item={{ label: 'Sənəd yükə' }} name='file'>
              {() => (
                <Button type='primary' icon={<Icons.DownloadOutlined />}>
                  Sənəd yüklə
                </Button>
              )}
            </UploadField>
          </Col>
          <Col xs={24} />
          {values.isSpecial && (
            <Col xs={24} md={8}>
              <SelectField name='planTypeId' item={{ label: 'Tarif' }} input={{ placeholder: 'Tarifi seçin...', loading: planTypes.isLoading, disabled: planTypes.isLoading }}>
                {planTypeOptions}
              </SelectField>
            </Col>
          )}
          <Col xs={24}>
            <TextAreaField name='description' item={{ label: 'Açıqlama' }} input={{ placeholder: 'Açıqlamanı daxil edin' }} />
          </Col>
        </Row>
      </AntForm>
    </Modal>
  );
};

export const CreateDeclaration = () => {
  const { initialValues, onSubmit, initializing } = useCreateDeclaration();

  if (!initialValues || initializing) {
    return null;
  }

  return <Formik initialValues={initialValues} enableReinitialize={true} onSubmit={onSubmit} component={FormikComponent} />;
};
