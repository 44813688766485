import React from 'react';
import { useDeclarationProductTypesStatistics } from '../../hooks/dashboard/useDeclarationProductTypesStatistics';
import { Col, Row, DatePicker, Card, Spin, Space, Select, Tabs, Table } from 'antd';
import { Bar, Pie } from 'react-chartjs-2';

const { TabPane } = Tabs;

const { RangePicker } = DatePicker;

const ProductTypesStatistics = () => {
  const { data, loading, date, stateId, onDateChange, onStateChange, rawData } = useDeclarationProductTypesStatistics(0);

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Card size='small'>
          <Space style={{ marginBottom: 8 }} size={8}>
            <RangePicker size='large' value={date as any} onChange={onDateChange} />
            <Select style={{ minWidth: 200 }} mode='multiple' value={stateId} onChange={onStateChange} placeholder='Status seçin' size='large'>
              <Select.Option value='5'>Öncədən bəyan</Select.Option>
              <Select.Option value='7'>Xarici anbarda</Select.Option>
              <Select.Option value='8'>Yolda</Select.Option>
              <Select.Option value='9'>Yerli anbarda</Select.Option>
              <Select.Option value='10'>Təhvil verilib</Select.Option>
            </Select>
          </Space>
          <Spin spinning={loading}>
            <Tabs defaultActiveKey='bar'>
              <TabPane tab='Sütunlu' key='bar'>
                <Bar options={{ scales: { xAxes: [{ gridLines: { display: false } }] } }} data={data} />
              </TabPane>
              <TabPane tab='Piroq' key='pie'>
                <Pie data={data} />
              </TabPane>
            </Tabs>
          </Spin>
          <div style={{ marginTop: 48 }}>
            <Row gutter={[16, 0]}>
              <Col span={24} sm={{ span: 12 }}>
                <Table size='small' bordered={true} pagination={false} rowKey='product_type_name' dataSource={rawData.filter((_, index) => index < rawData.length / 2)}>
                  <Table.Column key='product_type_name' dataIndex='product_type_name' title='Məhsul tipi' />
                  <Table.Column key='count' dataIndex='count' title='Say' />
                </Table>
              </Col>
              <Col span={24} sm={{ span: 12 }}>
                <Table size='small' bordered={true} pagination={false} rowKey='product_type_name' dataSource={rawData.filter((_, index) => index > rawData.length / 2)}>
                  <Table.Column key='product_type_name' dataIndex='product_type_name' title='Məhsul tipi' />
                  <Table.Column key='count' dataIndex='count' title='Say' />
                </Table>
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default ProductTypesStatistics;
