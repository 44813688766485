export const userQueryKeys = {
  id: 'id',
  fullName: 'user_name',
  firstname: 'name',
  lastname: 'surname',
  email: 'email',
  branch: 'branch_id',
  phoneNumber: 'number',
  balance: {
    usd: 'balance_usd',
    try: 'balance_try',
  },
  discount: 'discount',
  birthDate: 'birth_date',
  gender: 'gender',
  passport: {
    number: 'passport_number',
    secret: 'passport_secret',
  },
  blocked: 'blocked',
  role: 'admin',
  declarations: {
    state: {
      id: 'declaration_state_id',
    },
  },
};
