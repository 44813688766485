import { Alert, Button, Checkbox, Col, Input, Result, Row, Select, Space, Table } from 'antd';
import { PageContent, SuspenseSpin } from '@core/ui';
import { filterOption } from '@core/form';
import * as Icons from '@ant-design/icons';
import * as Colors from '@ant-design/colors';

import BarcodeScan from '../../../../components/Common/BarcodeScan';
import { useDeclarationsBoxAcceptance } from '../hooks';
import { useCallback } from 'react';

export const DeclarationBoxAcceptancePage = () => {
  const {
    boxes,
    myBox,
    tableData,
    onBarcodeSearch,
    onSelectBox,
    onCloseBox,
    barcodeInputRef,
    selectBoxMutation,
    closeBoxMutation,
    canClearBarcodes,
    resetBarcodes,
    removeBarcode,
    closeBoxMutationError,
    type,
    onTypeSwitch,
    transferBoxMutation,
    onTransferBox,
    duplicatedTrackCodes,
  } = useDeclarationsBoxAcceptance();

  const onRow = useCallback(
    (data) => ({
      style: closeBoxMutationError?.failedBarcodeIndexes?.includes(data.id - 1) || duplicatedTrackCodes.includes(data.barcode) ? { backgroundColor: Colors.red[0] } : undefined,
    }),
    [closeBoxMutationError?.failedBarcodeIndexes, duplicatedTrackCodes],
  );

  const title = useCallback(
    () => (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Checkbox onChange={onTypeSwitch} checked={type === 'transfer'}>
          Transfer
        </Checkbox>
        <Space size={8}>
          <Button
            icon={<Icons.CheckCircleOutlined />}
            onClick={type === 'acceptance' ? onCloseBox : onTransferBox}
            loading={type === 'acceptance' ? closeBoxMutation.isLoading : transferBoxMutation.isLoading}
            type='primary'
          >
            Yeşiyi bağla
          </Button>
          <Button icon={<Icons.ClearOutlined />} danger={true} onClick={resetBarcodes} disabled={!canClearBarcodes}>
            Təmizlə
          </Button>
        </Space>
      </div>
    ),
    [canClearBarcodes, closeBoxMutation.isLoading, onCloseBox, onTransferBox, onTypeSwitch, resetBarcodes, transferBoxMutation.isLoading, type],
  );

  const renderRemove = useCallback((value) => <Button onClick={() => removeBarcode(value - 1)} size='small' danger={true} icon={<Icons.DeleteOutlined />} />, [removeBarcode]);

  if (boxes.isLoading || myBox.isLoading) {
    return <SuspenseSpin />;
  }

  if (myBox.data) {
    return (
      <PageContent title={`#${myBox.data.id} - ${myBox.data.name}`}>
        {closeBoxMutationError && <Alert type='error' showIcon={true} message={closeBoxMutationError.message} />}
        {!!duplicatedTrackCodes.length && <Alert type='error' showIcon={true} message={`${duplicatedTrackCodes.length} ədəd təkrar izləmə kodu aşkarlandı`} />}
        <Row>
          <Col xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 1 }}>
            <div style={{ maxWidth: 224, padding: '48px 0', margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <BarcodeScan />
              <Input.Search ref={barcodeInputRef} onSearch={onBarcodeSearch} autoFocus={true} style={{ marginTop: 24 }} placeholder='İzləmə kodunu daxil edin...' />
            </div>
          </Col>
          <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }}>
            <Table onRow={onRow} title={title} rowKey='id' dataSource={tableData} size='small' bordered={true}>
              <Table.Column title='No' key='id' dataIndex='id' width={100} />
              <Table.Column title='Barkod' key='barcode' dataIndex='barcode' />
              <Table.Column key='barcode' dataIndex='id' width={1} render={renderRemove} />
            </Table>
          </Col>
        </Row>
      </PageContent>
    );
  }

  return (
    <PageContent>
      <Result
        icon={<img style={{ width: 224 }} src='https://www.flaticon.com/svg/vstatic/svg/685/685388.svg?token=exp=1619248334~hmac=e42a6e9d9d41029be739ecd933b42960' alt='' />}
        title={
          <Select
            disabled={selectBoxMutation.isLoading}
            loading={selectBoxMutation.isLoading}
            filterOption={filterOption}
            onSelect={onSelectBox}
            showSearch={true}
            style={{ width: 224 }}
            placeholder='Yeşik seçin'
          >
            {boxes.data?.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        }
        subTitle='Bağlamaları qəbul etmək üçün yeşik şeçmək zəruridir.'
      />
    </PageContent>
  );
};
