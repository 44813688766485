import { FC } from 'react';
import { useSelector } from 'react-redux';
import { permissionsSelector } from '../../selectors/common';
import { IAppState } from '../../interfaces/app-state.interface';

export type CanProps = { yes: JSX.Element; no?: JSX.Element | null; role: string };

const Can: FC<CanProps> = ({ yes, no = null, role }) => {
  const permissions = useSelector<IAppState, string[]>(permissionsSelector());

  if (permissions.includes(role)) {
    return yes;
  } else {
    return no;
  }
};

export default Can;
