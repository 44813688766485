import React, { FC } from 'react';

import ContentCard from '../containers/Layout/Sidebar/Content/Card';
import Table from '../containers/Models/Table';
import { useTitle } from '../hooks/common/useTitle';

const ModelsPage: FC = () => {
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - Modellər`);

  return (
    <ContentCard>
      <Table />
    </ContentCard>
  );
};

export default React.memo(ModelsPage);
