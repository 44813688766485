import React, { useCallback, useEffect, useState } from 'react';
import { Button, message, Spin } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { decode } from 'html-entities';

import ContentCard from '../containers/Layout/Sidebar/Content/Card';
import { caller, urlFactory } from '../core/utils/caller';
import { formDataBuilder } from '../core/utils/form-data-builder';
import { UnexpectedError } from '../core/helpers/errors';
import { useTitle } from '../hooks/common/useTitle';

const TransportationConditions = () => {
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - Daşınma şərtləri`);

  const [body, setBody] = useState('');
  const [initializing, setInitializing] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleChange = useCallback((event, editor) => {
    const body = editor.getData();
    setBody(body);
  }, []);

  const initialize = useCallback(async () => {
    const url = urlFactory('/api/client/condition');

    setInitializing(true);

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();

        setBody(decode(data));
      } else {
        UnexpectedError();
      }
    } catch (e) {
      message.error('Məlumatlar əldə edilə bilmədi.');
    } finally {
      setInitializing(false);
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    const messageKey = 'about-submit-message';
    const url = urlFactory('/api/admin/condition/create');
    const values = { body };
    const formData = formDataBuilder({ values });
    message.loading({ content: 'Əməliyyat aparılır', key: messageKey });

    try {
      const response = await caller(url, { method: 'POST', body: formData });

      if (response.ok) {
        message.success({ content: 'Daşınma şərtləri müvəffəqiyyətlə sadda saxlanıldı.', key: messageKey });
      } else {
        UnexpectedError();
      }
    } catch (e) {
      message.error({ content: 'Daşınma şərtləri sadda saxlanıla bilmədi.', key: messageKey });
    } finally {
      setSubmitting(false);
    }
  }, [body]);

  useEffect(() => {
    initialize()
      .then(() => null)
      .catch(() => null);
  }, [initialize]);

  return (
    <ContentCard className='content-card-scrollable'>
      <div className='ck-editor-wrapper'>
        <Spin spinning={initializing}>
          <CKEditor editor={ClassicEditor} data={body} onChange={handleChange} />
        </Spin>
      </div>
      <Button loading={submitting} type='primary' onClick={handleSubmit} block={true}>
        Yadda saxla
      </Button>
    </ContentCard>
  );
};

export default React.memo(TransportationConditions);
