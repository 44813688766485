import { caller, urlFactory } from '../core/utils/caller';
import { formDataBuilder } from '../core/utils/form-data-builder';

export const deleteConfigReturnTypes = async (ids: (number | string)[]) => {
  const url = urlFactory('/api/admin/return_reasons/cancel');
  const body = formDataBuilder({ values: { return_reason_id: ids } });

  try {
    const response = await caller(url, { method: 'POST', body });

    return response.ok;
  } catch (e) {
    return false;
  }
};
