import React, { useCallback, useMemo } from 'react';
import { dateCol, idCol } from '../../core/helpers/table';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';
import { NavLink } from 'react-router-dom';
import { stopPropagation } from '../../core/helpers/events';
import { UserCell } from '@modules/users';

export const useStateArchiveTable = () => {
  const columns = useMemo(
    () => [
      {
        ...idCol,
      },
      {
        accessor: 'user_name',
        Header: 'İcraçı',
        width: 200,
        Cell: ({ row: { original } }) => <UserCell id={original.user_id} title={original.user_name} />,
      },
      {
        accessor: 'model_name',
        Header: 'Model',
      },
      {
        ...idCol,
        accessor: 'object_id',
        Header: 'Obyekt',
        Cell: ({ cell: { value }, row: { original } }) => {
          const findUrl = useCallback(() => {
            switch (original.model_id) {
              case 1:
                return makePath('/@next/orders/:id', { id: original.object_id });
              case 2:
                return makePath('/@next/declarations/:id', { id: original.object_id });
              case 3:
                return makePath(routes.archive.state.couriers.info, { id: original.object_id }, { next: '/archive/state' });
              default:
                return null;
            }
          }, [original.object_id, original.model_id]);

          const foundUrl = findUrl();

          return <span onClick={stopPropagation}>{foundUrl ? <NavLink to={foundUrl}>{value}</NavLink> : <span>{value}</span>}</span>;
        },
      },
      {
        accessor: 'state_name',
        Header: 'Əməliyyat',
        Cell: ({ cell: { value } }) => {
          return <div className='overflow-cell'>Status "{value}" olaraq dəyişdirildi</div>;
        },
      },
      {
        accessor: 'created_at',
        Header: 'Əməliyyat tarixi',
        ...dateCol,
      },
    ],
    [],
  );

  return { columns };
};
