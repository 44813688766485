import React, { FC, useContext, useMemo } from 'react';
import { Formik, FormikProps } from 'formik';
import { Col, Modal, Radio, Row, Select } from 'antd';
import { AntForm } from '@core/ui';
import { DateField, RadioField, SelectField, TextField } from '@core/form';
import { Constants } from '@core/constants';

import { CreateUserContext } from '../contexts';
import { useCreateUser } from '../hooks';
import { CreateUserDto } from '../dtos';
import { useBranches } from '@modules/branches';

const FormikComponent: FC<FormikProps<CreateUserDto>> = ({ handleSubmit, isSubmitting }) => {
  const { id, onCancel } = useContext(CreateUserContext);

  const branches = useBranches();

  const branchOptions = useMemo(() => {
    if (!branches.data) return [];
    return branches.data.map((elem) => (
      <Select.Option key={elem.id} value={elem.id.toString()}>
        #{elem.id} {elem.name}
      </Select.Option>
    ));
  }, [branches.data]);

  return (
    <Modal width={768} visible={true} onOk={() => handleSubmit()} onCancel={onCancel} confirmLoading={isSubmitting} title={!id ? 'Yeni sifariş' : 'Sifarişdə düzəliş et'}>
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 0]}>
          <Col xs={24} md={12}>
            <TextField name='firstname' item={{ label: 'Ad' }} input={{ placeholder: 'Müştərinin adını daxil edin...' }} />
          </Col>
          <Col xs={24} md={12}>
            <TextField name='lastname' item={{ label: 'Soyad' }} input={{ placeholder: 'Müştərinin soyadını daxil edin...' }} />
          </Col>
          <Col xs={24} md={8}>
            <TextField name='email' item={{ label: 'Email' }} input={{ placeholder: 'Emaili daxil edin...' }} />
          </Col>
          <Col xs={24} md={8}>
            <TextField name='phoneNumber' item={{ label: 'Telefon nömrəsi' }} input={{ placeholder: 'Telefon nömrəsini daxil edin...' }} />
          </Col>
          <Col xs={24} md={8}>
            <SelectField name='branchId' item={{ label: 'Filial' }} input={{ placeholder: 'Filial nömrəsini daxil edin...' }}>
              {branchOptions}
            </SelectField>
          </Col>
          <Col xs={24} md={8}>
            <RadioField name='gender' item={{ label: 'Cinsi' }}>
              <Radio value='male'>Kişi</Radio>
              <Radio value='female'>Qadın</Radio>
            </RadioField>
          </Col>
          <Col xs={24}>
            <TextField name='address' item={{ label: 'Ünvan' }} input={{ placeholder: 'Ünvanı daxil edin...' }} />
          </Col>
          <Col xs={24} md={8}>
            <DateField name='birthDate' item={{ label: 'Təvəllüd' }} input={{ placeholder: 'Təvəllüdü daxil edin...' }} />
          </Col>
          <Col xs={24} md={8}>
            <TextField name='passport.number' item={{ label: 'Ş.V nömrəsi' }} input={{ placeholder: 'Ş.V nömrəsini daxil edin...' }} />
          </Col>
          <Col xs={24} md={8}>
            <TextField name='passport.secret' item={{ label: 'FİN kod' }} input={{ placeholder: 'FİN kodu daxil edin...' }} />
          </Col>
          <Col xs={24} md={12}>
            <TextField type='password' name='password' item={{ label: 'Şifrə' }} input={{ placeholder: 'Yeni şifrəni daxil edin...', type: 'password' }} />
          </Col>
          <Col xs={24} md={12}>
            <TextField name='passwordConfirmation' item={{ label: 'Təkrar şifrə' }} input={{ placeholder: 'Yeni şifrəni təkrar daxil edin...', type: 'password' }} />
          </Col>
          <Col xs={24} md={8}>
            <TextField name='discount.percent' item={{ label: 'Endirim' }} input={{ placeholder: 'Endirim faizini daxil edin...', addonAfter: '%' }} />
          </Col>
          <Col xs={24} md={8}>
            <DateField name='discount.dateUntil' item={{ label: 'Son endirim tarixi' }} input={{ placeholder: 'Son endirim tarixini daxil edin...', format: Constants.DATE_TIME, showTime: true }} />
          </Col>
        </Row>
      </AntForm>
    </Modal>
  );
};

export const CreateUser: FC = () => {
  const { initialValues, onSubmit } = useCreateUser();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
