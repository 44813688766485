import { useState } from 'react';

import { useUserAddress, useUserById, useUserRelatedSections } from './';

export const useUserWidget = (id: string) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { data, isLoading } = useUserById(id, { enabled: visible, staleTime: 1000 * 15 });
  const [address, { copyAddress }] = useUserAddress(data);

  const { openDeclarations, openCurrentMonthDeclarations, openOrders, openCustomsDeclarations, openCouriers, openTransactions, openTickets, openTicketsForm, openWhatsapp } = useUserRelatedSections(
    data,
  );

  return {
    visible,
    setVisible,
    data,
    isLoading,
    openWhatsapp,
    openDeclarations,
    openOrders,
    openCustomsDeclarations,
    openCouriers,
    openTransactions,
    openTickets,
    openTicketsForm,
    openCurrentMonthDeclarations,
    copyAddress,
    address,
  };
};
