import React, { FC, useCallback, useMemo } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { RouteComponentProps } from 'react-router-dom';
import { Form, message, Modal } from 'antd';
import { useClose } from '../../hooks/common';
import routes from '../../constants/routes';
import { tableFetchDataAction } from '../../actions/table';
import { Dispatch } from 'redux';
import { urlFactory } from '../../core/utils/caller';
import { useForm } from '../../hooks/common/useForm';
import { DateField } from '../../components/ReduxForm/DateField';

const FlightUpdateThisMonthsForm: FC<InjectedFormProps<any> & RouteComponentProps<any> & { dispatch: Dispatch }> = ({
  handleSubmit: baseHandleSubmit,
  submitting,
  initialize,
  match: { params },
  history,
  dispatch,
}) => {
  const handleClose = useClose(routes.flights.index);

  const handleSubmitSuccess = useCallback(
    async (response: Response) => {
      const { data } = await response.json();
      if (data) {
        message.success(`${data} bağlamada dəyişiklik edildi.`);
      } else {
        message.warn('Flight-da daxil olan bağlama yoxdur');
      }
      dispatch(tableFetchDataAction('declarations', '/api/admin/v2/flights/list'));
      history.push(routes.flights.index);
    },
    [dispatch, history],
  );

  const formOptions = useMemo(
    () => ({
      handleClose,
      handleSubmit: baseHandleSubmit,
      initialize,
      createUrl: routes.flights.updateThisMonth,
      createApiUrl: urlFactory('/api/admin/this_month/change'),
      onSuccess: handleSubmitSuccess,
      submitMiddlewares: [(values) => ({ ...values, flight_id: params.id })],
    }),
    [params.id, handleSubmitSuccess, baseHandleSubmit, initialize, handleClose],
  );

  const { handleSubmit } = useForm(formOptions);

  return (
    <Modal maskTransitionName='' transitionName='' width={576} visible={true} onCancel={handleClose} confirmLoading={submitting} onOk={handleSubmit} title='Cari ayı dəyiş'>
      <Form layout='vertical'>
        <Field name='this_month' item={{ style: { marginBottom: 0 } }} field={{ format: 'YYYY-MM', picker: 'month' }} component={DateField} />
      </Form>
    </Modal>
  );
};

export default reduxForm<any, any>({ form: '@declarations/update/thisMonth' })(FlightUpdateThisMonthsForm);
