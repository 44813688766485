import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, PageHeader, Skeleton, Row, Modal, Card } from 'antd';

import ContentCard from '../containers/Layout/Sidebar/Content/Card';
import { faqFetchAction, faqFetchSucceedAction, faqRemoveAction } from '../actions/faq';
import { baseModelSelector } from '../selectors/common';
import moment from 'moment';
import { CommonConstants } from '../constants/common';
import { makePath } from '../core/utils/makePath';
import routes from '../constants/routes';
import { useTitle } from '../hooks/common/useTitle';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { caller } from '../core/utils/caller';
import { urlMaker } from '../next/shared/utils/url-maker';

const FAQPage: FC<RouteComponentProps> = ({ history }) => {
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - Tez-tez verilən suallar`);

  const dispatch = useDispatch();

  const faq = useSelector(baseModelSelector<any>('faq'));
  const [selectedFaq, setSelectedFaq] = useState(faq.data?.[0]);

  const goToCreate = useCallback(() => {
    history.push(routes.faq.create);
  }, [history]);

  const handleRemove = useCallback(
    (id) => {
      dispatch(faqRemoveAction(id));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(faqFetchAction());
  }, [dispatch]);

  useEffect(() => {
    setSelectedFaq(faq.data?.[0]);
  }, [faq.data]);

  const goToEdit = () => {
    history.push(makePath(routes.faq.edit, { id: selectedFaq?.id }));
  };

  const remove = () => {
    Modal.confirm({
      title: 'Təsdiqlə',
      content: 'Sualı silməyə əminsinizmi?',
      onOk: () => handleRemove([selectedFaq?.id]),
    });
  };

  const SortableCard = useMemo(
    () =>
      SortableElement(({ item }) => {
        return (
          <Card style={{ marginBottom: 12 }} hoverable={true} onClick={() => setSelectedFaq(item)} size='small' key={item.id}>
            {item.question}
          </Card>
        );
      }),
    [],
  );

  const SortableList = useMemo(
    () =>
      SortableContainer(({ items }) => {
        return (
          <div>
            {items.map((item, index) => (
              <SortableCard key={item.id} index={index} item={item} />
            ))}
          </div>
        );
      }),
    [SortableCard],
  );

  const handleSortEnd = useCallback(
    async ({ oldIndex, newIndex }) => {
      if (!faq.data) {
        return null;
      }

      const copiedItems = [...faq.data];
      const item = faq.data[oldIndex];
      copiedItems.splice(oldIndex, 1);
      copiedItems.splice(newIndex, 0, item);
      dispatch(faqFetchSucceedAction(copiedItems));

      const body = new FormData();

      body.append('faq_id', item.id);
      body.append('question', item.question);
      body.append('answer', item.answer);
      body.append('sort', (newIndex + 1).toString());

      await caller(urlMaker('/api/admin/faq/edit'), { method: 'POST', body });
    },
    [faq, dispatch],
  );

  return (
    <ContentCard>
      {!faq.loading ? (
        <div>
          <Row>
            <Col xs={24} md={8}>
              <SortableList pressDelay={200} items={faq.data || []} onSortEnd={handleSortEnd} />
            </Col>
            <Col xs={24} md={16}>
              {selectedFaq && (
                <PageHeader
                  title={selectedFaq.question}
                  subTitle={`${moment(selectedFaq.created_at, CommonConstants.DATE_TIME).fromNow()} yaradılıb`}
                  extra={[
                    <Button type='link' key='edit-button' onClick={goToEdit} icon={<EditOutlined />} />,
                    <Button type='link' danger={true} key='delete-button' onClick={remove} icon={<DeleteOutlined />} />,
                    <Button type='primary' key='create-button' onClick={goToCreate} icon={<PlusCircleOutlined />}>
                      Yeni
                    </Button>,
                  ]}
                >
                  <div dangerouslySetInnerHTML={{ __html: selectedFaq.answer }} />
                </PageHeader>
              )}
            </Col>
          </Row>
        </div>
      ) : (
        <div className='skeleton-sidebar-wrapper'>
          <Row gutter={[24, 0]}>
            <Col span={10}>
              <div className='skeleton-sidebar-item-group'>
                {new Array(20).fill(undefined).map((_, index) => {
                  return <Skeleton.Input key={index} active={true} className='skeleton-sidebar-item' />;
                })}
              </div>
            </Col>
            <Col span={14}>
              <div className='skeleton-sidebar-content-wrapper'>
                <div className='skeleton-sidebar-content-header'>
                  <Skeleton.Input active={true} className='skeleton-sidebar-content-header-input' />
                  <Skeleton.Button active={true} className='skeleton-sidebar-content-header-button' shape='circle' />
                  <Skeleton.Button active={true} className='skeleton-sidebar-content-header-button' />
                </div>
                <div className='skeleton-sidebar-content-body'>
                  <Skeleton active={true} title={false} paragraph={{ rows: 6 }} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </ContentCard>
  );
};

export default React.memo(FAQPage);
