import { Modal } from 'antd';
import React, { useMemo } from 'react';
import { useClose } from '../../hooks/common';
import routes from '../../constants/routes';
import ReadyTable from '../../components/Table/ReadyTable';
import { makePath } from '../../core/utils/makePath';
import { useStateHistoryColumns } from '../../hooks/tables/useStateHistoryColumns';

export const TicketsStateHistory = ({ match: { params } }) => {
  const handleClose = useClose(routes.tickets.index);
  const apiUrl = useMemo(
    () =>
      makePath(
        '/api/admin/states/history',
        {},
        {
          model_id: 9,
          object_id: params.id,
        },
      ),
    [params.id],
  );

  const columns = useStateHistoryColumns();

  return (
    <Modal maskTransitionName='' transitionName='' footer={null} width={768} visible={true} closable={false} onCancel={handleClose}>
      <ReadyTable columns={columns} pagination={false} filterable={false} sortable={false} name='tickets.state.history' apiUrl={apiUrl} />
    </Modal>
  );
};
