import { useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { Button, Dropdown, Menu, Modal, Tag } from 'antd';
import { changeReadAction, changeStateAction, getDeclarationOrders, printProforma, printWaybill, removeAction } from '../actions/declarations';
import { makePath } from '../../../../core/utils/makePath';
import routes from '../../../../constants/routes';
import { stopPropagation } from '../../../../core/helpers/events';
import {
  AppstoreOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FileSearchOutlined,
  ImportOutlined,
  LineChartOutlined,
  MoreOutlined,
  PrinterOutlined,
  ReadOutlined,
  RollbackOutlined,
} from '@ant-design/icons/lib';
import { actionsCol, dateCol, midCol, smallCol } from '../../../../core/helpers/table';
import { TrackCodeCell } from '../../../../components/Orders/Cell/TrackCodeCell';
import { StateCell } from '../components/cells/state-cell';
import CheckCell from '../../../../components/Table/core/Components/Cell/CheckCell';
import PriceCell from '../../../../components/Table/core/Components/Cell/PriceCell';
import { StateFilter } from '../components/filters/state-filter';
import { OverflowCell } from '../../../../components/Table/core/Components/Cell/OverflowCell';
import { CheckboxFilter } from '../../../../components/Table/core/Components/Filters/CheckboxFilter';
import { TypeFilter } from '../components/filters/type-filter';
import { PlanFilter } from '../components/filters/plan-filter';
import { ProductTypeFilter } from '../components/filters/product-type-filter';
import { UserCell } from '@modules/users';

export const useDeclarationsTableColumns = ({ hasActions } = { hasActions: true }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const actionsColumn = useMemo(
    () => ({
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState(false);
        const [orders, setOrders] = useState([]);

        const handleDelete = useCallback(() => {
          Modal.confirm({
            title: 'Bağlamanı silməyə əminsinizmi?',
            content: 'Silməni təsdiqlədikdən sonra bağlamanın geri qaytarılması mümkün olmayacaq.',
            onOk: () => dispatch(removeAction([original.id])),
          });
        }, [original.id]);

        const goToEdit = useCallback(() => {
          history.push(makePath(routes.declarations.edit.index, { id: original.id }));
        }, [original.id]);

        const goToQuickPay = useCallback(() => {
          history.push(makePath(routes.declarations.quickPay, { id: original.id }));
        }, [original.id]);

        const goToInfo = useCallback(() => {
          history.push(makePath(routes.declarations.info, { id: original.id }));
        }, [original.id]);

        const goToOrder = useCallback((id) => {
          history.push(makePath(routes.declarations.orders, { id }, { next: routes.declarations.index }));
        }, []);

        const goToReturn = useCallback(() => {
          history.push(makePath(routes.declarations.return, { id: original.id }));
        }, [original.id]);

        const goToTimeline = useCallback(() => {
          history.push(makePath(routes.declarations.states.timeline, { id: original.id }));
        }, [original.id]);

        const handleChangeRead = useCallback(() => {
          dispatch(changeReadAction([original.id], !original.is_new));
        }, [original.id, original.is_new]);

        const handleChangeState = useCallback(
          (stateId: number) => {
            dispatch(changeStateAction([original.id], stateId));
          },
          [original.id],
        );

        const goToDocument = useCallback(() => {
          window.open(original.document_file, '_blank');
        }, [original.document_file]);

        const handlePrintWaybill = useCallback(() => {
          printWaybill({ declarationId: original.id }).catch(() => null);
        }, [original.id]);

        const handlePrintProforma = useCallback(() => {
          printProforma([original.id]).catch(() => null);
        }, [original.id]);

        const waybillIsPrintable = useMemo(() => original.state_id === 7 || original.state_id === 8, [original.state_id]);

        useEffect(() => {
          if (visible) {
            getDeclarationOrders(original.id).then((orders) => setOrders(orders));
          }
        }, [visible, original.id]);

        return (
          <span onClick={stopPropagation}>
            <Dropdown
              visible={visible}
              onVisibleChange={setVisible}
              overlay={
                <Menu>
                  <Menu.Item onClick={goToEdit}>
                    <EditOutlined />
                    <span>Düzəliş et</span>
                  </Menu.Item>
                  <Menu.Item onClick={goToQuickPay}>
                    <CheckCircleOutlined />
                    <span>Təhvil ver</span>
                  </Menu.Item>
                  <Menu.Item onClick={goToReturn}>
                    <RollbackOutlined />
                    <span>İadə et</span>
                  </Menu.Item>
                  <Menu.Item onClick={handleChangeRead}>
                    <ReadOutlined />
                    {original.is_new ? <span>Oxumunmamış kimi qeyd et</span> : <span>Oxunmuş kimi qeyd et</span>}
                  </Menu.Item>
                  <Menu.SubMenu
                    title={
                      <>
                        <AppstoreOutlined />
                        <span>Statusu dəyiş</span>
                      </>
                    }
                  >
                    <Menu.Item onClick={() => handleChangeState(9)}>Yerli anbarda</Menu.Item>
                  </Menu.SubMenu>
                  <Menu.Divider />
                  <Menu.Item onClick={goToInfo}>
                    <FileSearchOutlined />
                    <span>Ətraflı</span>
                  </Menu.Item>
                  <Menu.Item onClick={goToTimeline}>
                    <LineChartOutlined />
                    <span>Status xəritəsi</span>
                  </Menu.Item>
                  <Menu.Item onClick={handlePrintProforma}>
                    <PrinterOutlined />
                    <span>Proforma invosyu</span>
                  </Menu.Item>
                  <Menu.Item disabled={!waybillIsPrintable} onClick={handlePrintWaybill}>
                    <PrinterOutlined />
                    <span>Yol vərəqəsi</span>
                  </Menu.Item>
                  <Menu.Item disabled={!original.document_file} onClick={goToDocument}>
                    <DownloadOutlined />
                    <span>Sənəd</span>
                  </Menu.Item>
                  <Menu.SubMenu
                    disabled={!orders.length}
                    title={
                      <>
                        <ImportOutlined />
                        <span>Sifarişə keç</span>
                      </>
                    }
                  >
                    {orders.map((id, index) => (
                      <Menu.Item onClick={() => goToOrder(id)}>
                        <span>Sifariş #{index + 1}</span>
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                  <Menu.Divider />
                  <Menu.Item onClick={handleDelete}>
                    <DeleteOutlined />
                    <span>Sil</span>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button className='next-table-action-btn' type='text' icon={<MoreOutlined />} />
            </Dropdown>
          </span>
        );
      },
      ...actionsCol,
    }),
    [dispatch, history],
  );

  const baseColumns = useMemo<Column<any>[]>(
    () => [
      { accessor: 'user_id', Header: 'M. Kodu', ...midCol },
      {
        accessor: 'user_name',
        Header: 'Müştəri',
        width: 200,
        Cell: ({ row: { original } }) => <UserCell id={original.user_id} title={original.user_name} />,
      },
      { accessor: 'track_code', Header: 'İzləmə kodu', width: 100, Cell: TrackCodeCell },
      {
        accessor: 'global_track_code',
        Header: 'Q.İ kodu',
        width: 100,
        Cell: ({ cell: { value } }) => {
          return value ? <Tag className='overflow-tag'>#{value}</Tag> : null;
        },
      },
      { accessor: 'state_id', Header: 'Status', width: 150, Cell: StateCell, Filter: StateFilter },
      { accessor: 'payed', Header: 'Ödənilmə statusu', width: 90, Cell: CheckCell, Filter: CheckboxFilter },
      { accessor: 'customs', Header: 'Bəyan statusu', width: 90, Cell: CheckCell, Filter: CheckboxFilter },
      { accessor: 'return', Header: 'Qaytarılıb', width: 90, Cell: CheckCell, Filter: CheckboxFilter },
      {
        accessor: 'flight_name',
        Header: 'Flight',
        Cell: ({ cell: { value }, row: { original } }) => (
          <NavLink className='navlink-cell' onClick={stopPropagation} to={makePath(routes.declarations.flights, { id: original.id })}>
            {value}
          </NavLink>
        ),
      },
      { accessor: 'weight', Header: 'Çəki', width: 100, Cell: ({ cell: { value } }) => (value ? `${value} KQ` : null) },
      { accessor: 'price', Header: 'Qiymət', width: 100, Cell: PriceCell.TRY },
      { accessor: 'delivery_price', Header: 'Çatdırılma qiyməti', width: 100, Cell: PriceCell.USD },
      { accessor: 'quantity', Header: 'Say', width: 75 },
      { accessor: 'type', Header: 'Tip', Cell: ({ cell: { value } }) => (value === 1 ? 'Maye' : 'Digər'), Filter: TypeFilter, ...midCol },
      { accessor: 'tariff_category_id', Header: 'Tarif', Cell: ({ row: { original } }) => original.tariff_category_name || null, Filter: PlanFilter },
      { accessor: 'shop_name', Header: 'Mağaza', ...midCol, Cell: ({ cell: { value } }) => <div className='overflow-cell'>{value}</div> },
      { accessor: 'product_type_id', Header: 'Məhsul tipi', Cell: ({ row: { original } }) => <div className='overflow-cell'>{original.product_type_name}</div>, Filter: ProductTypeFilter, ...midCol },
      { accessor: 'box', Header: 'Koli', width: 90, Cell: ({ cell: { value } }) => (value ? `#${value}` : null) },
      { accessor: 'wardrobe_number', Header: 'Şkaf nömrəsi', ...midCol },
      { accessor: 'causer_name', Header: 'Düzəliş edən', Cell: ({ cell: { value } }) => <div className='overflow-cell'>{value}</div> },
      { accessor: 'created_at', Header: 'Yaradılıb', ...dateCol },
    ],
    [],
  );

  return useMemo(() => {
    const copiedColumns = [...baseColumns];

    if (hasActions) {
      copiedColumns.unshift(actionsColumn);
    }

    return copiedColumns;
  }, [actionsColumn, baseColumns, hasActions]);
};

export const useCompactColumns = () => {
  return useMemo(
    () => [
      { accessor: 'id', Header: 'Kod', ...smallCol },
      { accessor: 'track_code', Header: 'İzləmə kodu', width: 100, Cell: TrackCodeCell },
      { accessor: 'global_track_code', Header: 'Q.İ kodu', width: 100, Cell: ({ cell: { value } }) => (value ? <Tag className='overflow-tag'>#{value}</Tag> : null) },
      { accessor: 'payed', Header: 'Ödənilmə statusu', width: 90, Cell: CheckCell, Filter: CheckboxFilter },
      { accessor: 'weight', Header: 'Çəki', width: 100, Cell: ({ cell: { value } }) => (value ? `${value} KQ` : null) },
      { accessor: 'price', Header: 'Qiymət', width: 100, Cell: PriceCell.TRY },
      { accessor: 'delivery_price', Header: 'Çatdırılma qiyməti', width: 100, Cell: PriceCell.AZN },
      { accessor: 'shop_name', Header: 'Mağaza', ...midCol, Cell: OverflowCell },
    ],
    [],
  );
};
