import { useInfo } from '../common/useInfo';
import { urlFactory } from '../../core/utils/caller';
import { useMemo } from 'react';

export const useDailyStatistics = (delay = 0) => {
  const useInfoOptions = useMemo(
    () => ({
      apiUrl: urlFactory('/api/admin/charts/byday'),
      responseGetter: (response) => response.data,
      delay,
    }),
    [delay],
  );

  const { data: rawData, loading } = useInfo(useInfoOptions);

  const data = useMemo(() => {
    if (rawData) {
      const orders = {
        labels: rawData.orders.all.labels,
        datasets: [
          {
            label: 'Səbət statusu xaric',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#ed0f51',
            borderColor: '#ed0f51',
            borderCapStyle: 'round',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#ed0f51',
            pointBackgroundColor: '#ffffff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#ed0f51',
            pointHoverBorderColor: '#ed0f51',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: rawData.orders.all.datasets,
          },
          {
            label: 'Bugün çatdırılmış',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'round',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: rawData.orders.delivered.datasets,
          },
        ],
      };

      const declarations = {
        labels: rawData.declarations.all.labels,
        datasets: [
          {
            label: 'Səbət statusu xaric',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#ed0f51',
            borderColor: '#ed0f51',
            borderCapStyle: 'round',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#ed0f51',
            pointBackgroundColor: '#ffffff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#ed0f51',
            pointHoverBorderColor: '#ed0f51',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: rawData.declarations.all.datasets,
          },
          {
            label: 'Bugün çatdırılmış',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'round',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: rawData.declarations.delivered.datasets,
          },
        ],
      };

      return { orders, declarations };
    } else {
      return {};
    }
  }, [rawData]);

  return useMemo(() => ({ data, loading }), [data, loading]);
};
