import React, { FC, useCallback, useMemo } from 'react';
import { Form, Modal, Typography } from 'antd';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { RouteComponentProps } from 'react-router-dom';

import { useClose } from '../../hooks/common';
import routes from '../../constants/routes';
import { useFormFields } from '../../hooks/banners/useForm';
import { urlFactory } from '../../core/utils/caller';
import { useForm } from '../../hooks/common/useForm';
import { Dispatch } from 'redux';
import { bannersFetchAction } from '../../actions/banners';
import { useSelector } from 'react-redux';
import { formFieldValueSelector } from '../../selectors/form';

type IProps = InjectedFormProps<any> & RouteComponentProps<any> & { dispatch: Dispatch };

const BannersForm: FC<IProps> = ({ submitting, handleSubmit: baseHandleSubmit, initialize, dispatch, form }) => {
  const handleClose = useClose(routes.banners.index);
  const type = useSelector(formFieldValueSelector(form, 'type', null));

  const handleSubmitSuccess = useCallback(() => {
    dispatch(bannersFetchAction());
  }, [dispatch]);

  const formOptions = useMemo(
    () => ({
      handleClose,
      handleSubmit: baseHandleSubmit,
      initialize,
      createUrl: routes.banners.create,
      createApiUrl: urlFactory('/api/admin/sliders/create'),
      onSuccess: handleSubmitSuccess,
    }),
    [handleSubmitSuccess, baseHandleSubmit, initialize, handleClose],
  );

  const { handleSubmit } = useForm(formOptions);

  const { nameFieldProps, typeFieldProps, fileUploadFieldProps, isActiveFieldProps } = useFormFields();

  const title = useMemo(() => 'Yeni banner əlavə et', []);

  return (
    <Modal maskTransitionName='' transitionName='' width={576} visible={true} onCancel={handleClose} confirmLoading={submitting} onOk={handleSubmit} title={title}>
      <Form layout='vertical'>
        <Field {...nameFieldProps} />
        <Field {...typeFieldProps} />
        <Field {...isActiveFieldProps} />
        <Field {...fileUploadFieldProps} />
        {(type === 1 || type === 2) && (
          <Typography.Paragraph type='secondary'>
            Tövsiyyə edilən olçü:&nbsp;
            {type === 1 && '1920x600'}
            {type === 2 && '750x1024'}
          </Typography.Paragraph>
        )}
      </Form>
    </Modal>
  );
};

export default reduxForm<any, any>({ form: 'banners' })(React.memo<IProps>(BannersForm));
