import { IPlan, IPlanPersistence } from '../interfaces';

export class PlanMapper {
  public static toDomain(plan: IPlanPersistence): IPlan {
    return {
      id: plan.id,
      weight: {
        from: parseFloat(plan.from_weight),
        to: plan.to_weight ? parseFloat(plan.to_weight) : null,
      },
      price: parseFloat(plan.price),
      forPerUnit: parseFloat(plan.from_weight) > 1,
      type: parseInt(plan.type) === 1 ? 'maye' : 'digər',
      tariffCategory: { id: plan.tariff_category_id, name: plan.tariff_category_name },
      description: plan.descr,
      currency: plan.currency,
    };
  }
}
