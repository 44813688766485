import React from 'react';
import { Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useTicketTemplatesActions } from '../../hooks/ticketTemplates/useActions';

const TicketTemplateActions = () => {
  const { goToCreate } = useTicketTemplatesActions();

  return (
    <div className='actions-wrapper'>
      <div className='actions-wrapper-left'>
        <Button type='primary' onClick={goToCreate} icon={<PlusCircleOutlined />}>
          Yeni
        </Button>
      </div>
    </div>
  );
};

export default TicketTemplateActions;
