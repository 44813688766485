import { IUser, IUserPersistence } from '@modules/users';

export class UserMapper {
  public static toDomain(user: IUserPersistence): IUser {
    return {
      id: user.id,
      firstname: user.name,
      lastname: user.surname,
      branch: {
        id: user.branch_id,
        name: user.branch_name,
      },
      email: user.email,
      phoneNumber: user.number,
      balance: {
        usd: parseFloat(user.balance_usd),
        try: parseFloat(user.balance_try),
      },
      discount: user.discount,
      birthDate: user.birth_date,
      gender: user.gender,
      passport: {
        number: user.passport_number,
        secret: user.passport_secret,
      },
      isBlocked: !!user.blocked,
    };
  }
}
