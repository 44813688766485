import { useMemo } from 'react';

export const useStateHistoryColumns = () => {
  return useMemo(
    () => [
      {
        accessor: 'user_id',
        Header: 'S.D.E kodu',
      },
      {
        accessor: 'user_name',
        Header: 'S.D.E adı',
      },
      {
        accessor: 'state_name',
        Header: 'Status',
      },
      {
        accessor: 'created_at',
        Header: 'Yaradılıb',
      },
    ],
    [],
  );
};
