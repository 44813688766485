import { urlMaker } from '../../../shared/utils/url-maker';
import { Result } from '../../../shared/utils/result';
import { caller } from '../../../shared/utils/caller';
import { Settings, SettingsFormData, SettingsFormErrors } from '../types';
import { SettingsMapper } from '../mappers';
import { appendToFormData } from '../../../shared/utils/append-to-form-data';

export class SettingsService {
  public static async get(): Promise<Result<200, Settings> | Result<400 | 500, string>> {
    const url = urlMaker('/api/admin/settings');

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();

        return new Result(200, SettingsMapper.getFromApi(data), null);
      } else {
        return new Result(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new Result(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }

  public static async update(values: SettingsFormData): Promise<Result<200> | Result<422, SettingsFormErrors> | Result<400 | 500, string>> {
    const url = urlMaker('/api/admin/settings/edit');
    const body = new FormData();
    appendToFormData(SettingsMapper.formDataToApi(values), body);

    try {
      const response = await caller(url, { body, method: 'POST' });

      if (response.ok) {
        return new Result(200, null, null);
      } else if (response.status === 422 || response.status === 400) {
        const { errors } = await response.json();

        return new Result(400, Object.values(errors).flat().join('. '), null);
      } else {
        return new Result(400, 'Əməliyyat aparıla bilmədi.', null);
      }
    } catch (e) {
      return new Result(500, 'Şəbəkə ilə əlaqə qurula bilmədi.', null);
    }
  }
}
