import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { message, Popover, Switch } from 'antd';
import { blue, green, red } from '@ant-design/colors';
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons/lib';

import ReadyTable from '../../components/Table/ReadyTable';
import { dateCol, idCol, normalCol, smallCol } from '../../core/helpers/table';
import { OverflowCell } from '../../components/Table/core/Components/Cell/OverflowCell';
import { stopPropagation } from '../../core/helpers/events';
import { changeSmsActive, getState } from '../../actions/smsArchive';

const SmsArchiveTable = () => {
  const columns = useMemo<Column<any>[]>(
    () => [
      {
        ...idCol,
      },
      {
        accessor: 'number',
        Header: 'Telefon',
        ...normalCol,
      },
      {
        accessor: 'active',
        Header: 'Aktivlik',
        sortable: false,
        filterable: false,
        ...smallCol,
        Cell: ({ cell: { value }, row: { original } }) => {
          const [loading, setLoading] = useState(false);
          const [active, setActive] = useState(!!value);
          const disabled = !!original.sent;

          const handleChangeActive = useCallback(
            async (checked) => {
              try {
                setLoading(true);
                await changeSmsActive({ id: original.id, active: checked });
                setActive(checked);
              } catch (e) {
                message.error('Sms-in aktivliyi dəyişdirilə bilmədi.');
              } finally {
                setLoading(false);
              }
            },
            [original.id],
          );

          return (
            <div className='flex-center' onClick={stopPropagation}>
              <Switch disabled={disabled} checked={active} loading={loading} onChange={handleChangeActive} />
            </div>
          );
        },
      },
      {
        accessor: 'state',
        Header: 'Status',
        sortable: false,
        filterable: false,
        Cell: ({ row: { original } }) => {
          const [visible, setVisible] = useState(false);
          const [content, setContent] = useState('Yüklənir...');

          const sent = !!original.sent;

          const loadingIconProps = useMemo(() => ({ style: { color: blue[5] } }), []);
          const closeIconProps = useMemo(() => ({ style: { color: red[5] } }), []);
          const checkIconProps = useMemo(() => ({ style: { color: green[5] } }), []);

          const handleGetState = useCallback(async () => {
            try {
              const state = await getState(original.id);
              setContent(state.length ? state : 'Məlumat yoxdur...');
            } catch (e) {
              setContent('Məlumat yoxdur...');
            }
          }, [original.id]);

          useEffect(() => {
            if (visible) {
              setContent('Yüklənir...');
              handleGetState()
                .then(() => null)
                .catch(() => null);
            }
          }, [visible, handleGetState]);

          return (
            <Popover content={content} onVisibleChange={setVisible} visible={visible}>
              <div className='flex-center'>
                {!sent && !!original.active && <LoadingOutlined {...loadingIconProps} />}
                {!sent && !original.active && <CloseCircleOutlined {...closeIconProps} />}
                {sent && !!original.active && <CheckCircleOutlined {...checkIconProps} />}
              </div>
            </Popover>
          );
        },
        ...smallCol,
      },
      {
        accessor: 'model_name',
        Header: 'Bölmə',
        ...normalCol,
      },
      {
        accessor: 'retry_at',
        Header: 'Cəhd tarixi',
        ...dateCol,
      },
      {
        accessor: 'sended_at',
        Header: 'Göndərilmə tarixi',
        ...dateCol,
      },
      {
        accessor: 'created_at',
        Header: 'Yaradılıb',
        ...dateCol,
      },
      {
        Header: 'Məzmun',
        accessor: 'body',
        Cell: OverflowCell,
      },
    ],
    [],
  );

  return <ReadyTable name='archive.sms' apiUrl='/api/admin/archive/sms' columns={columns} />;
};

export default React.memo(SmsArchiveTable);
