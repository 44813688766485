import { FormErrors } from '@core/form';

import { CreateBranchDto, CreateBranchDtoPersistence } from '../dtos';
import { IBranchById } from '../interfaces';

export class CreateBranchMapper {
  public static toDomain(branch: IBranchById): CreateBranchDto {
    return {
      name: branch.name,
      address: branch.address,
      email: branch.email,
      isBranch: branch.isBranch,
      mapAddress: branch.mapAddress,
      phone: branch.phone,
      workingHours: branch.workingHours,
      descr: branch.descr,
      branchId: branch.id.toString(),
    };
  }

  public static toPersistence(branch: CreateBranchDto): CreateBranchDtoPersistence {
    return {
      address: branch.address,
      email: branch.email,
      is_branch: branch.isBranch ? '1' : '0',
      map_address: branch.mapAddress,
      name: branch.name,
      phone: branch.phone,
      descr: branch.descr,
      workinghours: branch.workingHours,
      branch_id: branch.branchId,
    };
  }
}

export class CreateBranchErrorsMapper {
  public static toDomain(errors): FormErrors<CreateBranchDto> {
    return {
      name: errors.name?.join('. '),
      address: errors.address?.join('. '),
      email: errors.email?.join('. '),
      isBranch: errors.is_branch?.join('. '),
      mapAddress: errors.map_address?.join('. '),
      phone: errors.phone?.join('. '),
      workingHours: errors.workinghours?.join('. '),
    };
  }
}
