import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { Result } from '../../../shared/utils/result';

import { RegionMapper } from '../mappers';
import { Region } from '../types';

export class RegionService {
  public static async getAll(): Promise<Result<200, Region[]> | Result<400, string> | Result<500, string>> {
    try {
      const url = urlMaker('/api/admin/regions/getlist');

      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        const mappedData = RegionMapper.manyFromApi(data);

        return new Result(200, mappedData, null);
      } else {
        return new Result(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new Result(500, 'Sorğu göndərilə bilmədi. İnternet bağlantınızı yoxlayın.', null);
    }
  }
}
