import { useCallback, useContext, useMemo } from 'react';
import { ActionBar } from '@core/ui';
import { Select, Space } from 'antd';
import * as Icons from '@ant-design/icons';

import { HeaderButton, HeadPortal } from '@modules/layout';

import { CustomsDeclarationsTableContext } from '../context';
import { customsDeclarationPersistenceKeys } from '../utils';
import { UploadCustomsDeclarationsDocument } from '../containers';
import { CustomsDeclarationsCounts } from '@modules/customs/containers/customs-declarations-counts';

export const CustomsDeclarationsActionBar = () => {
  const {
    fetch,
    reset,
    state: { filters },
    setFilterById,
  } = useContext(CustomsDeclarationsTableContext);

  const isDeclared = useMemo<string | undefined>(() => filters.find((filter) => filter.id === customsDeclarationPersistenceKeys.isDeclared)?.value, [filters]);
  const clientIsExist = useMemo<string | undefined>(() => filters.find((filter) => filter.id === customsDeclarationPersistenceKeys.clientIsExist)?.value, [filters]);
  const flightStatus = useMemo<string | undefined>(() => filters.find((filter) => filter.id === customsDeclarationPersistenceKeys.flightStatus)?.value, [filters]);

  const onIsDeclaredChange = useCallback(
    (value: string | undefined) => {
      setFilterById(customsDeclarationPersistenceKeys.isDeclared, value);
    },
    [setFilterById],
  );

  const onClientIsExistChange = useCallback(
    (value: string | undefined) => {
      setFilterById(customsDeclarationPersistenceKeys.clientIsExist, value);
    },
    [setFilterById],
  );

  const onFlightStatusChange = useCallback(
    (value: string | undefined) => {
      setFilterById(customsDeclarationPersistenceKeys.flightStatus, value);
    },
    [setFilterById],
  );

  return (
    <HeadPortal>
      <ActionBar $flex={true}>
        <Space size={0}>
          <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
            Yenilə
          </HeaderButton>
          <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
            Sıfırla
          </HeaderButton>
          <UploadCustomsDeclarationsDocument />
          <CustomsDeclarationsCounts />
        </Space>
        <Space size={8}>
          <Select placeholder='Bəyan statusu' value={isDeclared} onChange={onIsDeclaredChange} allowClear={true}>
            <Select.Option value='1'>Bəyan edilib</Select.Option>
            <Select.Option value='0'>Bəyan edilməyib</Select.Option>
          </Select>
          <Select placeholder='Müştəri statusu' value={clientIsExist} onChange={onClientIsExistChange} allowClear={true}>
            <Select.Option value='1'>Mövcud olan</Select.Option>
            <Select.Option value='0'>Mövcud olmayan</Select.Option>
          </Select>
          <Select placeholder='Uçuş statusu' value={flightStatus} onChange={onFlightStatusChange} allowClear={true}>
            <Select.Option value='in'>Uçuşda olan</Select.Option>
            <Select.Option value='out'>Uçuşda olmayan</Select.Option>
          </Select>
        </Space>
      </ActionBar>
    </HeadPortal>
  );
};
