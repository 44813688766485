import React, { FC } from 'react';

import ReadyTable from '../../components/Table/ReadyTable';
import { useConfigShopsTable } from '../../hooks/configShops/useTable';

const ConfigShopsTable: FC = () => {
  const { columns } = useConfigShopsTable();

  return <ReadyTable columns={columns} name='configShops' apiUrl='/api/admin/shop_names' />;
};

export default ConfigShopsTable;
