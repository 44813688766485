import { useCallback, useContext, useMemo } from 'react';

import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';

import { useBranchById } from './use-branch-by-id';
import { CreateBranchDto } from '../dtos';
import { CreateBranchMapper } from '../mappers';
import { CreateBranchContext } from '../contexts';
import { CreateBranchRepo, CreateBranchRepoType } from '../repos';

export const useCreateBranch = () => {
  const { id, onSucceed } = useContext(CreateBranchContext);
  const branch = useBranchById(id, { enabled: !!id });

  const createCourierRepo = useService<CreateBranchRepo>(CreateBranchRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<CreateBranchDto | undefined>(() => {
    if (id) {
      return branch.data ? CreateBranchMapper.toDomain(branch.data) : undefined;
    }

    return {
      address: '',
      email: '',
      isBranch: false,
      mapAddress: '',
      name: '',
      phone: '',
      branchId: '',
      workingHours: '',
      descr: '',
    };
  }, [branch.data, id]);

  const onSubmit = useCallback(
    async (values: CreateBranchDto, helpers: FormikHelpers<CreateBranchDto>) => {
      helpers.setStatus(null);
      const result = await createCourierRepo.execute(values);

      if (result.status === 200) {
        if (!id) {
          eventBus.publish({ type: '@branches/create/succeed' });
        } else {
          eventBus.publish({ type: '@branches/update/succeed' });
        }

        onSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createCourierRepo, eventBus, id, onSucceed],
  );

  return { onSubmit, initialValues };
};
