import React, { FC, useCallback } from 'react';

import ContentCard from '../containers/Layout/Sidebar/Content/Card';
import Table from '../containers/Logs/Table';
import { useTitle } from '../hooks/common/useTitle';
import { Button, Space } from 'antd';
import * as Icons from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { tableFetchDataAction, tableSetFiltersAction } from '../actions/table';

const LogsPage: FC = () => {
  const dispatch = useDispatch();
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - Status arxivi`);

  const fetchTableData = useCallback(() => {
    dispatch(tableFetchDataAction('logs', '/api/admin/logs'));
  }, [dispatch]);

  const resetTableFilter = useCallback(() => {
    dispatch(tableSetFiltersAction('logs', []));
  }, [dispatch]);

  return (
    <ContentCard>
      <div className='actions-wrapper'>
        <div className='actions-wrapper-left'>
          <Space size={8}>
            <Button icon={<Icons.ReloadOutlined />} onClick={fetchTableData}>
              Yenilə
            </Button>
            <Button icon={<Icons.ClearOutlined />} onClick={resetTableFilter}>
              Sıfırla
            </Button>
          </Space>
        </div>
      </div>
      <Table />
    </ContentCard>
  );
};

export default React.memo(LogsPage);
