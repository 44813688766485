import { useMemo } from 'react';
import { RowProps } from 'antd/lib/row';
import { ColProps } from 'antd/lib/col';

export const useDeclarationFormLayout = () => {
  const baseRowProps = useMemo<RowProps>(() => ({ gutter: [16, 0] }), []);
  const baseColProps = useMemo<ColProps>(() => ({ span: 24 }), []);
  const xsColProps = useMemo<ColProps>(() => ({ span: 12, sm: { span: 4 } }), []);
  const smColProps = useMemo<ColProps>(() => ({ span: 24, sm: { span: 6 } }), []);
  const mdColProps = useMemo<ColProps>(() => ({ span: 24, sm: { span: 8 } }), []);
  const xlColProps = useMemo<ColProps>(() => ({ span: 24, sm: { span: 12 } }), []);

  return useMemo(() => ({ baseRowProps, baseColProps, xsColProps, smColProps, mdColProps, xlColProps }), [baseRowProps, baseColProps, xsColProps, smColProps, mdColProps, xlColProps]);
};
