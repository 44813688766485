import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo } from '@core/repo';
import { bind } from '@infra/container';
import { GetPaginatedCollectionRepoType } from '@infra/repos';
import type { GetIGetPaginatedCollectionRepo, IGetPaginatedCollectionRepoResponse } from '@infra/repos';

import { IDeclaration } from '../interfaces';
import { DeclarationMapper } from '../mappers';

export type IGetBaseDeclarationsRepoResponse = IGetPaginatedCollectionRepoResponse<IDeclaration>;

export type IGetBaseDeclarationsRepo = IRepo<[{ url: string; query: object }], IGetBaseDeclarationsRepoResponse>;

export const GetBaseDeclarationsRepoType = Symbol.for('GetBaseDeclarationsRepo');

@bind(GetBaseDeclarationsRepoType)
export class GetBaseDeclarationsRepo implements IGetBaseDeclarationsRepo {
  constructor(
    @inject(HttpClientType) private readonly httpClient: IHttpClient,
    @inject(GetPaginatedCollectionRepoType) private readonly paginatedCollectionRepo: GetIGetPaginatedCollectionRepo<IDeclaration>,
  ) {}

  async execute({ url, query }) {
    return this.paginatedCollectionRepo.execute({ url, query, mapper: DeclarationMapper.toDomain });
  }
}
