import React, { FC } from 'react';

import ReadyTable from '../../components/Table/ReadyTable';
import { useBonazOffersTable } from '../../hooks/bonaz/useBonazOffersTable';

const BonazConversionsTable: FC = () => {
  const { columns } = useBonazOffersTable();

  return <ReadyTable sortable={false} filterable={false} columns={columns} source='bonaz' name='bonaz.offers' apiUrl='/api/admin/bonaz/offers' />;
};

export default BonazConversionsTable;
