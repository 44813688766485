import React, { useMemo } from 'react';
import { Card, Col, DatePicker, Divider, Row, Select, Spin, Table, Typography } from 'antd';
import routes from '../../constants/routes';
import { useModelStatistics } from '../../hooks/dashboard/useModelStatistics';

const { RangePicker } = DatePicker;

const DeclarationsStatistics = () => {
  const useModelStatisticsOptions = useMemo(
    () => ({
      defaultState: '-1',
      apiUrl: '/api/admin/stats/declaration_by_month',
      redirectUrl: routes.dashboard.declarations.info,
    }),
    [],
  );

  const { date, stateId, setStateId, handleChangeDate, loading, data, columns } = useModelStatistics(useModelStatisticsOptions);

  return (
    <Card size='small' className='dashboard-model-statistics-card'>
      <Spin spinning={loading}>
        <div className='dashboard-model-statistics-card-input-wrapper'>
          <span>
            <RangePicker value={date} onChange={handleChangeDate} />
          </span>
          <span>
            <Select style={{ width: 256 }} onChange={setStateId} value={stateId}>
              <Select.Option value='-1'>Bütün</Select.Option>
              <Select.Option value='5'>Öncədən bəyan</Select.Option>
              <Select.Option value='7'>Xarici anbarda</Select.Option>
              <Select.Option value='8'>Yolda</Select.Option>
              <Select.Option value='9'>Yerli anbarda</Select.Option>
              <Select.Option value='10'>Təhvil verilib</Select.Option>
            </Select>
          </span>
        </div>
        <Divider style={{ margin: '12px 0' }} />
        <Row gutter={[8, 8]}>
          {data.map((month: { items: any[]; total: number }, index) => (
            <Col key={index} span={8}>
              <Table
                size='small'
                bordered={true}
                pagination={false}
                rowKey={(data) => data.key}
                dataSource={month.items}
                columns={columns}
                summary={() => (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                      <Typography.Text strong={true}>Cəmi</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <Typography.Text strong={true}>{month.total}</Typography.Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
              />
            </Col>
          ))}
        </Row>
      </Spin>
    </Card>
  );
};

export default DeclarationsStatistics;
