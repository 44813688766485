import * as React from 'react';
import { Menu } from 'antd';
import * as Icons from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

import { useAuth } from '@modules/auth';

import * as UI from '../components';
import { useSidebar } from '../hooks';

export const AppSidebar = () => {
  const { isOpen, activeKey, counter, toggleSidebar } = useSidebar();
  const { can } = useAuth();

  return (
    <React.Fragment>
      <UI.Sider trigger={null} width={224} collapsible={true} collapsedWidth={46} collapsed={!isOpen} className={isOpen ? 'active' : 'inactive'}>
        <UI.Brand>{isOpen ? <img src='/logo.svg' alt={process.env.REACT_APP_COMPANY_NAME} /> : <img src='/logo-compact.svg' alt={process.env.REACT_APP_COMPANY_NAME} />}</UI.Brand>
        <Menu theme='dark' mode='inline' selectedKeys={[activeKey]}>
          <Menu.ItemGroup title='Ümumi'>
            {can('stat') && (
              <Menu.Item key='/@next/statistics' icon={<Icons.LineChartOutlined />}>
                Statistika
                <NavLink to='/@next/statistics' />
              </Menu.Item>
            )}
            {can('client_appointment') && (
              <Menu.Item key='/@next/appointment' icon={<Icons.FileSearchOutlined />}>
                Müştəri qəbul
                <NavLink to='/@next/appointment' />
              </Menu.Item>
            )}
            {can('local_warehouse') && (
              <Menu.Item key='/@next/warehouse/handover/queues' icon={<Icons.UserSwitchOutlined />}>
                Anbardar təhvil ({counter.handoverQueue.pending}/{counter.handoverQueue.executing})
                <NavLink to='/@next/warehouse/handover/queues' />
              </Menu.Item>
            )}
            {can('cashflow') && (
              <Menu.Item key='/@next/cash-flow' icon={<Icons.MoneyCollectOutlined />}>
                Cashflow
                <NavLink to='/@next/cash-flow' />
              </Menu.Item>
            )}
            <Menu.Item key='/@next/users' icon={<Icons.UserOutlined />}>
              Müştərilər
              <NavLink to='/@next/users' />
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title='Müştərilər'>
            <Menu.Item key='/@next/orders' icon={<Icons.ShoppingCartOutlined />}>
              Sifarişlər {!!counter.declarations && `(${counter.orders})`}
              <NavLink to='/@next/orders' />
            </Menu.Item>
            <Menu.Item key='/@next/declarations' icon={<Icons.FileTextOutlined />}>
              Bağlamalar {!!counter.declarations && `(${counter.declarations})`}
              <NavLink to='/@next/declarations' />
            </Menu.Item>
            <Menu.Item key='/@next/customs/declarations' icon={<Icons.ExceptionOutlined />}>
              Bəyannamələr
              <NavLink to='/@next/customs/declarations' />
            </Menu.Item>
            <Menu.Item key='/@next/declarations/deleted' icon={<Icons.DeleteOutlined />}>
              Silinmiş bəyannamələr
              <NavLink to='/@next/declarations/deleted' />
            </Menu.Item>
            <Menu.Item key='/@next/customs/dns-queues' icon={<Icons.OrderedListOutlined />}>
              BBS növbələri
              <NavLink to='/@next/customs/dns-queues' />
            </Menu.Item>
            {can('dgk_declarations') && (
              <Menu.Item key='/@next/customs/posts' icon={<Icons.AuditOutlined />}>
                DGK Bağlamalar
                <NavLink to='/@next/customs/posts' />
              </Menu.Item>
            )}
            <Menu.Item key='/@next/couriers' icon={<Icons.DropboxOutlined />}>
              Kuryerlər {!!counter.couriers && `(${counter.couriers})`}
              <NavLink to='/@next/couriers' />
            </Menu.Item>
            <Menu.Item key='/@next/couriers/deliverer-assignments' icon={<Icons.UserAddOutlined />}>
              Kuryer təhkim
              <NavLink to='/@next/couriers/deliverer-assignments' />
            </Menu.Item>
            <Menu.Item key='/@next/transactions' icon={<Icons.MoneyCollectOutlined />}>
              Balans əməliyyatları
              <NavLink to='/@next/transactions' />
            </Menu.Item>
            <Menu.Item key='/@next/declarations/unknowns' icon={<Icons.CodeSandboxOutlined />}>
              Mübahisəli bağlamalar {!!counter.unknownDeclarations && `(${counter.unknownDeclarations})`}
              <NavLink to='/@next/declarations/unknowns' />
            </Menu.Item>
            {can('flights') && (
              <Menu.Item key='/@next/flights' icon={<Icons.RocketOutlined />}>
                Flights
                <NavLink to='/@next/flights' />
              </Menu.Item>
            )}
            <Menu.Item key='/@next/supports' icon={<Icons.MessageOutlined />}>
              Müraciətlər {!!counter.supports && `(${counter.supports})`}
              <NavLink to='/@next/supports' />
            </Menu.Item>
            <Menu.Item key='/@next/refunds' icon={<Icons.RollbackOutlined />}>
              İadələr
              <NavLink to='/@next/refunds' />
            </Menu.Item>
          </Menu.ItemGroup>
          {can('bon_cashback') && (
            <Menu.ItemGroup title='Bon keşbək'>
              <Menu.Item key='/bonaz/conversions' icon={<Icons.TableOutlined />}>
                Konversiyalar
                <NavLink to='/bonaz/conversions' />
              </Menu.Item>
              <Menu.Item key='/bonaz/offers' icon={<Icons.TableOutlined />}>
                Təkliflər
                <NavLink to='/bonaz/offers' />
              </Menu.Item>
            </Menu.ItemGroup>
          )}
          <Menu.ItemGroup title='Məzmun'>
            <Menu.Item key='/@next/notifier/sms' icon={<Icons.TabletOutlined />}>
              SMS Arxivi
              <NavLink to='/@next/notifier/sms' />
            </Menu.Item>
            <Menu.Item key='/@next/notifier/email' icon={<Icons.MailOutlined />}>
              Mail Arxivi
              <NavLink to='/@next/notifier/email' />
            </Menu.Item>
            <Menu.Item key='/@next/notifier/mobile/bulk/send' icon={<Icons.NotificationOutlined />}>
              Toplu bildiriş
              <NavLink to='/@next/notifier/mobile/bulk/send' />
            </Menu.Item>
            {can('state_changes') && (
              <Menu.Item key='/archive/state' icon={<Icons.DatabaseOutlined />}>
                Status Arxivi
                <NavLink to='/archive/state' />
              </Menu.Item>
            )}
            {can('my_logs') && (
              <Menu.Item key='/logs' icon={<Icons.HistoryOutlined />}>
                Əməliyyat Arxivi
                <NavLink to='/logs' />
              </Menu.Item>
            )}
            {can('notification_templates') && (
              <Menu.Item key='/@next/notifier/templates' icon={<Icons.NotificationOutlined />}>
                Bildiriş şablonları
                <NavLink to='/@next/notifier/templates' />
              </Menu.Item>
            )}
            <Menu.Item key='/ticket_templates' icon={<Icons.FileTextOutlined />}>
              Müraciət şablonları
              <NavLink to='/ticket_templates' />
            </Menu.Item>
            {can('news') && (
              <Menu.Item key='/news' icon={<Icons.ReadOutlined />}>
                Xəbərlər
                <NavLink to='/news' />
              </Menu.Item>
            )}
            {can('faq') && (
              <Menu.Item key='/faq' icon={<Icons.QuestionCircleOutlined />}>
                Tez-tez verilən suallar
                <NavLink to='/faq' />
              </Menu.Item>
            )}
            {can('shops') && (
              <Menu.Item key='/shops' icon={<Icons.ShopOutlined />}>
                Mağazalar
                <NavLink to='/shops' />
              </Menu.Item>
            )}
            {can('about') && (
              <Menu.Item key='/about' icon={<Icons.InfoCircleOutlined />}>
                Haqqında
                <NavLink to='/about' />
              </Menu.Item>
            )}
            <Menu.Item key='/transportation_conditions' icon={<Icons.SnippetsOutlined />}>
              Daşınma şərtləri
              <NavLink to='/transportation_conditions' />
            </Menu.Item>
            <Menu.Item key='/banners' icon={<Icons.FileImageOutlined />}>
              Bannerlər
              <NavLink to='/banners' />
            </Menu.Item>
            <Menu.Item key='/@next/popups' icon={<Icons.BorderOutlined />}>
              Popuplar
              <NavLink to='/@next/popups' />
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title='Proqram ayarları'>
            {can('system_settings') && (
              <Menu.Item key='/@next/settings' icon={<Icons.SettingOutlined />}>
                Sistem ayarları
                <NavLink to='/@next/settings' />
              </Menu.Item>
            )}
            <Menu.Item key='/@next/coupons' icon={<Icons.GiftOutlined />}>
              Kuponlar
              <NavLink to='/@next/coupons' />
            </Menu.Item>
            <Menu.Item key='/@next/branches' icon={<Icons.BranchesOutlined />}>
              Filiallar
              <NavLink to='/@next/branches' />
            </Menu.Item>
            <Menu.Item key='/@next/boxes' icon={<Icons.InboxOutlined />}>
              Yeşiklər
              <NavLink to='/@next/boxes' />
            </Menu.Item>
            <Menu.Item key='/@next/shops' icon={<Icons.ShoppingOutlined />}>
              Mağaza reyestri
              <NavLink to='/@next/shops' />
            </Menu.Item>
            <Menu.Item key='/@next/return-types' icon={<Icons.RollbackOutlined />}>
              İadə səbəbləri
              <NavLink to='/@next/return-types' />
            </Menu.Item>
            {can('tarifs') && (
              <Menu.Item key='/@next/plans' icon={<Icons.FormOutlined />}>
                Tariflər
                <NavLink to='/@next/plans' />
              </Menu.Item>
            )}
            {can('producttypes') && (
              <Menu.Item key='/@next/product-types' icon={<Icons.BuildOutlined />}>
                Məhsul tipləri
                <NavLink to='/@next/product-types' />
              </Menu.Item>
            )}
            {can('regions') && (
              <Menu.Item key='/@next/regions' icon={<Icons.TranslationOutlined />}>
                Rayonlar
                <NavLink to='/@next/regions' />
              </Menu.Item>
            )}
            {can('models') && (
              <Menu.Item key='/@next/models' icon={<Icons.StarOutlined />}>
                Modellər
                <NavLink to='/@next/models' />
              </Menu.Item>
            )}
            {can('states') && (
              <Menu.Item key='/@next/status' icon={<Icons.WalletOutlined />}>
                Statuslar
                <NavLink to='/@next/status' />
              </Menu.Item>
            )}
          </Menu.ItemGroup>
        </Menu>
      </UI.Sider>
      <UI.SiderOverlay $visible={isOpen} onClick={toggleSidebar} />
    </React.Fragment>
  );
};
