import { Moment } from 'moment';

import { urlMaker } from '../../../shared/utils/url-maker';
import { Constants } from '../../../shared/constants';
import { caller } from '../../../shared/utils/caller';
import { Result } from '../../../shared/utils/result';

import { StatisticsMappers } from '../mappers';
import { PaymentTypeStatisticsByModel } from '../types/payment-types';

export class PaymentTypesStatisticsService {
  public static async getByModelId(modelId: string | number, startDate: Moment, endDate: Moment): Promise<Result<200, PaymentTypeStatisticsByModel[]> | Result<400 | 500, string>> {
    try {
      const url = urlMaker('/api/admin/stats/payment_type_by_model', {
        start_date: startDate.format(Constants.DATE_TIME),
        end_date: endDate.format(Constants.DATE_TIME),
        model_id: modelId,
      });

      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();

        return new Result(200, StatisticsMappers.PaymentType.manyFromApi(data), null);
      } else {
        return new Result(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new Result(500, 'Sorğu göndərilə bilmədi. İnternet bağlantınızı yoxlayın.', null);
    }
  }
}
