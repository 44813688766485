import React, { memo, useCallback, useMemo } from 'react';
import { Form, Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { FormItemProps } from 'antd/es/form';
import { FieldConfig, FieldInputProps, FieldMetaProps, useField } from 'formik';
import { twoLevelShallowEqualObjects } from '../../../utils/two-level-shallow-equal-objects';
import { selectFilterOption } from '../utils';

const MemoizedSelectField = memo<MemoizedSelectFieldProps>(({ item, input, field, meta, children }) => {
  return (
    <Form.Item validateStatus={meta.touched && !!meta.error ? 'error' : undefined} help={meta.error} {...item}>
      <Select allowClear={true} showSearch={true} filterOption={selectFilterOption} {...field} {...input}>
        {children}
      </Select>
    </Form.Item>
  );
}, twoLevelShallowEqualObjects);

export const SelectField = memo<SelectFieldProps>(({ item, input, children, ...props }) => {
  const [{ onChange: baseOnChange, ...field }, meta] = useField(props);

  const value = useMemo(() => field.value || null, [field.value]);

  const onChange = useCallback(
    (value) => {
      baseOnChange(field.name)(value || '');
    },
    [baseOnChange, field.name],
  );

  const newField = useMemo(() => ({ ...field, value: value || undefined, onChange }), [field, onChange, value]);

  return (
    <MemoizedSelectField field={newField} meta={meta} input={input} item={item}>
      {children}
    </MemoizedSelectField>
  );
});

export type SelectFieldProps = FieldConfig & { item?: FormItemProps; input?: SelectProps<any> };

type MemoizedSelectFieldProps = {
  item?: FormItemProps;
  input?: SelectProps<any>;
  field: FieldInputProps<any>;
  meta: FieldMetaProps<any>;
  children: any;
};
