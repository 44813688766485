import { useContext } from 'react';

import { useSub } from '@core/event-bus';

import { ShopsTableContext } from '../../contexts';
import { useShopsTableColumns } from './use-shops-table-columns';

export const useShopsTable = () => {
  const columns = useShopsTableColumns();
  const { fetch } = useContext(ShopsTableContext);
  useSub(['@shops/create/succeed', '@shops/update/succeed'], () => {
    fetch();
  });

  return { columns };
};
