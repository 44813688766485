import { FormErrors } from '@core/form';

import { CreateShopDto, CreateShopDtoPersistence } from '../dtos';
import { IShop } from '../interfaces';

export class CreateShopMapper {
  public static toDomain(shop: IShop): CreateShopDto {
    return {
      id: shop.id.toString(),
      name: shop.name,
    };
  }

  public static toPersistence(shop: CreateShopDto): CreateShopDtoPersistence {
    return {
      name: shop.name,
      shop_name_id: shop.id,
    };
  }
}

export class CreateShopErrorsMapper {
  public static toDomain(errors): FormErrors<CreateShopDto> {
    return {
      id: errors.id?.join('. '),
      name: errors.name?.join('. '),
    };
  }
}
