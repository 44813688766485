import { useMemo } from 'react';
import { InputField } from '../../components/ReduxForm/Input';

export const useConfigReturnTypeFields = () => {
  const nameFieldProps = useMemo(
    () => ({
      name: 'reason',
      item: { label: 'İadə növü', style: { marginBottom: 0 } },
      field: { placeholder: 'İadə növünü daxil edin...' },
      component: InputField,
    }),
    [],
  );

  return { nameFieldProps };
};
