import React, { FC } from 'react';

import ContentCard from '../containers/Layout/Sidebar/Content/Card';
import Table from '../containers/EmailArchive/Table';
import { useTitle } from '../hooks/common/useTitle';
import EmailArchiveActions from '../containers/EmailArchive/Actions';

const EmailArchivePage: FC = () => {
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - Mail arxivi`);

  return (
    <ContentCard>
      <EmailArchiveActions />
      <Table />
    </ContentCard>
  );
};

export default React.memo(EmailArchivePage);
