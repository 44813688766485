import { useMemo } from 'react';
import moment from 'moment';
import { CommonConstants } from '../../constants/common';
import { OverflowCell } from '../../components/Table/core/Components/Cell/OverflowCell';
import { dateCol } from '../../core/helpers/table';
import { useInfo } from '../common/useInfo';
import { urlFactory } from '../../core/utils/caller';
import { Select } from 'antd';
import { filterOption } from '@core/form';
import { UserCell } from '@modules/users';

export const useBonazConversionsTable = () => {
  const users = useInfo(
    useMemo(
      () => ({
        apiUrl: urlFactory('/api/admin/client/list', { start: 0, limit: 30000 }),
        responseGetter: (response) => response.data,
        initialState: [],
      }),
      [],
    ),
  );

  const offers = useInfo(
    useMemo(
      () => ({
        apiUrl: urlFactory('/api/admin/bonaz/offers', { start: 0, limit: 1000 }),
        responseGetter: (response) => response.result,
        initialState: [],
      }),
      [],
    ),
  );

  const columns = useMemo(
    () => [
      {
        accessor: 'transaction_id',
        Header: 'Kod',
        Cell: OverflowCell,
        filterable: false,
      },
      {
        accessor: 'user_id',
        Header: 'Istifadəçi kodu',
        Filter: ({ column: { filterValue, setFilter } }) => (
          <Select allowClear={true} filterOption={filterOption} showSearch={true} style={{ width: '100%' }} value={filterValue} onChange={setFilter}>
            {users.data.map((user) => (
              <Select.Option key={user.id} value={user.id}>
                {user.name} {user.surname}
              </Select.Option>
            ))}
          </Select>
        ),
        Cell: ({ cell: { value } }) => {
          const user = users.data.find((user) => user.id === value);

          if (user) {
            return <UserCell id={user.id} title={user.name + ' ' + user.surname} />;
          }

          return null;
        },
      },
      {
        accessor: 'order_number',
        Header: 'Sifariş kodu',
        filterable: false,
      },
      {
        accessor: 'status',
        Header: 'Status',
        filterable: false,
      },
      {
        accessor: 'rate',
        Header: 'Məzənnə',
        filterable: false,
      },
      {
        accessor: 'paid',
        Header: 'Ödənilib',
        filterable: false,
      },
      {
        accessor: 'percent',
        Header: 'Faiz',
        filterable: false,
      },
      {
        accessor: 'offer.name',
        id: 'offer_id',
        Header: 'Mağaza',
        Filter: ({ column: { filterValue, setFilter } }) => (
          <Select allowClear={true} filterOption={filterOption} showSearch={true} style={{ width: '100%' }} value={filterValue} onChange={setFilter}>
            {offers.data.map((offer) => (
              <Select.Option key={offer.id} value={offer.id}>
                {offer.name}
              </Select.Option>
            ))}
          </Select>
        ),
      },
      {
        accessor: 'cashback',
        Header: 'Keşbək',
        filterable: false,
      },
      {
        accessor: 'user_agent',
        Header: 'User Agent',
        Cell: OverflowCell,
        filterable: false,
      },
      {
        accessor: 'os',
        className: 'ant-table-col-100',
        Header: 'Ə.S',
        filterable: false,
      },
      {
        accessor: 'browser',
        Header: 'Brauzer',
        filterable: false,
      },
      {
        accessor: 'date',
        Header: 'Tarix',
        ...dateCol,
        Cell: ({ cell: { value } }) => {
          return moment(value).format(CommonConstants.DATE_TIME);
        },
        filterable: false,
      },
    ],
    [offers.data, users.data],
  );

  return { columns };
};
