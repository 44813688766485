import { Fragment } from 'react';
import { Select, Space } from 'antd';
import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';

import { HeaderButton, HeadPortal } from '@modules/layout';
import { useAuth } from '@modules/auth';

import { useUsersActionBar } from '../hooks';

export const UsersActionBar = () => {
  const { can } = useAuth();
  const {
    selection,
    resetSelection,
    create,
    selectAll,
    reset,
    fetch,
    openGeneralStatistics,
    role,
    onRoleChange,
    onDeclarationsStatusChange,
    declarationsStatusId,
    declarationStatus,
  } = useUsersActionBar();

  const selectAllButton = (
    <HeaderButton onClick={selectAll} icon={<Icons.CheckCircleOutlined />}>
      Hamısını seç
    </HeaderButton>
  );

  const resetSelectionButton = (
    <HeaderButton onClick={resetSelection} icon={<Icons.CloseCircleOutlined />}>
      {selection.length} sətir seçilib
    </HeaderButton>
  );

  return (
    <Fragment>
      <HeadPortal>
        <ActionBar $flex={true}>
          <Space size={0}>
            <HeaderButton onClick={create} icon={<Icons.PlusCircleOutlined />}>
              Yeni
            </HeaderButton>
            {!selection.length ? selectAllButton : resetSelectionButton}
            <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
              Yenilə
            </HeaderButton>
            <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
              Sıfırla
            </HeaderButton>
          </Space>
          {!selection.length && (
            <Space size={0}>
              <HeaderButton onClick={openGeneralStatistics} icon={<Icons.BarChartOutlined />} />
            </Space>
          )}
        </ActionBar>
      </HeadPortal>
      <Space size={8}>
        <Select style={{ width: 140 }} value={role} placeholder='İstifadəçi tipi' onChange={onRoleChange} allowClear={true} showSearch={true}>
          {can('admins') && <Select.Option value='admin'>Adminlər</Select.Option>}
          {can('admins') && <Select.Option value='warehouseman'>Anbardarlar</Select.Option>}
          <Select.Option value='back-office'>Back office</Select.Option>
        </Select>
        <Select style={{ minWidth: 140 }} mode='multiple' placeholder='Bağlama statusu' value={declarationsStatusId} showSearch={true} allowClear={true} onChange={onDeclarationsStatusChange}>
          {declarationStatus.data?.map((status) => (
            <Select.Option key={status.id} value={status.id}>
              {status.name}
            </Select.Option>
          ))}
        </Select>
      </Space>
    </Fragment>
  );
};
