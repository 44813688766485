import * as React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { InversifyProvider } from '@core/inversify-react';
import { TableCacheProvider } from '@core/table';
import './infra/container/bindings';

import { container } from '@infra/container';
import { AuthProvider } from '@modules/auth';
import { CounterProvider } from '@modules/counter';
import { LayoutProvider } from '@modules/layout';
import { SettingsProvider } from '@modules/settings';
import { CurrencyRatesProvider } from '@modules/currency-rates';

import { NextRouter } from './router';

export const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } });

export const NextModule = () => {
  return (
    <LayoutProvider>
      <CounterProvider>
        <NextRouter />
      </CounterProvider>
    </LayoutProvider>
  );
};

export const NextAdapter: React.FC = ({ children }) => {
  return (
    <InversifyProvider container={container}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <TableCacheProvider>
            <SettingsProvider>
              <CurrencyRatesProvider>{children}</CurrencyRatesProvider>
            </SettingsProvider>
          </TableCacheProvider>
        </AuthProvider>
      </QueryClientProvider>
    </InversifyProvider>
  );
};
