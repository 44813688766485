import React, { useCallback, useMemo } from 'react';
import { Button, Dropdown, Menu, Modal, Space } from 'antd';
import { AppstoreAddOutlined, CheckCircleOutlined, ClearOutlined, CloseCircleOutlined, DeleteOutlined, PlusCircleOutlined, ReloadOutlined, UnorderedListOutlined } from '@ant-design/icons/lib';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { tableSelector } from '../../selectors/table';
import { tableFetchDataAction, tableResetAction, tableSelectAllAction, tableSetSelectedRowIdsAction } from '../../actions/table';
import { changeStateAction, removeAction } from '../../actions/tickets';
import routes from '../../constants/routes';

const TicketsActions = () => {
  const dispatch = useDispatch();
  const table = useSelector(tableSelector('tickets'));
  const history = useHistory();

  const selection = useMemo(() => {
    return Object.keys(table.selectedRowIds);
  }, [table.selectedRowIds]);

  const clearSelection = useCallback(() => {
    dispatch(tableSetSelectedRowIdsAction('tickets', {}));
  }, [dispatch]);

  const resetTable = useCallback(() => {
    dispatch(tableResetAction('tickets'));
  }, [dispatch]);

  const fetchTableData = useCallback(() => {
    dispatch(tableFetchDataAction('tickets', '/api/admin/tickets/list'));
  }, [dispatch]);

  const handleChangeState = useCallback(
    (stateId: number) => {
      dispatch(changeStateAction(selection, stateId));
    },
    [dispatch, selection],
  );

  const handleDelete = useCallback(() => {
    const action = () => removeAction(selection)(dispatch).catch(() => null);

    Modal.confirm({
      title: 'Təsdiqlə',
      content: `${selection.length} ədəd kuryer seçilib. Silməyə əminsinizmi?`,
      onOk: action,
    });
  }, [selection, dispatch]);

  const handleSelectAll = useCallback(() => {
    dispatch(tableSelectAllAction('tickets'));
  }, [dispatch]);

  const goToCategories = useCallback(() => {
    history.push(routes.tickets.categories.index);
  }, [history]);

  const goToCreate = useCallback(() => {
    history.push(routes.tickets.create);
  }, [history]);

  return (
    <div className='actions-wrapper'>
      <div className='actions-wrapper-left'>
        <Space size={8}>
          <Button type='primary' onClick={goToCreate} icon={<PlusCircleOutlined />}>
            Yeni
          </Button>
          {!!selection.length && (
            <Button className='actions-wrapper-selection-button' icon={<CloseCircleOutlined />} onClick={clearSelection}>
              {selection.length} sətir seçilib
            </Button>
          )}
          {!selection.length && (
            <Button icon={<CheckCircleOutlined />} onClick={handleSelectAll}>
              Hamısını seç
            </Button>
          )}
          <Button icon={<ClearOutlined />} onClick={resetTable}>
            Sıfırla
          </Button>
          <Button icon={<ReloadOutlined />} onClick={fetchTableData}>
            Yenilə
          </Button>
          <Button type='primary' icon={<UnorderedListOutlined />} onClick={goToCategories}>
            Kateqoriyalar
          </Button>
        </Space>
      </div>
      <div className='actions-wrapper-right'>
        <Space size={8}>
          <Dropdown
            trigger={['click']}
            disabled={!selection.length}
            overlay={
              <Menu>
                <Menu.Item onClick={() => handleChangeState(32)}>Qəbul edilib</Menu.Item>
                <Menu.Item onClick={() => handleChangeState(33)}>Baxılır</Menu.Item>
                <Menu.Item onClick={() => handleChangeState(34)}>Açıqdır</Menu.Item>
                <Menu.Item onClick={() => handleChangeState(35)}>Bağlıdır</Menu.Item>
              </Menu>
            }
          >
            <Button type='primary' icon={<AppstoreAddOutlined />}>
              Statusu dəyiş
            </Button>
          </Dropdown>
          <Button ghost={true} disabled={!selection.length} type='primary' danger={true} onClick={handleDelete} icon={<DeleteOutlined />}>
            Sil
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default TicketsActions;
