import React, { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Badge, Menu } from 'antd';
import {
  BuildOutlined,
  CodeSandboxOutlined,
  DropboxOutlined,
  FileSearchOutlined,
  FormOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
  MailOutlined,
  MoneyCollectOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  RocketOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  SnippetsOutlined,
  StarOutlined,
  TabletOutlined,
  TranslationOutlined,
  UserOutlined,
  WalletOutlined,
  MessageOutlined,
  ExceptionOutlined,
  NotificationOutlined,
  TableOutlined,
  DatabaseOutlined,
  FileImageOutlined,
  BorderOutlined,
  HistoryOutlined,
  ShoppingOutlined,
  RollbackOutlined,
  SettingOutlined,
  DeleteOutlined,
  AuditOutlined,
  FileTextOutlined,
  OrderedListOutlined,
  UserSwitchOutlined,
  InboxOutlined,
  MobileOutlined,
  GiftOutlined,
} from '@ant-design/icons';
import { MenuProps } from 'antd/es/menu';
import { useSelector } from 'react-redux';
import { baseModelSelector } from '../../../selectors/common';
import { ICounterState } from '../../../interfaces/counter/state.interface';
import { usePermissions } from '../../../hooks/permissions/usePermissions';
import * as Icons from '@ant-design/icons';

const SidebarMenu: FC<MenuProps> = (props) => {
  const location = useLocation();
  const { can } = usePermissions();
  const counter = useSelector(baseModelSelector<ICounterState>('counter'));

  return (
    <Menu id='mainSidebarMenu' selectedKeys={[location.pathname]} theme='dark' mode='inline' {...props}>
      <Menu.ItemGroup title='Ümumi'>
        {can('stat') && (
          <Menu.Item key='/@next/statistics' icon={<LineChartOutlined />}>
            Statistika
            <NavLink to='/@next/statistics' />
          </Menu.Item>
        )}
        {can('client_appointment') && (
          <Menu.Item key='/@next/appointment' icon={<FileSearchOutlined />}>
            Müştəri qəbul
            <NavLink to='/@next/appointment' />
          </Menu.Item>
        )}
        {can('local_warehouse') && (
          <Menu.Item key='/@next/warehouse/handover/queues' icon={<UserSwitchOutlined />}>
            Anbardar təhvil
            <NavLink to='/@next/warehouse/handover/queues' />
          </Menu.Item>
        )}
        {can('cashflow') && (
          <Menu.Item key='/@next/cash-flow' icon={<Icons.MoneyCollectOutlined />}>
            Cashflow
            <NavLink to='/@next/cash-flow' />
          </Menu.Item>
        )}
        <Menu.Item key='/@next/users' icon={<UserOutlined />}>
          İstifadəçilər
          <NavLink to='/@next/users' />
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title='Müştərilər'>
        <Menu.Item key='/@next/orders' icon={<ShoppingCartOutlined />}>
          <Badge offset={[12, 0]} className='gray-menu-badge' count={counter.data.orders}>
            Sifarişlər
          </Badge>
          <NavLink to='/@next/orders' />
        </Menu.Item>
        <Menu.Item key='/@next/declarations' icon={<ReconciliationOutlined />}>
          <Badge offset={[12, 0]} className='gray-menu-badge' count={counter.data.declarations}>
            Bağlamalar
          </Badge>
          <NavLink to='/@next/declarations' />
        </Menu.Item>
        <Menu.Item key='/@next/declarations/post' icon={<ReconciliationOutlined />}>
          <Badge offset={[12, 0]} className='gray-menu-badge' count={counter.data.declarations}>
            Bəyan sonrası bağlamalar
          </Badge>
          <NavLink to='/@next/declarations/post' />
        </Menu.Item>
        {can('dgk_declarations') && (
          <Menu.Item key='/@next/customs/declarations' icon={<ExceptionOutlined />}>
            Bəyannamələr
            <NavLink to='/@next/customs/declarations' />
          </Menu.Item>
        )}
        <Menu.Item key='/@next/declarations/deleted' icon={<DeleteOutlined />}>
          Silinmiş bəyannamələr
          <NavLink to='/@next/declarations/deleted' />
        </Menu.Item>
        <Menu.Item key='/@next/customs/dns-queues' icon={<OrderedListOutlined />}>
          BBS növbələri
          <NavLink to='/@next/customs/dns-queues' />
        </Menu.Item>
        {can('dgk_declarations') && (
          <Menu.Item key='/@next/customs/posts' icon={<AuditOutlined />}>
            DGK Bağlamalar
            <NavLink to='/@next/customs/posts' />
          </Menu.Item>
        )}
        <Menu.Item key='/@next/couriers' icon={<DropboxOutlined />}>
          <Badge offset={[12, 0]} className='gray-menu-badge' count={counter.data.couriers}>
            Kuryerlər
          </Badge>
          <NavLink to='/@next/couriers' />
        </Menu.Item>
        <Menu.Item key='/@next/transactions' icon={<MoneyCollectOutlined />}>
          Balans əməliyyatları
          <NavLink to='/@next/transactions' />
        </Menu.Item>
        <Menu.Item key='/@next/declarations/unknowns' icon={<CodeSandboxOutlined />}>
          <Badge offset={[12, 0]} className='gray-menu-badge' count={counter.data.conflictedDeclarations}>
            Mübahisəli bağlamalar
          </Badge>
          <NavLink to='/@next/declarations/unknowns' />
        </Menu.Item>
        {can('flights') && (
          <Menu.Item key='/@next/flights' icon={<RocketOutlined />}>
            Flights
            <NavLink to='/@next/flights' />
          </Menu.Item>
        )}
        <Menu.Item key='/@next/supports' icon={<MessageOutlined />}>
          <Badge offset={[12, 0]} className='gray-menu-badge' count={counter.data.tickets}>
            Müraciətlər
          </Badge>
          <NavLink to='/@next/supports' />
        </Menu.Item>
      </Menu.ItemGroup>
      {can('bon_cashback') && (
        <Menu.ItemGroup title='Bon keşbək'>
          <Menu.Item key='/bonaz/conversions' icon={<TableOutlined />}>
            Konversiyalar
            <NavLink to='/bonaz/conversions' />
          </Menu.Item>
          <Menu.Item key='/bonaz/offers' icon={<TableOutlined />}>
            Təkliflər
            <NavLink to='/bonaz/offers' />
          </Menu.Item>
        </Menu.ItemGroup>
      )}
      <Menu.ItemGroup title='Məzmun'>
        <Menu.Item key='/@next/notifier/sms' icon={<TabletOutlined />}>
          SMS Arxivi
          <NavLink to='/@next/notifier/sms' />
        </Menu.Item>
        <Menu.Item key='/@next/notifier/email' icon={<MailOutlined />}>
          Mail Arxivi
          <NavLink to='/@next/notifier/email' />
        </Menu.Item>
        <Menu.Item key='/@next/notifier/mobile/bulk/send' icon={<MobileOutlined />}>
          Toplu bildiriş
          <NavLink to='/@next/notifier/mobile/bulk/send' />
        </Menu.Item>
        {can('state_changes') && (
          <Menu.Item key='/archive/state' icon={<DatabaseOutlined />}>
            Status Arxivi
            <NavLink to='/archive/state' />
          </Menu.Item>
        )}
        {can('my_logs') && (
          <Menu.Item key='/logs' icon={<HistoryOutlined />}>
            Əməliyyat Arxivi
            <NavLink to='/logs' />
          </Menu.Item>
        )}
        {can('notification_templates') && (
          <Menu.Item key='/@next/notifier/templates' icon={<NotificationOutlined />}>
            Bildiriş şablonları
            <NavLink to='/@next/notifier/templates' />
          </Menu.Item>
        )}
        <Menu.Item key='/ticket_templates' icon={<FileTextOutlined />}>
          Müraciət şablonları
          <NavLink to='/ticket_templates' />
        </Menu.Item>
        {can('news') && (
          <Menu.Item key='/news' icon={<ReadOutlined />}>
            Xəbərlər
            <NavLink to='/news' />
          </Menu.Item>
        )}
        {can('faq') && (
          <Menu.Item key='/faq' icon={<QuestionCircleOutlined />}>
            Tez-tez verilən suallar
            <NavLink to='/faq' />
          </Menu.Item>
        )}
        {can('shops') && (
          <Menu.Item key='/shops' icon={<ShopOutlined />}>
            Mağazalar
            <NavLink to='/shops' />
          </Menu.Item>
        )}
        {can('about') && (
          <Menu.Item key='/about' icon={<InfoCircleOutlined />}>
            Haqqında
            <NavLink to='/about' />
          </Menu.Item>
        )}
        <Menu.Item key='/transportation_conditions' icon={<SnippetsOutlined />}>
          Daşınma şərtləri
          <NavLink to='/transportation_conditions' />
        </Menu.Item>
        <Menu.Item key='/banners' icon={<FileImageOutlined />}>
          Bannerlər
          <NavLink to='/banners' />
        </Menu.Item>
        <Menu.Item key='/@next/popups' icon={<BorderOutlined />}>
          Popuplar
          <NavLink to='/@next/popups' />
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title='Proqram ayarları'>
        {can('system_settings') && (
          <Menu.Item key='/@next/settings' icon={<SettingOutlined />}>
            Sistem ayarları
            <NavLink to='/@next/settings' />
          </Menu.Item>
        )}
        <Menu.Item key='/@next/coupons' icon={<GiftOutlined />}>
          <Badge offset={[12, 0]} className='gray-menu-badge'>
            Kuponlar
          </Badge>
          <NavLink to='/@next/coupons' />
        </Menu.Item>
        <Menu.Item key='/@next/refunds' icon={<Icons.RollbackOutlined />}>
          İadələr
          <NavLink to='/@next/refunds' />
        </Menu.Item>
        <Menu.Item key='/@next/boxes' icon={<InboxOutlined />}>
          Yeşiklər
          <NavLink to='/@next/boxes' />
        </Menu.Item>
        <Menu.Item key='/@next/shops' icon={<ShoppingOutlined />}>
          Mağaza reyestri
          <NavLink to='/@next/shops' />
        </Menu.Item>
        <Menu.Item key='/@next/return-types' icon={<RollbackOutlined />}>
          İadə səbəbləri
          <NavLink to='/@next/return-types' />
        </Menu.Item>
        {can('tarifs') && (
          <Menu.Item key='/@next/plans' icon={<FormOutlined />}>
            Tariflər
            <NavLink to='/@next/plans' />
          </Menu.Item>
        )}
        {can('producttypes') && (
          <Menu.Item key='/@next/product-types' icon={<BuildOutlined />}>
            Məhsul tipləri
            <NavLink to='/@next/product-types' />
          </Menu.Item>
        )}
        {can('regions') && (
          <Menu.Item key='@next/regions' icon={<TranslationOutlined />}>
            Rayonlar
            <NavLink to='@next/regions' />
          </Menu.Item>
        )}
        {can('models') && (
          <Menu.Item key='/@next/models' icon={<StarOutlined />}>
            Modellər
            <NavLink to='/@next/models' />
          </Menu.Item>
        )}
        {can('states') && (
          <Menu.Item key='/@next/status' icon={<WalletOutlined />}>
            Statuslar
            <NavLink to='/@next/status' />
          </Menu.Item>
        )}
      </Menu.ItemGroup>
    </Menu>
  );
};

export default React.memo(SidebarMenu);
