import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { useTitle } from '../../hooks/common/useTitle';
import { tableSetFiltersAction } from '../../actions/table';
import ReadyTable from '../../components/Table/ReadyTable';
import { useSearchParams } from '../../next/shared/hooks/use-search-params';
import { useDeclarationsTableColumns } from '../../next/modules/declarations/hooks/use-table-columns';
import { useCouriersTableColumns } from '../../hooks/couriers/useTableColumns';

const DashboardTransactionsPage: FC = () => {
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - Balans əməliyyatları`);

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ model: string }>();
  const { searchParams } = useSearchParams<{ start_date?: string; end_date?: string; model_id?: string; payment_type?: string }>();

  const declarationsTableColumns = useDeclarationsTableColumns();
  const couriersTableColumns = useCouriersTableColumns();

  useEffect(() => {
    dispatch(
      tableSetFiltersAction(
        'dashboard.transactions.declarations',
        Object.entries(searchParams).map(([id, value]) => ({ id, value })),
      ),
    );
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(
      tableSetFiltersAction(
        'dashboard.transactions.couriers',
        Object.entries(searchParams).map(([id, value]) => ({ id, value })),
      ),
    );
  }, [dispatch, searchParams]);

  if (!(params.model === 'couriers' || params.model === 'declarations')) {
    return null;
  }

  if (params.model === 'declarations') {
    return (
      <Modal visible={true} width={992} footer={null} closable={false} onCancel={() => history.push('/dashboard')}>
        <ReadyTable name='dashboard.transactions.declarations' apiUrl='/api/admin/stats/payment_type_by_model_list' columns={declarationsTableColumns} />
      </Modal>
    );
  }

  if (params.model === 'couriers') {
    return (
      <Modal visible={true} width={992} footer={null} closable={false} onCancel={() => history.push('/dashboard')}>
        <ReadyTable name='dashboard.transactions.couriers' apiUrl='/api/admin/stats/payment_type_by_model_list' columns={couriersTableColumns} />
      </Modal>
    );
  }

  return null;
};

export default React.memo(DashboardTransactionsPage);
