import React, { useMemo } from 'react';
import { Popover } from 'antd';
import { Column } from 'react-table';
import { InfoCircleOutlined } from '@ant-design/icons';
import { smallCol } from '../../core/helpers/table';

export const useColumns = () => {
  return useMemo<Column<any>[]>(
    () => [
      {
        ...smallCol,
        accessor: 'goodsList',
        Header: 'Goods List',
        Cell: ({ cell: { value } }) => {
          const overlay = (
            <table>
              <tbody>
                {value.map((good) => (
                  <tr>
                    <td>{good['namE_OF_GOODS']}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          );

          return (
            <Popover destroyTooltipOnHide={true} content={overlay}>
              <span>
                {value.length} <InfoCircleOutlined />
              </span>
            </Popover>
          );
        },
      },
      {
        accessor: 'airwaybill',
        Header: 'Air Waybill',
      },
      {
        accessor: 'currencY_TYPE',
        Header: 'Curreny Type',
      },
      {
        accessor: 'depesH_DATE',
        Header: 'Depesh Date',
      },
      {
        accessor: 'depesH_NUMBER',
        Header: 'Depesh Number',
      },
      {
        accessor: 'direction',
        Header: 'Direction',
      },
      {
        accessor: 'documenT_TYPE',
        Header: 'Document Type',
      },
      {
        accessor: 'ecoM_REGNUMBER',
        Header: 'Ecom Reg Number',
      },
      {
        accessor: 'fin',
        Header: 'FIN',
      },
      {
        accessor: 'goodS_TRAFFIC_FR',
        Header: 'Goods Traffic FR',
      },
      {
        accessor: 'idxaL_ADRESS',
        Header: 'Idxal Address',
      },
      {
        accessor: 'idxaL_NAME',
        Header: 'Idxal Name',
      },
      {
        accessor: 'inserT_DATE',
        Header: 'Insert Date',
      },
      {
        accessor: 'invoyS_AZN',
        Header: 'Invoice AZN',
      },
      {
        accessor: 'invoyS_PRICE',
        Header: 'Invoice Price',
      },
      {
        accessor: 'invoyS_USD',
        Header: 'Invoice USD',
      },
      {
        accessor: 'phone',
        Header: 'Phone',
      },
      {
        accessor: 'quantitY_OF_GOODS',
        Header: 'Quantity of goods',
      },
      {
        accessor: 'status',
        Header: 'Status',
      },
      {
        accessor: 'trackinG_NO',
        Header: 'Tracking NO',
      },
      {
        accessor: 'transP_COSTS',
        Header: 'Trans Costs',
      },
      {
        accessor: 'weighT_GOODS',
        Header: 'Weight Goods',
      },
    ],
    [],
  );
};
