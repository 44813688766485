import flatten from 'flat';
import { formDataFlatter } from './form-data-flatter';

export const toSearch = (object: Record<string, any>): string => {
  const flattened = formDataFlatter(flatten(object));
  const arrayedObject = Object.entries(flattened);
  return arrayedObject.length
    ? arrayedObject.reduce((acc, [key, value], index) => {
        return acc + (index ? '&' : '?') + `${key}=${value}`;
      }, '')
    : '';
};
