import React, { FC, memo, useCallback, useMemo } from 'react';
import { Form, DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { FormItemProps } from 'antd/es/form';
import { FieldConfig, useField } from 'formik';
import { Moment } from 'moment';
import { twoLevelShallowEqualObjects } from '../../../utils/two-level-shallow-equal-objects';

const { RangePicker } = DatePicker;

export type RangeFieldProps = FieldConfig & { item?: FormItemProps; input?: RangePickerProps };
export type MemoizedRangeFieldProps = {
  item?: FormItemProps;
  input?: RangePickerProps;
  field: any;
  meta: any;
};

const MemoizedRangeField = memo<MemoizedRangeFieldProps>(({ meta, input, field, item }) => {
  const style = useMemo(() => ({ width: '100%' }), []);

  return (
    <Form.Item {...item} validateStatus={meta.touched && !!meta.error ? 'error' : undefined} help={meta.error}>
      <RangePicker style={style} {...field} {...input} />
    </Form.Item>
  );
}, twoLevelShallowEqualObjects);

export const RangeField: FC<RangeFieldProps> = ({ item, input, ...props }) => {
  const [{ onChange: baseOnChange, ...field }, meta] = useField(props);

  const onChange = useCallback(
    (value: [Moment | null, Moment | null] | null) => {
      baseOnChange(field.name)({ target: { type: 'text', value } } as any);
    },
    [baseOnChange, field.name],
  );

  const newField = useMemo(() => ({ ...field, onChange }), [field, onChange]);

  return <MemoizedRangeField field={newField} meta={meta} item={item} input={input} />;
};
