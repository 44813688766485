import { useCallback, useMemo } from 'react';
import { message } from 'antd';
import { SubmissionError } from 'redux-form';
import flatten from 'flat';

import { formDataBuilder } from '../../../core/utils/form-data-builder';
import { caller } from '../../../core/utils/caller';
import { UnexpectedError, ValidationError, ValidationMessage } from '../../../core/helpers/errors';

export const useSubmit = ({ baseSubmit, method, putUrl, postUrl, additionalParams, middlewares, onSuccess, onError, validationWithMessage }: Record<any, any>) => {
  const url = useMemo(() => {
    switch (method) {
      case 'POST':
        return postUrl;
      case 'PUT':
        return putUrl;
    }
  }, [method, postUrl, putUrl]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = useCallback(
    baseSubmit(async (values) => {
      try {
        const mutatedValues = middlewares
          ? middlewares.reduce((acc, middleware) => {
              return middleware(acc);
            }, values)
          : values;

        const body = formDataBuilder({
          values: { ...mutatedValues, ...additionalParams },
        });
        const response = await caller(url, { method, body });

        if (response.ok) {
          if (onSuccess) {
            onSuccess(response);
          }
        } else if (response.status === 400 || response.status === 422) {
          const { errors } = await response.json();
          const unFlattenErrors = flatten.unflatten(errors);

          onError?.(errors);
          if (validationWithMessage) {
            ValidationMessage(unFlattenErrors);
          }
          message.warn('Məlumatlar yalnış daxil edilib');
          ValidationError(unFlattenErrors || {});
        } else {
          UnexpectedError();
        }
      } catch (e) {
        if (e instanceof SubmissionError) {
          throw e;
        } else {
          message.error('Xəta baş verdi');
        }
      }
    }),
    [baseSubmit, url],
  );

  return useMemo(() => handleSubmit, [handleSubmit]);
};
