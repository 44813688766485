import { useCallback, useMemo } from 'react';
import { TableFetchParams, tableQueryMaker, TableState } from '@core/table';
import { useService } from '@core/inversify-react';

import { GetPostDeclarationsRepoType, IGetPostDeclarationsRepo } from '../../repos';
import { declarationKeys } from '../../utils';

export const usePostDeclarationTableContext = () => {
  const getPostDeclarationsRepo = useService<IGetPostDeclarationsRepo>(GetPostDeclarationsRepoType);

  const handleFetch = useCallback(
    async (params: TableFetchParams) => {
      const result = await getPostDeclarationsRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getPostDeclarationsRepo],
  );

  const defaultState = useMemo<Partial<TableState>>(
    () => ({
      filters: [{ id: declarationKeys.read, value: false }],
    }),
    [],
  );

  return { handleFetch, defaultState };
};
