import React, { FC } from 'react';
import { Row, TableBodyPropGetter, TableBodyProps } from 'react-table';
import TableRow from './TableRow';

type ITableBodyProps = {
  getTableBodyProps: (propGetter?: TableBodyPropGetter<any>) => TableBodyProps;
  rows: Row[];
  prepareRow: (row: Row<any>) => void;
  selectedRowIds: Record<string | number, true>;
};

const TableBody: FC<ITableBodyProps & { getRowProps?: Function }> = ({ getTableBodyProps, rows, prepareRow, selectedRowIds, getRowProps }) => {
  return (
    <div {...getTableBodyProps({ className: 'next-table-tbody' })}>
      {rows.map((row: any) => {
        return <TableRow selected={selectedRowIds[row.id]} key={row.id} getRowProps={getRowProps} prepareRow={prepareRow} row={row} />;
      })}
    </div>
  );
};

export default React.memo<ITableBodyProps & { getRowProps?: Function }>(TableBody, (prev, curr) => {
  return prev.rows === curr.rows && prev.selectedRowIds === curr.selectedRowIds;
});
