import { useType } from './useType';
import { useCallback, useMemo } from 'react';
import { useSubmit } from './useSubmit';
import { useInitialize } from './useInitialize';

export const useForm = ({
  initialize,
  handleClose,
  onSuccess,
  handleSubmit: baseHandleSubmit,
  submitMiddlewares,
  onError,
  initializeMiddlewares,
  initializeUrl,
  createApiUrl,
  editApiUrl,
  createUrl,
  additionalParams,
}: any) => {
  const type = useType(createUrl);

  const handleSubmitSuccess = useCallback(
    (response) => {
      if (type === 'create') {
        if (initialize) {
          initialize({});
        }
      } else {
        handleClose();
      }
      if (onSuccess) {
        onSuccess(response);
      }
    },
    [handleClose, type, onSuccess], //eslint-disable-line react-hooks/exhaustive-deps
  );

  const submitOptions = useMemo(
    () => ({
      baseSubmit: baseHandleSubmit,
      method: 'POST',
      postUrl: type === 'create' ? createApiUrl : editApiUrl,
      additionalParams: type === 'edit' ? additionalParams : undefined,
      onSuccess: handleSubmitSuccess,
      onError: onError,
      middlewares: submitMiddlewares,
    }),
    [baseHandleSubmit, type, createApiUrl, editApiUrl, additionalParams, handleSubmitSuccess, onError, submitMiddlewares],
  );

  const handleSubmit = useSubmit(submitOptions);

  const initializeOptions = useMemo(
    () => ({
      url: initializeUrl,
      onSuccess: initialize,
      disabled: type === 'create',
      middlewares: initializeMiddlewares,
    }),
    [initializeUrl, initialize, initializeMiddlewares, type],
  );

  const handleInitialize = useInitialize(initializeOptions);

  return useMemo(() => ({ handleSubmit, type, handleInitialize }), [handleSubmit, type, handleInitialize]);
};
