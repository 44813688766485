import React, { useCallback, useMemo } from 'react';
import { actionsCol, dateCol, idCol } from '../../core/helpers/table';
import { OverflowCell } from '../../components/Table/core/Components/Cell/OverflowCell';
import { stopPropagation } from '../../core/helpers/events';
import { Button, Dropdown, Menu } from 'antd';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { removeAction } from '../../actions/popups';

export const usePopupsTable = () => {
  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        ...actionsCol,
        Cell: ({ row: { original } }) => {
          const handleRemove = useCallback(() => {
            removeAction([original.id])(dispatch).catch(() => null);
          }, [original.id]);

          return (
            <span onClick={stopPropagation}>
              <Dropdown
                placement='bottomLeft'
                overlay={
                  <Menu>
                    <Menu.Item onClick={handleRemove}>
                      <DeleteOutlined />
                      <span>Sil</span>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button className='next-table-action-btn' type='text' icon={<MoreOutlined />} />
              </Dropdown>
            </span>
          );
        },
      },
      {
        ...idCol,
      },
      {
        accessor: 'message',
        Header: 'Mətn',
        Cell: OverflowCell,
      },
      {
        accessor: 'created_at',
        Header: 'Yaradılıb',
        ...dateCol,
      },
    ],
    [dispatch],
  );

  return { columns };
};
