import React, { FC, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Card, Col, Empty, Result, Row, Tabs } from 'antd';
import ContentCard from '../containers/Layout/Sidebar/Content/Card';
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import routes from '../constants/routes';
import { shopsFetchAction } from '../actions/shops';
import { useDispatch, useSelector } from 'react-redux';
import { baseModelSelector } from '../selectors/common';
import { IShopsState } from '../interfaces/shops/state';
import { ReloadOutlined } from '@ant-design/icons/lib';
import { makePath } from '../core/utils/makePath';
import { useTitle } from '../hooks/common/useTitle';

const { TabPane } = Tabs;
const { Meta } = Card;

const ShopsPage: FC<RouteComponentProps> = ({ history }) => {
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - Mağazalar`);

  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(baseModelSelector<IShopsState>('shops'));

  const gotoCreate = useCallback(() => {
    history.push(routes.shops.create);
  }, [history]);

  const handleFetch = useCallback(() => {
    dispatch(shopsFetchAction());
  }, [dispatch]);

  const gotoEdit = useCallback(
    (id) => {
      history.push(makePath(routes.shops.edit, { id }));
    },
    [history],
  );

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return (
    <ContentCard className='shops-page content-card-scrollable'>
      {loading && (
        <Row gutter={[16, 16]}>
          {new Array(20).fill(null).map((_, index) => {
            return (
              <Col key={index} span={6}>
                <Card cover={<div style={{ height: 203, width: '100%' }} />} loading={true} actions={[<EditOutlined key='edit-action' />, <DeleteOutlined key='delete-action' />]} />
              </Col>
            );
          })}
        </Row>
      )}
      {!loading && !error && (
        <Tabs
          className='shops-page-tab'
          tabBarExtraContent={
            <Button type='primary' onClick={gotoCreate} icon={<PlusCircleOutlined />}>
              Yeni
            </Button>
          }
        >
          {data.map((category) => (
            <TabPane key={category.category_id} tab={category.category_name}>
              <Row gutter={[16, 16]}>
                {!category.shops.length && (
                  <div className='absolute-empty'>
                    <Empty description={false} />
                  </div>
                )}
                {category.shops.map((shop) => {
                  return (
                    <Col key={shop.id} span={6}>
                      <Card
                        className='shops-page-card'
                        actions={[<EditOutlined key='edit-action' onClick={() => gotoEdit(shop.id)} />, <DeleteOutlined key='delete-action' />]}
                        hoverable
                        cover={<img alt={shop.label} src={shop.logo.replace('api2', 'api')} />}
                      >
                        <Meta title={shop.label} description={shop.url} />
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            </TabPane>
          ))}
        </Tabs>
      )}
      {!loading && error && (
        <Result
          className='absolute-result'
          extra={
            <Button onClick={handleFetch} type='primary' icon={<ReloadOutlined />}>
              Yenilə
            </Button>
          }
          title='Məlumatlar əldə edilə bilmədi'
          status='warning'
        />
      )}
    </ContentCard>
  );
};

export default React.memo(ShopsPage);
