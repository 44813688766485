import React, { FC } from 'react';

import ReadyTable from '../../components/Table/ReadyTable';
import { useLogsTable } from '../../hooks/logs/useTable';

const LogsTable: FC = () => {
  const { columns } = useLogsTable();

  return <ReadyTable columns={columns} name='logs' apiUrl='/api/admin/logs' />;
};

export default LogsTable;
