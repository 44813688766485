import React, { FC } from 'react';
import { Col, Form, Row, Spin } from 'antd';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { RouteComponentProps } from 'react-router-dom';

import FormModal from '../../components/Common/FormModal';
import { useFormLayout } from '../../hooks/common/useFormLayout';
import { useTicketTemplatesForm } from '../../hooks/ticketTemplates/useForm';

const TicketTemplatesForm: FC<InjectedFormProps & RouteComponentProps<any>> = (props) => {
  const { fields, handleSubmit, title, close, initializing } = useTicketTemplatesForm(props);
  const layout = useFormLayout();

  return (
    <FormModal title={title} onCancel={close} confirmLoading={props.submitting} onOk={handleSubmit}>
      <Spin spinning={initializing}>
        <Form {...layout.form}>
          <Row {...layout.row}>
            <Col {...layout.col[24]}>
              <Field {...fields.title} />
            </Col>
            <Col {...layout.col[24]}>
              <Field {...fields.body} />
            </Col>
          </Row>
        </Form>
      </Spin>
    </FormModal>
  );
};

export default reduxForm<any, any, any>({ form: 'ticketTemplates' })(TicketTemplatesForm);
