import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Column } from 'react-table';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tag } from 'antd';
import PriceCell from '../../components/Table/core/Components/Cell/PriceCell';
import CheckCell from '../../components/Table/core/Components/Cell/CheckCell';
import { StateCell } from '../../components/Orders/Cell/StateCell';
import ReadyTable from '../../components/Table/ReadyTable';
import { tableSetFiltersAction } from '../../actions/table';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';
import { dateCol, midCol } from '../../core/helpers/table';
import { UserInfoCell } from '../../components/Users/Cell/InfoCell';
import { StateFilter } from '../../components/Orders/Filter/StateFilter';
import { LinkOutlined } from '@ant-design/icons/lib';
import { stopPropagation } from '../../core/helpers/events';
import { CheckboxFilter } from '../../components/Table/core/Components/Filters/CheckboxFilter';

const OrdersTable: FC<{ defaultFilters?: any[] }> = ({ defaultFilters }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (defaultFilters) {
      dispatch(tableSetFiltersAction('orders', defaultFilters));
    }
  }, [defaultFilters, dispatch]);

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        accessor: 'user_id',
        Header: 'M. Kodu',
        ...midCol,
      },
      {
        accessor: 'user_name',
        Header: 'Müştəri',
        width: 200,
        Cell: UserInfoCell,
      },
      {
        accessor: 'track_code',
        Header: 'İzləmə kodu',
        width: 100,
        Cell: ({ cell: { value }, row: { original } }) => {
          const gotoUrl = useCallback(() => {
            window.open(`${process.env.REACT_APP_BONAZ_REDIRECT_URL}?order_id=${value}&url=${original.url}`, '_blank');
          }, [original.url, value]);

          return value ? (
            <Tag onClick={stopPropagation} color={!original.is_new ? 'green' : undefined}>
              #{value}&nbsp;
              <LinkOutlined disabled={!original.url} onClick={gotoUrl} />
            </Tag>
          ) : null;
        },
      },
      {
        accessor: 'price',
        Header: 'Qiymət',
        width: 100,
        Cell: PriceCell.TRY,
      },
      {
        accessor: 'cargo_price',
        Header: 'T.D.K qiyməti',
        width: 100,
        Cell: PriceCell.TRY,
      },
      {
        accessor: 'payed',
        Header: 'Ödənilmə statusu',
        width: 90,
        Cell: CheckCell,
        Filter: CheckboxFilter,
      },
      {
        accessor: 'quantity',
        Header: 'Say',
        width: 75,
      },
      {
        accessor: 'state_id',
        Header: 'Status',
        width: 150,
        Cell: StateCell,
        Filter: StateFilter,
      },
      {
        accessor: 'waiting',
        Header: 'Sifariş gözlənilir',
        ...dateCol,
      },
      {
        accessor: 'created_at',
        Header: 'Yaradılıb',
        ...dateCol,
      },
      {
        accessor: 'executive',
        Header: 'S.D edən',
        Cell: ({ cell: { value } }) => <div className='overflow-cell'>{value}</div>,
      },
    ],
    [],
  );

  const rowProps = useCallback(
    (id) => ({
      onDoubleClick: () => {
        history.push(makePath(routes.orders.info, { id }));
      },
    }),
    [history],
  );

  return <ReadyTable getRowProps={rowProps} name='orders' apiUrl='/api/admin/v2/orders/getlist' columns={columns} />;
};

export default React.memo(OrdersTable);
