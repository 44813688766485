import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';
import { GetPaginatedCollectionRepoType } from '@infra/repos';
import type { GetIGetPaginatedCollectionRepo, IGetPaginatedCollectionRepoResponse } from '@infra/repos';

import { IUnknownDeclaration } from '../interfaces';
import { UnknownDeclarationMapper } from '../mappers';
import { IRepo } from '@core/repo';

export type IGetUnknownDeclarationsRepoResponse = IGetPaginatedCollectionRepoResponse<IUnknownDeclaration>;

export type IGetUnknownDeclarationsRepo = IRepo<[object], IGetUnknownDeclarationsRepoResponse>;

export const GetUnknownDeclarationsRepoType = Symbol.for('GetUnknownDeclarationsRepo');

@bind(GetUnknownDeclarationsRepoType)
export class GetUnknownDeclarationsRepo implements IGetUnknownDeclarationsRepo {
  constructor(
    @inject(HttpClientType) private readonly httpClient: IHttpClient,
    @inject(GetPaginatedCollectionRepoType) private readonly paginatedCollectionRepo: GetIGetPaginatedCollectionRepo<IUnknownDeclaration>,
  ) {}

  async execute(query: object) {
    return this.paginatedCollectionRepo.execute({ url: '/', query, mapper: UnknownDeclarationMapper.toDomain });
  }
}
