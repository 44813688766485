import { useCallback, useContext, useEffect } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useSub } from '@core/event-bus';
import { TableState } from '@core/table';

import { useUnknownDeclarationsTableColumns } from './';
import { UnknownDeclarationsTableContext } from '../../context';

export const useUnknownDeclarationsTable = () => {
  const { fetch, mergeState } = useContext(UnknownDeclarationsTableContext);

  const history = useHistory();
  const location = useLocation<{ unknownDeclarationsTable?: { mergeState?: Partial<TableState> } }>();
  const columns = useUnknownDeclarationsTableColumns();

  const getRowProps = useCallback(
    (id) => ({
      onDoubleClick: () => {
        history.push(generatePath('/@next/declarations/unknowns/:id/update', { id }), { background: location });
      },
    }),
    [history, location],
  );

  useSub(
    [
      '@declarations/unknowns/create/succeed',
      '@declarations/unknowns/update/succeed',
      '@declarations/unknowns/handover/succeed',
      '@declarations/unknowns/update-status/succeed',
      '@declarations/unknowns/bulk-update–status/succeed',
    ],
    fetch,
  );

  useEffect(() => {
    if (location.state?.unknownDeclarationsTable?.mergeState) {
      mergeState(location.state.unknownDeclarationsTable.mergeState);
    }
  }, [location.state?.unknownDeclarationsTable?.mergeState, mergeState]);

  return { columns, getRowProps };
};
