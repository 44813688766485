import { ITransaction, ITransactionPersistence } from '@modules/transactions';

export class TransactionMapper {
  public static toDomain(transaction: ITransactionPersistence): ITransaction {
    return {
      id: transaction.id,
      object: {
        id: transaction.object_id,
        model: {
          id: transaction.model_id,
          name: transaction.model_name,
        },
      },
      user: {
        id: transaction.user_id,
        name: transaction.user_name,
      },
      amount: {
        value: parseFloat(transaction.amount),
        currency: transaction.currency.toLowerCase(),
      },
      paymentType: {
        id: transaction.payment_type,
        name: transaction.payment_type_name,
      },
      type: {
        id: transaction.type,
        name: transaction.type_name,
      },
      status: {
        id: transaction.state_id,
        name: transaction.state_name,
      },
      confirmedBy: transaction.confirmed_by
        ? {
            id: 0,
            name: transaction.confirmed_by,
          }
        : null,
      createdAt: transaction.created_at,
      description: transaction.descr || '',
    };
  }
}
