import React, { useMemo } from 'react';
import { Card, Col, DatePicker, Divider, Row, Spin, Table, Typography } from 'antd';
import routes from '../../constants/routes';
import { useModelStatistics } from '../../hooks/dashboard/useModelStatistics';

const { RangePicker } = DatePicker;

const ClientsStatistics = () => {
  const useModelStatisticsOptions = useMemo(
    () => ({
      apiUrl: '/api/admin/stats/user_by_month',
      redirectUrl: routes.dashboard.clients.info,
    }),
    [],
  );

  const { date, handleChangeDate, loading, data, columns } = useModelStatistics(useModelStatisticsOptions);

  return (
    <Card size='small' className='dashboard-model-statistics-card'>
      <Spin spinning={loading}>
        <div className='dashboard-model-statistics-card-input-wrapper'>
          <span>
            <RangePicker value={date} onChange={handleChangeDate} />
          </span>
          <span />
        </div>
        <Divider style={{ margin: '12px 0' }} />
        <Row gutter={[8, 8]}>
          {data.map((month: { items: any[]; total: number }, index) => (
            <Col key={index} span={8}>
              <Table
                size='small'
                bordered={true}
                pagination={false}
                rowKey={(data) => data.key}
                dataSource={month.items}
                columns={columns}
                summary={() => (
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                      <Typography.Text strong={true}>Cəmi</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={0}>
                      <Typography.Text strong={true}>{month.total}</Typography.Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                )}
              />
            </Col>
          ))}
        </Row>
      </Spin>
    </Card>
  );
};

export default ClientsStatistics;
