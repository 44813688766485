import React, { FC, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Router as BaseRouter, Switch } from 'react-router-dom';

import MainLayout from './Layout';
import LoginPage from '../pages/Login';

import routes from '../constants/routes';
import Regions from '../pages/Regions';
import RegionsForm from './Regions/Form';
import Models from '../pages/Models';
import States from '../pages/States';
import StatesForm from './States/Form';
import ProductTypes from '../pages/ProductTypes';
import ProductTypesForm from './ProductTypes/Form';
import Plans from '../pages/Plans';
import PlansForm from './Plans/Form';
import Shops from '../pages/Shops';
import ShopsForm from './Shops/Form';
import About from '../pages/About';
import TransportationConditions from '../pages/TransportationConditions';
import SmsArchive from '../pages/SmsArchive';
import EmailArchive from '../pages/EmailArchive';
import FAQ from '../pages/FAQ';
import FAQForm from './FAQ/Form';
import News from '../pages/News';
import NewsForm from './News/Form';
import Flights from '../pages/Flights';
import FlightsForm from './Flights/Form';
import FlightsInfo from './Flights/Info';
import ConflictedDeclarations from '../pages/ConflictedDeclarations';
import ConflictedDeclarationsForm from './ConflictedDeclarations/Form';
import { useDispatch, useSelector } from 'react-redux';
import { authFetchAction } from '../actions/auth';
import { baseModelSelector } from '../selectors/common';
import { IAuthState } from '../interfaces/auth/state';
import ProtectedRoute from '../components/Common/ProtectedRoute';
import history from '../core/configs/history';
import DashboardPage from '../pages/Dashboard/Dashboard';
import DashboardSpecialBalanceDetails from './Dashboard/Special/BalanceDetails';
import DashboardSpecialDeclarationsDetails from './Dashboard/Special/DeclarationsDetails';
import DashboardOrdersTable from './Dashboard/Orders/Table';
import DashboardDeclarationsTable from './Dashboard/Declarations/Table';
import DashboardClientsTable from './Dashboard/Clients/Table';
import DashboardCouriersTable from './Dashboard/Couriers/Table';
import TicketsPage from '../pages/Tickets/Tickets';
import TicketsInfoPage from '../pages/Tickets/Info';
import TicketCategories from './Tickets/Categories';
import TicketsForm from './Tickets/Form';
import { TicketsStateHistory } from './Tickets/StateHistory';
import PlansCategories from './Plans/Categories';
import SmsArchiveBulkForm from './SmsArchive/BulkForm';
import { usePermissions } from '../hooks/permissions/usePermissions';
import NotificationTemplates from '../pages/NotificationTemplates/NotificationTemplates';
import NotificationTemplatesForm from './NotificationTemplates/Form';
import NotificationTemplatesInfo from './NotificationTemplates/Info';
import EmailArchiveBulkForm from './EmailArchive/BulkForm';
import BonazConversionsPage from '../pages/Bonaz/Conversions';
import BonazOffersPage from '../pages/Bonaz/Offers';
import FlightsUpdateThisMonthsForm from './Flights/UpdateThisMothsForm';
import StateArchivePage from '../pages/StateArchive';
import BannersPage from '../pages/Banners';
import BannersForm from './Banners/Form';
import PopupsPage from '../pages/Popups';
import PopupsForm from './Popups/Form';
import LogsPage from '../pages/Logs';
import LogsInfo from './Logs/Info';
import ConfigShopsPage from '../pages/ConfigShops/ConfigShops';
import ConfigShopsForm from './ConfigShops/Form';
import ConfigReturnTypesPage from '../pages/ConfigReturnTypes/ConfigShops';
import ConfigReturnTypesForm from './ConfigReturnTypes/Form';
import FlightsModule from '../next/modules/flights';
import TicketsFilterMenu from '../next/modules/tickets/containers/filter-menu';
import DnsQueuesPage from '../pages/DnsQueues';
import CustomsDeclarations from '../next/modules/declarations/pages/customs';
import DeletedDeclarations from '../next/modules/declarations/pages/deleted';

import SettingsModule from '../next/modules/settings';
import TicketTemplates from '../pages/TicketTemplates/TicketTemplates';
import TicketTemplatesInfo from './TicketTemplates/Info';
import TicketTemplatesForm from './TicketTemplates/Form';
import DashboardTransactionsPage from '../pages/Dashboard/Transactions';
import { CourierHandoverDetailsTable } from '../next/modules/statistics/containers/courier-handover-details';
import { CourierHandoverDeclarationsTable } from '../next/modules/statistics/containers/courier-handover-declarations';
import { NextModule } from '../@next';
import NotificationArchiveBulkForm from './NotificationArchive/BulkForm';

const Router: FC = () => {
  const dispatch = useDispatch();
  const auth = useSelector(baseModelSelector<IAuthState>('auth'));
  const { can } = usePermissions();

  useEffect(() => {
    dispatch(authFetchAction({}));
  }, [dispatch]);

  return (
    <BrowserRouter>
      <BaseRouter history={history}>
        <React.Suspense fallback={() => 'Loading...'}>
          <Switch>
            <Route path='/@next' component={NextModule} />
            <Route path={routes.login.index} component={LoginPage} />
            <ProtectedRoute authed={auth.authed} path={routes.home.index}>
              <MainLayout
                headerLeft={
                  <Route path={routes.tickets.index}>
                    <TicketsFilterMenu />
                  </Route>
                }
              >
                <Switch>
                  {can('system_settings') && <Route path='/settings' component={SettingsModule} />}
                  {can('stat') && (
                    <Route path={routes.dashboard.index}>
                      <Route path={routes.dashboard.index} component={DashboardPage} />
                      <Route path={routes.dashboard.transactions.index} component={DashboardTransactionsPage} />
                      <Route path={routes.dashboard.special.balance.details} component={DashboardSpecialBalanceDetails} />
                      <Route path={routes.dashboard.special.declarations.details} component={DashboardSpecialDeclarationsDetails} />
                      <Route path={routes.dashboard.orders.info} component={DashboardOrdersTable} />
                      <Route path={routes.dashboard.declarations.info} component={DashboardDeclarationsTable} />
                      <Route path={routes.dashboard.clients.info} component={DashboardClientsTable} />
                      <Route path={routes.dashboard.couriers.info} component={DashboardCouriersTable} />
                      <Route path={routes.dashboard.couriers.handovers.couriers} component={CourierHandoverDetailsTable} />
                      <Route path={routes.dashboard.couriers.handovers.declarations} component={CourierHandoverDeclarationsTable} />
                    </Route>
                  )}
                  <Route path={routes.tickets.index}>
                    <Route path={routes.tickets.categories.index} component={TicketCategories} />
                    <Route path={routes.tickets.categories.index} component={TicketCategories} />
                    <Route path={routes.tickets.create} component={TicketsForm} />
                    <Route path={routes.tickets.states.history} component={TicketsStateHistory} />
                    <Switch>
                      <Route path={routes.tickets.info} component={TicketsInfoPage} />
                      <Route path={routes.tickets.index} component={TicketsPage} />
                    </Switch>
                  </Route>
                  {can('regions') && (
                    <Route path={routes.regions.index}>
                      <Route path={routes.regions.index} component={Regions} />
                      <Route path={[routes.regions.create, routes.regions.edit]} component={RegionsForm} />
                    </Route>
                  )}
                  {can('models') && (
                    <Route path={routes.models.index}>
                      <Route path={routes.models.index} component={Models} />
                    </Route>
                  )}
                  {can('states') && (
                    <Route path={routes.states.index}>
                      <Route path={routes.states.index} component={States} />
                      <Route path={[routes.states.create, routes.states.edit]} component={StatesForm} />
                    </Route>
                  )}
                  {can('producttypes') && (
                    <Route path={routes.productTypes.index}>
                      <Route path={routes.productTypes.index} component={ProductTypes} />
                      <Route path={[routes.productTypes.create, routes.productTypes.edit]} component={ProductTypesForm} />
                    </Route>
                  )}
                  {can('tarifs') && (
                    <Route path={routes.plans.index}>
                      <Route path={routes.plans.index} component={Plans} />
                      <Route path={[routes.plans.create, routes.plans.edit]} component={PlansForm} />
                      <Route path={routes.plans.categories} component={PlansCategories} />
                    </Route>
                  )}
                  {can('news') && (
                    <Route path={routes.shops.index}>
                      <Route path={routes.shops.index} component={Shops} />
                      <Route path={[routes.shops.create, routes.shops.edit]} component={ShopsForm} />
                    </Route>
                  )}
                  {can('about') && <Route path={routes.about.index} component={About} />}
                  <Route path={routes.transportationConditions.index} component={TransportationConditions} />
                  <Route path={routes.popups.index}>
                    <Route path={routes.popups.index} component={PopupsPage} />
                    <Route path={routes.popups.create} component={PopupsForm} />
                  </Route>
                  <Route path='/archive/notifications/bulk'>
                    <Route path='/archive/notifications/bulk' component={NotificationArchiveBulkForm} />
                    <Route path='/archive/notifications/bulk/template/create' component={NotificationTemplatesForm} />
                  </Route>
                  <Route path={routes.archive.sms.index}>
                    <Switch>
                      {can('bulksms') && <Route path={routes.archive.sms.bulk.index} component={SmsArchiveBulkForm} />}
                      <Route path={routes.archive.sms.index} component={SmsArchive} />
                    </Switch>
                  </Route>
                  {can('state_changes') && (
                    <Route path={routes.archive.state.index}>
                      <Route path={routes.archive.state.index} component={StateArchivePage} />
                    </Route>
                  )}
                  <Route path={routes.config.returnTypes.index}>
                    <Route path={routes.config.returnTypes.index} component={ConfigReturnTypesPage} />
                    <Route path={[routes.config.returnTypes.create, routes.config.returnTypes.edit]} component={ConfigReturnTypesForm} />
                  </Route>
                  <Route path={routes.config.shops.index}>
                    <Route path={routes.config.shops.index} component={ConfigShopsPage} />
                    <Route path={[routes.config.shops.create, routes.config.shops.edit]} component={ConfigShopsForm} />
                  </Route>
                  {can('my_logs') && (
                    <Route path={routes.logs.index}>
                      <Route path={routes.logs.index} component={LogsPage} />
                      <Route path={routes.logs.info} component={LogsInfo} />
                    </Route>
                  )}
                  <Route path={routes.archive.email.index}>
                    <Switch>
                      <Route path={routes.archive.email.bulk.index}>
                        <Route path={routes.archive.email.bulk.index} component={EmailArchiveBulkForm} />
                        <Route path={routes.archive.email.bulk.templates.create} component={NotificationTemplatesForm} />
                      </Route>
                      <Route path={routes.archive.email.index} component={EmailArchive} />
                    </Switch>
                  </Route>
                  {can('faq') && (
                    <Route path={routes.faq.index}>
                      <Route path={routes.faq.index} component={FAQ} />
                      <Route path={[routes.faq.create, routes.faq.edit]} component={FAQForm} />
                    </Route>
                  )}
                  {can('dgk_declarations') && <Route path={routes.declarations.remote.customs} component={CustomsDeclarations} />}
                  <Route path={routes.declarations.remote.deleted.index} component={DeletedDeclarations} />
                  {can('news') && (
                    <Route path={routes.news.index}>
                      <Route path={routes.news.index} component={News} />
                      <Route path={[routes.news.create, routes.news.edit]} component={NewsForm} />
                    </Route>
                  )}
                  <Route path={routes.banners.index}>
                    <Route path={routes.banners.index} component={BannersPage} />
                    <Route path={routes.banners.create} component={BannersForm} />
                  </Route>
                  {can('flights') && (
                    <Route path={routes.flights.index}>
                      <Route path='/flights' component={FlightsModule} />
                      <Route path={routes.flights.index} component={Flights} />
                      <Route path={routes.flights.create} component={FlightsForm} />
                      <Route path={routes.flights.info} component={FlightsInfo} />
                      <Route path={routes.flights.updateThisMonth} component={FlightsUpdateThisMonthsForm} />
                    </Route>
                  )}
                  {can('bon_cashback') && (
                    <Route path={routes.bonaz.index}>
                      <Route path={routes.bonaz.conversions} component={BonazConversionsPage} />
                      <Route path={routes.bonaz.offers} component={BonazOffersPage} />
                    </Route>
                  )}
                  <Route path={routes.declarations.conflicted.index}>
                    <Route path={routes.declarations.conflicted.index} component={ConflictedDeclarations} />
                    <Route path={[routes.declarations.conflicted.create, routes.declarations.conflicted.edit]} component={ConflictedDeclarationsForm} />
                  </Route>
                  <Route path={routes.dnsQueues.index} component={DnsQueuesPage} />
                  {can('notification_templates') && (
                    <Route path={routes.notificationTemplates.index}>
                      <Route path={routes.notificationTemplates.index} component={NotificationTemplates} />
                      <Route path={routes.notificationTemplates.info} component={NotificationTemplatesInfo} />
                      <Route path={[routes.notificationTemplates.create, routes.notificationTemplates.edit]} component={NotificationTemplatesForm} />
                    </Route>
                  )}
                  <Route path={routes.ticketTemplates.index}>
                    <Route path={routes.ticketTemplates.index} component={TicketTemplates} />
                    <Route path={routes.ticketTemplates.info} component={TicketTemplatesInfo} />
                    <Route path={[routes.ticketTemplates.create, routes.ticketTemplates.edit]} component={TicketTemplatesForm} />
                  </Route>
                  <Redirect to={can('stat') ? '/@next/statistics' : '/@next/declarations'} />
                </Switch>
              </MainLayout>
            </ProtectedRoute>
          </Switch>
        </React.Suspense>
      </BaseRouter>
    </BrowserRouter>
  );
};

export default React.memo(Router);
