import { useMemo } from 'react';
import { urlFactory } from '../../core/utils/caller';
import { useInfo } from '../common/useInfo';

export const useDeclarationStatusStatistics = (delay = 0) => {
  const useDeliveredInfoOptions = useMemo(
    () => ({
      apiUrl: urlFactory('/api/admin/declaration/getstats'),
      responseGetter: (r) => r.data,
      delay,
    }),
    [delay],
  );
  const { data: rawData, loading } = useInfo(useDeliveredInfoOptions);

  const data = useMemo(() => {
    if (rawData) {
      return rawData.declarations.find((item) => item.state_id === 0);
    } else {
      return {
        total_price: 0,
        total_weight: 0,
      };
    }
  }, [rawData]);

  return { data, loading };
};
