import React, { FC } from 'react';
import { Layout } from 'antd';
import { BasicProps as ContentProps } from 'antd/lib/layout/layout';

const { Content } = Layout;

const LayoutContent: FC<ContentProps> = (props) => {
  return <Content id='mainContent' {...props} />;
};

export default React.memo(LayoutContent);
