import { caller, urlFactory } from '../core/utils/caller';
import { formDataBuilder } from '../core/utils/form-data-builder';

export const deleteConfigShops = async (ids: (number | string)[]) => {
  const url = urlFactory('/api/admin/shop_names/cancel');
  const body = formDataBuilder({ values: { shop_name_id: ids } });

  try {
    const response = await caller(url, { method: 'POST', body });

    return response.ok;
  } catch (e) {
    return false;
  }
};
