import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { CashFlowTransactionSumProvider } from '../contexts';
import { CashFlowTransactionsTable, CashFlowTransactionsTableActionBar } from '../containers';
import { useCashFlowTransactionsTableContext } from '../hooks';
import { CashFlowTransactionsTableContext } from '../contexts';

export const CashFlowTransactionsPage = () => {
  return (
    <CashFlowTransactionSumProvider>
      <CashFlowTransactionsPageChild />
    </CashFlowTransactionSumProvider>
  );
};
export const CashFlowTransactionsPageChild = () => {
  const { onFetch } = useCashFlowTransactionsTableContext();

  return (
    <TableProvider name='cashFlowTransactions' onFetch={onFetch} context={CashFlowTransactionsTableContext}>
      <PageContent $contain>
        <CashFlowTransactionsTableActionBar />
        <CashFlowTransactionsTable />
      </PageContent>
    </TableProvider>
  );
};
