import React, { FC } from 'react';

import ContentCard from '../containers/Layout/Sidebar/Content/Card';
import Table from '../containers/StateArchive/Table';
import { useTitle } from '../hooks/common/useTitle';

const StateArchivePage: FC = () => {
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - Status arxivi`);

  return (
    <ContentCard>
      <Table />
    </ContentCard>
  );
};

export default React.memo(StateArchivePage);
