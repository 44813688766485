import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Input, List, message, Modal, Row } from 'antd';
import { useFormik } from 'formik';
import { EditOutlined } from '@ant-design/icons';
import { useService } from '@core/inversify-react';
import { useCloseModal } from '@core/router';

import { usePlanCategories } from '../hooks';
import { IPlanCategory } from '../interfaces';
import { CreatePlanCategoryDto } from '../dtos';
import { CreatePlanCategoryDtoMapper } from '../mappers';
import { ICreatePlanCategoryRepo, CreatePlanCategoryRepoType } from '../repos';
import { queryClient } from '../../../index';
import { AntForm } from '@core/ui';

export const PlanCategoriesPage = () => {
  const close = useCloseModal();
  const [selectedItem, setSelectedItem] = useState<IPlanCategory>();
  const { data, isLoading } = usePlanCategories();

  const createPlanCategoryRepo = useService<ICreatePlanCategoryRepo>(CreatePlanCategoryRepoType);

  const onSubmit = useCallback(
    async (values: CreatePlanCategoryDto, { resetForm }) => {
      const result = await createPlanCategoryRepo.execute(values);

      if (result.status === 200) {
        await queryClient.invalidateQueries(['plans', 'categories']);
        setSelectedItem(undefined);
        resetForm();
      } else {
        message.error(result.response);
      }
    },
    [createPlanCategoryRepo],
  );
  const initialValues = useMemo(() => (selectedItem ? CreatePlanCategoryDtoMapper.toDomain(selectedItem) : { id: '', name: '', description: '' }), [selectedItem]);

  const { values, handleBlur, handleChange, submitForm, isSubmitting } = useFormik<CreatePlanCategoryDto>({
    enableReinitialize: true,
    initialValues,
    onSubmit,
  });

  const styles = useMemo(
    () => ({
      form: { display: 'flex', alignItems: 'center', marginBottom: 12 },
      input: { flex: 1, marginRight: 12 },
      checkbox: { marginRight: 4 },
    }),
    [],
  );

  useEffect(() => {
    const modal = document.querySelector('.ant-modal-wrap');

    if (modal) {
      modal.scrollTop = 0;
    }
  }, [selectedItem]);

  if (!data) {
    return null;
  }

  return (
    <Modal width={576} visible={true} footer={null} onCancel={() => close('/@next/supports')} title='Müraciət kateqoriyaları'>
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 0]} style={{ marginBottom: '20px' }}>
          <Input style={styles.input} name='name' placeholder='Kateqoriya adını daxil edin...' value={values.name} onBlur={handleBlur} onChange={handleChange} />
          <Button loading={isSubmitting} onClick={submitForm} type='primary'>
            {!!selectedItem ? 'Dəyişdir' : 'Yarat'}
          </Button>
          {!!selectedItem && (
            <Button onClick={() => setSelectedItem(undefined)} type='link'>
              Ləğv et
            </Button>
          )}
        </Row>

        <Row gutter={[24, 0]} style={{ marginBottom: '20px' }}>
          <Input.TextArea style={styles.input} name='description' placeholder='Kateqoriya adını daxil edin...' value={values.description} onBlur={handleBlur} onChange={handleChange} />
        </Row>
      </AntForm>
      <List
        loading={isLoading}
        dataSource={data.data}
        renderItem={(item: IPlanCategory) => (
          <List.Item actions={[<EditOutlined onClick={() => setSelectedItem(item)} key='edit-action' />]} key={item.id}>
            <List.Item.Meta title={item.name} description={item.description} />
          </List.Item>
        )}
      />
    </Modal>
  );
};
