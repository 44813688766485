import React, { FC } from 'react';
import { HeaderGroup } from 'react-table';
import Sorter from './Sorter';
import classNames from 'classnames';

type ITableHeadProps = {
  headerGroups: HeaderGroup[];
  filterable: boolean;
};

const TableHead: FC<ITableHeadProps> = ({ headerGroups, filterable }) => {
  return (
    <div className='next-table-thead'>
      {headerGroups.map((headerGroup) => (
        <div
          {...headerGroup.getHeaderGroupProps({ className: 'next-table-tr' })}
        >
          {headerGroup.headers.map((column: any) => {
            return (
              <div
                {...column.getHeaderProps(
                  column.sortable
                    ? column.getSortByToggleProps({
                        className: classNames(
                          'next-table-th',
                          column.className,
                        ),
                      })
                    : {
                        className: classNames(
                          'next-table-th',
                          column.className,
                        ),
                      },
                )}
              >
                {column.render('Header')}
                {column.sortable && (
                  <Sorter
                    dir={
                      column.isSorted
                        ? column.isSortedDesc
                          ? 'desc'
                          : 'asc'
                        : undefined
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
      ))}
      {filterable &&
        headerGroups.map((headerGroup) => (
          <div
            {...headerGroup.getHeaderGroupProps({ className: 'next-table-tr' })}
          >
            {headerGroup.headers.map((column: any) => {
              return (
                <div
                  {...column.getHeaderProps({
                    className: classNames('next-table-th', column.className),
                  })}
                >
                  {column.filterable ? column.render('Filter') : null}
                </div>
              );
            })}
          </div>
        ))}
    </div>
  );
};

export default TableHead;
