import * as React from 'react';
import { Button, Dropdown, Menu, Result, Space, Steps, Tooltip } from 'antd';
import * as Icons from '@ant-design/icons';
import { Details, SuspenseSpin } from '@core/ui';
import { useHistory } from 'react-router-dom';

import { useAuth } from '@modules/auth';

import { useDeclaration } from '../hooks';
import { DeclarationStatusTag } from '../components';

export const DeclarationDetails: React.FC<{ id: string }> = ({ id }) => {
  const { go } = useHistory();
  const { can } = useAuth();

  const {
    returnDec,
    handover,
    canHandover,
    customsStatus,
    waybillIsDisabled,
    openEdit,
    openTimeline,
    openOrder,
    orderUrls,
    printWaybill,
    printProformaInvoice,
    remove,
    data,
    isLoading,
    error,
    removeFromFlight,
    cancelDispatch,
  } = useDeclaration(id);

  const ordersNode = (
    <Dropdown
      disabled={!orderUrls.data?.length}
      overlay={
        <Menu>
          {orderUrls.data?.map((id, index) => (
            <Menu.Item onClick={() => openOrder(id)} key={id}>
              Sifariş #{index + 1}
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Button type='primary' icon={<Icons.ShoppingCartOutlined />} ghost={true} block={true}>
        Sifarişlər
      </Button>
    </Dropdown>
  );

  if (isLoading) {
    return (
      <Details.Wrapper>
        <SuspenseSpin />
      </Details.Wrapper>
    );
  }

  if (error) {
    return (
      <Details.Wrapper>
        <Result status='404' title='Xəta baş verdi' subTitle={error} />
      </Details.Wrapper>
    );
  }

  if (!data) {
    return null;
  }

  return (
    <Details.Wrapper>
      <Details.Row>
        <Details.Col xs={24}>
          <Details.PageHeader
            title={
              <Space size={8}>
                <Icons.LeftCircleOutlined onClick={() => go(-1)} />
                <span>#{data.trackCode}</span>
              </Space>
            }
            subTitle={data.user.name}
            tags={<DeclarationStatusTag id={data.status.id} name={data.status.name} />}
            extra={[
              <Tooltip key='state-timeline-button' title='Status xəritəsi'>
                <Button type='link' onClick={openTimeline} icon={<Icons.FieldTimeOutlined />} />
              </Tooltip>,
              <Tooltip key='edit-button' title='Düzəliş et'>
                <Button type='link' onClick={openEdit} icon={<Icons.EditOutlined />} />
              </Tooltip>,
              <Tooltip key='delete-button' title='Sil'>
                <Button type='link' onClick={remove} disabled={!can('declaration_cancel')} danger={true} icon={<Icons.DeleteOutlined />} />
              </Tooltip>,
            ]}
          />
        </Details.Col>
        <Details.Col xs={24}>
          <Details.Actions>
            <Details.ActionCol>
              <Button type='primary' disabled={!canHandover} onClick={handover} icon={<Icons.CheckCircleOutlined />} ghost={true} block={true}>
                Təhvil ver
              </Button>
              <Button type='primary' onClick={returnDec} icon={<Icons.RollbackOutlined />} ghost={true} block={true}>
                İadə et
              </Button>
              <Button type='primary' onClick={printWaybill} disabled={waybillIsDisabled} icon={<Icons.PrinterOutlined />} ghost={true} block={true}>
                Yol vərəqəsi
              </Button>
              <Button type='primary' onClick={printProformaInvoice} icon={<Icons.FileMarkdownOutlined />} ghost={true} block={true}>
                Proforma invoice
              </Button>
              <Button type='primary' onClick={removeFromFlight} icon={<Icons.DeleteOutlined />} ghost={true} block={true}>
                Uçuşdan çıxar
              </Button>
              {can('canceldepesh') && (
                <Button type='primary' onClick={cancelDispatch} icon={<Icons.IssuesCloseOutlined />} disabled={data.status.id !== 8} ghost={true} block={true}>
                  Depeşi ləğv et
                </Button>
              )}
            </Details.ActionCol>
            <Details.ActionCol>{ordersNode}</Details.ActionCol>
          </Details.Actions>
        </Details.Col>
        <Details.Col xs={24} lg={12}>
          <Details.Row>
            <Details.Col xs={24}>
              <Details.Card title='Ümumi məlumat'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='İzləmə kodu'>#{data.trackCode}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Qlobal izləmə kodu'>{data.globalTrackCode}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Məhsul tipi'>{data.productType.name}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Mağaza'>{data.shop}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Filial'>{data.branch.name || 'Yoxdur'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Tərkibi'>{data.type === 'liquid' ? 'Maye' : 'Digər'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Partnyor'>{data.partner?.name || 'Yoxdur'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Sənəd'>
                    {data.file ? (
                      <a href={data.file} target='_blank' rel='noreferrer noopener'>
                        Sənədə bax
                      </a>
                    ) : (
                      'Mövcud deyil'
                    )}
                  </Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Yaradılma tarixi'>{data.createdAt}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
            <Details.Col xs={24}>
              <Details.Card title='Anbar məlumatları'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='Səbət'>{data.basket?.name || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Koli'>{data.parcel?.id || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Şkaf (Yerli)'>{data.wardrobeNumber || 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
          </Details.Row>
        </Details.Col>
        <Details.Col xs={24} lg={12}>
          <Details.Row>
            <Details.Col xs={24}>
              <Details.Card title='Qiymətlər'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='Məhsulun qiyməti'>{data.price ? `${data.price.toFixed(2)}₺` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Çatdırılma qiyməti'>{data.deliveryPrice ? `${data.deliveryPrice.toFixed(2)}$` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
            <Details.Col xs={24}>
              <Details.Card title='Ölçülər'>
                <Details.Descriptions bordered={true} column={1} size='small'>
                  <Details.Descriptions.Item label='Çəki'>{data.weight ? `${data.weight.toFixed(2)} kq` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='En'>{data.depth ? `${data.depth.toFixed(2)} sm` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Uzunluq'>{data.width ? `${data.width.toFixed(2)} sm` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                  <Details.Descriptions.Item label='Hündürlük'>{data.height ? `${data.height.toFixed(2)} sm` : 'Qeyd olunmayıb'}</Details.Descriptions.Item>
                </Details.Descriptions>
              </Details.Card>
            </Details.Col>
          </Details.Row>
        </Details.Col>
        <Details.Col xs={24}>
          <Details.Card title='Açıqlama'>{data.description || 'Qeyd olunmayıb'}</Details.Card>
        </Details.Col>
        {!customsStatus.isLoading ? (
          <React.Fragment>
            <Details.Col xs={24}>
              <Details.SectionTitle>DGK statusu</Details.SectionTitle>
            </Details.Col>
            <Details.Col xs={24}>
              <Details.StepsWrapper>
                <Steps current={typeof customsStatus.data === 'number' ? customsStatus.data : -1} responsive={true} progressDot={true}>
                  <Steps.Step title='Əlavə edilib' description='Karqo şirkəti bağlamanı əlavə edib' />
                  <Steps.Step title='Bəyan edilib' description='Müştəri bağlamanı bəyan edib' />
                  <Steps.Step title='Kolilənib' description='Bağlamalar kolilərə yığılıb' />
                  <Steps.Step title='Depesh göndərilib' description='Depesh sorğusu göndərilib' />
                </Steps>
              </Details.StepsWrapper>
            </Details.Col>
          </React.Fragment>
        ) : (
          <Details.Col xs={24}>
            <SuspenseSpin />
          </Details.Col>
        )}
      </Details.Row>
    </Details.Wrapper>
  );
};
