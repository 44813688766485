import * as React from 'react';
import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { GetCashFlowTransactionsRepoType, IGetCashFlowTransactionsRepo } from '../../repos';
import { CashFlowTransactionSumContext } from '../..';

export const useCashFlowTransactionsTableContext = () => {
  const getCashFlowTransactionsRepo = useService<IGetCashFlowTransactionsRepo>(GetCashFlowTransactionsRepoType);
  const { setSumState } = React.useContext(CashFlowTransactionSumContext);

  const onFetch = React.useCallback(
    async (params: TableFetchParams) => {
      const result = await getCashFlowTransactionsRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        if (setSumState) {
          setSumState(result.response.sum);
        }

        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getCashFlowTransactionsRepo, setSumState],
  );

  return { onFetch };
};
