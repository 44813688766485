import { createSelector } from 'reselect';
import { IAppState } from '../interfaces/app-state.interface';
import { IThemeState } from '../interfaces/theme/model.interface';
import { IAuthState } from '../interfaces/auth/state';
import { ICounterState } from '../interfaces/counter/state.interface';

export const layoutSelector = createSelector<IAppState, IThemeState, IAuthState, ICounterState, any>(
  (state: IAppState) => {
    return state.theme as IThemeState;
  },
  (state: IAppState) => {
    return state.auth as IAuthState;
  },
  (state: IAppState) => {
    return state.counter as ICounterState;
  },
  (theme: IThemeState, auth: IAuthState, counter: ICounterState) => {
    return {
      theme,
      auth,
      counter,
    };
  },
);
