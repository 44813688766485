import React, { FC } from 'react';

import ContentCard from '../../containers/Layout/Sidebar/Content/Card';
import Table from '../../containers/ConfigShops/Table';
import Actions from '../../containers/ConfigShops/Actions';
import { useTitle } from '../../hooks/common/useTitle';

const ConfigShopsPage: FC = () => {
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - Mağazalar`);

  return (
    <ContentCard>
      <Actions />
      <Table />
    </ContentCard>
  );
};

export default React.memo(ConfigShopsPage);
