import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { message } from 'antd';

import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';

import { CreateTransactionDto } from '../dtos';
import { CreateTransactionRepoType, ICreateTransactionRepo } from '../repos';

export const useCreateTransaction = ({ onSucceed }) => {
  const createTransactionRepo = useService<ICreateTransactionRepo>(CreateTransactionRepoType);
  const eventBus = useBus();
  const location = useLocation<{ mergeCreateTransactionValues?: Partial<CreateTransactionDto> } | undefined>();

  const initialValues = useMemo<CreateTransactionDto>(() => {
    return {
      userId: location.state?.mergeCreateTransactionValues?.userId || '',
      amount: {
        value: '0',
        currency: 'TRY',
      },
      convertedAmount: {
        value: '0',
        currency: 'AZN',
      },
      type: '1',
      paymentType: '1',
      description: '',
    };
  }, [location.state?.mergeCreateTransactionValues?.userId]);

  const onSubmit = useCallback(
    async (values: CreateTransactionDto, helpers: FormikHelpers<CreateTransactionDto>) => {
      helpers.setStatus(null);
      const result = await createTransactionRepo.execute(values);

      if (result.status === 200) {
        eventBus.publish({ type: '@transactions/create/succeed' });

        onSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createTransactionRepo, eventBus, onSucceed],
  );

  return { onSubmit, initialValues };
};
