import React, { FC } from 'react';

import ReadyTable from '../../components/Table/ReadyTable';
import { usePopupsTable } from '../../hooks/popups/useTable';

const PopupsTable: FC = () => {
  const { columns } = usePopupsTable();

  return <ReadyTable columns={columns} name='popups' apiUrl='/api/admin/popups' />;
};

export default PopupsTable;
