import React, { Context, memo, useContext, useMemo } from 'react';

import { TableUI, TableContext } from '../';

export const NextTable = memo<{
  context: Context<TableContext>;
  columns: any;
  getRowProps?: any;
  filterable?: boolean;
  sortable?: boolean;
  pagination?: boolean;
}>(({ context, columns, getRowProps, filterable, sortable, pagination }) => {
  const { state, setFilters, setSortBy, setPageIndex, setPageSize, setSelectedRowIds } = useContext<TableContext>(context);

  const controlledState = useMemo(
    () => ({
      filters: state.filters,
      sortBy: state.sortBy,
      pageSize: state.pageSize,
      pageIndex: state.pageIndex,
      hiddenColumns: state.hiddenColumns,
      selectedRowIds: state.selectedRowIds,
    }),
    [state.filters, state.sortBy, state.pageSize, state.pageIndex, state.hiddenColumns, state.selectedRowIds],
  );

  return (
    <TableUI
      columns={columns}
      data={state.data}
      total={state.total}
      state={controlledState}
      loading={state.loading}
      initialized={true}
      filterable={filterable}
      sortable={sortable}
      getRowProps={getRowProps}
      pagination={pagination}
      onChangeFilter={setFilters}
      onChangeSort={setSortBy}
      onChangePage={setPageIndex}
      onChangePageSize={setPageSize}
      onChangeSelectedRowIds={setSelectedRowIds}
    />
  );
});
