import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
import { RouteComponentProps } from 'react-router-dom';
import { useNotificationTemplatesFormFields } from './useFormFields';
import { useClose } from '../common';
import { useForm } from '../common/useForm';
import { tableFetchDataAction } from '../../actions/table';
import { urlFactory } from '../../core/utils/caller';
import routes from '../../constants/routes';
import { message } from 'antd';
import { formFieldValueSelector } from '../../selectors/form';

export const useNotificationTemplatesForm = (props: InjectedFormProps & RouteComponentProps<any>) => {
  const dispatch = useDispatch();
  const close = useClose(routes.notificationTemplates.index);
  const fields = useNotificationTemplatesFormFields(props);
  const modelId = useSelector(formFieldValueSelector(props.form, 'model_id'));
  const notificationTypeId = useSelector(formFieldValueSelector(props.form, 'template_type_id'));

  const handleSubmitSuccess = useCallback(
    async (response: Response) => {
      message.success('Bildiriş şablonu müvəffəqiyyətlə dəyişdirildi.');
      dispatch(tableFetchDataAction('notificationTemplates', '/api/admin/templates/list'));
      const { data: id } = await response.json();
      close({ id });
    },
    [dispatch, close],
  );

  const { handleSubmit, type } = useForm(
    useMemo(
      () => ({
        handleClose: close,
        handleSubmit: props.handleSubmit,
        initialize: props.initialize,
        createUrl: [routes.notificationTemplates.create, routes.archive.email.bulk.templates.create, '/archive/notifications/bulk/template/create'],
        createApiUrl: urlFactory('/api/admin/templates/create'),
        editApiUrl: urlFactory('/api/admin/templates/edit'),
        onSuccess: handleSubmitSuccess,
        initializeUrl: urlFactory('/api/admin/templates/info', { template_id: props.match.params?.id }),
        initializeMiddlewares: [(response) => ({ ...response, template_id: response.id, active: !!response.active })],
      }),
      [close, props.handleSubmit, handleSubmitSuccess, props.initialize, props.match.params],
    ),
  );

  const title = useMemo(() => (type === 'create' ? 'Yeni bildiriş şablonu yarat' : 'Bildirişdə dəyişiklik et'), [type]);
  const initializing = useMemo(() => type === 'edit' && !props.initialized, [props.initialized, type]);

  useEffect(() => {
    if (type === 'create') {
      props.initialize({ active: false });
    }
  }, [type]); //eslint-disable-line react-hooks/exhaustive-deps

  return { fields, title, handleSubmit, close, initializing, values: { modelId, notificationTypeId } };
};
