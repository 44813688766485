import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IDeclaration } from '../interfaces';
import { DeclarationMapper } from '../mappers';

export type IGetDeclarationByTrackCodeRepoResponse = IRepoResponse<200, IDeclaration> | IRepoResponse<400 | 500, string>;

export type IGetDeclarationByTrackCodeRepo = IRepo<[{ trackCode: string }], IGetDeclarationByTrackCodeRepoResponse>;

export const GetDeclarationByTrackCodeRepoType = Symbol.for('GetDeclarationByTrackCodeRepo');

@bind(GetDeclarationByTrackCodeRepoType)
export class GetDeclarationByTrackCodeRepo implements IGetDeclarationByTrackCodeRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ trackCode }) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/declaration/info_by_track_code', { track_code: trackCode }) });
      if (result.status === 200) {
        const data: IDeclaration = DeclarationMapper.toDomain(result.data.data);
        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
