import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  tableDestroyAction,
  tableFetchDataAction,
  tableInitializeAction,
  tableSetFiltersAction,
  tableSetPageIndexAction,
  tableSetPageSizeAction,
  tableSetSelectedRowIdsAction,
  tableSetSortByAction,
} from '../../actions/table';
import NextTable from './Table';
import { tableSelector } from '../../selectors/table';

type IReadyTableProps = {
  name: string;
  apiUrl: string;
  columns: any[];
  sortable?: boolean;
  filterable?: boolean;
  pagination?: boolean;
  getRowProps?: Function;
  source?: string;
  description?: string;
};

const ReadyTable: FC<IReadyTableProps> = ({ name, apiUrl, columns, sortable, filterable, pagination, getRowProps, source, description }) => {
  const dispatch = useDispatch();
  const { data, total, filters, sortBy, pageSize, pageIndex, hiddenColumns, selectedRowIds, loading, error, initialized } = useSelector(tableSelector(name));

  const state = useMemo(
    () => ({
      filters,
      sortBy,
      pageSize,
      pageIndex,
      hiddenColumns,
      selectedRowIds,
    }),
    [filters, sortBy, pageSize, pageIndex, hiddenColumns, selectedRowIds],
  );

  useEffect(() => {
    dispatch(tableInitializeAction(name));

    return () => {
      dispatch(tableDestroyAction(name));
    };
  }, [dispatch, name]);

  const handleSetFilters = useCallback(
    (filters) => {
      dispatch(tableSetFiltersAction(name, filters));
    },
    [dispatch, name],
  );

  const handleSetSortBy = useCallback(
    (sortBy) => {
      dispatch(tableSetSortByAction(name, sortBy));
    },
    [dispatch, name],
  );

  const handleSetPageIndex = useCallback(
    (pageIndex) => {
      dispatch(tableSetPageIndexAction(name, pageIndex));
    },
    [dispatch, name],
  );

  const handleSetPageSize = useCallback(
    (pageSize) => {
      localStorage.setItem('tablePageSize', pageSize.toString());
      dispatch(tableSetPageSizeAction(name, pageSize));
    },
    [dispatch, name],
  );

  const handleSetSelectedRowIds = useCallback(
    (selectedRowIds) => {
      dispatch(tableSetSelectedRowIdsAction(name, selectedRowIds));
    },
    [dispatch, name],
  );

  const handleFetch = useCallback(() => {
    dispatch(tableFetchDataAction(name, apiUrl, source));
  }, [dispatch, name, apiUrl, source]);

  return (
    <NextTable
      columns={columns}
      data={data}
      total={total}
      state={state}
      loading={loading}
      error={error}
      getRowProps={getRowProps}
      initialized={!!initialized}
      onChangeFilter={handleSetFilters}
      onChangeSort={handleSetSortBy}
      onChangePage={handleSetPageIndex}
      onChangePageSize={handleSetPageSize}
      onChangeSelectedRowIds={handleSetSelectedRowIds}
      onFetchData={handleFetch}
      sortable={sortable}
      filterable={filterable}
      pagination={pagination}
      description={description}
    />
  );
};

ReadyTable.defaultProps = {
  columns: [],
  filterable: true,
  sortable: true,
  pagination: true,
  source: 'base',
};

export default ReadyTable;
