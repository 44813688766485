import { NextTable } from '@core/table';

import { useShopsTable } from '../hooks';
import { ShopsTableContext } from '../contexts';

export const ShopsTable = () => {
  const { columns } = useShopsTable();

  return <NextTable context={ShopsTableContext} columns={columns} />;
};
