import React, { useCallback, useMemo } from 'react';
import { Column } from 'react-table';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReadyTable from '../../components/Table/ReadyTable';
import { Button, Dropdown, Menu, Modal } from 'antd';
import { DeleteOutlined, EditOutlined, MoreOutlined } from '@ant-design/icons/lib';
import { remove } from '../../actions/regions';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';
import { actionsCol, dateCol, smallCol } from '../../core/helpers/table';

const RegionsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const columns = useMemo<Column<any>[]>(
    () => [
      {
        ...actionsCol,
        Cell: ({ row: { original } }) => {
          const gotoEdit = useCallback(() => {
            history.push(makePath(routes.regions.edit, { id: original.id }));
          }, [original.id]);

          const handleDelete = useCallback(() => {
            Modal.confirm({
              title: 'Rayonu silməyə əminsinizmi?',
              content: 'Silməni təsdiqlədikdən sonra rayonların geri qaytarılması mümkün olmayacaq.',
              onOk: () => {
                remove([original.id])(dispatch)
                  .then(() => {})
                  .catch(() => {});
              },
            });
          }, [original.id]);

          return (
            <span onClick={(e) => e.stopPropagation()}>
              <Dropdown
                placement='bottomLeft'
                overlay={
                  <Menu>
                    <Menu.Item onClick={gotoEdit}>
                      <EditOutlined />
                      <span>Düzəliş et</span>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item onClick={handleDelete}>
                      <DeleteOutlined />
                      <span>Sil</span>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button className='next-table-action-btn' type='text' icon={<MoreOutlined />} />
              </Dropdown>
            </span>
          );
        },
      },
      {
        accessor: 'id',
        Header: 'Kod',
        ...smallCol,
      },
      {
        accessor: 'name',
        Header: 'Rayon',
        width: 200,
      },
      {
        accessor: 'price',
        Header: 'Qiymət',
        width: 150,
      },
      {
        accessor: 'state_name',
        Header: 'Status',
        width: 150,
      },
      {
        accessor: 'created_at',
        Header: 'Yaradılıb',
        ...dateCol,
      },
    ],
    [dispatch, history],
  );

  return <ReadyTable name='regions' apiUrl={'/api/admin/regions/getlist'} columns={columns} sortable={false} pagination={false} filterable={false} />;
};

export default React.memo(RegionsTable);
