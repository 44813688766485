import { useMemo } from 'react';
import { TextAreaField } from '../../../components/ReduxForm/TextArea';
import { SelectField } from '../../../components/ReduxForm/Select';
import { urlFactory } from '../../../core/utils/caller';
import { UploadField } from '../../../components/ReduxForm/Upload';

export const useFormFields = () => {
  const userFieldProps = useMemo(
    () => ({
      name: 'user_id',
      item: { label: 'Müştəri' },
      field: { placeholder: 'Müştəri seçin' },
      type: 'select',
      component: SelectField,
      apiUrl: urlFactory('/api/admin/client/list'),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => `${object.id} - ${object.name} ${object.surname}`,
      responseGetter: (object: any) => object.data,
    }),
    [],
  );

  const categoryFieldProps = useMemo(
    () => ({
      name: 'ticket_category_id',
      item: { label: 'Kateqoriya' },
      field: { placeholder: 'Kateqoriya seçin' },
      type: 'select',
      component: SelectField,
      apiUrl: urlFactory('/api/admin/tickets/categories'),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => object.name,
      responseGetter: (object: any) => object.data,
    }),
    [],
  );

  const messageFieldProps = useMemo(
    () => ({
      name: 'message',
      item: { label: 'Mesaj' },
      field: { placeholder: 'Mesajı daxil edin', rows: 4 },
      component: TextAreaField,
    }),
    [],
  );

  const fileUploadFieldProps = useMemo(
    () => ({
      name: 'document_file',
      item: { label: 'Sənədlər', placeholder: 'Müraciətə aid sənədləri yükləyin' },
      component: UploadField.Multi,
    }),
    [],
  );

  return useMemo(
    () => ({
      userFieldProps,
      categoryFieldProps,
      messageFieldProps,
      fileUploadFieldProps,
    }),
    [userFieldProps, categoryFieldProps, messageFieldProps, fileUploadFieldProps],
  );
};
