import { useCallback, useContext, useMemo } from 'react';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';

import { UsersTableContext } from '../contexts';
import { useStatusByModelId } from '@modules/status';
import { userQueryKeys } from '@modules/users';

export const useUsersActionBar = () => {
  const { state, selection, setFilterById, selectAll, fetch, reset, resetSelection } = useContext(UsersTableContext);

  const location = useLocation();
  const history = useHistory();
  const { role } = useParams<{ role?: string }>();
  const declarationStatus = useStatusByModelId('2');

  const create = useCallback(() => {
    history.push('/@next/users/create', { background: location });
  }, [history, location]);

  const openGeneralStatistics = useCallback(() => {
    history.push('/@next/statistics/users/general', { background: location });
  }, [history, location]);

  const onRoleChange = useCallback((role: string) => history.push(generatePath('/@next/users/:role?', { role: role !== 'all' ? role : undefined })), [history]);

  const onDeclarationsStatusChange = useCallback(
    (statusIds: string[]) => {
      setFilterById(userQueryKeys.declarations.state.id, statusIds);
    },
    [setFilterById],
  );

  const declarationsStatusId = useMemo(() => state.filters.find((filter) => filter.id === userQueryKeys.declarations.state.id)?.value || [], [state.filters]);

  return {
    selection,
    resetSelection,
    create,
    selectAll,
    reset,
    fetch,
    openGeneralStatistics,
    onRoleChange,
    role,
    onDeclarationsStatusChange,
    declarationsStatusId,
    declarationStatus,
  };
};
