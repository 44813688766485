import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import { AppstoreOutlined, DeleteOutlined, FileSearchOutlined, HistoryOutlined, MoreOutlined, ReadOutlined } from '@ant-design/icons/lib';

import { actionsCol, dateCol, idCol, midCol } from '../../core/helpers/table';
import { stopPropagation } from '../../core/helpers/events';
import ReadyTable from '../../components/Table/ReadyTable';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { changeReadAction, changeStateAction, removeAction } from '../../actions/tickets';
import { useQuery } from '../../next/shared/modules/use-query/hooks';
import { TicketsCounts } from '../../next/modules/tickets/types';
import { TicketService } from '../../next/modules/tickets/services';
import { tableSelector } from '../../selectors/table';
import { StateFilter } from '../../components/Tickets/Filters/StateFilter';
import { UserCell } from '@modules/users';

const TicketsTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const table = useSelector(tableSelector('tickets'));

  const counts = useQuery<TicketsCounts | null>(
    useCallback(async () => {
      const result = await TicketService.getCounts(table.filters);

      if (result.status === 200) {
        return result.data;
      } else {
        return null;
      }
    }, [table.filters]),
    useMemo(() => ({ initialData: null }), []),
  );

  const columns = useMemo(
    () => [
      {
        ...actionsCol,
        Cell: ({ row: { original } }) => {
          const handleChangeState = useCallback(
            (id: number) => {
              changeStateAction([original.id], id)(dispatch);
            },
            [original.id],
          );
          const handleChangeRead = useCallback(() => {
            changeReadAction([original.id], !original.is_new_admin)(dispatch).catch(() => {});
          }, [original.is_new_admin, original.id]);
          const goToInfo = useCallback(() => {
            history.push(makePath(routes.tickets.info, { id: original.id }));
          }, [original.id]);
          const goToStateHistory = useCallback(() => {
            history.push(makePath(routes.tickets.states.history, { id: original.id }));
          }, [original.id]);
          const handleDelete = useCallback(() => {
            removeAction([original.id])(dispatch).catch(() => {});
          }, [original.id]);

          return (
            <span onClick={stopPropagation}>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item onClick={handleChangeRead}>
                      <ReadOutlined />
                      {!original.is_new_admin ? <span>Oxunmamış kimi qeyd et</span> : <span>Oxunmuş kimi qeyd et</span>}
                    </Menu.Item>
                    <Menu.SubMenu
                      title={
                        <>
                          <AppstoreOutlined />
                          <span>Statusu dəyiş</span>
                        </>
                      }
                    >
                      <Menu.Item onClick={() => handleChangeState(32)}>Qəbul edilib</Menu.Item>
                      <Menu.Item onClick={() => handleChangeState(33)}>Baxılır</Menu.Item>
                      <Menu.Item onClick={() => handleChangeState(34)}>Açıqdır</Menu.Item>
                      <Menu.Item onClick={() => handleChangeState(35)}>Bağlıdır</Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Divider />
                    <Menu.Item onClick={goToInfo}>
                      <FileSearchOutlined />
                      <span>Ətraflı</span>
                    </Menu.Item>
                    <Menu.Item onClick={goToStateHistory}>
                      <HistoryOutlined />
                      <span>Status tarixçəsi</span>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item onClick={handleDelete}>
                      <DeleteOutlined />
                      <span>Sil</span>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button className='next-table-action-btn' type='text' icon={<MoreOutlined />} />
              </Dropdown>
            </span>
          );
        },
      },
      { ...idCol },
      {
        ...midCol,
        accessor: 'user_id',
        Header: 'M. kodu',
      },
      {
        accessor: 'user_name',
        Header: 'Müştəri',
        width: 200,
        Cell: ({ row: { original } }) => <UserCell id={original.user_id} title={original.user_name} />,
      },
      {
        accessor: 'ticket_category_name',
        Header: 'Kateqoriya',
      },
      {
        accessor: 'state_id',
        Header: 'Status',
        Filter: StateFilter,
        Cell: ({ row: { original } }) => original.state_name,
      },
      {
        id: 'messages',
        Header: 'Mesajlar',
        filterable: false,
        Cell: ({ row: { original } }) => `${original.new_message_count}/${original.all_message_count}`,
      },
      {
        accessor: 'user_name_admin',
        Header: 'Əməkdaş',
      },
      {
        accessor: 'created_at',
        Header: 'Yaradılıb',
        ...dateCol,
      },
    ],
    [history, dispatch],
  );

  const rowProps = useCallback(
    (id, { is_new_admin }) => ({
      onDoubleClick: () => {
        history.push(makePath(routes.tickets.info, { id }));
      },
      style: { backgroundColor: !!is_new_admin ? '#fff1f0' : undefined },
    }),
    [history],
  );

  return <ReadyTable getRowProps={rowProps} description={counts.data ? `${counts.data.unread}/${counts.data.all}` : undefined} name='tickets' columns={columns} apiUrl='/api/admin/tickets/list' />;
};

export default React.memo(TicketsTable);
