import { BalanceStatisticsMappers } from './balance';
import { DeclarationExecutiveMappers } from './declaration-executive';
import { PaymentTypesStatisticsMappers } from './payment-types';
import { StatisticsByCourierRegionMapper } from './courier-region';

export class StatisticsMappers {
  public static Balance = BalanceStatisticsMappers;
  public static DeclarationExecutive = DeclarationExecutiveMappers;
  public static PaymentType = PaymentTypesStatisticsMappers;
  public static CourierRegion = StatisticsByCourierRegionMapper;
}
