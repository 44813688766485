import { inject } from 'inversify';

import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { SettingsMapper } from '../mappers';
import { IBranchesSettingsDto } from '../dtos';

export type IGetBranchesSettingsResponse = IRepoResponse<200, IBranchesSettingsDto> | IRepoResponse<400 | 500, string>;

export type IGetBranchesSettings = IRepo<[number | string], IGetBranchesSettingsResponse>;

export const GetBranchesSettingsType = Symbol.for('GetBranchesSettings');

@bind(GetBranchesSettingsType)
export class GetBranchesSettings implements IGetBranchesSettings {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute() {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/settings/data', { group_id: 'contact' }) });

      if (result.status === 200) {
        const data: any = SettingsMapper.branchesToDomain(result.data.data);
        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}
