import { useCallback, useContext, useMemo } from 'react';
import { message, Modal } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useService } from '@core/inversify-react';
import { tableFilterQueryMaker, tableQueryMaker } from '@core/table';
import { pathMaker } from '@core/utils';

import {
  BulkUpdateDeclarationsStatusRepoType,
  GetCombinedDeclarationRepoType,
  GetDeclarationsExcelRepoType,
  IBulkUpdateDeclarationsStatusRepo,
  IGetCombinedDeclarationRepo,
  IGetDeclarationsExcelRepo,
  IRemoveDeclarationsRepo,
  RemoveDeclarationsRepoType,
} from '../repos';
import { IDeclaration } from '../interfaces';
import { DeclarationTableContext } from '../context';
import { usePrintProformaInvoice, useUpdateDeclarationsStatus } from '../hooks';

export const useDeclarationsActionBar = () => {
  const location = useLocation();
  const history = useHistory();
  const { selection, state, resetSelection, selectAll, fetch, reset } = useContext(DeclarationTableContext);

  const printProformaFactory = usePrintProformaInvoice();
  const updateStatusFactory = useUpdateDeclarationsStatus();

  const bulkUpdateDeclarationStateRepo = useService<IBulkUpdateDeclarationsStatusRepo>(BulkUpdateDeclarationsStatusRepoType);
  const combineDeclarationsRepo = useService<IGetCombinedDeclarationRepo>(GetCombinedDeclarationRepoType);
  const getDeclarationsExcelRepo = useService<IGetDeclarationsExcelRepo>(GetDeclarationsExcelRepoType);
  const removeDeclarationsRepo = useService<IRemoveDeclarationsRepo>(RemoveDeclarationsRepoType);

  const selectedItems = useMemo<IDeclaration[]>(() => state.data.filter((item) => selection.includes(item.id.toString())), [selection, state.data]);

  const totalPrice = useMemo(() => {
    const usd = selectedItems.reduce((acc, item) => {
      return acc + (item.deliveryPrice || 0);
    }, 0);
    const azn = Math.round(usd * 1.7 * 100) / 100;

    return { usd: usd.toFixed(2), azn: azn.toFixed(2) };
  }, [selectedItems]);

  const bulkUpdateStatus = useCallback(
    (statusId: string | number) => {
      if (statusId === 36) {
        history.push('/@next/declarations/stuck-at-customs', { bulkUpdateDeclarationsStatus: { filters: state.filters } });
        return;
      }

      const onOk = async () => {
        const query = tableQueryMaker({ filters: state.filters, sortBy: state.sortBy, pageIndex: state.pageIndex, pageSize: state.pageSize });
        const result = await bulkUpdateDeclarationStateRepo.execute(query, { statusId });

        if (result.status === 200) {
          fetch();
        } else {
          message.error(result.response);
        }
      };

      Modal.confirm({
        title: 'Diqqət',
        content: `${state.total} ədəd bağlama seçilib. Toplu status dəyişməyə əminsinizmi?`,
        onOk,
      });
    },
    [bulkUpdateDeclarationStateRepo, fetch, history, state.filters, state.pageIndex, state.pageSize, state.sortBy, state.total],
  );

  const updateStatus = useCallback(
    (statusId: string | number) => {
      return updateStatusFactory({ declarationIds: selection, statusId });
    },
    [selection, updateStatusFactory],
  );

  const combine = useCallback(async () => {
    message.loading({ content: 'Bağlamalar birləşdirilir', duration: null });
    const result = await combineDeclarationsRepo.execute({ ids: selection });
    message.destroy();

    if (result.status === 200) {
      history.push('/@next/declarations/create', { background: location, combined: { ids: selection, declaration: result.response } });
    } else {
      message.error(result.response);
    }
  }, [combineDeclarationsRepo, history, location, selection]);

  const exportAsExcel = useCallback(async () => {
    message.loading({ key: 1, content: 'Sənəd hazırlanır...', duration: null });

    const result = await getDeclarationsExcelRepo.execute({ query: tableFilterQueryMaker(state.filters) });

    if (result.status === 200) {
      message.success({ key: 1, content: 'Sənəd yüklənir' });
      const blob = result.response;
      const objectURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = objectURL;
      a.download = `declarations_export_${Math.round(Math.random() * 1000)}.xls`;
      a.click();
    } else {
      message.error({ key: 1, content: result.response });
    }
  }, [getDeclarationsExcelRepo, state.filters]);

  const remove = useCallback(() => {
    const onOk = async () => {
      const result = await removeDeclarationsRepo.execute(selection);

      if (result.status === 200) {
        fetch();
      } else {
        message.error(result.response);
      }
    };

    Modal.confirm({ title: 'Diqqət', content: 'Bağlamaları silməyə əminsinizmi?', onOk });
  }, [fetch, removeDeclarationsRepo, selection]);

  const printProforma = useCallback(() => {
    return printProformaFactory({ declarationIds: selection });
  }, [printProformaFactory, selection]);

  const create = useCallback(() => {
    history.push('/@next/declarations/create', { background: location });
  }, [history, location]);

  const handover = useCallback(() => {
    history.push(pathMaker('/@next/declarations/:id/handover', { id: selection.join('/') }), { background: location });
  }, [history, location, selection]);

  const bulkHandover = useCallback(() => {
    history.push(pathMaker('/@next/declarations/bulk-handover'), { background: location });
  }, [history, location]);

  const openAcceptance = useCallback(() => {
    history.push(pathMaker('/@next/declarations/acceptance'));
  }, [history]);

  const openBoxAcceptance = useCallback(() => {
    history.push(pathMaker('/@next/declarations/acceptance/box'));
  }, [history]);

  const openCountsByStatus = useCallback(() => {
    history.push(pathMaker('/@next/statistics/status/declaration-counts'), { background: location });
  }, [history, location]);

  return {
    totalPrice,
    selection,
    resetSelection,
    selectAll,
    fetch,
    reset,
    bulkUpdateStatus,
    updateStatus,
    combine,
    create,
    handover,
    exportAsExcel,
    bulkHandover,
    remove,
    printProforma,
    openAcceptance,
    openCountsByStatus,
    openBoxAcceptance,
  };
};
