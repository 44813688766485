import { useMemo, useCallback, useState } from 'react';
import { Column } from 'react-table';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import * as Icons from '@ant-design/icons';

import { StopPropagation } from '@core/ui';
import { tableColumns } from '@core/table';

import { planQueryKeys } from '../../utils';
import { IPlan } from '../../interfaces';

export const usePlanTableColumns = () => {
  const history = useHistory();
  const location = useLocation();

  const actionsColumn = useMemo<Column<IPlan>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openEdit = useCallback(() => {
          history.push(generatePath('/@next/plans/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
              Düzəliş et
            </Menu.Item>
          </Menu>
        );

        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [history, location],
  );
  return useMemo<Column<IPlan>[]>(
    () => [
      actionsColumn,
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: planQueryKeys.id,
        Header: 'Kod',
      },
      {
        accessor: (row) => row.weight.from,
        id: planQueryKeys.weightFrom,
        Header: 'Minimal çəki',
      },
      {
        accessor: (row) => row.weight.to,
        id: planQueryKeys.weightTo,
        Header: 'Maksimal çəki',
      },
      {
        accessor: (row) => row.price,
        id: planQueryKeys.price,
        Header: 'Qiymət',
      },
      {
        accessor: (row) => row.type,
        id: planQueryKeys.type,
        Header: 'Tip',
      },
      {
        accessor: (row) => row.tariffCategory.name,
        id: planQueryKeys.tariffCategoryName,
        Header: 'Tarif',
      },
      {
        accessor: (row) => row.description,
        id: planQueryKeys.description,
        Header: 'Açıqlama',
      },
    ],
    [actionsColumn],
  );
};
