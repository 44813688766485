import React, { useCallback, useMemo } from 'react';
import { actionsCol, dateCol, idCol } from '../../core/helpers/table';
import { makePath } from '../../core/utils/makePath';
import routes from '../../constants/routes';
import { NavLink, useHistory } from 'react-router-dom';
import { stopPropagation } from '../../core/helpers/events';
import { Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { UserCell } from '@modules/users';

export const useLogsTable = () => {
  const history = useHistory();

  const columns = useMemo(
    () => [
      {
        ...actionsCol,
        Cell: ({ row: { original } }) => {
          const goToInfo = useCallback(
            (e) => {
              e.stopPropagation();
              history.push(makePath(routes.logs.info, { id: original.id }));
            },
            [original.id],
          );

          return (
            <Button
              disabled={original.action === 'payment' || original.action === 'delete' || original.action === 'return'}
              className='next-table-action-btn'
              type='text'
              onClick={goToInfo}
              icon={<InfoCircleOutlined />}
            />
          );
        },
      },
      {
        ...idCol,
      },
      {
        accessor: 'user_name',
        Header: 'İcraçı',
        width: 200,
        Cell: ({ row: { original } }) => <UserCell id={original.user_id} title={original.user_name} />,
      },
      {
        accessor: 'model_name',
        Header: 'Model',
      },
      {
        accessor: 'action',
        Header: 'Əməliyyat',
      },
      {
        ...idCol,
        accessor: 'object_id',
        Header: 'Obyekt',
        Cell: ({ cell: { value }, row: { original } }) => {
          const findUrl = useCallback(() => {
            switch (original.model_id) {
              case 1:
                return makePath('/@next/orders/:id', { id: original.object_id });
              case 2:
                return makePath('/@next/declarations/:id', { id: original.object_id });
              case 3:
                return makePath('/@next/couriers/:id', { id: original.object_id });
              default:
                return null;
            }
          }, [original.object_id, original.model_id]);

          const foundUrl = findUrl();

          return <span onClick={stopPropagation}>{foundUrl ? <NavLink to={foundUrl}>{value}</NavLink> : <span>{value}</span>}</span>;
        },
      },
      {
        accessor: 'created_at',
        Header: 'Əməliyyat tarixi',
        ...dateCol,
      },
    ],
    [history],
  );

  return { columns };
};
