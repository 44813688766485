import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export const useSearchParams = <T = Record<string, string>>() => {
  const remove = useRef<(name: string) => void>(() => {});
  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo<T>(() => {
    const searchParams = new URLSearchParams(location.search);

    return (Object.fromEntries(searchParams.entries()) as unknown) as any;
  }, [location.search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    remove.current = (name: string) => {
      searchParams.delete(name);
      history.push({ pathname: '', search: searchParams.toString() });
    };
  }, [history, location.search]);

  return { searchParams, remove };
};
