import { useMemo } from 'react';
import { Gutter } from 'antd/lib/grid/row';

export const useFormLayout = () => {
  return useMemo(
    () => ({
      form: { layout: 'vertical' as 'vertical' },
      row: { gutter: [16, 0] as [Gutter, Gutter] },
      col: new Array(25).fill(null).map((_, index) => {
        return { span: 24, sm: index !== 0 ? { span: index } : undefined };
      }),
    }),
    [],
  );
};
