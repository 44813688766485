import React, { FC, useContext } from 'react';
import { Formik, FormikProps } from 'formik';
import { Col, Modal, Row } from 'antd';
import { AntForm } from '@core/ui';
import { FormAlert, TextField } from '@core/form';

import { CreateBoxContext } from '../contexts';
import { useCreateBox } from '../hooks';
import { CreateBoxDto } from '../dtos';

const FormikComponent: FC<FormikProps<CreateBoxDto>> = ({ status, handleSubmit, isSubmitting }) => {
  const { id, onCancel } = useContext(CreateBoxContext);

  return (
    <Modal width={768} visible={true} onOk={() => handleSubmit()} onCancel={onCancel} confirmLoading={isSubmitting} title={!id ? 'Yeşik əlavə et' : 'Yeşikdə düzəliş et'}>
      <FormAlert $visible={!!status} message={status?.message} />
      <AntForm layout='vertical' component='div' size='large'>
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24}>
            <TextField name='name' item={{ label: 'Ad' }} input={{ placeholder: 'Yeşiyin adını daxil edin...' }} />
          </Col>
        </Row>
      </AntForm>
    </Modal>
  );
};

export const CreateBox: FC = () => {
  const { initialValues, onSubmit } = useCreateBox();

  if (!initialValues) {
    return null;
  }

  return <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} component={FormikComponent} />;
};
