import { createSelector } from 'reselect';

import { ITableReducer, ITableState } from '../interfaces/table/model.interface';
import { IAppState } from '../interfaces/app-state.interface';
import { deepMerge } from '../core/utils/deep-merge';
import { initialTableState } from '../reducers/table';

export const tableSelector = (name: string) => {
  return createSelector<IAppState, ITableReducer | undefined, ITableState>(
    (state) => state.table,
    (table) => deepMerge(initialTableState, table?.[name]) as ITableState,
  );
};

export const tableSelectionSelector = (name: string) => {
  return createSelector<IAppState, ITableReducer | undefined, Record<any, any>>(
    (state) => state.table,
    (table) => {
      return table?.[name]?.selectedRowIds || {};
    },
  );
};
