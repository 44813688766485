export const positiveIntNormalizer = (value: any, prevValue: any) => {
  if (/^(\s*|\d+)$/.test(value)) {
    return value;
  } else {
    return prevValue;
  }
};

export const positiveFloatNormalizer = (value: any, prevValue: any) => {
  if (/^(?:\d{0,10}\.\d{0,2})$|^\d{0,10}$/.test(value)) {
    return value;
  } else {
    return prevValue;
  }
};
