import React, { useCallback, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { message, Switch } from 'antd';
import moment from 'moment';

import ReadyTable from '../../components/Table/ReadyTable';
import { dateCol, idCol, largeCol, normalCol, smallCol } from '../../core/helpers/table';
import { OverflowCell } from '../../components/Table/core/Components/Cell/OverflowCell';
import { stopPropagation } from '../../core/helpers/events';
import { changeActive } from '../../actions/emailArchive';

const EmailArchive = () => {
  const columns = useMemo<Column<any>[]>(
    () => [
      {
        ...idCol,
      },
      {
        accessor: 'email',
        Header: 'E-Mail',
        ...largeCol,
        Cell: ({ cell: { value } }) => <div className='overflow-cell'>{value}</div>,
      },
      {
        accessor: 'active',
        Header: 'Aktivlik',
        sortable: false,
        filterable: false,
        ...smallCol,
        Cell: ({ cell: { value }, row: { original } }) => {
          const [loading, setLoading] = useState(false);
          const [active, setActive] = useState(!!value);
          const disabled = useMemo(() => moment(original.sended_at, 'YYYY-MM-DD HH:mm:ss').diff(moment()) < 0, [original.sended_at]);

          const handleChangeActive = useCallback(
            async (checked) => {
              try {
                setLoading(true);
                await changeActive({ id: original.id, active: checked });
                setActive(checked);
              } catch (e) {
                message.error('Email-in aktivliyi dəyişdirilə bilmədi.');
              } finally {
                setLoading(false);
              }
            },
            [original.id],
          );

          return (
            <div className='flex-center' onClick={stopPropagation}>
              <Switch disabled={disabled} checked={active} loading={loading} onChange={handleChangeActive} />
            </div>
          );
        },
      },
      {
        accessor: 'model_name',
        Header: 'Bölmə',
        ...normalCol,
      },
      {
        accessor: 'retry_at',
        Header: 'Cəhd tarixi',
        ...dateCol,
      },
      {
        accessor: 'sended_at',
        Header: 'Göndərilmə tarixi',
        ...dateCol,
      },
      {
        accessor: 'created_at',
        Header: 'Yaradılıb',
        ...dateCol,
      },
      {
        Header: 'Məzmun',
        accessor: 'body',
        Cell: OverflowCell,
      },
    ],
    [],
  );

  return <ReadyTable name='archive.email' apiUrl='/api/admin/archive/mail' columns={columns} />;
};

export default React.memo(EmailArchive);
