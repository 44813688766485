import React, { FC } from 'react';
import { Tag } from 'antd';

export const TrackCodeCell: FC<any> = ({
  cell: { value },
  row: {
    original: { is_new },
  },
}) => {
  return value ? <Tag color={!is_new ? 'green' : undefined}>#{value}</Tag> : null;
};
