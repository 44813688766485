import { TicketMappers } from '../mappers';
import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { Result } from '../../../shared/utils/result';
import { TicketsCounts } from '../types';

export class TicketService {
  public static async getCounts(filters: any[]): Promise<Result<200, TicketsCounts> | Result<400 | 500, string>> {
    try {
      const query = TicketMappers.countsQueryToApi(filters);
      const url = urlMaker('/api/admin/tickets/stats', query);

      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();

        return new Result(200, TicketMappers.countsFromApi(data), null);
      } else {
        return new Result(400, 'Məlumatlar əldə edilə bilmədi.', null);
      }
    } catch (e) {
      return new Result(500, 'Sorğu göndərilə bilmədi. İnternet bağlantınızı yoxlayın.', null);
    }
  }
}
