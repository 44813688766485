import moment from 'moment';
import { Constants } from '@core/constants';
import { FormErrors } from '@core/form';

import { CreateCashFlowTransactionDto, CreateCashFlowTransactionDtoPersistence, CreateCashFlowTransferTransactionDtoPersistence } from '../dtos';
import { ICashFlowTransaction } from '../interfaces';

export class CreateCashFlowTransactionDtoMapper {
  public static toDomain(item: ICashFlowTransaction): CreateCashFlowTransactionDto {
    return {
      id: item.id.toString(),
      type: item.isTransfer ? 'transfer' : item.type,
      target: {
        cashRegisterId: item.target?.cashRegister.id.toString() || '',
        amount: item.target?.amount.toString() || '',
      },
      amount: item.amount.toString(),
      cashRegisterId: item.cashRegister.id.toString(),
      operationIds: [item.operation.id.toString(), item.operation.child.id.toString()],
      operatedAt: moment(item.operatedAt, Constants.DATE_TIME),
      description: item.description || '',
    };
  }

  public static toPersistence(dto: CreateCashFlowTransactionDto): CreateCashFlowTransactionDtoPersistence | CreateCashFlowTransferTransactionDtoPersistence {
    if (dto.type !== 'transfer') {
      return {
        cashbox_operation_id: dto.id,
        operation: dto.type === 'income' ? '1' : '2',
        cashbox_id: dto.cashRegisterId,
        amount: dto.amount,
        category_id: !!dto.operationIds.length ? dto.operationIds[dto.operationIds.length - 1] : '',
        operation_date: dto.operatedAt.format(Constants.DATE_TIME),
        descr: dto.description,
      };
    }

    return {
      cashbox_operation_id: dto.id,
      operation: '1',
      cashbox_id: dto.cashRegisterId,
      amount: dto.amount,
      transfer_cashbox_id: dto.target.cashRegisterId,
      transfer_amount: dto.target.amount,
      category_id: !!dto.operationIds.length ? dto.operationIds[dto.operationIds.length - 1] : '',
      operation_date: dto.operatedAt.format(Constants.DATE_TIME),
      descr: dto.description,
    };
  }
}

export class CreateCashFlowTransactionErrorMapper {
  public static toDomain(errors, type: 'income' | 'expense' | 'transfer'): FormErrors<CreateCashFlowTransactionDto> {
    return {
      id: errors.cashbox_operation_id?.join('. '),
      type: errors.operation?.join('. '),
      target:
        type === 'transfer'
          ? {
              cashRegisterId: errors.transfer_cashbox_id?.join('. '),
              amount: errors.transfer_amount?.join('. '),
            }
          : undefined,
      operationIds: errors.category_id?.join('. '),
      amount: type !== 'transfer' ? errors.amount?.join('. ') : undefined,
      cashRegisterId: type !== 'transfer' ? errors.cashbox_id?.join('. ') : undefined,
      operatedAt: errors.operation_date?.join('. '),
      description: errors.descr?.join('. '),
    };
  }
}
