import { useMemo } from 'react';
import { RowProps } from 'antd/lib/row';
import { ColProps } from 'antd/lib/col';

export const useFormLayout = () => {
  const baseRowProps = useMemo<RowProps>(() => ({ gutter: [16, 0] }), []);
  const baseColProps = useMemo<ColProps>(() => ({ span: 24 }), []);

  const halfColProps = useMemo<ColProps>(() => ({ span: 24, sm: { span: 12 } }), []);

  return useMemo(() => ({ baseRowProps, baseColProps, halfColProps }), [baseRowProps, baseColProps, halfColProps]);
};
