import React, { FC, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Card, Col, Empty, Modal, Result, Row, Tabs } from 'antd';
import ContentCard from '../containers/Layout/Sidebar/Content/Card';
import { CheckOutlined, CloseOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import routes from '../constants/routes';
import { activateBannersAction, bannersFetchAction, removeBannersAction } from '../actions/banners';
import { useDispatch, useSelector } from 'react-redux';
import { baseModelSelector } from '../selectors/common';
import { ReloadOutlined } from '@ant-design/icons/lib';
import { useTitle } from '../hooks/common/useTitle';
import { IBannersState } from '../interfaces/banners/state';

const { TabPane } = Tabs;
const { Meta } = Card;

const BannersPage: FC<RouteComponentProps> = ({ history }) => {
  useTitle(`${process.env.REACT_APP_COMPANY_NAME} - Xəbərlər`);

  const dispatch = useDispatch();
  const { loading, data, error } = useSelector(baseModelSelector<IBannersState>('banners'));

  const gotoCreate = useCallback(() => {
    history.push(routes.banners.create);
  }, [history]);

  const handleFetch = useCallback(() => {
    dispatch(bannersFetchAction());
  }, [dispatch]);

  const handleActivate = useCallback(
    (id: number, active: boolean) => {
      activateBannersAction([id], active)(dispatch).catch(() => null);
    },
    [dispatch],
  );

  const handleDelete = useCallback(
    (id) => {
      const action = () => removeBannersAction([id])(dispatch).catch(() => null);

      Modal.confirm({
        title: 'Banneri silməyə əminsinizmi?',
        content: 'Silməni təsdiqlədikdən sonra bannerin geri qaytarılması mümkün olmayacaq.',
        onOk: action,
      });
    },
    [dispatch],
  );

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  return (
    <ContentCard className='shops-page content-card-scrollable'>
      {loading && (
        <Row gutter={[16, 16]}>
          {new Array(20).fill(null).map((_, index) => {
            return (
              <Col key={index} span={6}>
                <Card cover={<div style={{ height: 203, width: '100%' }} />} loading={true} actions={[<CheckOutlined key='activate-action' />, <DeleteOutlined key='delete-action' />]} />
              </Col>
            );
          })}
        </Row>
      )}
      {!loading && !error && (
        <Tabs
          className='shops-page-tab'
          tabBarExtraContent={
            <Button type='primary' onClick={gotoCreate} icon={<PlusCircleOutlined />}>
              Yeni
            </Button>
          }
        >
          <TabPane tab='Bannerlər'>
            <Row gutter={[16, 16]}>
              {!data.length && (
                <div className='absolute-empty'>
                  <Empty description={false} />
                </div>
              )}
              {data.map((banner) => {
                return (
                  <Col key={banner.id} span={6}>
                    <Card
                      className='shops-page-card'
                      actions={[
                        !banner.active ? (
                          <CheckOutlined key='activate-action' onClick={() => handleActivate(banner.id, true)} />
                        ) : (
                          <CloseOutlined key='deactivate-action' onClick={() => handleActivate(banner.id, false)} />
                        ),
                        <DeleteOutlined onClick={() => handleDelete(banner.id)} key='delete-action' />,
                      ]}
                      hoverable
                      cover={<img alt={banner.name} src={banner.document_file} />}
                    >
                      <Meta
                        title={banner.name}
                        description={
                          <>
                            {banner.type === 1 && 'Ana səhifə (Masaüstü)'}
                            {banner.type === 2 && 'Ana səhifə (Mobil)'}
                            {banner.type === 3 && 'Xəbərlər'}
                            {banner.type === 4 && 'Diqqət'}
                            {banner.type === 5 && 'Xidmətlər'}
                            {banner.type === 6 && 'Qeydiyyat'}
                          </>
                        }
                      />
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </TabPane>
        </Tabs>
      )}
      {!loading && error && (
        <Result
          className='absolute-result'
          extra={
            <Button onClick={handleFetch} type='primary' icon={<ReloadOutlined />}>
              Yenilə
            </Button>
          }
          title='Məlumatlar əldə edilə bilmədi'
          status='warning'
        />
      )}
    </ContentCard>
  );
};

export default React.memo(BannersPage);
