import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export const RichTextField = React.memo(({ input, item, meta: { touched, error, warning } }) => {
  const { onChange } = input;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [focused, setFocused] = useState(false);

  const errorText = useMemo(() => touched && error, [touched, error]);
  const warningText = useMemo(() => touched && warning, [touched, warning]);
  const validateStatus = useMemo(() => {
    if (errorText) {
      return 'error';
    } else if (warningText) {
      return 'warning';
    }
  }, [errorText, warningText]);

  const onEditorStateChange = useCallback((editorState) => {
    setEditorState(editorState);
  }, []);

  const onBlur = useCallback(
    (event, editorState) => {
      onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      setFocused(false);
    },
    [onChange],
  );

  const toolbar = useMemo(
    () => ({
      image: {
        previewImage: true,
        uploadCallback: (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve({ data: { link: reader.result } });
            reader.onerror = (error) => reject(error);
          }),
      },
    }),
    [],
  );

  useEffect(() => {
    if (!focused) {
      const html = input.value;
      const contentBlock = htmlToDraft(html);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, undefined);
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }, [focused, input.value]);

  return (
    <Form.Item {...item} validateStatus={validateStatus} help={errorText || warningText}>
      <Editor editorState={editorState} onFocus={() => setFocused(true)} onBlur={onBlur} onEditorStateChange={onEditorStateChange} toolbar={toolbar} />
    </Form.Item>
  );
});
