import { useMemo } from 'react';

import { InputField } from '../../../components/ReduxForm/Input';
import { TextAreaField } from '../../../components/ReduxForm/TextArea';
import { SelectField } from '../../../components/ReduxForm/Select';
import { urlFactory } from '../../../core/utils/caller';

export const useFormFields = () => {
  const nameFieldProps = useMemo(
    () => ({
      name: 'name',
      item: { label: 'Ad' },
      field: { placeholder: 'Statusun adını daxil edin' },
      component: InputField,
    }),
    [],
  );

  const modelFieldProps = useMemo(
    () => ({
      name: 'model_id',
      item: { label: 'Model' },
      field: { placeholder: 'Modeli seçin' },
      type: 'select',
      component: SelectField,
      apiUrl: urlFactory('/api/admin/models/getlist'),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => object.name,
      responseGetter: (object: any) => object.data,
    }),
    [],
  );

  const parentFieldProps = useMemo(
    () => ({
      name: 'parent_id',
      item: { label: 'Valideyn status' },
      field: { placeholder: 'Valideyn statusu seçin' },
      type: 'select',
      component: SelectField,
      apiUrl: urlFactory('/api/admin/states/getlist'),
      valueGetter: (object: any) => object.id,
      labelGetter: (object: any) => object.name,
      responseGetter: (object: any) => object.data,
    }),
    [],
  );

  const descriptionsFieldProps = useMemo(
    () => ({
      name: 'descr',
      item: { label: 'Açıqlama', style: { marginBottom: 0 } },
      field: { placeholder: 'Açıqlama daxil edin', rows: 3 },
      component: TextAreaField,
    }),
    [],
  );

  return useMemo(
    () => ({
      nameFieldProps,
      modelFieldProps,
      parentFieldProps,
      descriptionsFieldProps,
    }),
    [nameFieldProps, modelFieldProps, parentFieldProps, descriptionsFieldProps],
  );
};
