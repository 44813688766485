import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import { CustomsMappers } from '../mappers';
import { Result } from '../../../shared/utils/result';
import { CustomsPingResult } from '../types/ping';

export class CustomsPingService {
  public static async get(): Promise<Result<number, CustomsPingResult>> {
    const url = urlMaker('/api/warehouse/customs/ping');

    try {
      const response = await caller(url);

      if (response.ok) {
        const { data } = await response.json();
        const mappedData = CustomsMappers.Ping.fromApi(data);

        return new Result(200, mappedData, null);
      }
    } catch (e) {}

    return new Result(400, { status: 'warning' }, null);
  }
}
