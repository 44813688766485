import React, { FC, useMemo } from 'react';
import { Button, Modal, Spin } from 'antd';
import { useClose } from '../../hooks/common';
import routes from '../../constants/routes';
import { useInfo } from '../../hooks/common/useInfo';
import { urlFactory } from '../../core/utils/caller';
import { RouteComponentProps } from 'react-router-dom';

const LogsInfo: FC<RouteComponentProps<any>> = ({ match: { params } }) => {
  const handleClose = useClose(routes.logs.index);

  const { loading, data } = useInfo(
    useMemo(
      () => ({
        apiUrl: urlFactory('/api/admin/logs/info', { log_id: params.id }),
        responseGetter: (response) => response.data,
        initialState: null,
      }),
      [params.id],
    ),
  );

  return (
    <Modal
      title={`#${params.id} kodlu əməliyyatdakı dəyişikliklər`}
      footer={[
        <Button key='close-button' onClick={handleClose}>
          Bağla
        </Button>,
      ]}
      maskTransitionName=''
      transitionName=''
      width={768}
      visible={true}
      onCancel={handleClose}
    >
      <Spin spinning={loading}>
        <div>
          {data && (
            <table className='logs-table'>
              <tbody>
                {!!Object.keys(data.new_value).length
                  ? Object.keys(data.new_value).map((key) => (
                      <tr key={key}>
                        <th className='logs-table-field'>{key}</th>
                        <td className='logs-table-old-value'>{data.old_value[key]}</td>
                        <td>{data.new_value[key]}</td>
                      </tr>
                    ))
                  : Object.entries(data.old_value).map(([key, value]) => (
                      <tr key={key}>
                        <th className='logs-table-field'>{key}</th>
                        <td>{value as string}</td>
                      </tr>
                    ))}
              </tbody>
            </table>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default LogsInfo;
